<div class="px-10 textStyle" fluidRowWidth [type]="header.name" [style.pointerEvents]="!header.can_edit || data?.is_locked || data?.lock_fields?.includes(this.header?.name)?'none':'auto'">
  <div class="checkBoxStyle min-height-37 align-center d-flex" style="justify-content: center;">
    <label
    [disabled]="!header.can_edit"
      nz-checkbox
      [ngModel]="data[header.name]=='true'|| data[header.name]== true ?true:false"
      (ngModelChange)="getchec($event)"
    ></label>
  </div>
</div>

