import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { generateUUID } from 'projects/processo-custom-table/src/usecase';

@Component({
  selector: 'lib-priority',
  templateUrl: './priority.component.html',
  styleUrls: ['./priority.component.scss'],
})
export class PriorityComponent {
  @HostBinding('attr.unique-id')
  uniqueId = `${generateUUID()}`;
  @Input() header: any;
  @Input() innerTable: any;
  @Input() data: any;
  @Input() parent: any;
  priority: any = [
    {
      name: 'Urgent',
      color: 'rgb(227, 131, 136)',
    },
    {
      name: 'High',
      color: 'rgb(251, 203, 92)',
    },
    {
      name: 'Normal',
      color: 'rgb(135, 157, 255)',
    },
    {
      name: 'Low',
      color: 'rgb(101, 111, 125)',
    },
  ];
  editClicked = false;
  @Output() startDropDownEditEmitter: any = new EventEmitter<any>();
  onEditClick() {
    this.editClicked = true;
    this.startDropDownEditEmitter.emit();
  }
  handleDropdownClose(event: any) {
    if (!event) {
      this.editClicked = false;
    }
  }

  getColor(value: any) {
    let color = this.priority?.find((e: any) => e?.name === value)?.color;
    return color || '';
  }
  filterpriorityDefault(searchValue: any) {
    this.header.options = this.priority?.filter((e: any) =>
      e?.name?.toLowerCase()?.includes(searchValue?.toLowerCase())
    );
  }
  selectOption(name: string) {
    this.parent?.updateDataOnStopEdit(
      { target: { value: name } },
      this.header?.name,
      this.data,
      this.parent.erulistData
    );
  }
  removePriority() {
    this.parent?.updateDataOnStopEdit(
      { target: { value: '' } },
      this.header?.name,
      this.data,
      this.parent.erulistData
    );
  }
  clearInput: string = 'clear';

  close = false;
  private timeoutRef: any;
  event(e: any) {
    if (e) {
      this.timeoutRef = setTimeout(() => {
        this.close = true;
      }, 100);
    } else {
      this.close = false;
    }
  }
  ngOnDestroy(): void {
    if (this.timeoutRef) {
      clearTimeout(this.timeoutRef);
    }
  }
}
