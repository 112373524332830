import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  inject,
} from '@angular/core';
import { AddNewTabComponent } from '../popup/add-new-tab/add-new-tab.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMarks } from 'ng-zorro-antd/slider';
import { AddStatusesPopupComponent } from '../popup/add-statuses-popup/add-statuses-popup.component';
import { BehaviorSubject, Observable, filter, map, of, tap } from 'rxjs';
import { ProcessoCustomTableService } from '../processo-custom-table.service';
import { AddSelectOptionPopupComponent } from '../popup/add-select-option-popup/add-select-option-popup.component';
import { AddTagPopupComponent } from '../popup/add-tag-popup/add-tag-popup.component';
import * as moment from 'moment';
import { NzSelectComponent } from 'ng-zorro-antd/select';
import { generateUUID } from '../../usecase';

@Component({
  selector: 'lib-add-new-field',
  templateUrl: './add-new-field.component.html',
  styleUrls: ['./add-new-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddNewFieldComponent implements OnInit {
  // Inject services
  private processoService = inject(ProcessoCustomTableService);
  private modalService = inject(NzModalService);
  private cdr = inject(ChangeDetectorRef);
  // userService = inject(UserService);

  // @Input/@output decorators
  @Input()
  selectedMenu: any;
  @Input() stateService: any;
  @Input() selectedTabName: any;
  @Input() isEdit: any;
  @Input() isVisited: any;
  @Input() erulistData: any;
  @Input() tabName: any;
  @Input() selectedMenuData: any;
  @Input() newColumnValue: any;
  @Input() select_api_options: any = [];
  @Input() api_field_options: any = [];
  @Output() updateFields: any = new EventEmitter<any>();
  @Output() addSaveColumns: EventEmitter<any> = new EventEmitter();
  @Output() editSaveColumns: EventEmitter<any> = new EventEmitter();
  @Output() UpdateTabs: EventEmitter<any> = new EventEmitter();
  @Output() selectAPI: EventEmitter<any> = new EventEmitter();
  Object = Object;
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() delTab: EventEmitter<any> = new EventEmitter();
  @Output() entitySingleSelect: EventEmitter<any> = new EventEmitter();
  @Input() getEntityptionList$!: Observable<any>;
  @Input() CurrencyLists: any;
  // Observables
  getCurrencyList$ = new Observable<any>();
  allFields: any = [];
  save_api_options: any;

  // Variables
  showDependentField = false;
  entities: any[] = [];
  entityFields: any = [];
  public form: any = {
    option_type: false,
    parent_entity: '',
    parent_field: '',
  };
  public formData: any = {
    data_type: {
      value: '',
      type: 'dropdown',
      validators: {
        required: true,
        inputValidators: [],
      },
    },

    fieldForm: {},
    isMandatory: false,
    is_hidden: false,
    showGrid: false,
    tab_name: {
      value: '',
      validators: {
        required: true,
        inputValidators: [],
      },
      placeholder: 'Tab Name',
    },
    can_group: false,
    can_edit:true,
    default_group: false,

    tooltip: {
      type: 'text',
      value: '',
      validators: {
        required: false,
        inputValidators: [],
      },
      error: {},
    },
    default_value: '',
    is_pii: false,
    to_encrypt: false,
    is_ephi: false,
    fieldName: {
      placeholder: 'Field Name',
      value: '',
      validators: {
        required: true,
        inputValidators: ['^[A-Za-z0-9s]/g'],
        error: '',
      },
      type: 'text',
    },
    fieldLabel: {
      placeholder: 'Field Label',
      value: '',
      validators: {
        required: true,
        inputValidators: [],
        error: '',
      },
      type: 'text',
    },
    cef: '',
    ce: '',
    dfc: false,
    editable: false,
    cefa: 'min',
  };
  closeDrawer() {
    this.close.emit('close');
  }
  parentEntityFields: boolean = false;
  fieldEntity: boolean = false;
  savedEntities: any;
  @Output() getCurrencyLists = new EventEmitter<any>();

  ngOnInit(): void {
    this.entities = this.erulistData.entities;
    this.savedEntities = this.entities;
    this.allFields = this.entities
      .find((item: any) => item.name == this.tabName)
      ?.fields?.map((item: any) => item.name);
    this.selectedDataType = this.selectedMenuData;
    let newColumnName = this.selectedMenuData?.label;
    this.setFormFieldValue(this.selectedMenuData?.datatype);
    // this.getCurrencyList$ = this.CurrencyLists;
    this.getEntity();
    if (
      this.formData.data_type.value === 'dropdown_single_select' ||
      this.formData.data_type.value === 'dropdown_multi_select'
    ) {
      this.getSelectedTabField();
      this.showDefaultdependency();
    }

    this.savenewColumnValue = this.newColumnValue;
    this.getField();
  }
  tabFieldsEntity: any;
  entityData: any;
  getCurrencyList() {
    this.getCurrencyLists.emit(true);
  }
  getEntity() {
    let tabFields = this.entities
      .find((item: any) => item?.name == this.tabName)
      ?.fields?.filter((e: any) => e?.datatype === 'entity');
    this.entityData = tabFields?.sort((a: any, b: any) =>
      a.name.localeCompare(b.name)
    );
    this.tabFieldsEntity = this.entityData;
  }

  optionTypeEntityData: any;
  saveoptionTypeEntityData: any;
  getField() {
    let fields = this.entities.find(
      (item: any) => item?.name == this.tabName
    )?.fields;
    let entityDataOption = fields?.filter(
      (e: any) => e.option_type === 'ENTITY_DATA'
    );
    this.optionTypeEntityData = entityDataOption;
    this.saveoptionTypeEntityData = this.optionTypeEntityData;
  }
  tabFields: any;
  savetabFields: any;
  selectEntityType(event: any) {
    this.getEntityField();
    this.formData.cef = '';
  }
  getEntityField() {
    let fields = this.entities
      .find((item: any) => item?.name == this.formData.ce)
      ?.fields?.filter(
        (e: any) => e?.datatype === this.formData?.data_type?.value
      );
    this.savetabFields = fields?.sort((a: any, b: any) =>
      a.name.localeCompare(b.name)
    );
    this.tabFields = this.savetabFields;
  }

  saveFields: any;
  showdfcEntityError: boolean = false;
  showdfcFieldError: boolean = false;
  getEntityFields(): any {
    if (this.formData.fieldForm.entity_name != '') {
      this.entityFields = this.entities
        .find(
          (item: any) => item.name === this.formData?.fieldForm?.entity_name
        )
        ?.fields?.filter((e: any) => e?.datatype !== 'entity')
        .sort((a: any, b: any) =>
          a.label.toLowerCase().localeCompare(b.label.toLowerCase())
        );
      this.saveFields = this.entityFields;
      return this.entityFields;
    }
  }
  fieldData: any;
  storeFieldData: any;
  dependencyFieldData: any;
  selectedField() {
    if (this.formData.fieldForm.field_name === this.formData.fieldForm.def) {
      this.formData.fieldForm.def = '';
    }
    this.entityFields = this.saveFields;
  }
  showDefaultdependency() {
    this.dependencyFieldData = this.entities
      .slice()
      .sort((a: any, b: any) =>
        a.display_name.toLowerCase().localeCompare(b.display_name.toLowerCase())
      );
  }
  getSelectedTabField() {
    let selectedFields = this.entities
      .find((e: any) => e.name === this.tabName)
      ?.fields?.filter((e: any) => e?.datatype !== 'entity')
      .sort((a: any, b: any) => a.label.localeCompare(b.label));
    this.fieldData = selectedFields;
    this.storeFieldData = selectedFields;
  }
  filterFieldData(input: any) {
    this.fieldData = this.storeFieldData.filter((e: any) =>
      e.label.toLowerCase().includes(input.toLowerCase())
    );
  }
  filterDependencyField(input: any) {
    this.dependencyFieldData = this.entities
      .filter((e: any) => e.label.toLowerCase().includes(input.toLowerCase()))
      .sort((a: any, b: any) => a.label.localeCompare(b.label));
  }
  // getTabNames() {
  //   return of(
  //     this.erulistData.entities[
  //       this.erulistData.entities.findIndex((e: any) => e.name === this.tabName)
  //     ].tabs
  //   );
  // }
  getTabNames() {
    let tabIndex = this.erulistData.entities.findIndex(
      (e: any) => e.name === this.tabName
    );
    let tabOption = this.erulistData.entities[tabIndex]?.tabs || [];

    if (tabOption.length === 0) {
      this.formData.tab_name.value = '';
    }

    return of(tabOption);
  }

  showGridChange(event: any) {
    this.formData.showGrid = event;
  }
  setFormFieldValue(keyName: any) {
    if (this.isEdit) {
      if (this.selectedMenuData?.option_type === 'ENTITY_DATA') {
        if (
          (this.selectedMenuData?.datatype === 'dropdown_single_select' ||
            this.selectedMenuData?.datatype === 'dropdown_multi_select') &&
          this.selectedMenuData?.def !== '' &&
          this.selectedMenuData?.dpef !== ''
        ) {
          this.showDependentField = true;
        } else {
          this.showDependentField = false;
        }
      }

      if (this.selectedMenuData?.option_type === 'API') {
        if (
          (this.selectedMenuData?.datatype === 'dropdown_single_select' ||
            this.selectedMenuData?.datatype === 'dropdown_multi_select') &&
          this.selectedMenuData?.def !== '' &&
          this.selectedMenuData?.def
        ) {
          this.showDependentField = true;
        } else {
          this.showDependentField = false;
        }
      }
      if (this.selectedMenuData.datatype === 'currency') {
        this.getCurrencyList();
      }
      this.formData.data_type.value = this.selectedMenuData.datatype;

      // this.formData.fieldForm.optionType =
      this.getFieldForm(this.selectedMenuData.datatype);
      Object.keys(this.selectedMenuData).forEach((a: any) => {
        let e = this.getCorrectkey(a);
        if (Object.keys(this.formData).includes(e)) {
          if (e !== 'fieldForm') {
            if (
              ['string', 'boolean', 'number'].includes(typeof this.formData[e])
            ) {
              this.formData[e] = this.selectedMenuData[a];
            } else {
              if (!Array.isArray(this.formData[e])) {
                this.formData[e].value = this.selectedMenuData[a];
              } else {
                this.formData[e] = this.selectedMenuData[a];
              }
            }
          }
        } else {
          if (Object.keys(this.formData.fieldForm).includes(e)) {
            if (
              ['string', 'boolean', 'number'].includes(
                typeof this.formData.fieldForm[e]
              )
            ) {
              this.formData.fieldForm[e] = this.selectedMenuData[a];
              if (this.selectedMenuData[a] == 'API') {
                this.selectAPI.emit({ action: true });
                this.selectAPI.emit({
                  action: true,
                  data: this.formData.fieldForm.api_name,
                });
              }
            } else {
              if (!Array.isArray(this.formData.fieldForm[e])) {
                this.formData.fieldForm[e].value = this.selectedMenuData[a];
              } else {
                if (
                  [
                    'options',
                    'open_status',
                    'close_status',
                    'optionType',
                  ].includes(e)
                ) {
                  this.formData.fieldForm[e] = this.selectedMenuData[a];
                } else {
                  if (e == 'AllowDays') {
                    this.formData.fieldForm[e] = this.formData.fieldForm[e].map(
                      (day: any) => {
                        if (this.selectedMenuData[a].includes(day.value)) {
                          return { ...day, checked: true };
                        }
                        return day;
                      }
                    );
                  }
                }
              }
            }
          } else {
          }
        }
      });

      if (this.selectedMenuData.datatype === 'status') {
        this.filterStatusDefault('');
      }
      if (this.selectedMenuData?.datatype === 'date') {
        this.formData.fieldForm.dateFormat.value =
          this.selectedMenuData.date_format;
      }
      if (
        (this.selectedMenuData.datatype === 'dropdown_single_select' ||
          this.selectedMenuData.datatype === 'dropdown_multi_select') &&
        this.selectedMenuData?.option_type === 'ENTITY_DATA'
      ) {
        this.getEntityFields();
      }
    } else {
      this.formData.data_type.value = keyName;
      this.getFieldForm(keyName);
    }
    if (this.selectedMenuData?.ce !== '') {
      this.getEntityField();
    }
  }
  derivedFromChild(e: any) {
    if (this.formData.data_type.value === 'status') {
      if (e) {
        this.addStatusOption();
      } else {
        this.removeStatusOption();
      }
    }
  }
  addStatusOption() {
    const isOpenExists = this.formData?.fieldForm?.open_status?.some(
      (item: any) => item.name === 'Open'
    );
    const isCloseExists = this.formData?.fieldForm?.close_status?.some(
      (item: any) => item.name === 'Close'
    );
    if (this.formData.dfc) {
      if (!isOpenExists) {
        this.formData?.fieldForm?.open_status?.push({
          name: 'Open',
          color: '',
          df: true,
        });
      }
      if (!isCloseExists) {
        this.formData?.fieldForm?.close_status.push({
          name: 'Close',
          color: '',
          df: true,
        });
      }
    }
  }
  removeStatusOption() {
    this.formData.fieldForm.open_status =
      this.formData.fieldForm.open_status.filter(
        (item: any) => item.name !== 'Open'
      );
    this.formData.fieldForm.close_status =
      this.formData.fieldForm.close_status.filter(
        (item: any) => item.name !== 'Close'
      );
    this.cdr.detectChanges();
  }
  getCorrectkey(key: any) {
    switch (key) {
      case 'can_edit':
        return 'can_edit';
      case 'open_status':
        return 'open_status';
      case 'close_status':
        return 'close_status';
      case 'api_name':
        return 'api_name';
      case 'can_group':
        return 'can_group';
      case 'rich_text':
        return 'rich_text';
      case 'datatype':
        return 'data_type';
      case 'default':
        return 'default_value';
      case 'default_group':
        return 'default_group';
      case 'entity_name':
        return 'entity_name';
      case 'field_name':
        return 'field_name';
      case 'emoji_value':
        return 'emojiValue';
      case 'field_type':
        return 'field_type';
      case 'grid_index':
        return 'grid_index';
      case 'label':
        return 'fieldLabel';
      case 'mandatory':
        return 'isMandatory';
      case 'map_roles':
        return 'map_roles';
      case 'map_users':
        return 'map_roles';
      case 'name':
        return 'fieldName';
      case 'option_type':
        return 'optionType';
      case 'options':
        return 'options';
      case 'show_grid':
        return 'showGrid';
      case 'tab_name':
        return 'tab_name';
      case 'is_ephi':
        return 'is_ephi';
      case 'is_pii':
        return 'is_pii';
      case 'to_encrypt':
        return 'to_encrypt';
      case 'tool_tip':
        return 'tooltip';
      case 'end_value':
        return 'number';
      case 'data_length_check':
        return 'dataLengthCheck';
      case 'num_val_check':
        return 'dataLengthCheck';
      case 'data_length':
        return 'dataLength';
      case 'num_val':
        return 'dataLength';
      case 'options':
        return 'options';
      case 'symbol':
        return 'Symbol';
      case 'seperator':
        return 'Seperator';
      case 'decimal':
        return 'Decimal';
      case 'endValue':
        return 'endValue';
      case 'startValue':
        return 'startValue';
      case 'allow_days':
        return 'AllowDays';
      case 'default':
        return 'default';
      case 'api_field':
        return 'api_field';
      case 'tf_idx':
        return 'tf_idx';
      case 'dfc':
        return 'dfc';
      case 'ce':
        return 'ce';
      case 'cef':
        return 'cef';
      case 'dpef':
        return 'dpef';
      case 'def':
        return 'def';
      case 'is_hidden':
        return 'is_hidden';
      case 'editable':
        return 'editable';
      case 'cefa':
        return 'cefa';
      case 'is_unique':
        return 'is_unique';
      case 'unique_fn':
        return 'unique_fn';
      default:
        return '';
    }
  }

  allowedDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  selectedDataType: any;
  fields: any;
  selectDataTYpe(item: any) {
    this.newDropDown = [];
    this.formData.dfc = false;
    this.formData.ce = '';
    this.formData.cef = '';
    this.formData.data_type.value = item;
    // this.selectedMenu = item;
    this.formData.fieldForm = {};
    this.getFieldForm(item);
    this.getEntityField();
    if (
      this.formData.data_type.value === 'dropdown_single_select' ||
      this.formData.data_type.value === 'dropdown_multi_select'
    ) {
      this.getSelectedTabField();
      this.showDefaultdependency();
      this.parentEntityFields = false;
      this.fieldEntity = false;
    }
  }

  addNewTab() {
    let noOfTab =
      this.erulistData.entities[
        this.erulistData.entities.findIndex((e: any) => e.name === this.tabName)
      ].tabs;
    let datas = {
      popupName: 'addNew',
    };
    let popup = this.modalService.create({
      nzClassName: 'add-userPopup',
      nzWidth: '600px',
      nzTitle: 'ADD NEW TAB',
      nzContent: AddNewTabComponent,
      nzMaskClosable: false,
      nzFooter: null,
      nzClosable: false,
      nzData: {
        data: datas,
        tabValue: noOfTab,
      },
    });
    popup.afterClose.subscribe((res) => {
      if (res?.length > 0) {
        this.erulistData.entities[
          this.erulistData.entities.findIndex(
            (e: any) => e.name === this.tabName
          )
        ].tabs = res;
        this.UpdateTabs.emit(res);
      }
    });
  }
  marks: NzMarks = {
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
  };
  @HostBinding('attr.unique-id')
  uniqueId = `${generateUUID()}`;
  newAddedStatus: any[] = [];
  openStatuses(form: any) {
    let datas = {
      popupName: 'status',
    };
    let popup = this.modalService.create({
      nzClassName: 'add-userPopup',
      nzWidth: '450px',
      nzTitle: 'ADD OPEN STATUSES',
      nzContent: AddStatusesPopupComponent,
      nzMaskClosable: false,
      nzFooter: null,
      nzClosable: false,
      nzData: {
        data: datas,
        value: this.formData.fieldForm.open_status,
        derivedFromChild: this.formData.dfc,
        selectedStatus: 'openStatus',
      },
    });
    popup.afterClose.subscribe((res) => {
      if (res?.length > 0) {
        // this.newDropDown?.push(res);
        const flattenedArray = [].concat(...res);
        this.formData.fieldForm.open_status = flattenedArray;
        this.cdr.detectChanges();
      }
    });
  }
  newAddedtag: any[] = [];
  addtag(value: string) {
    let datas = {
      popupName: value,
    };
    let popup = this.modalService.create({
      nzClassName: 'add-userPopup',
      nzWidth: '450px',
      nzTitle: 'ADD TAG OPTION',
      nzContent: AddTagPopupComponent,
      nzMaskClosable: false,
      nzFooter: null,
      nzClosable: false,
      nzData: {
        data: datas,
        value: this.formData.fieldForm.options,
      },
    });
    popup.afterClose.subscribe((res) => {
      if (res != 'cancel') {
        // this.newDropDown?.push(res);
        const flattenedArray = [].concat(...res);
        this.formData.fieldForm.options = flattenedArray;
        this.cdr.detectChanges();
      }
    });
  }
  openTag() {
    this.addDropDown('status');
  }
  newDropDown: any[] = [];
  addDropDown(value: string) {
    let datas = {
      popupName: value,
    };
    let popup = this.modalService.create({
      nzClassName: 'add-userPopup',
      nzWidth: '450px',
      nzTitle: 'ADD DROP DOWN',
      nzContent: AddStatusesPopupComponent,
      nzMaskClosable: false,
      nzFooter: null,
      nzClosable: false,
      nzData: {
        data: datas,
        value: this.formData.fieldForm.options,
      },
    });
    popup.afterClose.subscribe((res) => {
      if (res?.length > 0) {
        // this.newDropDown?.push(res);
        const flattenedArray = [].concat(...res);
        this.formData.fieldForm.options = flattenedArray;
      }
    });
  }
  saveDefaultValue: any;
  addDropDownoption(value: string) {
    let datas = {
      popupName: value,
    };
    let popup = this.modalService.create({
      nzClassName: 'add-userPopup',
      nzWidth: '450px',
      nzTitle: 'ADD VALUES',
      nzContent: AddSelectOptionPopupComponent,
      nzMaskClosable: false,
      nzFooter: null,
      nzClosable: false,
      nzData: {
        data: datas,
        value: this.formData.fieldForm.options,
      },
    });
    popup.afterClose.subscribe((res) => {
      if (res != 'cancel') {
        // this.newDropDown?.push(res);
        const flattenedArray = []?.concat(...res);
        this.formData.fieldForm.options = flattenedArray;
        this.saveDefaultValue = this.formData.fieldForm.options;
        this.cdr.detectChanges();
      }
    });
  }

  delteDropDown(i: number): void {
    if (i >= 0 && i < this.formData.fieldForm.options.length) {
      this.formData.fieldForm.options.splice(i, 1);
    }
  }

  deleteStatus(index: number): void {
    if (index >= 0 && index < this.formData.fieldForm.open_status.length) {
      this.formData.fieldForm.open_status.splice(index, 1);
    }
  }
  deletetag(i: number) {
    if (i >= 0) {
      this.formData.fieldForm.options.splice(i, 1);
    }
  }
  getDefaultStatusOptions(): any {
    return [
      ...this.formData.fieldForm.open_status,
      ...this.formData.fieldForm.close_status,
    ];
  }

  newCloseStatuses: any[] = [];
  closeStatuses() {
    let datas = {
      popupName: 'status',
    };
    let popup = this.modalService.create({
      nzClassName: 'add-userPopup',
      nzWidth: '450px',
      nzTitle: 'ADD CLOSE STATUSES',
      nzContent: AddStatusesPopupComponent,
      nzMaskClosable: false,
      nzFooter: null,
      nzClosable: false,
      nzData: {
        data: datas,
        value: this.formData.fieldForm.close_status,
        derivedFromChild: this.formData.dfc,
        selectedStatus: 'closeStatus',
      },
    });
    popup.afterClose.subscribe((res: any) => {
      if (res != 'cancel') {
        // this.formData.fieldForm.closeStatuses?.push(res);
        const flattenedArray = [].concat(...res);
        this.formData.fieldForm.close_status = flattenedArray;
        this.cdr.detectChanges();
      }
    });
  }

  delteCloseStatus(i: number): void {
    if (i >= 0 && i < this.formData.fieldForm.close_status.length) {
      this.formData.fieldForm.close_status.splice(i, 1);
    }
  }

  selectNewTab: any = new Observable();

  checkValidation(formData: any) {
    let errors = [];
    let keysToValidate = ['fieldName', 'fieldLabel', 'tab_name'];
    Object.keys(formData).forEach((_: any) => {
      if (keysToValidate.includes(_)) {
        if (formData[_].validators.required) {
          if (!(formData[_].value.length > 0)) {
            formData[
              _
            ].validators.error = `${formData[_].placeholder} is required.`;
          } else {
            formData[_].validators.error = ``;
          }
        }
        if (formData[_].validators.required && formData[_].value.length > 0) {
          if (formData[_]?.validators?.inputValidators?.length > 0) {
            if (formData[_].value.split(' ').length > 1) {
              formData[_].validators.error = `No spaces allowed.`;
            } else if (_ === 'fieldName') {
              if (this.allFields.includes(formData[_].value) && !this.isEdit) {
                formData[_].validators.error = `Field Name already exists.`;
              }
            } else {
              formData[_].validators.error = ``;
            }
          }
        }
      } else {
        if (formData.data_type.value === 'people') {
          if (!formData.fieldForm.api_field) {
            formData.fieldForm.api_field_validators.error = `Field Name is required.`;
            errors.push(true);
          } else {
            formData.fieldForm.api_field_validators.error = '';
          }
        }
        if (
          formData.data_type.value === 'currency' ||
          formData.data_type.value === 'number' ||
          formData.data_type.value === 'textbox' ||
          formData.data_type.value === 'textarea'
        ) {
          if (
            !(formData?.fieldForm?.dataLength?.value?.length === 0) &&
            formData?.fieldForm?.dataLengthCheck.value?.length === 0
          ) {
            errors.push(true);
          }
        }
        if (
          ['status', 'number', 'currency', 'date', 'time', 'currency'].includes(
            formData.data_type.value
          ) &&
          formData.dfc
        ) {
          if (!formData.ce) {
            this.showdfcEntityError = true;
            errors.push(true);
          }
          if (!formData.cef) {
            this.showdfcFieldError = true;
            errors.push(true);
          }
        }
      }
    });
    Object.keys(formData).forEach((_: any) => {
      if (keysToValidate.includes(_)) {
        if (formData[_].validators.error) {
          errors.push(true);
        }
      }
    });
    return errors.length == 0;
  }
  getFieldForm(keyName: any) {
    if (keyName === 'date') {
      this.formData.fieldForm = {
        dateFormat: {
          placeholder: '',
          value: 'dd-MM-YYYY',
          options: [
            ['dd-MM-YYYY', 'DD-MM-YYYY'],
            ['YYYY-MM-dd', 'YYYY-MM-DD'],
            ['MM-dd-YYYY', 'MM-DD-YYYY'],
          ],
          validators: {
            required: false,
            inputValidators: [],
            error: '',
          },
          type: '',
        },
        defaultValue: {
          placeholder: null,
          value: '',
          validators: {
            required: false,
            inputValidators: [],
            error: '',
          },
          type: '',
        },
        AllowDays: [
          { value: 'Mon', checked: true },
          { value: 'Tue', checked: true },
          { value: 'Wed', checked: true },
          { value: 'Thu', checked: true },
          { value: 'Fri', checked: true },
          { value: 'Sat', checked: true },
          { value: 'Sun', checked: true },
        ],
      };
      this.formData.cefa = 'max';
      this.formData.is_unique = false;
    } else if (keyName === 'textarea') {
      this.formData.fieldForm = {
        default_value: '',
        dataLengthCheckOptions: [
          { value: 'EXACT' },
          { value: 'MAX' },
          { value: 'MIN' },
        ],
        dataLengthCheck: {
          placeholder: '',
          value: '',
          validators: {
            required: false,
            inputValidators: [],
            error: '',
          },
          type: '',
        },
        dataLength: {
          placeholder: 'Enter',
          value: '',
          validators: {
            required: false,
            inputValidators: [],
            error: '',
          },
          type: '',
        },
      };
      this.formData.rich_text = false;
      this.formData.is_unique = false;
    } else if (keyName === 'textbox') {
      this.formData.fieldForm = {
        dataLength: {
          placeholder: 'Enter',
          value: '',
          validators: {
            required: false,
            inputValidators: [],
            error: '',
          },
          type: '',
        },
        default_value: '',
        dataLengthCheck: {
          placeholder: '',
          value: '',
          validators: {
            required: false,
            inputValidators: [],
            error: '',
          },
          type: '',
        },
        dataLengthCheckOptions: [
          { value: 'EXACT' },
          { value: 'MAX' },
          { value: 'MIN' },
        ],
      };
      this.formData.is_unique = false;
      this.formData.unique_fn = [];
      this.formData.is_unique = false;
    } else if (keyName === 'location') {
      this.formData.fieldForm = {
        default_value: '',
      };
      this.formData.is_unique = false;
    } else if (
      keyName === 'website' ||
      keyName === 'phone' ||
      keyName === 'rm'
    ) {
      this.formData.fieldForm = {
        default_value: '',
      };
      if (keyName === 'website' || keyName === 'phone') {
        this.formData.is_unique = false;
        this.formData.unique_fn = [];
      }
    } else if (keyName === 'attachment') {
      this.formData.default_value = [];
      this.formData.is_unique = false;
    } else if (keyName === 'currency') {
      this.formData.fieldForm = {
        default_value: null,
        dataLengthCheckOptions: [{ value: 'MAX' }, { value: 'MIN' }],
        dataLengthCheck: {
          placeholder: '',
          value: '',
          validators: {
            required: false,
            inputValidators: [],
            error: '',
          },
          type: '',
        },
        dataLength: {
          placeholder: 'Enter',
          value: '',
          validators: {
            required: false,
            inputValidators: [],
            error: '',
          },
          type: '',
        },
        Decimal: {
          placeholder: 'Enter',
          value: 0,
          validators: {
            required: false,
            inputValidators: [],
            error: '',
          },
          type: '',
        },
        DecimalOptions: [{ value: '1', checked: 'false' }],
        Seperator: {
          placeholder: 'Enter',
          value: 'none',
          validators: {
            required: false,
            inputValidators: [],
            error: '',
          },
          type: '',
        },
        SeparatorOptions: [
          { label: 'None', value: 'none' },
          { label: 'Millions', value: 'millions' },
          { label: 'Thousands', value: 'thousands' },
        ],
        Symbol: {
          placeholder: 'Enter',
          value: '',
          validators: {
            required: false,
            inputValidators: [],
            error: '',
          },
          type: '',
        },
      };
      this.formData.cefa = 'sum';
      this.formData.is_unique = false;
    } else if (keyName === 'number') {
      this.formData.fieldForm = {
        default_value: null,
        dataLengthCheckOptions: [{ value: 'MAX' }, { value: 'MIN' }],
        dataLengthCheck: {
          placeholder: '',
          value: '',
          validators: {
            required: false,
            inputValidators: [],
            error: '',
          },
          type: '',
        },
        dataLength: {
          placeholder: 'Enter',
          value: '',
          validators: {
            required: false,
            inputValidators: [],
            error: '',
          },
          type: '',
        },
        Decimal: {
          placeholder: 'Enter',
          value: 0,
          validators: {
            required: false,
            inputValidators: [],
            error: '',
          },
          type: '',
        },
        Seperator: {
          placeholder: 'Enter',
          value: 'none',
          validators: {
            required: false,
            inputValidators: [],
            error: '',
          },
          type: '',
        },
        SeparatorOptions: [
          { label: 'None', value: 'none' },
          { label: 'Millions', value: 'millions' },
          { label: 'Thousands', value: 'thousands' },
        ],
      };
      this.formData.cefa = 'sum';
      this.formData.is_unique = false;
    } else if (keyName === 'status') {
      this.formData.fieldForm = {
        open_status: [],
        close_status: [],
        default_value: '',
      };
      this.formData.is_unique = false;
    } else if (keyName === 'rating') {
      this.formData.fieldForm = {
        emojiType: [
          {
            value: '(smile)',
            checked: false,
            img1: '../../../assets/images/addNewFieldIcons/blueSmiley.svg',
            img2: '../../../assets/images/addNewFieldIcons/whiteSmiley.svg',
          },
          {
            value: '(tick)',
            checked: false,
            img1: '../../../assets/images/addNewFieldIcons/blueTick.svg',
            img2: '../../../assets/images/addNewFieldIcons/whiteTick.svg',
          },
          {
            value: '(like)',
            checked: false,
            img1: '../../../assets/images/addNewFieldIcons/blueLike.svg',
            img2: '../../../assets/images/addNewFieldIcons/whiteLike.svg',
          },
          {
            value: '(star)',
            checked: false,
            img1: '../../../assets/images/addNewFieldIcons/bluestar.svg',
            img2: '../../../assets/images/addNewFieldIcons/whitestar.svg',
          },
        ],
        emojiValue: '',
        start_value: '1',
        end_value: '',
        number: {
          placeholder: '',
          value: 1,
          validators: {
            required: false,
            inputValidators: [],
            error: '',
          },
          type: '',
        },
        default_value: null,
      };
      this.formData.is_unique = false;
    } else if (keyName === 'progress') {
      this.formData.fieldForm = {
        startValue: {
          placeholder: 'Enter',
          value: 0,
          validators: {
            required: false,
            inputValidators: [],
            error: '',
          },
          type: '',
        },
        endValue: {
          placeholder: 'Enter',
          value: 100,
          validators: {
            required: false,
            inputValidators: [],
            error: '',
          },
          type: '',
        },
        default_value: '',
      };
      this.formData.is_unique = false;
    } else if (keyName === 'tag') {
      this.formData.fieldForm = {
        default_value: [],
        options: [],
      };
      this.formData.default_value = [];
      this.formData.is_unique = false;
    } else if (keyName === 'dropdown_single_select') {
      this.formData.fieldForm = {
        options: [],
        optionType: 'STATIC',
        api_name: '',
        api_field: '',
        entity_name: '',
        field_name: '',
        default_value: '',
        def: '',
        dpef: '',
      };
      this.formData.is_unique = false;
    } else if (keyName === 'dropdown_multi_select') {
      this.formData.fieldForm = {
        options: [],
        optionType: 'STATIC',
        api_name: '',
        api_field: '',
        entity_name: '',
        field_name: '',
        default_value: [],
        def: '',
        dpef: '',
      };
      this.formData.default_value = [];
      this.formData.is_unique = false;
    } else if (keyName === 'checkbox') {
      this.formData.fieldForm = {
        default_value: '',
      };
      this.formData.default_value = false
      this.formData.is_unique = false;
    } else if (keyName === 'people') {
      this.formData.fieldForm = {
        default_value: [],
        api_field: '',
        api_field_validators: {
          required: true,
          error: '',
        },
      };
      this.formData.default_value = [];
      this.formData.is_unique = false;
    } else if (keyName === 'priority') {
      this.formData.fieldForm = {
        default_value: '',
        options: [
          {
            name: 'Urgent',
          },
          {
            name: 'High',
          },
          {
            name: 'Normal',
          },
          {
            name: 'Low',
          },
        ],
      };
      this.formData.is_unique = false;
    } else if (keyName === 'email') {
      this.formData.is_unique = false;
      this.formData.unique_fn = [];
      this.formData.is_unique = false;
    }
  }

  onSave() {
    if (this.checkValidation(this.formData)) {
      let fields =
        this.erulistData.entities[
          this.erulistData.entities.findIndex(
            (e: any) => e.name === this.tabName
          )
        ].fields;
      let data: any = {
        entity_name: this.tabName,
        field: {
          can_edit:this.formData?.can_edit,
          name: this.formData?.fieldName.value,
          label: this.formData?.fieldLabel.value,
          default: this.formData?.default_value,
          datatype: this.selectedMenu,
          tab_name: this.formData.tab_name.value,
          can_group: this.formData?.can_group,
          rich_text: this.formData?.rich_text,
          mandatory:!this.formData.can_edit?false: this.formData?.is_hidden
            ? false
            : this.formData?.isMandatory,
          is_hidden: this.formData?.is_hidden,
          editable: this.formData?.editable,
          is_unique: this.formData?.is_unique,
          show_grid:
            this.formData.showGrid === true ||
            this.formData.showGrid === 'yes' ||
            this.formData.showGrid === 'always'
              ? 'yes'
              : 'no',
          grid_index: this.isEdit
            ? this.selectedMenuData?.grid_index
            : this.getMaxGrid(fields,'grid_index')+1,
          default_group: this.formData?.default_group,
          tool_tip: this.formData.tooltip.value,
          is_pii: this.formData.is_pii,
          to_encrypt: this.formData.to_encrypt,
          is_ephi: this.formData.is_ephi,
          dfc: this.formData.dfc,
          ce: this.formData.ce,
          cef: this.formData.cef,
          cefa: this.formData.cefa,
          tf_idx: this.isEdit ? this.formData.tf_idx :  this.getMaxGrid(fields,'tf_idx')+1,
          unique_fn: this.formData.unique_fn,
        },
      };
      if (
        this.formData.dfc &&
        this.formData.ce === '' &&
        this.formData.cef === ''
      ) {
        return;
      }
      if (this.formData.data_type.value === 'textbox') {
        data['field'] = {
          ...data['field'],
          ...{
            default: this.formData.is_unique
              ? ''
              : this.formData?.default_value,
            data_length: this.formData?.fieldForm.dataLength.value,
            data_length_check: this.formData.fieldForm.dataLengthCheck.value,
          },
        };
      } else if (this.formData.data_type.value === 'textarea') {
        data['field'] = {
          ...data['field'],
          ...{
            default: this.formData?.default_value,
            data_length: this.formData?.fieldForm.dataLength.value,
            data_length_check: this.formData.fieldForm.dataLengthCheck.value,
          },
        };
      } else if (this.formData.data_type.value === 'number') {
        let defaultValue!: Number;
        if (this.formData?.default_value) {
          let defaltData = Number(this.formData?.default_value).toFixed(
            this.formData?.fieldForm?.Decimal?.value
          );
          defaultValue = parseFloat(defaltData);
        }
        data['field'] = {
          ...data['field'],
          ...{
            default: defaultValue || null,
            decimal: this.formData.fieldForm.Decimal.value,
            seperator: this.formData.fieldForm.Seperator.value,
            num_val: this.formData?.fieldForm.dataLength.value,
            num_val_check: this.formData.fieldForm.dataLengthCheck.value,
          },
        };
      } else if (this.formData.data_type.value === 'currency') {
        let defaultValue!: Number;
        if (this.formData?.default_value) {
          let defaltData = Number(this.formData?.default_value).toFixed(
            this.formData?.fieldForm?.Decimal?.value
          );
          defaultValue = parseFloat(defaltData);
        }
        data['field'] = {
          ...data['field'],
          ...{
            default: defaultValue || null,
            symbol: this.formData.fieldForm.Symbol.value,
            decimal: this.formData.fieldForm.Decimal.value,
            seperator: this.formData.fieldForm.Seperator.value,
            num_val: this.formData?.fieldForm.dataLength.value,
            num_val_check: this.formData.fieldForm.dataLengthCheck.value,
          },
        };
      } else if (
        ['rm', 'phone', 'attachment'].includes(this.formData.data_type.value)
      ) {
        data['field'] = {
          ...data['field'],
          ...{
            default: this.formData?.default_value,
          },
        };
      } else if (this.formData.data_type.value === 'progress') {
        if (
          !(this.formData?.fieldForm.startValue.value.toString().length > 0) ||
          !(this.formData?.fieldForm.endValue.value.toString().length > 0)
        ) {
          return;
        }
        data['field'] = {
          ...data['field'],
          ...{
            default: Number(this.formData?.default_value),
            startValue: this.formData?.fieldForm.startValue.value,
            endValue: this.formData?.fieldForm.endValue.value,
          },
        };
      } else if (this.formData.data_type.value === 'date') {
        data['field'] = {
          ...data['field'],
          ...{
            default:
              this.formData?.default_value === 'Current_Date'
                ? 'Current_Date'
                : '',
            date_format: this.formData?.fieldForm.dateFormat.value,
            allow_days: this.formData?.fieldForm.AllowDays.filter(
              (day: any) => day.checked == true
            )?.map((item: any) => item.value),
          },
        };
      } else if (this.formData.data_type.value === 'rating') {
        data['field'] = {
          ...data['field'],
          ...{
            default: this.formData?.default_value
              ? this.formData?.default_value
              : null,
            start_value: 1,
            end_value: this.formData?.fieldForm.number.value,
            emoji_value: this.formData?.fieldForm?.emojiValue
              ? this.formData?.fieldForm?.emojiValue
              : '(smile)',
          },
        };
      } else if (this.formData.data_type.value === 'dropdown_single_select') {
        data['field'] = {
          ...data['field'],
          ...{
            default: this.formData?.default_value,
            option_type: this.formData?.fieldForm.optionType,
            api_name: this.formData.fieldForm.api_name,
            api_field: this.formData.fieldForm.api_field,
            entity_name: this.formData.fieldForm.entity_name,
            field_name: this.formData.fieldForm.field_name,
            options: this.formData.fieldForm.options,
            dpef:
              this.formData.fieldForm.optionType === 'ENTITY_DATA'
                ? this.formData.fieldForm.dpef
                : undefined,
            def:
              this.formData.fieldForm.optionType === 'ENTITY_DATA' ||
              this.formData.fieldForm.optionType === 'API'
                ? this.formData.fieldForm.def
                : undefined,
          },
        };

        if (
          (this.formData.fieldForm.optionType === 'ENTITY_DATA' &&
            this.formData.fieldForm.def !== '' &&
            this.formData.fieldForm.dpef === '') ||
          (this.formData.fieldForm.def === '' &&
            this.formData.fieldForm.dpef !== '')
        ) {
          return;
        }
      } else if (this.formData.data_type.value === 'dropdown_multi_select') {
        data['field'] = {
          ...data['field'],
          ...{
            default:
              this.formData?.default_value?.length > 0
                ? [this.formData?.default_value]
                : [],
            option_type: this.formData?.fieldForm.optionType,
            api_name: this.formData.fieldForm.api_name,
            api_field: this.formData.fieldForm.api_field,
            entity_name: this.formData.fieldForm.entity_name,
            field_name: this.formData.fieldForm.field_name,
            options: this.formData.fieldForm.options,
            dpef:
              this.formData.fieldForm.optionType === 'ENTITY_DATA'
                ? this.formData.fieldForm.dpef
                : undefined,
            def:
              this.formData.fieldForm.optionType === 'ENTITY_DATA' ||
              this.formData.fieldForm.optionType === 'API'
                ? this.formData.fieldForm.def
                : undefined,
          },
        };
        if (
          (this.formData.fieldForm.optionType === 'ENTITY_DATA' &&
            this.formData.fieldForm.def !== '' &&
            this.formData.fieldForm.dpef === '') ||
          (this.formData.fieldForm.def === '' &&
            this.formData.fieldForm.dpef !== '')
        ) {
          return;
        }
      } else if (this.formData.data_type.value === 'status') {
        data['field'] = {
          ...data['field'],
          ...{
            default: this.formData?.default_value,
            open_status: this.formData.fieldForm.open_status,
            close_status: this.formData.fieldForm.close_status,
          },
        };
        data.field[`_${this.formData?.fieldName.value}`] =
          this.formData?.fieldForm?.open_status?.some(
            (e: any) => e?.name === this.formData?.default_value
          )
            ? 'open'
            : this.formData?.fieldForm?.close_status?.some(
                (e: any) => e?.name === this.formData?.default_value
              )
            ? 'close'
            : '';
      } else if (this.formData.data_type.value === 'checkbox') {
        data['field'] = {
          ...data['field'],
          ...{
            default: this.formData.default_value,
          },
        };
      } else if (this.formData.data_type.value === 'location') {
        data['field'] = {
          ...data['field'],
          ...{
            default: this.formData?.default_value,
            options: this.formData.fieldForm.options,
          },
        };
      } else if (this.formData.data_type.value === 'tag') {
        data['field'] = {
          ...data['field'],
          ...{
            default: this.formData?.default_value,
            options: this.formData?.fieldForm.options,
          },
        };
      } else if (this.formData.data_type.value === 'people') {
        data['field'] = {
          ...data['field'],
          ...{
            default: this.formData?.default_value,
            api_field: this.formData.fieldForm.api_field,
          },
        };
      } else if (this.formData.data_type.value === 'priority') {
        data['field'] = {
          ...data['field'],
          ...{
            default: this.formData?.default_value,
            options: this.formData?.fieldForm.options,
          },
        };
      } else if (this.formData.data_type.value === 'website') {
        data['field'] = {
          ...data['field'],
          ...{
            default: this.formData.is_unique
              ? ''
              : this.formData?.default_value,
          },
        };
      }
      if (data.field.default_group) {
        this.entityFields = this.entities
          .find(
            (item: any) => item.name === this.formData.fieldForm.entity_name
          )
          ?.fields.map((e: any) => ({ ...e, default_group: false }));
      }
      if (this.isEdit) {
        data.field.tf_idx = this.selectedDataType?.tf_idx;
        if (!(this.number_default_error === '')) {
          return;
        }
        this.editSaveColumns.emit(data);
      } else {
        let datas = {
          data: data,
          form: {
            ...this.form,
            option_type: this.form.option_type.toString(),
          },
        };
        if (!(this.number_default_error === '')) {
          return;
        }
        this.addSaveColumns.emit(datas);
      }
    }
  }

  onCanGroupChange(e: any) {
    if (e === false) {
      this.formData.default_group = false;
      this.entityField = [];
    }
  }
  entityField: any = [];
  onDefaultGropChange(e: any) {
    if (e === true) {
      this.entityField = this.entities
        .find((item: any) => item.name === this.tabName)
        ?.fields.filter((item: any) => item.default_group === true);
      if (this.entityField.length > 0) {
        this.formData['defaultGroup'] == true;
      } else {
        this.formData['defaultGroup'] == false;
        this.entityField = [];
      }
    } else {
      this.entityField = [];
    }
  }
  getDropdown(a: any) {
    return a;
  }

  getEmojiicon(v: any, type: any) {
    let result = this.formData.fieldForm.emojiType.find(
      (e: any) => e.value == v
    );
    if (type == 'fill') {
      return result.img1;
    } else {
      return result.img2;
    }
  }

  deleteTab(tab: any, node: any) {
    this.delTab.emit({
      tab: tab,
      component: this,
    });
  }

  optionTypeChange(e: any) {
    if (e === 'API') {
      this.selectAPI.emit({ action: true });
    }
  }

  selectApiChange(e: any) {
    this.selectAPI.emit({ action: true, data: e });
  }
  selectPeopleChange(e: any) {
    if (e) {
      this.formData.default_value = [];
    }
  }
  people$() {
    return of(this.api_field_options).pipe(
      map((e) => e[0] || {}),
      map((e) => Object.keys(e).filter((_: any) => _ !== 'rowIndex'))
    );
  }

  onPeopleOpenChange(e: any) {
    this.selectAPI.emit({ action: true, people: 'people' });
  }
  entityselectFields(e: any, f: any, field: any) {
    if (field === 'select') {
      this.formData.fieldForm.field_name = e;
    }
    let data = {
      entity_name: f.fieldForm.entity_name,
      field_str: field === 'input' ? e?.target?.value : '',
      field_name: f.fieldForm.field_name,
    };
    this.entitySingleSelect.emit({ data });
  }
  peopleDefaultInput: any = '';
  getPeopleOption() {
    // let getUserData = ((this.formData?.fieldForm?.api_field==='user_name'?'UserName':'')||(this.formData?.fieldForm?.api_field==='public___users__email_id'?'EmailID':''));
    // if (this.api_field_options && this.formData?.fieldForm?.api_field) {
    //   let optionValues = this.api_field_options.map(
    //     (e: any) => e[getUserData],
    //   );
    //   return optionValues;
    // } else {
    //   return [];
    // }
    document.querySelector('.ant-select-item-option-content');
    const elements = document.querySelectorAll(
      '.ant-select-item-option-content'
    );
    elements.forEach((element) => {
      if (element instanceof HTMLElement) {
        element.style.fontSize = '12px';
      }
    });
    // return of(this.api_field_options).pipe(
    //   map((e: any) =>
    //     e.map((_e: any) => _e[this.formData?.fieldForm?.api_field])
    //   )
    // );
    return of(this.api_field_options).pipe(
      map((e: any) =>
        e?.map((_e: any) => _e[this.formData?.fieldForm?.api_field])
      ),
      map((options: string[]) =>
        options?.filter((option) =>
          option
            ?.toLowerCase()
            ?.includes(this.peopleDefaultInput?.toLowerCase())
        )
      )
    );
  }
  isEntityDataType$ = new BehaviorSubject<any>([]);
  getTabs() {
    const tabs = this.stateService
      .appState()
      .metaData?.entities?.find((e: any) => e.name == this.tabName)
      ?.tabs?.sort((a: any, b: any) => a.index - b.index);
    return tabs;
  }
  filterEntity(searchValue: any) {
    if (!searchValue) {
      this.tabFieldsEntity = this.entityData;
    } else {
      this.tabFieldsEntity = this.entityData.filter((option: any) =>
        option.label.toLowerCase().includes(searchValue.toLowerCase())
      );
    }
  }
  filterFields(searchValue: any) {
    if (!searchValue) {
      this.tabFields = this.savetabFields;
    } else {
      this.tabFields = this.savetabFields.filter((option: any) =>
        option.label.toLowerCase().includes(searchValue.toLowerCase())
      );
    }
  }
  parentEntityField(event: any) {
    if (event) {
      this.fieldEntity = true;
    } else {
      this.fieldEntity = false;
      this.formData.fieldForm.dpef = '';
    }
  }
  Fieldentity(event: any) {
    if (event) {
      this.parentEntityFields = true;
    } else {
      this.parentEntityFields = false;
      this.formData.fieldForm.def = '';
    }
  }
  filterdefault(searchValue: any) {
    this.formData.fieldForm.options = this.saveDefaultValue?.filter(
      (option: any) =>
        option?.name?.toLowerCase()?.includes(searchValue?.toLowerCase())
    );
  }
  filterEntities(searchValue: any) {
    this.entities = this.savedEntities?.filter((option: any) =>
      option?.display_name?.toLowerCase()?.includes(searchValue?.toLowerCase())
    );
  }
  filterField(searchValue: any) {
    this.entityFields = this.saveFields?.filter((option: any) =>
      option?.label?.toLowerCase()?.includes(searchValue?.toLowerCase())
    );
  }
  filterapi(searchValue: any) {
    this.select_api_options = this.save_api_options?.filter((option: any) =>
      option?.api_name?.toLowerCase()?.includes(searchValue?.toLowerCase())
    );
  }
  Save_api_field_options: any;
  filterFieldOption(searchValue: any) {
    this.api_field_options = this.Save_api_field_options?.filter(
      (option: any) =>
        option?.toLowerCase()?.includes(searchValue?.toLowerCase())
    );
  }
  saveoptions: any;
  saveentities: any;
  saveCurrencyLists: any;
  saveTagDefault: any;
  savenewColumnValue: any;
  filterMultiDefault(searchValue: any) {
    this.formData.fieldForm.options = this.saveoptions.filter((option: any) =>
      option.name.toLowerCase().includes(searchValue.toLowerCase())
    );
  }
  filterEntitie(searchValue: any) {
    this.entities = this.saveentities.filter((option: any) =>
      option.display_name.toLowerCase().includes(searchValue.toLowerCase())
    );
  }
  filterTagDefault(searchValue: any) {
    this.formData.fieldForm.options = this.saveTagDefault.filter(
      (option: any) =>
        option.name.toLowerCase().includes(searchValue.toLowerCase())
    );
  }
  filterCurrency(searchValue: any) {
    this.CurrencyLists = this.saveCurrencyLists.filter((option: any) =>
      option.CurrencyName.toLowerCase().includes(searchValue.toLowerCase())
    );
  }
  filteredStatusOptions: any;
  filterStatusDefault(searchValue: any) {
    this.filteredStatusOptions = this.getDefaultStatusOptions().filter(
      (option: any) =>
        option.name.toLowerCase().includes(searchValue.toLowerCase())
    );
  }

  filterDataTYpe(searchValue: any) {
    this.newColumnValue = this.savenewColumnValue.filter((option: any) =>
      option.label.toLowerCase().includes(searchValue.toLowerCase())
    );
  }
  priorityDefaultOption: any;
  filterpriorityDefault(searchValue: any) {
    this.priorityDefaultOption = this.priority.filter((option: any) =>
      option.name.toLowerCase().includes(searchValue.toLowerCase())
    );
  }
  priority: any = [
    {
      name: 'Urgent',
      color: 'rgb(227, 131, 136)',
    },
    {
      name: 'High',
      color: 'rgb(251, 203, 92)',
    },
    {
      name: 'Normal',
      color: 'rgb(135, 157, 255)',
    },
    {
      name: 'Low',
      color: 'rgb(101, 111, 125)',
    },
  ];
  getColor(value: any) {
    let color = this.priority.find((e: any) => e.name === value).color;
    return color || '';
  }
  hexToRgba(hex: string, alpha: number): string {
    hex = hex.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  checkInputValidation(value: string, error: any) {
    if (value?.length > 0) {
      if (error === 'fieldName') {
        this.formData.fieldName.validators.error = '';
      }
      if (error === 'fieldLabel') {
        this.formData.fieldLabel.validators.error = '';
      }
    }
  }

  filteruniquEntityOption(searchValue: any) {
    this.optionTypeEntityData = this.saveoptionTypeEntityData.filter(
      (option: any) =>
        option.label.toLowerCase().includes(searchValue.toLowerCase())
    );
  }
  numberInputValue(value: any) {
    if (this.formData?.fieldForm?.dataLength.value === '') {
      this.formData.fieldForm.dataLengthCheck.value = '';
      this.number_default_error = '';
    }
    if (
      this.formData?.fieldForm?.dataLength?.value?.toString() === '' ||
      !this.formData?.fieldForm?.dataLength?.value?.toString()
    ) {
      this.formData.fieldForm.dataLengthCheck.value = '';
      this.number_default_error = '';
    }
  }
  number_default_error: any = '';
  numberFieldValidation(value: any, event: any, value_check: any) {
    if (value && value_check) {
      let inputText = event?.replace(/[-.]/g, '');
      let length = Number(value);
      if (event?.length > 0) {
        if (value_check === 'MAX') {
          if (inputText?.length > length) {
            this.number_default_error = `Maximum value is ${value}`;
          } else {
            this.number_default_error = '';
          }
        } else if (value_check === 'MIN') {
          if (inputText?.length < length) {
            this.number_default_error = `Minimum value is ${value}`;
          } else {
            this.number_default_error = '';
          }
        } else {
          this.number_default_error = '';
        }
      } else {
        this.number_default_error = '';
      }
    } else {
      this.number_default_error = '';
    }
  }
  textboxValidation(value: any, event: any, value_check: any) {
    let inputText = event;
    let length = Number(value);
    if (event?.length > 0) {
      if (value_check === 'MAX') {
        if (inputText?.length > length) {
          this.number_default_error = `Maximum value is ${value}`;
        } else {
          this.number_default_error = '';
        }
      } else if (value_check === 'MIN') {
        if (inputText?.length < length) {
          this.number_default_error = `Minimum value is ${value}`;
        } else {
          this.number_default_error = '';
        }
      } else if (value_check === 'EXACT') {
        if (inputText?.length !== length) {
          this.number_default_error = `Exact value is ${value}`;
        } else {
          this.number_default_error = '';
        }
      } else {
        this.number_default_error = '';
      }
    } else {
      this.number_default_error = '';
    }
  }
  getMaxGrid(fields:any[],type:string):number{
  try{
    let result =fields?.reduce((max:any, item:any) => {
      return (item?.[type] < 9000 && item?.[type] > max) ? item?.[type] : max;
    }, 0)
    return result
  }catch (e:any){
    return 0
  }
  }

}
