<nz-row class="lb-bgcolor sidebarContainer" style="width: 100%">
  <nz-row class="menuContainer" [nzAlign]="'middle'">
    <nz-col [nzXs]="{ span: 5 }">
      <img
        (click)="home()"
        class="side_logo" [src]="environment.is_artfine?'../../../../assets/images/logo/ArtfineBilzSideLogo.svg':'../../../../assets/images/processo-icon.svg'"
      />
    </nz-col>
    <nz-col [nzXs]="{ span: 5 }">
      <img
        *ngIf="profileSection"
        (click)="open()"
        src="../../../../assets/images/icons/openMenuIcon.svg"
        alt=""
      />
    </nz-col>
    <nz-col [nzXs]="{ span: 3, offset: 2 }">
      <img (click)="setting()" class="cPointer"
        [hidden]="organizationSection"
        src="../../../../assets/images/settings.svg"
        alt=""
      />
    </nz-col>
    <nz-col [nzXs]="{ span: 3 }">
      <img
        [hidden]="organizationSection"
        src="../../../../assets/images/solar.svg"
        alt=""
      />
    </nz-col>
    <nz-col [nzXs]="{ span: 3 }">
      <img
        [hidden]="organizationSection"
        src="../../../../assets/images/search.svg"
        alt=""
      />
    </nz-col>
    <nz-col [nzXs]="{ span: 3 }">
      <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
        <div class="profileLogoContainer fw-400 f-12">
          {{ firstName }}{{ lastName }}
        </div>
      </a>
      <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu class="sideBarContainers">
          <li nz-menu-item (click)="gotoOrganization()">Organizations</li>
          <li nz-menu-item (click)="gotoProfileSettings()">Profile Settings</li>
          <li nz-menu-item (click)="signOut()">Sign Out</li>
          <li nz-menu-item>{{ fullName }}</li>
        </ul>
      </nz-dropdown-menu>
    </nz-col>
  </nz-row>
  <nz-row class="mt-10 w-100" [hidden]="organizationSection">
    <nz-col [nzXs]="{ span: 24 }">
      <a
        nz-dropdown
        nzTrigger="click"
        [nzDisabled]="stateService.orgList()?.length == 0"
        [nzDropdownMenu]="menu2"
        class="textcolor f-12 fw-400 text-center w100 menustyle"
      >
        {{
          stateService.appState().selectedOrganization?.org_name ||
            "Select Organization Name"
        }}
        <span nz-icon
          ><img src="../../../../../assets/images/arrow-up.png"
        /></span>
      </a>
      <nz-dropdown-menu #menu2="nzDropdownMenu">
        <ul nz-menu>
          <li
            nz-menu-item
            *ngFor="let org of stateService.orgList()"
            (click)="menuTextChange(org)"
          >
            {{ org?.org_name }}
          </li>
        </ul>
      </nz-dropdown-menu>
    </nz-col>
  </nz-row>
</nz-row>

<nz-drawer
  [nzClosable]="false"
  [nzVisible]="visible"
  nzPlacement="left"
  (nzOnClose)="close()"
  [nzWrapClassName]="'drawerContainer'"
>
  <ng-container *nzDrawerContent class="lb-bgcolor">
    <nz-row class="w-100">
      <div class="w-100 drawerHeader">
        <img
          (click)="home(); close()"
          class="side_logo" [src]="environment.is_artfine?'../../../../assets/images/logo/ArtfineBilzSideLogo.svg':'../../../../assets/images/processo-icon.svg'"
        />

        <img
          (click)="close()"
          src="../../../../assets/images/Cross.png"
          alt=""
        />
      </div>
    </nz-row>
    <nz-row class="w-100" *ngIf="checkRoutes()">
      <nz-col
        class="mt-10 processContainers w-100"
        *ngFor="let item of getProcess() || []"
        (click)="selectedProcess(item)"
        [ngClass]="currentProcessSelected(item) ? 'bgColorBlue' : ''"
      >
        <img src="../../../../assets/images/logo/processImg.svg" />
        <div class="f-11 fw-400 berlinBluecolor">{{ item.d_process_name }}</div>
      </nz-col>
    </nz-row>
  </ng-container>
</nz-drawer>
