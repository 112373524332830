import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Editor } from 'ngx-editor';
import { generateUUID } from 'projects/processo-custom-table/src/usecase';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'lib-text-area-wrapped',
  templateUrl: './text-area-wrapped.component.html',
  styleUrls: ['./text-area-wrapped.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextAreaWrappedComponent implements OnInit {
  @HostBinding('attr.unique-id')
  uniqueId = `${generateUUID()}`;
  @Input() innerTable: any;
  @Input() header: any;
  @Input() i: any;
  @Input() data: any;
  @Input() parent: any;
  editor: Editor = new Editor();
  ngOnInit(): void {
    if (this.data[this.header.name]) {
      this.data[this.header.name] = atob(this.data[this.header.name] ?? '');
    }
  }

  editval$: any;
  editval = new BehaviorSubject<boolean>(false);
  constructor(private cdr: ChangeDetectorRef) {
    this.editval$ = this.editval;
  }
  error = '';
  editing() {
    if(this.data?.is_locked || this.data?.lock_fields?.includes(this.header?.name)){
      return
    }
    if (this.editval.getValue()) {
      this.editval.next(false);
    } else {
      this.editval.next(true);
    }
    this.cdr.markForCheck();
  }
  checkValidation(e: any) {
    this.error = this.textboxValidation(this.header, e.target.value);
    let tableEdit: any;
    if (!this.error) {
      if (this.innerTable) {
        tableEdit = 'innerTable';
        this.parent.updateDataOnInnerStopEdit(
          { target: { value: btoa(e.target.value) } },
          this.header.name,
          this.data
        );
        this.editval.next(false);
      } else {
        this.parent.updateDataOnStopEdit(
          { target: { value: btoa(e.target.value) } },
          this.header.name,
          this.data,
          this.parent.erulistData
        );
        this.editval.next(false);
      }
      this.parent.saveEntityDropdownData(this.header, tableEdit, this.data);
    }
  }
  textareaUpdate(value: any, event: any) {
    let timeoutId = setTimeout(() => {
      if (!this.isPasting && event) {
        let tableEdit: any;
        if (this.innerTable) {
          tableEdit = 'innerTable';

          this.parent.updateDataOnInnerStopEdit(
            { target: { value: btoa(`<html><body>${value}</body></html>`) } },
            this.header.name,
            this.data
          );
          this.editval.next(false);
        } else {
          this.parent.updateDataOnStopEdit(
            { target: { value: btoa(`<html><body>${value}</body></html>`) } },
            this.header.name,
            this.data,
            this.parent.erulistData
          );
          this.editval.next(false);
        }
        this.parent.saveEntityDropdownData(this.header, tableEdit, this.data);
      } else {
        this.isPasting = false;
        clearTimeout(timeoutId);
      }
    }, 200);
  }
  isPasting: boolean = false;
  onPaste(): void {
    this.isPasting = true;
  }
  textboxValidation(field: any, event: any) {
    let inputText = event;
    if (field.mandatory && !inputText) {
      return `${field?.label} is mandatory`;
    }
    let length = Number(field?.data_length);
    if (event?.length > 0) {
      if (field?.data_length_check === 'MAX') {
        if (inputText?.length > length) {
          return `Maximum value is ${field?.data_length}`;
        } else {
          return '';
        }
      } else if (field?.data_length_check === 'MIN') {
        if (inputText?.length < length) {
          return `Minimum value is ${field?.data_length}`;
        } else {
          return '';
        }
      } else if (field?.data_length_check === 'EXACT') {
        if (inputText?.length !== length) {
          return `Exact value is ${field?.data_length}`;
        } else {
          return '';
        }
      } else {
        return '';
      }
    } else {
      return '';
    }
  }
}
