import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@processo/services/user.service';
import { NgZorroAntdModule } from '@processo/shared/ng-zorro-antd.module';
import { SharedModule } from '@processo/shared/shared.module';
import { StateService } from '@processo/shared/state/state.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  standalone: true,
  imports: [NgZorroAntdModule, CommonModule, SharedModule],
})
export class LoadingComponent implements OnInit {
  router = inject(Router);
  stateService = inject(StateService);
  userService = inject(UserService);
  private _destroy$ = new Subject<void>();
  ngOnInit(): void {
    setTimeout(() => {
      this.router.navigate(['dashboard']);
    }, 2000);
  }
}
