import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { debounceTime } from 'rxjs';
import { ProcessoCustomTableService } from '../../processo-custom-table.service';
import { generateUUID } from 'projects/processo-custom-table/src/usecase';

@Component({
  selector: 'lib-people',
  templateUrl: './people.component.html',
  styleUrls: ['./people.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PeopleComponent implements OnInit {
  // ngOnChanges(changes: SimpleChanges): void {
  // }
  @Input() innerTable: any;
  @Input() data: any = [];
  @Input() header: any;
  @Output() peopleDatas = new EventEmitter<any>();
  @Output() peopleDropdownEventEmitters = new EventEmitter<any>();
  @Output() selectedPeoples = new EventEmitter<any>();
  @Input() api_fields: any;
  @Input() parent: any;

  // @Output() startDropDownEditEmitter = new EventEmitter<any>();
  // @ViewChild('selectBox') selectBox!: ElementRef;
  // peopleData(header: any, data: any) {
  //   this.peopleDatas.emit({ header, data })
  // }
  // slectboxOptionOpen: boolean = true;
  // selectBoxFocus(comp: any) {
  //   if (comp) {
  //     comp.focus();

  //   }
  // }
  // onSelectBlur(e: any) {
  //   if (this.slectboxOptionOpen) {
  //     this.editClicked = false;

  //   }
  // }
  // peopleDropdownEventEmitter(event: any, data: any) {
  //   this.slectboxOptionOpen = false
  //   this.peopleDropdownEventEmitters.emit({ event, data })
  // }
  // selectedPeople() {
  //   this.selectedPeoples.emit('confirm')
  // }
  // getPeopleOption(header: any) {
  //   let getUserData = header.api_field;
  //   if (this.api_fields && header.api_field) {
  //     let optionValues = this.api_fields.map(
  //       (e: any) => e[getUserData],
  //     );
  //     return optionValues;
  //   } else {
  //     return [];
  //   }
  // }
  // shortenName(name: string) {
  //   if (name) {

  //     const words = name?.trim()?.split(' ');

  //     if (words?.length === 1) {
  //       return words[0].charAt(0);
  //     } else if (words?.length >= 2) {
  //       return words[0]?.charAt(0) + words[words?.length - 1]?.charAt(0);
  //     } else {
  //       return '';
  //     }
  //   } else {
  //     return '';
  //   }
  // }

  // editClicked = false
  // onEditClick() {
  //   this.editClicked = true;
  //   this.slectboxOptionOpen = true
  //   this.startDropDownEditEmitter.emit()

  // }
  // handleDropdownClose(event: any) {
  //   if (!event) {
  //     this.editClicked = false
  //   }
  // }
  // peopleData: any = [
  //   'Hrithik Roshan',
  //   'Ranbir Kapoor',
  //   'John Abraham',
  // ];

  peopleData: any = [
    'Hrithik Roshan',
    'Ranbir Kapoor',
    'John Abraham',
    'Shah Rukh Khan',
  ];
  showDropdown = false;
  searchInput: string = '';
  searchData: any = [];
  selectedPeople: any = [];

  ngOnInit(): void {
    if (this.data[this.header.name] && this.data[this.header.name].length > 0) {
      this.selectedPeople = [...this.data[this.header.name]];
      this.selectedPeople = this.selectedPeople?.filter((item: any) => item !== '');
    }
    this.searchData = [...this.peopleData];
  }
  @HostBinding('attr.unique-id')
  uniqueId = `${generateUUID()}`;
  shortenName(name: string) {
    if (name) {
      const words = name?.trim()?.split(' ');

      if (words?.length === 1) {
        return words[0].charAt(0);
      } else if (words?.length >= 2) {
        return words[0]?.charAt(0) + words[words?.length - 1]?.charAt(0);
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  searchMenu(e: any) {
    this.searchData = [
      ...this.peopleData.filter((i: any) => {
        return i.toLowerCase().search(e.toLowerCase()) != -1;
      }),
    ];
  }

  selectOption(p: any, c: any) {
    if (this.selectedPeople?.includes(p)) {
      const index = this.selectedPeople.findIndex((i: any) => i == p);
      this.selectedPeople.splice(index, 1);
      if (this.innerTable) {
        this.parent.updateDataOnInnerStopEdit(
          { target: { value: this.selectedPeople } },
          this.header.name,
          this.data
        );
      } else {
        this.parent.updateDataOnStopEdit(
          { target: { value: this.selectedPeople } },
          this.header.name,
          this.data,
          this.parent.erulistData
        );
      }
    } else {
      this.selectedPeople.push(p);
      if (this.innerTable) {
        this.parent.updateDataOnInnerStopEdit(
          { target: { value: this.selectedPeople } },
          this.header.name,
          this.data
        );
      } else {
        this.parent.updateDataOnStopEdit(
          { target: { value: this.selectedPeople } },
          this.header.name,
          this.data,
          this.parent.erulistData
        );
      }
    }
  }
  constructor(
    private service: ProcessoCustomTableService,
    private cdr: ChangeDetectorRef
  ) {}

  fields = [];
  openDropDownAPIChange(f: any, search = '', event?: any): void {
    if (event) {
      let body = {
        org_id: this.data.org_id,
        api_name: 'People',
        process_id: this.data.process_id,
        entity_id:[this.data.entity_id||'']
      };
      this.parent.pservice
        .getApiFields(body)
        .pipe(debounceTime(300))
        .subscribe((res: any) => {
          this.fields = res?.org
            .filter((item: any) => item[f.api_field].includes(search))
            .map((item: any) => item[f.api_field]);
          // this.cdr.markForCheck()
          this.showDropdown = true;
        });
    }
  }
  deleteUser(e: any) {
    this.selectedPeople.filter((E: any) => E !== e);
    if (this.innerTable) {
      this.parent.updateDataOnInnerStopEdit(
        { target: { value: this.selectedPeople } },
        this.header.name,
        this.data
      );
    } else {
      this.parent.updateDataOnStopEdit(
        { target: { value: this.selectedPeople } },
        this.header.name,
        this.data,
        this.parent.erulistData
      );
    }
  }
  close = false;
  private timeoutRef: any;
  ngOnDestroy(): void {
    if (this.timeoutRef) {
      clearTimeout(this.timeoutRef);
    }
  }
  event(e: any) {
    if (e) {
      this.timeoutRef = setTimeout(() => {
        this.close = true;
      }, 100);
    } else {
      this.close = false;
    }
  }
}
