
<div class="px-10 textStyle website" fluidRowWidth [type]="header.name">


<div>
    <div class="editable-cell d-flex p-0 text-start word-break-all min-height-37 align-center"
    (mouseenter)="showEditIcon = true"
    (mouseleave)="showEditIcon = false"
    *ngIf="!(editval$|async)" (click)="editing()">
  <a style="font-size: 12px;" class="word-break-all ">
{{
data[header.name]
? data[header.name]
: header.default
? header.default
: "&nbsp;&nbsp;&nbsp;"
}}</a>
    <span
      *ngIf="showEditIcon"
      (click)="editing()"
      style="margin-left: 10px"
      >
      <!-- <span nz-icon nzType="edit" nzTheme="outline">
        </span    > -->
  </span>
</div>
<nz-form-control class="d-flex text-start p-0 flex-column websiteContainer" *ngIf="(editval$|async)">
<nz-input-group>
<input auto-focus-input class="text-start p-0" type="text" nz-input [(ngModel)]="data[header.name]" 
  (focusout)="checkValidation($event)"
/>
</nz-input-group>
<div style="color: red ;font-size: smaller;text-align: left;">
  {{header.error}}
  </div>
</nz-form-control>
</div>
</div>