import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageLayoutComponent } from './page-layout/page-layout.component';
import { NgZorroAntdModule } from './ng-zorro-antd.module';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { EmptyOrganisationComponent } from './components/empty-organisation/empty-organisation.component';
import { MobileSidebarComponent } from './components/mobile-sidebar/mobile-sidebar.component';
import { ConfirmSwitchTabPopupComponent } from './popup/confirm-switch-tab-popup/confirm-switch-tab-popup.component';
import { TimePopupComponentComponent } from './components/time-popup-component/time-popup-component.component';
import { AddMorePopupComponent } from './popup/add-more-popup/add-more-popup.component';
import { CreateNewEntityComponent } from './components/create-new-entity/create-new-entity.component';
import { ConfirmDeletePopupComponent } from './popup/confirm-delete-popup/confirm-delete-popup.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { CreateFieldComponent } from './components/create-field/create-field.component';
import { AddNewColumnComponent } from './components/add-new-column/add-new-column.component';
import { DropdownComponent } from './components/add-new-column/popup/dropdown/dropdown.component';
import { StatusPopupComponent } from './components/add-new-column/popup/status-popup/status-popup.component';
import { TagPopupComponent } from './components/add-new-column/popup/tag-popup/tag-popup.component';
import { ShowFieldPopupComponent } from './popup/show-field-popup/show-field-popup.component';
import { CommonFilterComponent } from './components/common-filter/common-filter.component';
import { JsonTextAreaComponent } from './components/json-text-area/json-text-area.component';
import { SingleFilterComponent } from './components/single-filter/single-filter.component';
import { RecordVisibilityFilterComponent } from './components/record-visibility-filter/record-visibility-filter.component';

@NgModule({
  declarations: [
    PageLayoutComponent,
    HeaderComponent,
    SidebarComponent,
    EmptyOrganisationComponent,
    MobileSidebarComponent,
    ConfirmSwitchTabPopupComponent,
    TimePopupComponentComponent,
    AddMorePopupComponent,
    CreateNewEntityComponent,
    ConfirmDeletePopupComponent,
    CreateFieldComponent,
    AddNewColumnComponent,
    DropdownComponent,
    StatusPopupComponent,
    TagPopupComponent,
    ShowFieldPopupComponent,
    CommonFilterComponent,
    JsonTextAreaComponent,
    SingleFilterComponent,
    RecordVisibilityFilterComponent
  ],
  imports: [CommonModule, RouterModule, NgZorroAntdModule,PickerModule],
  exports: [
    PageLayoutComponent,
    HeaderComponent,
    SidebarComponent,
    EmptyOrganisationComponent,
    MobileSidebarComponent,
    ConfirmSwitchTabPopupComponent,
    CreateNewEntityComponent,
    ConfirmDeletePopupComponent, 
    CreateFieldComponent,
    AddNewColumnComponent,
    ShowFieldPopupComponent,
    CommonFilterComponent,
    JsonTextAreaComponent,
    SingleFilterComponent,
    RecordVisibilityFilterComponent
  ],
})
export class SharedModule {}
