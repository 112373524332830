import { CommonModule } from '@angular/common';
import { Component, OnInit, effect, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TokenService } from '@processo/services/token.service';
import { UserService } from '@processo/services/user.service';
import { StateService } from '@processo/shared/state/state.service';
import { Subject, takeUntil } from 'rxjs';
import { environment } from 'projects/processo/src/environments/environment';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  router = inject(Router);
  stateService = inject(StateService);
  firstName: string = '';
  lastName: string = '';
  fullName: string = '';
  environment=environment
  constructor(private tokenService: TokenService,) {}
  ngOnInit(): void {
    const userObj = this.stateService.isUserLoggedIn().userDetail.attributes;
    this.firstName = userObj?.first_name.charAt(0);
    this.lastName = userObj?.last_name.charAt(0);
    this.fullName = `${userObj?.first_name} ${userObj?.last_name}`;
  }
  getProcess() {
    return this.stateService
      .appState()
      .selectedOrganization?.public___org_processes?.sort(
        (a: any, b: any) => a.rowIndex - b.rowIndex,
      );
  }
 async gotoProfileSettings() {
  if(await this.stateService.canNavigate()){
    this.router.navigateByUrl('profile');
  }
  }

  async signOut() {
    if(await this.stateService.canNavigate()){
    let body ={
      refresh_token:this.tokenService?.getRefreshToken()
    }
    this.stateService.logout(body).subscribe((res)=>{
      if(res){
        this.stateService.clearState();
      }
    })
  }
  }

  async gotoOrganization() {
    if(await this.stateService.canNavigate()){
    this.router.navigateByUrl('organization/organization-details');
    }
  }

  async home() {
    
    if(await this.stateService.canNavigate()){
      this.stateService.resetPagination()
      if(!this.stateService.orgList()){
        this.router.navigate(['dashboard']);
        return
      }
      const islocationInDashboard = this.router.url.indexOf('dashboard') > -1;
      if (!islocationInDashboard) {
        this.stateService?.updateAppstate({
          event: 'UPDATE_ORG',
          value:
            this.stateService
              .orgList()
              ?.find((e: any) => e?.public___user_org_default__is_default) ||
            this.stateService?.orgList()[0]?.org?.[0] ||
            null,
        });
  
        let loginUserId =
          this.stateService?.isUserLoggedIn()?.userDetail.attributes?.sub || null;
        let result = this.stateService
          .appState()
          ?.selectedOrganization?.public___org_processes?.find((process: any) => {
            let users = process?.public___vw_org_process_users?.find(
              (user: any) => user.user_id === loginUserId,
            );
            return users && users?.public___user_org_process_default__is_default;
          });
  
        if (result) {
          this.stateService.updateAppstate({
            event: 'UPDATE_PROCESS',
            value: result || null,
          });
        } else {
          this.stateService.updateAppstate({
            event: 'UPDATE_PROCESS',
            value:
              this.stateService.appState()?.selectedOrganization
                ?.public___org_processes[0] || null,
          });
        }
        this.router.navigate(['dashboard']);
      }
      
    }
 
  }

  showCrm!: boolean;

  getCurrentUrl() {
    this.router.events.subscribe((res: any) => {
      const currentURL = window.location.href;
      if (currentURL.includes('dashboard')) {
        this.showCrm = true;
      } else {
        this.showCrm = false;
      }
    });
  }

  selectedProcessID: any;

 async selectedProcess(process: any) {
    if(await this.stateService.canNavigate()){
      this.stateService.resetPagination()
    this.stateService.updateAppstate({
      event: 'UPDATE_PROCESS',
      value: process,
    });
  }
  }

  currentProcessSelected(item: any) {
    return (
      this.stateService.appState().selectedProcess.process_id ===
      item.process_id
    );
  }

  checkRoutes() {
    const islocationInDashboard = this.router.url.indexOf('dashboard') > -1;
    return islocationInDashboard ? true : false;
  }
}
