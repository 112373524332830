<ng-container *ngFor="let item of filterby; let i = index; let last = last">
    <ng-container *ngIf="!Array.isArray(item)">
      <nz-row [nzGutter]="16">
        <nz-col [nzSpan]="12" class="mt-10">
          <nz-select
            class="w-100"
            nzPlaceHolder="Select field"
            [nzDropdownClassName]="'reverse_ant-select-dropdown'"
            [nzDropdownRender]="selectSearchTemplateField"
            [(ngModel)]="item.entity_name"
            (ngModelChange)="
              selectedEntities($event, item);
              call_save($event, item, item.entity_name)
            "
          >
            <ng-template #selectSearchTemplateField> </ng-template>
            <ng-container *ngFor="let option of entitiesData">
              <ng-container *ngIf="option.label">
                <nz-option
                  nzValue="{{ option?.field_name }}"
                  nzLabel="{{ option?.label }}"
                ></nz-option>
              </ng-container>
            </ng-container>
          </nz-select>
        </nz-col>
        <nz-col [nzSpan]="12" class="mt-10">
          <nz-select
            class="w-100"
            [(ngModel)]="item.key1"
            (ngModelChange)="
              filterTypeFieldChange($event, i, item.entity_name);
              call_save($event, item, item.key1)
            "
            nzPlaceHolder="Select field"
            [nzDropdownClassName]="'reverse_ant-select-dropdown'"
            [nzDropdownRender]="selectSearchTemplateField"
          >
            <ng-template #selectSearchTemplateField> </ng-template>
            <ng-container *ngFor="let option of getFields('', item.entity_name)">
              <ng-container *ngIf="option.label">
                <nz-option
                  nzValue="{{ option?.name }}"
                  nzLabel="{{ option?.label }}"
                ></nz-option>
              </ng-container>
            </ng-container>
          </nz-select>
        </nz-col>
        <nz-col [nzSpan]="12" class="mt-10">
          <nz-select
            [(ngModel)]="item.key2"
            (ngModelChange)="call_save($event, item, item.key2)"
            class="w-100"
            nzPlaceHolder="Select field"
            [nzDropdownClassName]="'reverse_ant-select-dropdown'"
            [nzDropdownRender]="selectSearchTemplateField"
          >
            <ng-template #selectSearchTemplateField> </ng-template>
            <ng-container
              *ngFor="let item of getOperators(item?.key1, item.entity_name)"
            >
              <ng-container *ngIf="item.label">
                <nz-option
                  nzValue="{{ item?.value }}"
                  nzLabel="{{ item?.label }}"
                ></nz-option>
              </ng-container>
            </ng-container>
          </nz-select>
        </nz-col>
        <nz-col [nzSpan]="12" class="mt-10">
            <ng-template
            [ngTemplateOutlet]="valueOutlet"
            [ngTemplateOutletContext]="{ filter: item }"
          ></ng-template>
        </nz-col>
        <ng-container *ngIf="filterby?.length > 1">
          <nz-col [nzSpan]="24" class="mt-10 text-end color_blue f-12 fw-400">
            <span class="cPointer" (click)="filterby.splice(i, 1); submit()"
              >Delete</span
            >
          </nz-col>
        </ng-container>
        <ng-container *ngIf="!last">
          <nz-col [nzSpan]="24" class="mt-10 text-center">
            <nz-switch
              (ngModelChange)="changeOperatorAdd($event, i)"
              [(ngModel)]="filterby[0].key4"
              [disabled]="i != 0"
              nzCheckedChildren="AND"
              nzUnCheckedChildren="OR"
            ></nz-switch>
          </nz-col>
        </ng-container>
      </nz-row>
    </ng-container>
    <ng-container *ngIf="Array.isArray(item)">
      <div
        style="
          width: 100%;
          background-color: #f2f2f2;
          padding: 10px;
          margin-top: 10px;
          border-radius: 8px;
        "
      >
        <ng-container
          *ngFor="let childFilter of item; let j = index; let final = last"
        >
          <nz-row [nzGutter]="16">
            <nz-col [nzSpan]="12" class="mt-10">
              <nz-select
                class="w-100"
                nzPlaceHolder="Select field"
                [nzDropdownClassName]="'reverse_ant-select-dropdown'"
                [nzDropdownRender]="selectSearchTemplateField"
                [(ngModel)]="childFilter.entity_name"
                (ngModelChange)="
                  selectedEntities($event, item);
                  call_save($event, childFilter, childFilter.entity_name)
                "
              >
                <ng-template #selectSearchTemplateField> </ng-template>
                <ng-container *ngFor="let option of entitiesData">
                  <ng-container *ngIf="option.label">
                    <nz-option
                      nzValue="{{ option?.field_name }}"
                      nzLabel="{{ option?.label }}"
                    ></nz-option>
                  </ng-container>
                </ng-container>
              </nz-select>
            </nz-col>
            <nz-col [nzSpan]="12" class="mt-10">
              <nz-select
                class="w-100"
                [(ngModel)]="childFilter.key1"
                (ngModelChange)="
                  filterTypeFieldChangeGroups(
                    $event,
                    i,
                    j,
                    childFilter.entity_name
                  );
                  call_save($event, childFilter, childFilter.key1)
                "
                nzPlaceHolder="Select field"
                [nzDropdownClassName]="'reverse_ant-select-dropdown'"
                [nzDropdownRender]="selectSearchTemplateField"
              >
                <ng-template #selectSearchTemplateField> </ng-template>
                <ng-container
                  *ngFor="let option of getFields('', childFilter.entity_name)"
                >
                  <ng-container *ngIf="option.label">
                    <nz-option
                      nzValue="{{ option?.name }}"
                      nzLabel="{{ option?.label }}"
                    ></nz-option>
                  </ng-container>
                </ng-container>
              </nz-select>
            </nz-col>
            <nz-col [nzSpan]="12" class="mt-10">
              <nz-select
                [(ngModel)]="childFilter.key2"
                (ngModelChange)="call_save($event, childFilter, childFilter.key2)"
                class="w-100"
                nzPlaceHolder="Select field"
                [nzDropdownClassName]="'reverse_ant-select-dropdown'"
                [nzDropdownRender]="selectSearchTemplateField"
              >
                <ng-template #selectSearchTemplateField> </ng-template>
                <ng-container
                  *ngFor="
                    let item of getOperators(
                      childFilter?.key1,
                      childFilter.entity_name
                    )
                  "
                >
                  <ng-container *ngIf="item.label">
                    <nz-option
                      nzValue="{{ item?.value }}"
                      nzLabel="{{ item?.label }}"
                    ></nz-option>
                  </ng-container>
                </ng-container>
              </nz-select>
            </nz-col>
            <nz-col [nzSpan]="12" class="mt-10">
                <ng-template
                [ngTemplateOutlet]="valueOutlet"
                [ngTemplateOutletContext]="{ filter: childFilter }"
              ></ng-template>
            </nz-col>
            <ng-container>
              <nz-col [nzSpan]="24" class="mt-10 text-end color_blue f-12 fw-400">
                <span class="cPointer" (click)="deleteGroupFilter(i, j)"
                  >Delete</span
                >
              </nz-col>
            </ng-container>
            <ng-container *ngIf="!final">
              <nz-col [nzSpan]="24" class="mt-10 text-center">
                <nz-switch
                  [(ngModel)]="item[0].key4"
                  [disabled]="i != 0"
                  nzCheckedChildren="AND"
                  nzUnCheckedChildren="OR"
                ></nz-switch>
              </nz-col>
            </ng-container>
          </nz-row>
        </ng-container>
        <ng-container>
          <div
            class="f-12 fw-400 mt-20 color_blue"
            style="column-gap: 15px; display: flex"
          >
            <span class="cPointer" (click)="addNewGroupFilter(i)">++Filter</span>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
  <ng-container>
    <div
      class="f-12 fw-400 mt-20 color_blue"
      style="column-gap: 15px; display: flex"
    >
      <span class="cPointer" (click)="addFilterOne()">++Filter</span>
      <span class="cPointer" (click)="addGroupOne()">++Group</span>
    </div>
  </ng-container>
  
  <ng-template #valueOutlet let-filter="filter">
    <ng-container
      *ngIf="findItsDataTypeValue(filter.key1,filter.entity_name) as field; else default"
    >
      <div [ngSwitch]="field.datatype">
        <div *ngSwitchCase="'email'">
          <input
            (blur)="call_save(filter.key3, filter, filter.key3)"
            [(ngModel)]="filter.key3"
            nz-input
            class="w_100"
          />
        </div>
        <div *ngSwitchCase="'location'">
          <input
            (blur)="call_save(filter.key3, filter, filter.key3)"
            [(ngModel)]="filter.key3"
            nz-input
            class="w_100"
          />
        </div>
  
        <div *ngSwitchCase="'website'">
          <input
            (blur)="call_save(filter.key3, filter, filter.key3)"
            [(ngModel)]="filter.key3"
            nz-input
            class="w_100"
          />
        </div>
  
        <div *ngSwitchCase="'textarea'">
          <input
            (blur)="call_save(filter.key3, filter, filter.key3)"
            [(ngModel)]="filter.key3"
            nz-input
            class="w_100"
          />
        </div>
  
        <div *ngSwitchCase="'textbox'">
          <input
            (blur)="call_save(filter.key3, filter, filter.key3)"
            [(ngModel)]="filter.key3"
            nz-input
            class="w_100"
          />
        </div>
  
        <div *ngSwitchCase="'phone'">
          <input
            (blur)="call_save(filter.key3, filter, filter.key3)"
            [(ngModel)]="filter.key3"
            nz-input
            oninput="this.value = this.value.replace(/[^0-9]/g, '');"
            class="w_100"
          />
        </div>
  
        <div *ngSwitchCase="'currency'">
          <input
            (blur)="call_save(filter.key3, filter, filter.key3)"
            [(ngModel)]="filter.key3"
            nz-input
            oninput="this.value = this.value.replace(/(?!^-)[^0-9.]+/g, '').replace(/(\..*)\./g, '$1').replace(/(?!^)-/g, '');"
            class="w_100"
          />
        </div>
  
        <div *ngSwitchCase="'number'">
          <input
            (blur)="call_save(filter.key3, filter, filter.key3)"
            [(ngModel)]="filter.key3"
            nz-input
            oninput="this.value = this.value.replace(/(?!^-)[^0-9.]+/g, '').replace(/(\..*)\./g, '$1').replace(/(?!^)-/g, '');"
            class="w_100"
          />
        </div>
  
        <div *ngSwitchCase="'checkbox'"></div>
  
        <div *ngSwitchCase="'rating'">
          <div class="">
            <nz-rate
              [nzCharacter]="characterIcon"
              #rate
              [(ngModel)]="filter.key3"
              [nzCount]="field.end_value"
              (ngModelChange)="call_save(filter.key3, filter, filter.key3)"
              class="w_100"
            >
              <ng-template #characterIcon let-index>
                <img
                  *ngIf="!getHoverValue(rate, index)"
                  [src]="getEmojiImage(field.emoji_value, 'blue')"
                  style="width: 15px"
                />
                <img
                  *ngIf="getHoverValue(rate, index)"
                  [src]="getEmojiImage(field.emoji_value, 'white')"
                  style="width: 15px"
                />
              </ng-template>
            </nz-rate>
          </div>
        </div>
  
        <div *ngSwitchCase="'progress'">
          <nz-row [nzAlign]="'middle'" class="w_100">
            <nz-col [nzSpan]="18" class="slidersContainer">
              <nz-slider
                [ngModel]="convertToString(filter.key3)"
                (ngModelChange)="
                  filter.key3 = convertToString($event);
                  call_save(filter.key3, filter, filter.key3)
                "
                
              ></nz-slider>
            </nz-col>
            <nz-col [nzSpan]="6" style="font-size: 9px">
              {{ filter.key3 || "0" }} %
            </nz-col>
          </nz-row>
        </div>
  
        <div *ngSwitchCase="'date'">
          <div class="w_100" style="min-height: 37px">
            <nz-date-picker
              style="min-height: 37px"
              [ngModel]="filter.key3"
              (ngModelChange)="
                $event
                  ? (filter.key3 = moment($event).format('YYYY-MM-DD'))
                  : (filter.key3 = $event);
                call_save(filter.key3, filter, filter.key3)
              "
            ></nz-date-picker>
          </div>
        </div>
  
        <div *ngSwitchCase="'people'">
          <nz-select
            class="dropdownui w-100"
            nzPlaceHolder="-"
            class="w_100"
            [(ngModel)]="filter.key3"
            (nzOpenChange)="
              openDropDownPeopleChange(field, '', $event);
              call_save(filter.key3, filter, filter.key3)
            "
            nzMode="multiple"
            [nzDropdownClassName]="'reverse_ant-select-dropdown'"
            [nzDropdownRender]="selectSearchTemplatepeople"
          >
            <ng-template #selectSearchTemplatepeople>
              <input
                #searchF7
                nz-input
                placeholder="Search..."
                (input)="filterpeopleOptions(searchF7.value)"
              />
            </ng-template>
            <ng-container *ngIf="filter.key3">
              <ng-container *ngFor="let item of filter.key3">
                <nz-option            
                [nzLabel]="item"
                [nzValue]="item"
                nzHide
              ></nz-option>
              </ng-container>
  
            </ng-container>
            <nz-layout *ngFor="let option of getPeopleOptionList$ | async">
              <nz-option [nzLabel]="option" [nzValue]="option"> </nz-option>
            </nz-layout>
          </nz-select>
        </div>
  
        <div *ngSwitchCase="'dropdown_single_select'">
          <ng-container *ngIf="field?.option_type === 'STATIC'">
            <nz-select
              class="w_100"
              nzPlaceHolder="-"
              [(ngModel)]="filter.key3"
              (ngModelChange)="call_save($event, filter, filter.key3)"
              nzMode="multiple"
              [nzDropdownClassName]="'reverse_ant-select-dropdown'"
              [nzDropdownRender]="selectSearchTemplateSingleStatic"
              (click)="multiStaticInput = ''"
            >
              <ng-template #selectSearchTemplateSingleStatic>
                <input
                  #search1
                  nz-input
                  placeholder="Search..."
                  [(ngModel)]="multiStaticInput"
                  (input)="getMultiStaticOption(field.options, multiStaticInput)"
                />
              </ng-template>
              <!--  -->
              <!-- <nz-option
                  *ngFor="
                    let option of getMultiStaticOption(
                      field.options,
                      multiStaticInput
                    )
                  "
                  [nzValue]="option.name"
                  nzLabel="{{ option.name }}"
                ></nz-option> -->
              <ng-container
                *ngFor="
                  let option of getMultiStaticOption(
                    field.options,
                    multiStaticInput
                  )
                "
              >
              <ng-container *ngIf="option.name !== ''">
                <nz-option                
                  [nzValue]="option.name"
                  [nzLabel]="option.name"
                ></nz-option>
              </ng-container>
              </ng-container>
              <ng-container *ngFor="let item of filter.key3">
                <nz-option
                *ngIf="filter.key3"
                [nzLabel]="item"
                [nzValue]="item"
                nzHide
              ></nz-option>
              </ng-container>
            </nz-select>
          </ng-container>
          <ng-container *ngIf="field?.option_type === 'ENTITY_DATA'">
            <nz-select
              [(ngModel)]="filter.key3"
              (nzOpenChange)="openDropDownEntityChange(field, '', $event)"
              (ngModelChange)="call_save(filter.key3, filter, filter.key3)"
              nzPlaceHolder="Select option"
              class="w_100"
              [nzDropdownClassName]="'reverse_ant-select-dropdown'"
              [nzDropdownRender]="selectSearchTemplatesingleEntity"
              [nzShowArrow]="true"
              nzMode="multiple"
            >
              <ng-template #selectSearchTemplatesingleEntity>
                <input
                  #search
                  nz-input
                  placeholder="Search..."
                  (input)="openDropDownEntityChange(field, search.value, true)"
                />
              </ng-template>
              <nz-layout *ngFor="let option of getEntityptionList$ | async">
                <nz-option
                  [nzLabel]="option.entity_data"
                  [nzValue]="option.entity_data"
                >
                </nz-option>
              </nz-layout>
              <ng-container *ngIf="filter.key3">
                <ng-container *ngFor="let item of filter.key3">
                  <nz-option            
                  [nzLabel]="item"
                  [nzValue]="item"
                  nzHide
                ></nz-option>
                </ng-container>
              </ng-container>
            </nz-select>
          </ng-container>
          <ng-container *ngIf="field?.option_type === 'API'" class="w-100">
            <nz-select
              class="dropdownui"
              (ngModelChange)="call_save(filter.key3, filter, filter.key3)"
              nzPlaceHolder="-"
              class="w_100"
              [(ngModel)]="filter.key3"
              nzMode="multiple"
              (nzOpenChange)="openDropDownAPIChange(field, '', $event)"
              [nzDropdownClassName]="'reverse_ant-select-dropdown'"
              [nzDropdownRender]="selectSearchTemplateFieldSingleApi"
            >
              <ng-template #selectSearchTemplateFieldSingleApi>
                <input
                  #searchF2
                  nz-input
                  placeholder="Search..."
                  (input)="filterApiOptions(searchF2.value)"
                />
              </ng-template>
              <ng-container *ngIf="filter.key3">
                <ng-container *ngFor="let item of filter.key3">
                  <nz-option            
                  [nzLabel]="item"
                  [nzValue]="item"
                  nzHide
                ></nz-option>
                </ng-container>
              </ng-container>
              <nz-layout *ngFor="let option of getApiOptionList$ | async">
                <nz-option [nzLabel]="option" [nzValue]="option"> </nz-option>
              </nz-layout>
            </nz-select>
          </ng-container>
        </div>
  
        <div *ngSwitchCase="'dropdown_multi_select'">
          <ng-container *ngIf="field?.option_type === 'STATIC'">
            <nz-select
              class="w_100"
              nzPlaceHolder="-"
              [(ngModel)]="filter.key3"
              (ngModelChange)="call_save($event, filter, filter.key3)"
              
              nzMode="multiple"
              [nzDropdownClassName]="'reverse_ant-select-dropdown'"
              [nzDropdownRender]="selectSearchTemplateFieldStatic"
              (click)="multiStaticInput = ''"
            >
              <ng-template #selectSearchTemplateFieldStatic>
                <input
                  #search1
                  nz-input
                  placeholder="Search..."
                  [(ngModel)]="multiStaticInput"
                  (input)="getMultiStaticOption(field.options, multiStaticInput)"
                />
              </ng-template>
              <ng-container *ngIf="filter.key3">
                <ng-container *ngFor="let item of filter.key3">
                  <nz-option            
                  [nzLabel]="item"
                  [nzValue]="item"
                  nzHide
                ></nz-option>
                </ng-container>
              </ng-container>
              <nz-option
                *ngFor="
                  let option of getMultiStaticOption(
                    field.options,
                    multiStaticInput
                  )
                "
                [nzValue]="option.name"
                nzLabel="{{ option.name }}"
              ></nz-option>
            </nz-select>
          </ng-container>
          <ng-container *ngIf="field?.option_type === 'ENTITY_DATA'">
            <nz-select
              [(ngModel)]="filter.key3"
              (nzOpenChange)="openDropDownEntityChange(field, '', $event)"
              (ngModelChange)="call_save(filter.key3, filter, filter.key3)"
              nzPlaceHolder="Select option"
              class="w_100"
              [nzDropdownClassName]="'reverse_ant-select-dropdown'"
              [nzDropdownRender]="selectSearchTemplateFieldEntity"
              [nzShowArrow]="true"
              nzMode="multiple"
            >
              <ng-template #selectSearchTemplateFieldEntity>
                <input
                  #searchF4
                  nz-input
                  placeholder="Search..."
                  (input)="openDropDownEntityChange(field, searchF4.value, true)"
                />
              </ng-template>
              <ng-container *ngIf="filter.key3">
                <ng-container *ngFor="let item of filter.key3">
                  <nz-option            
                  [nzLabel]="item"
                  [nzValue]="item"
                  nzHide
                ></nz-option>
                </ng-container>
              </ng-container>
              <nz-layout *ngFor="let option of getEntityptionList$ | async">
                <nz-option
                  [nzLabel]="option?.entity_data"
                  [nzValue]="option?.entity_data"
                >
                </nz-option>
              </nz-layout>
            </nz-select>
          </ng-container>
          <ng-container *ngIf="field?.option_type === 'API'" class="w-100">
            <nz-select
              class="dropdownui w-100"
              nzPlaceHolder="-"
              class="w_100"
              [(ngModel)]="filter.key3"
              (nzOpenChange)="openDropDownAPIChange(field, '', $event)"
              (ngModelChange)="call_save(filter.key3, filter, filter.key3)"
              nzMode="multiple"
              [nzDropdownClassName]="'reverse_ant-select-dropdown'"
              [nzDropdownRender]="selectSearchTemplateFieldApi"
            >
              <ng-template #selectSearchTemplateFieldApi>
                <input
                  #searchF2
                  nz-input
                  placeholder="Search..."
                  (input)="filterApiOptions(searchF2.value)"
                />
              </ng-template>
              <ng-container *ngIf="filter.key3">
                <ng-container *ngFor="let item of filter.key3">
                  <nz-option            
                  [nzLabel]="item"
                  [nzValue]="item"
                  nzHide
                ></nz-option>
                </ng-container>
              </ng-container>
              <nz-layout *ngFor="let option of getApiOptionList$ | async">
                <nz-option [nzLabel]="option" [nzValue]="option"> </nz-option>
              </nz-layout>
            </nz-select>
          </ng-container>
        </div>
        <div *ngSwitchCase="'status'">
          <ng-container>
            <nz-select
              class="w_100"
              nzPlaceHolder="-"
              [(ngModel)]="filter.key3"
              (ngModelChange)="call_save(filter.key3, filter, filter.key3)"
              nzMode="multiple"
              [nzDropdownClassName]="'reverse_ant-select-dropdown'"
              [nzDropdownRender]="selectSearchTemplatestatus"
              (click)="statusInput = ''"
            >
              <ng-template #selectSearchTemplatestatus>
                <input
                  #search1
                  nz-input
                  placeholder="Search..."
                  [(ngModel)]="statusInput"
                  (input)="
                    getStatusOptions(
                      field.open_status,
                      field.close_status,
                      statusInput
                    )
                  "
                />
              </ng-template>
              <ng-container *ngIf="filter.key3">
                <ng-container *ngFor="let item of filter.key3">
                  <nz-option            
                  [nzLabel]="item"
                  [nzValue]="item"
                  nzHide
                ></nz-option>
                </ng-container>
              </ng-container>
              <nz-option
                *ngFor="
                  let option of getStatusOptions(
                    field.open_status,
                    field.close_status,
                    statusInput
                  )
                "
                [nzValue]="option.name"
                nzLabel="{{ option.name }}"
              ></nz-option>
            </nz-select>
          </ng-container>
        </div>
        <div *ngSwitchCase="'tag'">
          <ng-container>
            <nz-select
              class="w_100"
              nzPlaceHolder="-"
              [(ngModel)]="filter.key3"
              (ngModelChange)="call_save(filter.key3, filter, filter.key3)"
              nzMode="multiple"
              [nzDropdownClassName]="'reverse_ant-select-dropdown'"
              [nzDropdownRender]="selectSearchTemplatetag"
              (click)="multiStaticInput = ''"
            >
              <ng-template #selectSearchTemplatetag>
                <input
                  #search1
                  nz-input
                  placeholder="Search..."
                  [(ngModel)]="multiStaticInput"
                  (input)="getMultiStaticOption(field.options, multiStaticInput)"
                />
              </ng-template>
              <ng-container *ngIf="filter.key3">
                <ng-container *ngFor="let item of filter.key3">
                  <nz-option            
                  [nzLabel]="item"
                  [nzValue]="item"
                  nzHide
                ></nz-option>
                </ng-container>
              </ng-container>
              <nz-option
                *ngFor="
                  let option of getMultiStaticOption(
                    field.options,
                    multiStaticInput
                  )
                "
                [nzValue]="option.name"
                nzLabel="{{ option.name }}"
              ></nz-option>
            </nz-select>
          </ng-container>
        </div>
        <div *ngSwitchCase="'priority'">
          <nz-select
            class="w_100"
            nzPlaceHolder="-"
            [(ngModel)]="filter.key3"
            (ngModelChange)="call_save(filter.key3, filter, filter.key3)"
            nzMode="multiple"
            [nzDropdownClassName]="'reverse_ant-select-dropdown'"
            (click)="multiStaticInput = ''"
          >
            <ng-template #selectSearchTemplateFieldStatic> </ng-template>
            <nz-option
              *ngFor="let option of field?.options"
              [nzValue]="option?.name"
              [nzLabel]="option?.name"
            ></nz-option>
          </nz-select>
        </div>
        <div *ngSwitchCase="'time'">
          <div
            class="w_100"
            class="timerInputstyle"
            nz-dropdown
            nzTrigger="click"
            [nzDropdownMenu]="timeMenu"
            (click)="onOpenTime(field, filter)"
            style="font-size: 11px"
          >
            {{ getTimeValue(filter) || " " }}
          </div>
  
          <nz-dropdown-menu #timeMenu="nzDropdownMenu">
            <ul nz-menu class="timerMenuStyle">
              <nz-tabset [(nzSelectedIndex)]="selectedRTimeTabIndex">
                <nz-tab nzTitle="Manual">
                  <div style="padding: 0 15px">
                    <input
                      oninput="this.value = this.value.replace(/[^0-9]/g, '');"
                      class="f-12"
                      [(ngModel)]="manualTime"
                      type="text"
                      nz-input
                      nzPlaceHolder="-"
                    />
                  </div>
                </nz-tab>
              </nz-tabset>
              <div class="btnAlign">
                <div class="primaryBtnStyle">
                  <button
                    nz-menu-item
                    nz-button
                    nzType="primary"
                    class="save"
                    (click)="
                      saveTime(filter);call_save(filter.key3, filter, filter.key3)
                    "
                  >
                    SAVE
                  </button>
                </div>
                <div class="text-center f-10 fw-700 lh-18 blueColor cPointer">
                  <button
                    nz-menu-item
                    nz-button
                    nzType="default"
                    class="buttonDefault f-10 fw-700"
                    style="height: 37px !important; border-radius: 5px"
                  >
                    CANCEL
                  </button>
                </div>
              </div>
            </ul>
          </nz-dropdown-menu>
        </div>
        <div *ngSwitchDefault>
          <input
            [(ngModel)]="filter.key3"
            nz-input
            (blur)="call_save(filter.key3, filter, filter.key3)"
            nzPlaceHolder="in"
            class="w_100"
          />
        </div>
      </div>
    </ng-container>
  
    <ng-template #default>
      <input
        [(ngModel)]="filter.key3"
        nz-input
        nzPlaceHolder="in"
        class="w_100"
      />
    </ng-template>
  </ng-template>
  