<div class="px-10 textStyle" fluidRowWidth [type]="header.name">
  <nz-layout style="width: 100%!important;
           
display: flex;
align-items: center;">
<ng-container *ngIf="editClicked">
  <ng-container *ngIf="header?.option_type === 'STATIC'" class="w-100 selectContainer">
    <nz-select [nzMode]="'multiple'" (nzOpenChange)="handleDropdownClose($event)" [nzOpen]="true"
      class="dropdownui w-100 f-12 text-start" nzPlaceHolder="-" [(ngModel)]="data[header.name]"
      (ngModelChange)="stopEdit()" [nzDropdownClassName]="'reverse_ant-select-dropdown'" #staticSelectFieldRef
      nzAllowClear [nzDropdownRender]="searchTemplateStatic">
      <ng-template #searchTemplateStatic>
        <input #saearhx2 nz-input placeholder="Search..." (input)="filter(saearhx2.value)
        " />
      </ng-template>
      <nz-option *ngIf="data[header.name] || header.default" [nzLabel]="data[header.name] || header.default"
        [nzValue]="data[header.name] || header.default" nzHide></nz-option>
      <ng-container>
        <nz-layout *ngFor="let option of  filteredOptions;">
          <nz-option [nzLabel]="option?.name" [nzValue]="option?.name"></nz-option>
        </nz-layout>
      </ng-container>
    </nz-select>
  </ng-container>
  <ng-container *ngIf="header?.option_type === 'ENTITY_DATA'">
    <nz-select (nzOpenChange)="handleDropdownClose($event);" [nzMode]="'multiple'" [nzOpen]="true"
      class="dropdownui w-100 f-12 text-start" nzPlaceHolder="-" [(ngModel)]="data[header.name]"
      (ngModelChange)="emit_entity_data($event);checkIsunique()" [nzDropdownClassName]="'reverse_ant-select-dropdown'" #entitySelectFieldRef
      nzAllowClear [nzDropdownRender]="searchTemplateEntity">
      <ng-template #searchTemplateEntity>
        <input #saearhx2 nz-input placeholder="Search..."
          (input)="openDropDownEntityChange(header, saearhx2.value, true)" />
      </ng-template>
      <nz-option *ngIf="data[header.name] || header.default" [nzLabel]="data[header.name] || header.default"
        [nzValue]="data[header.name] || header.default" nzHide></nz-option>
      <ng-container>
        <nz-layout *ngFor="let option of  getEntityptionList$ | async;">
          <nz-option [nzLabel]="option?.entity_data" [nzValue]="option?.entity_data"></nz-option>
        </nz-layout>
      </ng-container>
    </nz-select>
  </ng-container>
  <ng-container *ngIf="header?.option_type === 'API'" class="w-100">
    <nz-select [nzMode]="'multiple'" (nzOpenChange)="handleDropdownClose($event)" [nzOpen]="true"
      class="dropdownui w-100 f-12 text-start" nzPlaceHolder="-" [(ngModel)]="data[header.name]"
      (ngModelChange)="stopEdit()" [nzDropdownClassName]="'reverse_ant-select-dropdown'" #apiSelectFieldRef nzAllowClear
      [nzDropdownRender]="searchTemplate">
      <ng-template #searchTemplate>
        <input #saearhx nz-input placeholder="Search..." (input)="openDropDownAPIChange(header, saearhx.value, true)" />
      </ng-template>
      <nz-option *ngIf="data[header.name] || header.default" [nzLabel]="data[header.name] || header.default"
        [nzValue]="data[header.name] || header.default" nzHide></nz-option>
      <ng-container *ngIf="fields.length > 0">
        <nz-layout *ngFor="let option of fields">
          <nz-option [nzLabel]="option" [nzValue]="option"></nz-option>
        </nz-layout>
      </ng-container>
    </nz-select>
  </ng-container>
</ng-container>
<div style="color: red ;font-size: smaller;text-align: left;">
  {{header.error}}
  </div>
<ng-container *ngIf="!editClicked">
  <div (click)="onEditClick()" class="showDefault " style="text-align: start;cursor: pointer;font-size: 12px;align-items: center;
  display: flex;min-height: 37px; word-break: break-all;">{{data[header.name] || header.default ||'-'}}</div>
</ng-container>
  </nz-layout>
</div>