import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Observable, map, shareReplay } from 'rxjs';
@Component({
  selector: 'app-common-filter',
  templateUrl: './common-filter.component.html',
  styleUrls: ['./common-filter.component.scss'],
})
export class CommonFilterComponent implements OnInit {
  @Input() filters: any = [{ key1: '', key2: '', key3: '', key4: true }];
  // @Input()totalFields:any;
  @Input() totalEntities: any;
  @Input() stateService: any;
  @Input() tabName: any;
  Array = Array;
  searchField: any = '';
  moment = moment;
  datatypesOperator: any = {
    dropdown_single_select: [
      { label: 'in', value: '$in' },
      { label: 'not in', value: '$nin' },
    ],
    dropdown_multi_select: [
      { label: 'in', value: '$jin' },
      { label: 'not in', value: '$jnin' },
    ],
    status: [
      { label: 'in', value: '$in' },
      { label: 'not in', value: '$nin' },
    ],
    location: [
      { label: 'is', value: '' },
      { label: 'is not', value: '$ne' },
    ],
    textarea: [
      { label: 'contains', value: '$like' },
      { label: 'does not contains', value: '$nlike' },
    ],
    checkbox: [
      { label: 'is selected', value: 'true' },
      { label: 'is not selected', value: 'false' },
    ],
    rating: [
      { label: '=', value: '' },
      { label: '<>', value: '$ne' },
      { label: '>=', value: '$gte' },
      { label: '<=', value: '$lte' },
      { label: '>', value: '$gt' },
      { label: '<', value: '$lt' },
    ],
    currency: [
      { label: '=', value: '' },
      { label: '<>', value: '$ne' },
      { label: '>=', value: '$gte' },
      { label: '<=', value: '$lte' },
      { label: '>', value: '$gt' },
      { label: '<', value: '$lt' },
    ],
    number: [
      { label: '=', value: '' },
      { label: '<>', value: '$ne' },
      { label: '>=', value: '$gte' },
      { label: '<=', value: '$lte' },
      { label: '>', value: '$gt' },
      { label: '<', value: '$lt' },
    ],
    labels: [
      { label: 'is', value: '$in' },
      { label: 'is not', value: '$nin' },
    ],
    website: [
      { label: 'is', value: '' },
      { label: 'is not', value: '$ne' },
      { label: 'contains', value: '$like' },
      { label: 'does not contains', value: '$nlike' },
    ],
    people: [
      { label: 'in', value: '$jin' },
      { label: 'not in', value: '$jnin' },
    ],
    phone: [
      { label: 'is', value: '' },
      { label: 'is not', value: '$ne' },
      { label: 'contains', value: '$like' },
      { label: 'does not contains', value: '$nlike' },
    ],
    progress: [
      { label: '=', value: '' },
      { label: '<>', value: '$ne' },
      { label: '>=', value: '$gte' },
      { label: '<=', value: '$lte' },
      { label: '>', value: '$gt' },
      { label: '<', value: '$lt' },
    ],
    date: [
      { label: 'is', value: '' },
      { label: 'is not', value: '$ne' },
      { label: 'is after or equal', value: '$gte' },
      { label: 'is before or equal', value: '$lte' },
      { label: 'is after', value: '$gt' },
      { label: 'is before', value: '$lt' },
    ],
    textbox: [
      { label: 'is', value: '' },
      { label: 'is not', value: '$ne' },
      { label: 'contains', value: '$like' },
      { label: 'does not contains', value: '$nlike' },
    ],
    email: [
      { label: 'is', value: '' },
      { label: 'is not', value: '$ne' },
      { label: 'contains', value: '$like' },
      { label: 'does not contains', value: '$nlike' },
    ],
    tag: [
      { label: 'in', value: '$jin' },
      { label: 'not in', value: '$jnin' },
    ],
    time: [
      { label: '=', value: '' },
      { label: '<>', value: '$ne' },
      { label: '>=', value: '$gte' },
      { label: '<=', value: '$lte' },
      { label: '>', value: '$gt' },
      { label: '<', value: '$lt' },
    ],
    priority: [
      { label: 'in', value: '$in' },
      { label: 'not in', value: '$nin' },
    ],
  };
  getApiOptionList$: any = new Observable();
  getPeopleOptionList$: any = new Observable();
  entitiesData: any = [];
  totalFields: any;
  ngOnInit() {
    let entitiesData = [];
    let entities = this.totalEntities;
    let defaultEntities = entities?.find((_e: any) => _e.name === this.tabName);
    if (defaultEntities) {
      let data = {
        label: defaultEntities?.display_name,
        entity_name: defaultEntities?.name,
        field_name:defaultEntities?.name,
        default:true
      };
      entitiesData.push(data);
    }

    defaultEntities.fields?.map((_field: any) => {
      if (
        (_field.datatype === 'dropdown_single_select' &&
          _field.option_type === 'ENTITY_DATA') ||
        (_field.datatype === 'dropdown_multi_select' &&
          _field.option_type === 'ENTITY_DATA')
      ) {
        let data = {
          label: _field?.label,
          field_name:_field.name,
          entity_name: _field?.entity_name,
          default:false
        };
        entitiesData.push(data);
      }

    });
    this.entitiesData = entitiesData
    
  }
  selectedEntities($event: any,filter:any) {
    let entities = this.totalEntities;
    this.totalFields = entities
      ?.find((_e: any) => _e.name === $event)
      ?.fields.sort((a: any, b: any) => a.label.localeCompare(b.label));
      if($event){
        filter.key1=''
        filter.key2=''
        filter.key3=''
        filter.key4=true
      }
  }
  changeOperatorAdd(e: any, i: any) {
    this.filters.map((item: any, index: any) => {
      if (!this.Array.isArray(item) && index != 0) {
        item.key4 = e;
      } else {
        if (this.Array.isArray(item)) {
          for (let i of item) {
            if (!this.Array.isArray(i)) {
              i.key4 = !e;
            } else {
              if (this.Array.isArray(i)) {
                for (let j of i) {
                  j.key4 = e;
                }
              }
            }
          }
        }
      }
    });
  }
  filterTypeFieldChange(event: any, i: any, entityName: string) {
   let entity_Name= this.entitiesData?.find((_e:any)=>_e?.field_name === entityName)?.entity_name
    const fields = this.stateService
      .appState()
      ?.metaData?.entities?.find((_e: any) => _e.name === entity_Name)?.fields;
    const selectedField = fields?.find((item: any) => item?.name === event);
    if (selectedField) {
      if (
        [
          'dropdown_single_select',
          'people',
          'dropdown_multi_select',
          'status',
          'tag',
          'priority',
        ].includes(selectedField?.datatype)
      ) {
        this.filters[i].key3 = [];
      }
      if (
        ['currency', 'progress', 'number', 'ratings', 'phone'].includes(
          selectedField?.datatype
        )
      ) {
        this.filters[i].key3 = null;
      }
      this.filters[i].key2 =
        this.getOperators(this.filters[i]?.key1, entityName)?.[0]?.value || '';
    }
  }
  getFields(searchField: any, entityName: string): any {
    let entity_Name= this.entitiesData?.find((_e:any)=>_e?.field_name === entityName)?.entity_name;
    if (entityName) {
      let filterFields = this.stateService
        .appState()
        ?.metaData?.entities?.find((_e: any) => _e.name === entity_Name)
        ?.fields?.filter(
          (e: any) =>
            e?.datatype !== 'entity' &&
            e?.datatype !== 'textarea' &&
            e?.datatype !== 'attachment'
        )
        ?.sort((a: any, b: any) => a.label?.localeCompare(b.label));
      if (filterFields && searchField !== '') {
        return filterFields?.filter((e: any) =>
          e.label?.toLowerCase()?.includes(searchField?.toLowerCase())
        );
      } else {
        return filterFields;
      }
    }
  }
  getOperators(field: any, entityName: string): any {
    let entity_Name= this.entitiesData?.find((_e:any)=>_e?.field_name === entityName)?.entity_name;

    if (entityName) {
      const fields = this.stateService
        .appState()
        ?.metaData?.entities?.find((_e: any) => _e.name === entity_Name)
        ?.fields?.filter(
          (e: any) =>
            e?.datatype !== 'entity' &&
            e?.datatype !== 'textarea' &&
            e?.datatype !== 'attachment'
        );
      const selectedtype = fields?.find(
        (item: any) => item?.name === field
      )?.datatype;
      const operators = this.datatypesOperator[selectedtype];
      return operators;
    }
  }
  deleteItem(i: any) {
    this.filters.splice(i, 1);
  }
  changegroupOperatorAdd(e: any, i: any, j: any) {
    this.filters[i].map((item: any, index: any) => {
      if (!this.Array.isArray(item)) {
        item.key4 = e;
      }
    });
  }
  filterTypeFieldChangeGroups(event: any, i: any, j: any, entityName: string) {
    let entity_Name= this.entitiesData?.find((_e:any)=>_e?.field_name === entityName)?.entity_name;

    const fields = this.stateService
      .appState()
      ?.metaData?.entities?.find((_e: any) => _e.name === entity_Name)
      ?.fields?.filter(
        (e: any) =>
          e?.datatype !== 'entity' &&
          e?.datatype !== 'textarea' &&
          e?.datatype !== 'attachment'
      );
    const selectedField = fields?.find((item: any) => item?.name === event);
    if (selectedField) {
      if (
        [
          'dropdown_single_select',
          'people',
          'dropdown_multi_select',
          'status',
          'tag',
          'priority',
        ].includes(selectedField?.datatype)
      ) {
        this.filters[i][j].key3 = [];
      }
      if (
        ['currency', 'progress', 'number', 'ratings', 'phone'].includes(
          selectedField?.datatype
        )
      ) {
        this.filters[i][j].key3 = null;
      }
    }
    this.filters[i][j].key2 = '';
  }
  deleteGroupFilter(item: any, index: any) {
    if (this.filters[item].length > 1) {
      this.filters[item].splice(index, 1);
    } else {
      const index = this.filters.splice(item, 1);
    }
  }
  filterTypeFieldChangeChildGroups(
    event: any,
    i: any,
    j: any,
    k: any,
    entityName: string
  ) {
    let entity_Name= this.entitiesData?.find((_e:any)=>_e?.field_name === entityName)?.entity_name;

    const fields = this.stateService
      .appState()
      ?.metaData?.entities?.find((_e: any) => _e.name === entity_Name)
      ?.fields?.filter(
        (e: any) =>
          e?.datatype !== 'entity' &&
          e?.datatype !== 'textarea' &&
          e?.datatype !== 'attachment'
      );
    const selectedField = fields?.find((item: any) => item?.name === event);
    if (selectedField) {
      if (
        [
          'dropdown_single_select',
          'people',
          'dropdown_multi_select',
          'status',
          'tag',
          'priority',
        ].includes(selectedField?.datatype)
      ) {
        this.filters[i][j][k].key3 = [];
      }
      if (
        ['currency', 'progress', 'number', 'ratings', 'phone'].includes(
          selectedField?.datatype
        )
      ) {
        this.filters[i][j][k].key3 = null;
      }
    }
    this.filters[i][j][k].key2 = '';
  }
  deleteChildGroup(i: any, j: any, k: any) {
    if (this.filters[i][j].length > 1) {
      this.filters[i][j].splice(k, 1);
    } else {
      this.filters[i].splice(j, 1);
    }
  }
  addNewChildFilter(i: any, j: any) {
    this.filters[i][j].push({
      key1: '',
      key2: '',
      key3: '',
      key4: 'AND',
      entity_name: this.tabName,
    });
  }

  clearChildGroup(i: any, j: any) {
    this.filters[i].splice(j, 1);
  }
  addNewGroupFilter(i: any) {
    this.filters[i].push({
      key1: '',
      key2: '',
      key3: '',
      key4: this.filters[i][0].key4,
      entity_name: this.tabName,
    });
  }

  addChildGroup(i: any) {
    this.filters[i].push([
      {
        key1: '',
        key2: '',
        key3: '',
        key4: this.filters[i][0].key4,
        entity_name: this.tabName,
      },
    ]);
  }

  clearGroup(index: any) {
    this.filters.splice(index, 1);
  }

  addNewFilter() {
    this.filters.push({
      key1: '',
      key2: '',
      key3: '',
      key4: this.filters[0].key4,
      entity_name: this.tabName,
    });
  }

  addGroup() {
    this.filters.push([
      {
        key1: '',
        key2: '',
        key3: '',
        key4: !this.filters[0].key4,
        entity_name: this.tabName,
      },
    ]);
  }
  findItsDataTypeValue(field: string, filter: any) {
    let entity_Name = this.entitiesData?.find((_e:any)=>_e?.field_name === filter.entity_name)?.entity_name;
    let headersField = this.stateService
      .appState()
      ?.metaData?.entities?.find((_e: any) => _e.name === entity_Name)
      ?.fields?.filter(
        (e: any) =>
          e?.datatype !== 'entity' &&
          e?.datatype !== 'textarea' &&
          e?.datatype !== 'attachment'
      );
    let fieldInfo = headersField?.find((e: any) => e?.name == field);
    
    return fieldInfo;
  }
  emojiType: any = [
    {
      value: '(smile)',
      checked: false,
      img1: '../../../../assets/images/addNewFieldIcons/whiteSmiley.svg',
      img2: '../../../../assets/images/addNewFieldIcons/blueSmiley.svg',
    },
    {
      value: '(tick)',
      checked: false,
      img1: '../../../../assets/images/addNewFieldIcons/whiteTick.svg',
      img2: '../../../../assets/images/addNewFieldIcons/blueTick.svg',
    },
    {
      value: '(like)',
      checked: false,
      img1: '../../../../assets/images/addNewFieldIcons/whiteLike.svg',
      img2: '../../../../assets/images/addNewFieldIcons/blueLike.svg',
    },
    {
      value: '(star)',
      checked: false,
      img1: '../../../../assets/images/addNewFieldIcons/whitestar.svg',
      img2: '../../../../assets/images/addNewFieldIcons/bluestar.svg',
    },
  ];
  getEmojiImage(value: any, data: any) {
    let result = this.emojiType.find((e: any) => e.value === value);
    if (result) {
      return data === 'blue' ? result.img1 : result.img2;
    } else {
      return data === 'blue' ? this.emojiType[0].img1 : this.emojiType[0].img2;
    }
  }
  getHoverValue(c: any, i: any) {
    if (i + 1 <= c.hoverValue) {
      return true;
    } else {
      return false;
    }
  }
  priority: any = [
    {
      name: 'Urgent',
      color: 'rgb(227, 131, 136)',
    },
    {
      name: 'High',
      color: 'rgb(251, 203, 92)',
    },
    {
      name: 'Normal',
      color: 'rgb(135, 157, 255)',
    },
    {
      name: 'Low',
      color: 'rgb(101, 111, 125)',
    },
  ];
  getColor(value: any) {
    let color = this.priority.find((e: any) => e.name === value).color;
    return color || '';
  }
  convertToString(value: any) {
    return value?.toString();
  }
  savePeopleList: any;
  openDropDownPeopleChange(f: any, search = '', event?: any) {
    if (event) {
      let body = {
        org_id: this.stateService?.appState()?.selectedOrganization?.org_id,
        api_name: 'People',
        process_id: this.stateService?.appState()?.selectedProcess?.process_id,
        entity_id:[]
      };
      this.getPeopleOptionList$ = this.stateService.getApiFields(body).pipe(
        map((res: any) => {
          return res?.org.map((item: any) => item[f.api_field]);
        })
      );
      this.savePeopleList = this.getPeopleOptionList$;
    }
  }
  filterpeopleOptions(value: string) {
    this.getPeopleOptionList$ = this.savePeopleList?.pipe(
      map((options: any) =>
        options?.filter((option: any) =>
          option.toLowerCase().includes(value.toLowerCase())
        )
      )
    );
  }
  statusInput: any = '';
  getStatusOptions(o: any, c: any, search: any) {
    let combinedStatus = [...new Set([...o, ...c])];
    if (search !== '') {
      return combinedStatus?.filter((e: any) =>
        e.name?.toLowerCase()?.includes(search?.toLowerCase())
      );
    }
    return combinedStatus;
  }
  multiStaticInput: any = '';
  getMultiStaticOption(options: any, search: any) {
    if (search !== '' && options) {
      return options?.filter((e: any) =>
        e?.name?.toLowerCase().includes(search)
      );
    }
    return options;
  }
  getEntityptionList$: any = new Observable();
  openDropDownEntityChange(f: any, search = '', event?: any) {
    if (event) {
      let body = {
        org_id: this.stateService?.appState()?.selectedOrganization?.org_id,
        process_id: this.stateService?.appState()?.selectedProcess?.process_id,
        entity_name: f.entity_name,
        process_name: this.stateService
          ?.appState()
          ?.selectedProcess?.process_name?.replace(/ /g, '_'),
        field_name: f.field_name,
        limit: 50,
        skip: 0,
        field_str: search,
      };
      this.getEntityptionList$ = this.stateService
        .getentityfielddata(body)
        .pipe(
          shareReplay(),
          map((r: any) => {
            return r?.[0]?.entity_data;
          })
        );
    }
  }
  saveApiOption: any;
  openDropDownAPIChange(f: any, search = '', event?: any) {
    if (event) {
      let body = {
        org_id: this.stateService?.appState()?.selectedOrganization?.org_id,
        api_name: f.api_name,
        process_id: this.stateService?.appState()?.selectedProcess?.process_id,
        entity_id:[]
      };
      this.getApiOptionList$ = this.stateService.getApiFields(body).pipe(
        map((res: any) => {
          return res?.org
            .map((item: any) => item[f.api_field])
            .sort((a: any, b: any) =>
              a?.toLowerCase().localeCompare(b?.toLowerCase())
            );
        })
      );
      this.saveApiOption = this.getApiOptionList$;
    }
  }
  filterApiOptions(value: string) {
    this.getApiOptionList$ = this.saveApiOption.pipe(
      map((options: any) =>
        options.filter((option: any) =>
          option.toLowerCase().includes(value.toLowerCase())
        )
      )
    );
  }
  //time
  timerRange1: any;
  timerRange2: any;
  manualTime: any;
  selectedRTimeTabIndex: any = 0;
  defaultOpenValue = new Date(0, 0, 0, 0, 0, 0);
  getTimeValue(d: any) {
    let totalMinutes = Number(d.key3);
    let hours = Math.floor(totalMinutes / 60);
    let remainingMinutes = (totalMinutes - hours * 60) % 60;
    if (remainingMinutes > 60) {
      hours += Math.floor(remainingMinutes / 60);
      remainingMinutes %= 60;
    }

    return `${(hours || 0).toString().padStart(1, '0')} hr ${(
      remainingMinutes || 0
    )?.toFixed(0)} min`;
  }

  onOpenTime(d: any, f: any) {
    this.manualTime = (Number(f.key3) || 0).toFixed(0) || 0;
  }
  saveTime(filter: any) {
    filter.key3 = this.manualTime;
    this.manualTime = null;
  }
}
