<ng-container *ngIf="data?.show === 'addOrganization'">
  <nz-row [nzGutter]="10">
    <nz-col [nzSpan]="24" >
      <div class="f-16 fw-400">Organization : {{data?.orgName}}</div>
    </nz-col>
    <form nz-form [formGroup]="addOrgForm" autocomplete="off" class="w-100">
      <nz-row [nzGutter]="10">
        <nz-col [nzSpan]="12" class="mt-10">
          <nz-form-control >
            <nz-form-label class="dgrey-text InputAlignLeft">Process Name <span
                class="redColor">*</span></nz-form-label>
            <input #in1 nz-input (input)="checkExistingProcessName(in1.value,'process')" placeholder="Process Name" formControlName="processname" type="text" oninput="this.value = this.value.replace(/[^a-z_]/g, '');"/>

          </nz-form-control>
      <div *ngIf="processNameError" style="color:red;font-size: 11px;margin-left: 5px;margin-top: 2px;">Name already exists</div>
        </nz-col>
        <nz-col [nzSpan]="12" class="mt-10">
          <nz-form-control>
            <nz-form-label class="dgrey-text InputAlignLeft">Display Name<span
                class="redColor">*</span></nz-form-label>
            <input #in2 (input)="checkExistingProcessName(in2.value,'display')" nz-input placeholder="Display Name" formControlName="displayname"  type="text" oninput="this.value = this.value.replace(/[^a-zA-Z0-9_ ]/g, '').replace(/\s+/g, ' ');"/>
          </nz-form-control>
          <div *ngIf="displayNameError" style="color:red;font-size: 11px;margin-left: 5px;margin-top: 2px;">Name already exists</div>
        </nz-col>
      </nz-row>
    </form>
    <nz-col  [nzSpan]="24" class="mt-10">
      <nz-form-label class="dgrey-text InputAlignLeft">Select Icon</nz-form-label>
      <div class="selectIconContainer">
        <div class="iconMainContainer">
          <ng-container *ngFor="let item of processIcons">
            <div [ngClass]="selectedImgKey===item.key ?'greenColor':'lb-bgcolor'" class="iconContainer mt-15" (click)="selectedProcessIcon(item)">
              <img [src]="item.image">
            </div>
          </ng-container>
        </div>
        <!-- <div class="mt-15" style="position: relative;">
          <div class="spliter"></div>
          <div class="spliterText">OR</div>
        </div>
        <div class="dragImageContainer">
          Drop your image here
        </div> -->
      </div>
    </nz-col>
  </nz-row>
  <nz-row class="flex-end mt-30 mb-10">
    <div class="text-center cPointer" style="border: none!important;">
      <button style="border: none!important;padding: 0 24px;" nzType="primary" class="f-10 fw-700" type="button" (click)="save()">
        CREATE PROCESS

      </button>
    </div>
    <div class="text-center f-10 fw-700 lh-18 blueColor cPointer">
      <button nz-button nzType="default" class="buttonDefault f-10 fw-700"
        style="height: 37px; border-radius: 5px; margin-left: 20px" (click)="cancel()">
        CANCEL
      </button>
    </div>
  </nz-row>
</ng-container>
<ng-container *ngIf="data?.show === 'entity'">
  <form nz-form [formGroup]="entityForm" autocomplete="off" class="w-100">
    <nz-row [nzGutter]="10">
      <nz-col [nzSpan]="12" class="mt-10">
        <nz-form-control >
          <nz-form-label class="dgrey-text InputAlignLeft">Entity Name<span
              class="redColor">*</span></nz-form-label>
          <input nz-input placeholder="Entity Name" formControlName="entityname" type="text" oninput="this.value = this.value.replace(/[^a-zA-Z\s]/g, '');"/>
        </nz-form-control>
      </nz-col>
      <nz-col [nzSpan]="12" class="mt-10">
        <nz-form-control>
          <nz-form-label class="dgrey-text InputAlignLeft">Display Name<span
              class="redColor">*</span></nz-form-label>
          <input nz-input placeholder="Display Name" formControlName="displayname"  type="text" oninput="this.value = this.value.replace(/[^a-zA-Z\s]/g, '');"/>
        </nz-form-control>
      </nz-col>
      <div nz-col [nzSpan]="8" class="switchStyle mt-24">
        <nz-form-item>
          <nz-form-control>
            <div class="" style="display: flex; align-items: center">
              <nz-switch formControlName="isPeople" id="switch0"></nz-switch>
              <label for="switch0" class="f-12">&nbsp;&nbsp;Is People ?</label>
            </div>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzSpan]="8" class="switchStyle mt-24">
        <nz-form-item>
          <nz-form-control>
            <div class="" style="display: flex; align-items: center">
              <nz-switch formControlName="enableConversation" id="switch1"></nz-switch>
              <label for="switch1" class="f-12">&nbsp;&nbsp;Enable Conversation ?</label>
            </div>
          </nz-form-control>
        </nz-form-item>
      </div>
      <nz-col [nzSpan]="8" style="display: flex;justify-content: end;align-items: center;">
        <div class="text-center cPointer" style="border: none!important;">
          <button style="border: none!important;padding: 0 24px;" nzType="primary" class="f-10 fw-700" type="button" (click)="saveEntityForm()">
            ADD ENTITY
          </button>
        </div>
      </nz-col>
    </nz-row>
    <nz-row [nzGutter]="12">
      <nz-col [nzSpan]="24">
      <div class="textEntity mb-10" >Position this Entity</div>
      </nz-col>
    </nz-row>
    <nz-row [formGroup]="addFieldForm" class="addNewFieldContainer">
      <div formArrayName="statuesDetails" style="width: 100%;max-height: 90px;overflow-y: auto;overflow-x: hidden;">
        <nz-layout
          draggable="true"
          (dragstart)="dragStartColumn(i, $event)"
          (dragover)="allowDrop($event)"
          (drop)="dropColumn(i, $event)"
          *ngFor="let variable of statuesDetails().controls; let i = index"
          [formGroupName]="i"
        >
          <nz-row [nzGutter]="10">
            <div
              style="cursor: grab"
              nz-col
              [nzXs]="{ span: 1 }"
              [nzLg]="{ span: 1 }"
              [nzXXl]="1"
            >
              <img src="../../../../assets/images/icons/arrowDragIcon.svg" />
            </div>
            <div nz-col [nzXs]="{ span: 11 }" [nzLg]="{ span: 11 }" [nzXXl]="11">
              <div class="d-flex" style="width: 100%">
                <nz-form-item style="width: 100%">
                  <nz-form-control nzErrorTip="Enter field name">
                    <nz-input-group>
                      <input
                        #defaultFieldValue
                        nz-input
                        formControlName="name"
                        placeholder="field name"
                        readonly
                      />
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-col [nzXs]="{ span: 11 }" [nzLg]="{ span: 11 }" [nzXXl]="11">
              <div class="d-flex" style="width: 100%;align-items: center;margin-top: 5px;">
                <!-- <nz-form-item style="width: 100%">
                  <nz-form-control nzErrorTip="Enter label name">
                    <nz-input-group>
                      <input
                        #defaultFieldValue
                        nz-input
                        formControlName="label"
                        placeholder="label name"
                      />
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item> -->

                <img class="cPointer " src="../../../../assets/images/Pencil.svg" (click)="editEntityName(variable,i)">
                <img class="cPointer ml-10" src="../../../../assets/images/eye.svg" alt="">
                <img class="cPointer ml-10" src="../../../../assets/images/delete.svg" alt="">
              </div>
            </div>
          </nz-row>
        </nz-layout>
      </div>

      <!-- <nz-col [nzSpan]="24">
        <div class="addMore">
          <span  style="cursor: pointer" (click)="addMore()">++Add More</span>
        </div>
      </nz-col> -->
    </nz-row>

    <nz-row class="flex-end mt-30 mb-10">
      <div class="text-center cPointer" style="border: none!important;">
        <button style="border: none!important;padding: 0 24px;" nzType="primary" class="f-10 fw-700" type="button" (click)="save()">
          SAVE

        </button>
      </div>
      <div class="text-center f-10 fw-700 lh-18 blueColor cPointer">
        <button nz-button nzType="default" class="buttonDefault f-10 fw-700"
          style="height: 37px; border-radius: 5px; margin-left: 20px" (click)="cancel()">
          CANCEL
        </button>
      </div>
    </nz-row>
  </form>
</ng-container>
