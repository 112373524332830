import {
  AfterViewInit,
  Component,
  OnChanges,
  OnInit,
  SimpleChanges,
  effect,
  inject,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '@processo/services/notification.service';
import { UserService } from '@processo/services/user.service';
import { AddMorePopupComponent } from '@processo/shared/popup/add-more-popup/add-more-popup.component';
import { ConfirmSwitchTabPopupComponent } from '@processo/shared/popup/confirm-switch-tab-popup/confirm-switch-tab-popup.component';
import { StateService } from '@processo/shared/state/state.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { environment } from 'projects/processo/src/environments/environment';
import { Subject, filter, switchMap, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  environment = environment;
  selectedOrg = 'Organization Name';
  showProfile: boolean = false;
  userService = inject(UserService);
  modalService = inject(NzModalService);

  organizationData: any;
  currentOrgProcess: any = null;
  private _destroy$ = new Subject<void>();
  stateService = inject(StateService);
  tabsNames: any = [];
  viewNewOrganizationInvites!: boolean;
  profileTabs: any = [
    {
      name: 'settings',
      display_name: 'Settings',
    },
    {
      name: 'invitations',
      display_name: 'Invitations',
    },
  ];
  orglist: any;
  showDashboarHeader: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private state: StateService,
    private _nt: NotificationService
  ) {
    effect(() => {
      this.orglist = this.stateService.orgList();
      this.setIndex();
    });
    this.router.events.subscribe((_res) => {
      const currentURL = window.location.href;
      if (currentURL.includes('dashboard')) {
        this.showDashboarHeader = true;
      } else {
        this.showDashboarHeader = false;
      }
    });
  }
  ngAfterViewInit(): void {
    this.setIndex();
  }
  ngOnInit(): void {}
  invitedUsersPendingList = [];
  checkInvitedUser() {
    this.userService.get_invite_user_pending().subscribe({
      next: (response: any) => {
        this.invitedUsersPendingList = response?.[0].invites || [];
      },
    });
  }

  async navigate(tabname: any) {
    this.hide_selected_tab = false;
    this.stateService.update_is_my_uproval(this.hide_selected_tab)
    if (tabname === 'Dashboard') {
      this.stateService.appState().selectedEntity = 'overview';
      this.navigateDashboard();
      return;
    }else{
      this.selectedTabe = tabname;
      if (await this.stateService.canNavigate()) {
        this.stateService.updateAppstate({
          event: 'UPDATE_TABNAME',
          value: tabname || '',
        });
        this.stateService.resetPagination();
        this.router.navigate([tabname?.toLowerCase()], {
          relativeTo: this.route,
        });
      }
    }

  }
  // selectedTabIndex(){
  //   return 0
  // }
  customer() {
    this.router.navigate(['customer'], { relativeTo: this.route });
  }

  contacts() {
    this.router.navigate(['contact'], { relativeTo: this.route });
  }

  deals() {
    this.router.navigate(['deals'], { relativeTo: this.route });
  }

  products() {
    this.router.navigate(['product'], { relativeTo: this.route });
  }

  reports() {
    this.router.navigate(['report'], { relativeTo: this.route });
  }

  getOrganizations() {
    this.userService
      .getUserOrgProcess()
      .pipe(
        takeUntil(this._destroy$),
        tap((res: any) => {
          if (this.stateService.getOrgCollection()) {
            this.stateService.updateProcessState(
              this.stateService.getOrgCollection()[0]
            );
            this.stateService.updateProcessId(
              this.stateService.getOrgCollection()[0]?.[
                'public___org_processes'
              ]?.[0]?.process_id
            );
            this.call_metaProcess();
          }
        }),
        switchMap(() => this.stateService.currentState),
        filter((process) => !!process)
      )
      .subscribe((process: any) => {
        this.currentOrgProcess = process;
      });
    this.stateService.currentMetaDataState.subscribe((res) => {
      this.tabsNames = [];
      this.tabsNames =
        res?.meta_data?.entities?.map((v: any) => v.display_name) || [];
    });
  }

  gopassowrd() {
    this.router.navigate(['settings'], { relativeTo: this.route });
  }

  goinviteUser() {
    this.router.navigate(['invitations'], { relativeTo: this.route });
  }

  getProfileTabName(t: any) {
    if (this.router.url.indexOf(t.toLowerCase()) > 0) {
      return true;
    } else {
      return false;
    }
  }
  goTeam() {}

  async setting() {
    if (await this.stateService.canNavigate()) {
      this.userService.id =
        this.stateService.appState().selectedOrganization?.org_id;
      if (this.userService.id) {
        this.userService.userFrom = 'header';
        this.router.navigate(['organization/details/basic-details']);
      }
    }
  }
  async menuTextChange(org: any) {
    if (await this.stateService.canNavigate()) {
      // this.stateService.appState().selectedEntity = null;
      this.hide_selected_tab = false;
      this.stateService.update_is_my_uproval(this.hide_selected_tab)
      let defaultProcess: any = org?.public___org_processes
        ?.map((e: any) => {
          let findProcess = e?.public___vw_org_process_users?.find(
            (e: any) => e.public___user_org_process_default__is_default === true
          );
          if (findProcess) {
            return e;
          } else {
          }
        })
        .filter((process: any) => process !== undefined);
      let selectedProcess =
        defaultProcess.length > 0
          ? defaultProcess[0]
          : org.public___org_processes[0];

      this.state.updateAppstate({
        event: 'UPDATE_ORG',
        value: org,
        from: 'headerSelect',
        selectedOrg: selectedProcess,
      });
    }
  }

  call_metaProcess() {
    const body = {
      org_id: this.stateService.getCurrentSelectedOrganization()?.org_id,
      process_id: this.stateService.getCurrentProcessID(),
    };
    this.userService.get_process_metadata(body).subscribe((res: any) => {
      if (
        this.stateService.getMetaDataSection()?.meta_data?.entities?.length > 0
      ) {
        this.navigate(
          this.stateService.getMetaDataSection()?.meta_data?.entities[0].name
        );
      }
    });
  }
  viewNewOrganization() {
    this.stateService.viewNewOrganizationInvites = false;
    this.router.navigateByUrl('view-organization');
  }
  selectedTabDetail: any;
  selectedTabe: any;
  getTabName(t: any) {
    if (this.router.url.indexOf(t?.toLowerCase()) > 0) {
      return true;
    } else {
      return false;
    }
  }
  selectedTab() {
    let selected_tab = this.stateService?.appState()?.selectedEntity;
    let selectedTabDetail = this.getTabes()?.find(
      (e: any) => e?.name === selected_tab
    );

    return selectedTabDetail?.is_conv;
  }
  getTabes() {
    if (this.router.url.indexOf('profile') > 0) {
      return [...this.profileTabs];
    } else {
      let tab = this.stateService?.appState()?.metaData
        ? this.stateService?.appState()?.metaData?.entities
        : [];

      if (this.stateService.appState().metaData.dashboard) {
        let isDashboard = {
          display_name: 'Dashboard',
          name: 'Dashboard',
          index: '-1',
          hide_entity: this.stateService.appState().metaData.dashboard
            ? 'false'
            : 'true',
        };
        let dashboardExists = tab?.some(
          (tabItem: any) => tabItem.name === 'Dashboard'
        );

        if (!dashboardExists) {
          tab.push(isDashboard);
        }
      }
      return tab
        ? tab?.sort((a: any, b: any) => Number(a?.index) - Number(b?.index))
        : [];
    }
  }
  // selectedTabIndex(){
  //   return 0
  // }
  selectedTabIndex: any = 0;
  api() {
    this.router.navigateByUrl('/dashboard/api');
  }
  navigateDashboard() {
    this.router.navigateByUrl('/dashboard/overview');
  }
  navigateConversations() {
    let selected_tab = this.stateService?.appState()?.selectedEntity;
    this.selectedTabIndex = this.getTabes()?.findIndex(
      (e: any) => e?.name === selected_tab
    );

    this.router.navigateByUrl('/dashboard/conversation');
  }
  confirmSwitchOnEdit() {
    return;
    let datas = {};
    let popup = this.modalService.create({
      nzWidth: '394px',
      nzTitle: 'CONFIRM NAVIGATION',
      nzContent: ConfirmSwitchTabPopupComponent,
      nzMaskClosable: false,
      nzClosable: false,
      nzFooter: null,
      nzData: {
        data: datas,
      },
      nzClassName: 'popupheader',
    });
  }
  // processJson =[
  //   {
  //     "display_name": "Properties",
  //     "fields":[],
  //     "has_am": false,
  //     "index": "0",
  //     "is_people": "false",
  //     "map_roles": [],
  //     "map_users": [
  //       "1b566d2e-1cb1-4dc5-bdb1-fd2cb2f352eb"
  //     ],
  //     "name": "properties",
  //     "tabs": [
  //       {
  //         "field_type": "USER",
  //         "index": 0,
  //         "label": "Property Details",
  //         "name": "property_details"
  //       },
  //     ],
  //     "visibility_type": "private",
  //     "workflow": {}
  //   },
  // ]
  showNewEntityDrawer: boolean = false;
  showNewEntityDrawerDatas: any;
  setIndex() {
    let selected_tab = this.stateService?.appState()?.selectedEntity;
    this.selectedTabIndex = this.getTabes()
      ?.filter((_e: any) => !(_e?.hide_entity === 'true'))
      ?.findIndex((e: any) => e?.name === selected_tab);
    this.getMetaData();
  }
  async openAddmore() {
    if (!(await this.stateService.canNavigate())) {
      return;
    }
    this.showNewEntityDrawerDatas = {
      show: 'entity',
      tab: this.getTabes(),
    };

    // let popup = this.modalService.create({
    //   nzWidth: '698px',
    //   nzTitle: 'ADD ENTITY',
    //   nzContent: AddMorePopupComponent,
    //   nzMaskClosable: false,
    //   nzClosable: false,
    //   nzFooter: null,
    //   nzData: {
    //     data: datas,
    //   },
    //   nzClassName: 'popupheader',
    // });
    if (this.showNewEntityDrawerDatas) {
      this.changeEntity = false;
      this.showNewEntityDrawer = true;
    }
  }
  isAdmin() {
    let UserIds =
      this.state.appState()?.selectedProcess?.public___vw_org_process_users;
    let loggedInUser = this.state.isUserLoggedIn()?.userDetail?.id;
    let user = UserIds?.find((u: any) => u.user_id == loggedInUser);
    if (user?.user_access == 'ADMIN') {
      return true;
    } else {
      return false;
    }
  }
  changeEntity: boolean = false;
  openNewEntity() {
    this.showNewEntityDrawer = true;
  }
  newEntityclose() {
    this.showNewEntityDrawer = false;

    if (this.changeEntity) {
      this.state.getMetaData();

      let isCurrentEntityisHidden = this.getTabes()?.find(
        (v: any) => this.stateService.appState().selectedEntity == v.name
      );
      if (isCurrentEntityisHidden?.hide_entity === 'true') {
        let firstProcess = this.getTabes()?.find(
          (ef: any) => ef.hide_entity !== 'true'
        );
        this.navigate(firstProcess?.name);
      } else {
        let firstProcess = this.getTabes()?.find(
          (ef: any) => ef.hide_entity !== 'true'
        );
        this.navigate(firstProcess?.name);
      }
    }
  }
  newEntityOutput(e: any) {
    let isCurrentEntityisHidden = this.getTabes()?.find(
      (v: any) => this.stateService.appState().selectedEntity == v.name
    );

    if (e.method === 'save') {
      this.showNewEntityDrawer = false;

      if (isCurrentEntityisHidden?.hide_entity === 'true') {
        let firstProcess = this.getTabes()?.find(
          (ef: any) => ef.hide_entity !== 'true'
        );
        this.navigate(firstProcess?.name);
      } else {
        let firstProcess = this.getTabes()?.find(
          (ef: any) => ef.hide_entity !== 'true'
        );
        this.navigate(firstProcess?.name);
      }
    } else if (e.method === 'cancel') {
      this.showNewEntityDrawer = false;

      if (isCurrentEntityisHidden?.hide_entity === 'true') {
        let firstProcess = this.getTabes()?.find(
          (ef: any) => ef.hide_entity !== 'true'
        );
        this.navigate(firstProcess?.name);
      } else {
        let firstProcess = this.getTabes()?.find(
          (ef: any) => ef.hide_entity !== 'true'
        );
        this.navigate(firstProcess?.name);
      }
    } else if (e.method === 'saveEntity') {
      this.saveModifyEntity(e.data);
    }
  }
  saveModifyEntity(entity: any) {
    this.changeEntity = true;
    let body = {
      org_id: this.stateService.appState().selectedOrganization.org_id,
      process_id: this.stateService.appState().selectedProcess.process_id,
      process_name: this.stateService
        .appState()
        .selectedProcess.process_name.replace(/\s/g, '_'),
      entity_data: entity,
    };
    this.state.saveProcessEntityIndex(body).subscribe((res: any) => {
      if (res) {
        // this.stateService.appState();
        this._nt.show('Success', '', 'Entity updated sucessfully');
      }
    });
  }
  getorglist() {
    this.orglist = this.stateService.orgList();
  }
  filterorg(searchValue: any) {
    if (!searchValue) {
      this.orglist = this.stateService.orgList();
    } else {
      this.orglist = this.stateService
        .orgList()
        .filter((option: any) =>
          option.org_name.toLowerCase().includes(searchValue.toLowerCase())
        );
    }
  }
  getEntity(data: any) {
    let showProcess = data
      ?.map((e: any) => {
        if (e.hide_entity === 'false') {
          return e;
        }
      })
      ?.filter(Boolean);
    if (showProcess?.length > 0) {
      return true;
    }

    return false;
  }
  selected_entities: any;
  getMetaData() {
    let entities = this.stateService?.appState()?.metaData?.entities;
    let _selected_entity_name = this.stateService.appState()?.selectedEntity;
    if (entities && _selected_entity_name) {
      let meta = entities?.find((_e: any) => _e.name === _selected_entity_name);
      this.selected_entities = meta;
    }
  }
  hide_selected_tab:boolean = false
  open_my_approval() {
    this.hide_selected_tab = true;
    this.stateService.appState().selectedEntity = 'my_approval'
    this.stateService.update_is_my_uproval(this.hide_selected_tab)

  }
}
