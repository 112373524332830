import { Component, Inject, OnInit, inject } from '@angular/core';
import {
  NavigationEnd,
  NavigationSkipped,
  NavigationStart,
  Router,
} from '@angular/router';
import { LoadingService } from './services/loading.service';
import { environment } from '../environments/environment';
import { Subscription, fromEvent } from 'rxjs';
import { UserIdleService } from 'angular-user-idle';
import { NzModalService } from 'ng-zorro-antd/modal';
import { TimePopupComponentComponent } from './shared/components/time-popup-component/time-popup-component.component';
import { DOCUMENT } from '@angular/common';
import { AuthService } from './core/authentication/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'processo';
  environmentType!: string;
  ShowLoader: boolean = true;
  router = inject(Router);
  private _loadingService = inject(LoadingService);
  private auth_Service = inject(AuthService);
  dialogRef: any;
  isIdleTimerNotRequired = false;
  subscription!: Subscription;
  idleTimerStartSubscription!: Subscription;
  idleTimerStopSubscription!: Subscription;
  pingSubscription!: Subscription;

  constructor(
    private userIdle: UserIdleService,
    private nzModalService: NzModalService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.setPageTitleAndIcon();
    this.subscribeToRouterEvents();
  }

  ngOnInit(): void {
    this.initializeIdleTimerSubscriptions();
  }

  private setPageTitleAndIcon(): void {
    const link: HTMLLinkElement = this.document.createElement('link');
    link.rel = 'icon';
    link.type = 'image/x-icon';
    this.environmentType = environment.type;
    if (environment.is_artfine) {
      document.title = 'ArtfineBilz';
      link.href = 'assets/images/logo/ArtfineBilzSideLogo.svg';
    } else {
      document.title = 'processo';
      link.href = 'assets/images/logo/favicon.ico';
    }
    const head =
      this.document.head || this.document.getElementsByTagName('head')[0];
    head.appendChild(link);
  }

  private subscribeToRouterEvents(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this._loadingService.show();
      } else if (
        event instanceof NavigationEnd ||
        event instanceof NavigationSkipped
      ) {
        this._loadingService.hide();
      }

      if (event instanceof NavigationEnd) {
        this.handleNavigationEnd(event.url);
      }
    });
  }

  private handleNavigationEnd(url: string): void {
    const correctedUrl = this.router.url;
    this.isIdleTimerNotRequired =
      correctedUrl === '/login' ||
      correctedUrl === '/register' ||
      correctedUrl === '/forgot-password';

    if (this.isIdleTimerNotRequired) {
      this.stopIdleTimer();
    } else {
      this.startIdleTimer();
    }
  }

  private startIdleTimer(): void {
    this.clearSubscription();
    const down$ = fromEvent(document, 'mousedown');
    this.subscription = down$.subscribe(() => {
      this.restartIdleTimer();
    });
    this.restartIdleTimer();
    this.startWatching();
  }

  private stopIdleTimer(): void {
    this.stopWatching();
    this.clearSubscription();
  }

  private initializeIdleTimerSubscriptions(): void {
    this.idleTimerStartSubscription = this.userIdle
      .onTimerStart()
      .subscribe((count: any) => {
        this.showTimerPopup(count);
      });

    this.idleTimerStopSubscription = this.userIdle.onTimeout().subscribe(() => {
      this.stopIdleTimer();
      this.showAlertPopup();
    });
  }

  private showTimerPopup(count: any): void {
    if (
      count != null &&
      (this.dialogRef === undefined || this.dialogRef === null)
    ) {
      this.dialogRef = this.nzModalService.create({
        nzContent: TimePopupComponentComponent,
        nzWidth: '394px',
        nzFooter: null,
        nzMaskClosable: false,
        nzClosable: false,
        nzWrapClassName: 'modalContainer',
        nzData: {
          bodyText: 'Your session is about to expire',
        },
      });

      if (this.dialogRef.componentInstance) {
        const configValue: any = this.userIdle.getConfigValue();
        this.dialogRef.componentInstance.count = configValue.timeout - count;
        this.dialogRef.afterClose.subscribe((result: any) => {
          if (result && result.clickedCancel) {
            this.dialogRef = null;
          }
          if (result === 'reset') {
            this.dialogRef = null;
            this.subscribeToRouterEvents();
            this.initializeIdleTimerSubscriptions();
          }
        });
      }
    }
  }

  private showAlertPopup(): void {
    this.dialogRef?.close();
    this.dialogRef?.afterClose.subscribe(() => {
      this.dialogRef = undefined;
    });
    localStorage.clear();
    this.router.navigateByUrl('/login');
  }

  private startWatching(): void {
    this.userIdle.startWatching();
    if (this.pingSubscription) {
      this.pingSubscription.unsubscribe();
    }
    this.pingSubscription= this.userIdle?.ping$?.subscribe(() => {
      const refresh_token: any = localStorage.getItem('refresh_token');
      if (
        refresh_token &&
        refresh_token != '' &&
        refresh_token != 'undefined'
      ) {
        this.auth_Service.updateToken().subscribe();
      } else {
        localStorage.clear();
        this.router.navigateByUrl('/login');
      }
    });
  }

  private stopWatching(): void {
    this.userIdle.stopWatching();
  }

  private restartIdleTimer(): void {
    this.userIdle.resetTimer();
  }

  private clearSubscription(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  ngOnDestroy() {
    this.idleTimerStartSubscription.unsubscribe();
    this.idleTimerStopSubscription.unsubscribe();
    this.pingSubscription.unsubscribe();
  }
}
