import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-empty-organisation',
  templateUrl: './empty-organisation.component.html',
  styleUrls: ['./empty-organisation.component.scss'],
})
export class EmptyOrganisationComponent {
  router = inject(Router);

  addOrganization() {
    this.router.navigate(['organization', 'add-organization']);
  }
}
