import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { generateUUID } from 'projects/processo-custom-table/src/usecase';
import { Observable, map, of } from 'rxjs';

@Component({
  selector: 'lib-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
})
export class StatusComponent implements OnDestroy {
  @Input() header: any;
  @Input() data: any;
  @Input() parent: any;
  @Input() innerTable: any;
  searchs = '';

  // @Output() stopEdits = new EventEmitter<any>();
  // stopEdit(v?: any, type?: any, entityId?: any) {
  //   this.stopEdits.emit({ v, type, entityId });
  // }
  ngOnInit() {}
  @HostBinding('attr.unique-id')
  uniqueId = `${generateUUID()}`;
  o$(e: any) {
    return of([...this.header.open_status, ...this.header.close_status]).pipe(
      map((r) => r.filter((v: any) => v.name.includes(e.toLowerCase())))
    );
  }

  d$(d: any) {
    return of([...this.header.open_status, ...this.header.close_status]).pipe(
      map((r) => r.find((v: any) => v.name == d))
    );
  }
  update(status: any) {
    this.data[this.header.name] = status.name;
    let tableEdit: any;
    let selectedOption = this.header.open_status?.some(
      (e: any) => e?.name === status.name
    )
      ? 'open'
      : this.header.close_status?.some((e: any) => e?.name === status.name)
      ? 'close'
      : '';
    let data = {
      value: status.name,
      selectedOption: selectedOption,
      dataType: 'status',
    };
    if (this.innerTable) {
      tableEdit = 'innerTable';

      this.parent.updateDataOnInnerStopEdit(data, this.header.name, this.data);
    } else {
      this.parent.updateDataOnStopEdit(
        data,
        this.header.name,
        this.data,
        this.parent.erulistData
      );
    }
    this.parent.saveEntityDropdownData(this.header, tableEdit, this.data);
  }
  private timeoutRef: any;
  close = false;
  event(e: any) {
    if (e) {
      this.timeoutRef = setTimeout(() => {
        this.close = true;
      }, 100);
    } else {
      this.close = false;
    }
  }
  ngOnDestroy(): void {
    if (this.timeoutRef) {
      clearTimeout(this.timeoutRef);
    }
  }
}
