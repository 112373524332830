import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { DeleteColumnPopupComponent } from '@processo/modules/dashboard/popup/delete-column-popup/delete-column-popup.component';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-create-new-entity',
  templateUrl: './create-new-entity.component.html',
  styleUrls: ['./create-new-entity.component.scss'],
})
export class CreateNewEntityComponent implements OnInit {
  @Input() data: any;
  @Input() headerText: any;
  entityForm!: FormGroup;
  addFieldForm!: FormGroup;
  draggedColumnIndex: any;
  editIndex: any;
  tab: any;
  @Output() newEntityOutput = new EventEmitter<any>();
  @Input() stateService: any;
  entityNameError: boolean = false;
  displayNameError: boolean = false;
  constructor(
    private _createform: UntypedFormBuilder,
    private fb: FormBuilder
  ) {}
  ngOnInit(): void {
    if (this.data) {
      this.initiateEntityForm();
      if (this.data.show === 'entity') {
        this.tab = this.data.tab.sort((a: any, b: any) =>
          a.index.localeCompare(b.index)
        );
      }
    }
  }
  initiateEntityForm() {
    this.entityForm = this._createform.group({
      dummy: [null],
      entityname: [null, [Validators.required]],
      displayname: [null, [Validators.required]],
      isPeople: [false, [Validators.required]],
      enableConversation: [false],
      
    });
    
  }
  saveEntityForm() {


    if (this.entityForm.valid) {
      if (this.displayNameError || this.entityNameError) {
        return;
      }
      if (this.isEdit) {
        this.tab[this.editIndex].name = this.tab[this.editIndex].name;
        this.tab[this.editIndex].is_people = this.entityForm.value.isPeople;
        (this.tab[this.editIndex].display_name =          this.entityForm.value.displayname);
          (this.tab[this.editIndex].is_conv =
            this.entityForm.value.enableConversation);
        this.isEdit = true;
        this.tab?.map((e: any) => (e.exet = true));
      } else {
        let addEntity = {
          name: this.entityForm.value.entityname,
          index: `${this.tab.length}`,
          is_people: `${this.entityForm.value.isPeople}`,
          display_name: this.entityForm.value.displayname,
          hide_entity: `${false}`,
          is_conv: this.entityForm.value.enableConversation,
          exet: false,
        };
        // this.tab = [...this.tab, addEntity];
        this.tab?.map((e: any) => (e.exet = true));
        this.tab.push(addEntity);
      }
      this.initiateEntityForm();
      this.savePayload();
    }else{
      this.entityForm.markAllAsTouched();
    }
  }
  dragStartColumn(i: any, e: any) {
    this.draggedColumnIndex = i;
  }
  movingIndex: any;
  allowDrop(e: any, i: any) {
    this.movingIndex = i;
    e.preventDefault();
  }
  dropColumn(i: any, e: any) {
    this.arrayMove(this.tab, this.draggedColumnIndex, i);
    this.movingIndex = null;
  }
  arrayMove(arr: any[], from: any, to: any) {
    if (from < 0 || from >= arr.length || to < 0 || to >= arr.length) {
      return;
    }
    let aa = arr[from];
    let bb = arr[to];
    aa.index = `${to}`;
    bb.index = `${from}`;
    this.draggedColumnIndex = null;
    this.tab = this.tab.map((e: any) => ({ ...e, exet: true })).sort((a: any, b: any) =>
      a.index.localeCompare(b.index)
    );
    this.savePayload();
  }
  isEdit: boolean = false;
  editEntityName(value: any, index: any) {
    this.editIndex = index;
    this.isEdit = true;
    if (value) {
      this.entityForm.get('entityname')?.setValue(value.name);
      this.entityForm.get('displayname')?.setValue(value.display_name);
      this.entityForm
        .get('isPeople')
        ?.setValue(value.is_people === 'true' ? true : false);
      this.entityForm.get('enableConversation')?.setValue(value.is_conv);
      this.entityForm.get('entityname')?.disable();
    }
  }
  save() {
    let data = {
      method: 'save',
    };
    this.newEntityOutput.emit(data);
  }
  cancel() {
    let data = {
      method: 'cancel',
    };
    this.newEntityOutput.emit(data);
  }
  hideEntity(hideEntity: any, index: any) {
    this.tab[index].hide_entity = hideEntity === 'true' ? 'false' : 'true';
    this.tab?.map((e: any) => (e.exet = true));
    this.savePayload();
  }
  savePayload() {
    let data = this.tab.map((e: any) => {
      return {
        name: e.name,
        index: e.index,
        is_people: e.is_people,
        display_name: e.display_name,
        hide_entity: e.hide_entity,
        is_conv: e.is_conv,
        exet: e.exet !== undefined ? e.exet : true,
      };
    });
    let datas = {
      method: 'saveEntity',
      data: data,
    };
    this.newEntityOutput.emit(datas);
  }
  checkExistingName(input: string, type: any) {
    if (type === 'Entity') {
      const inputValue = input.trim().toLowerCase().replace(/\s+/g, '');

      let isDuplicate = this.tab.some((el: any) => {
        const normalizedProcessName = el.name
          .trim()
          .toLowerCase()
          .replace(/\s+/g, '');
        return normalizedProcessName === inputValue;
      });
      this.entityNameError = isDuplicate;
    }

    if (type === 'Display') {
      const inputValue = input.trim().toLowerCase().replace(/\s+/g, '');

      let isDuplicate = this.tab.some((el: any) => {
        const normalizedProcessName = el.display_name
          .trim()
          .toLowerCase()
          .replace(/\s+/g, '');
        return normalizedProcessName === inputValue;
      });
      this.displayNameError = isDuplicate;
    }
  }
  modalService = inject(NzModalService);
  deleteEntity(data: any, i: number) {
    let datas = {
      deleteType: 'workFlow',
      text: `Are you sure you  want to delete ${data?.display_name} entity`,
    };
    let popup = this.modalService.create({
      nzWidth: '394px',
      nzTitle: 'DELETE ENTITY',
      nzContent: DeleteColumnPopupComponent,
      nzMaskClosable: false,
      nzClosable: false,
      nzFooter: null,
      nzData: {
        data: datas,
      },
      nzClassName: 'popupheader',
    });

    popup.afterClose.subscribe((res: any) => {
      if (res?.action === 'confirm') {
        let payload = {
          org_id: this.stateService.appState().selectedOrganization.org_id,
          process_id: this.stateService.appState().selectedProcess.process_id,
          entity_name: data.name,
        };
        this.stateService
          .removeUserProcessEntity(payload)
          .subscribe((res: any) => {
            if (res) {
              this.tab.splice(i, 1);
            }
          });
      }
    });
  }
}
