<div class="form-fields inputIconContainer selectContainer">
    <div class="newColumn1 containers">
      <section>
        <nz-row [nzGutter]="16">
          <div [ngClass]="{'disableField': isEdit}" [style.pointerEvents]="isEdit ? 'none' : 'auto'" nz-col
            [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
            <nz-form-label class="dgrey-text titletext" [nzNoColon]="true">Data Type</nz-form-label>
            <nz-form-item>
              <nz-form-control>
                <ng-container>
                  <nz-select (click)="filterDataTYpe('')" nzPlaceHolder="Select "
                    [(ngModel)]="formData.data_type.value" (ngModelChange)="selectDataTYpe($event)"
                    [nzDropdownClassName]="'reverse_ant-select-dropdown'"
                    [nzDropdownRender]="selectSearchTemplateDataType">
                    <ng-template #selectSearchTemplateDataType>
                      <input #saearhx2 nz-input placeholder="Search..." (input)="filterDataTYpe(saearhx2.value)
                    " />
                    </ng-template>
                    <ng-container *ngFor="let item of newColumnValue">
                        <nz-option class="f-12"  [nzValue]="item.datatype"
                        [nzLabel]="(item.label| titlecase)"></nz-option>
                    </ng-container>

                  </nz-select>
                </ng-container>
              </nz-form-control>
            </nz-form-item>
          </div>
        </nz-row>
      </section>
    </div>
    <nz-divider></nz-divider>
    <nz-row [nzGutter]="16" style="margin-bottom: 10px;">
      <div [style.pointerEvents]="isEdit ? 'none' : 'auto'" nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }"
        [nzXXl]="12">
        <nz-form-item>
          <nz-form-label [nzXs]="{ span: 24 }" class="dgrey-text titletext" [nzLabelAlign]="'left'"
            [nzNoColon]="true">Field Name <span class="redColor">*</span></nz-form-label>
          <nz-form-control [nzXs]="{ span: 24 }">
            <input class="f-12" #defaultFieldValue nz-input [(ngModel)]="formData?.fieldName.value"
            oninput="this.value = this.value.replace(/[^a-z_]/g, '');"
              [placeholder]="formData?.fieldName?.placeholder" (input)="fieldNameValidation()"/>
          </nz-form-control>
        </nz-form-item>
        <span style="color: red; font-size: smaller" *ngIf="
            formData?.fieldName.validators?.required &&
            formData?.fieldName.validators?.error
          ">{{ formData?.fieldName?.validators?.error | titlecase }}</span>
      </div>
      <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
        <nz-form-item>
          <nz-form-label [nzXs]="{ span: 24 }" [nzLabelAlign]="'left'" class="dgrey-text titletext"
            [nzNoColon]="true">Field Label <span class="redColor">*</span></nz-form-label>
          <nz-form-control [nzXs]="{ span: 24 }">
            <input class="f-12" #defaultFieldValue nz-input [(ngModel)]="formData?.fieldLabel.value"
            oninput="this.value = this.value.replace(/[^a-zA-Z0-9_ ]/g, '').replace(/\s+/g, ' ');"
              [placeholder]="formData?.fieldLabel?.placeholder" (input)="checkInputValidation(formData?.fieldLabel.value,'fieldLabel')"/>
          </nz-form-control>
        </nz-form-item>
        <span style="color: red; font-size: smaller" *ngIf="
            formData?.fieldLabel.validators?.required &&
            formData?.fieldLabel.validators?.error
          ">
          {{ formData?.fieldLabel?.validators?.error | titlecase }}</span>
      </div>
     
    </nz-row>
    <ng-container *ngIf="Object.keys(formData.fieldForm).length > 0">
      <ng-container [ngSwitch]="formData.data_type.value">
        <ng-container *ngSwitchCase="'date'">
          <nz-row [nzGutter]="16" class="mt-10">
            <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
              <nz-form-item>
                <nz-form-label [nzXs]="{ span: 24 }" [nzLabelAlign]="'left'" class="dgrey-text titletext"
                  [nzNoColon]="true">Date Format</nz-form-label>
  
                <nz-form-control [nzXs]="{ span: 24 }">
                  <nz-select [(ngModel)]="formData?.fieldForm?.dateFormat.value"
                    [nzDropdownClassName]="'dropDownContainer'" nzAllowClear>
                    <nz-option class="f-12" *ngFor="
                        let option of formData?.fieldForm?.dateFormat?.options
                      " [nzValue]="option[0]" [nzLabel]="option[1]"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12" *ngIf="!formData.dfc">
              <nz-form-item>
                <nz-form-label [nzXs]="{ span: 24 }" [nzLabelAlign]="'left'" class="dgrey-text titletext"
                  [nzNoColon]="true">Value</nz-form-label>
                <nz-select style="width: 100%" (ngModelChange)="formData.default_value = $event"
                  [ngModel]="formData.default_value ? 'Current_Date' : 'Blank'"
                  [nzDropdownClassName]="'dropDownContainer'" nzAllowClear>
                  <nz-option class="f-12" [nzValue]="''" [nzLabel]="''"></nz-option>
                  <nz-option class="f-12" [nzValue]="'Current_Date'" [nzLabel]="'Current Date'"></nz-option>
                </nz-select>
              </nz-form-item>
            </div>
          </nz-row>
          <nz-row style="width: 100%" *ngIf="!formData.dfc">
            <nz-form-item style="width: 100%">
              <nz-form-label [nzXs]="{ span: 24 }" [nzLabelAlign]="'left'" class="dgrey-text titletext"
                [nzNoColon]="true">Allow Days</nz-form-label>
  
              <div class="" *ngFor="let j of formData?.fieldForm?.AllowDays; let i = index">
                <button (click)="j.checked = true" *ngIf="!j?.checked" [ngStyle]="{ 'margin-left': i != 0 ? '15px' : '' }"
                  style="
                    color: black !important;
                    padding: 8px 10px;
                    min-width: 52px;
                    border: 1px solid #1d66ff !important;
                    background: white !important;
                  ">
                  {{ j?.value }}
                </button>
                <button (click)="j.checked = false" *ngIf="j?.checked" [ngStyle]="{ 'margin-left': i != 0 ? '15px' : '' }"
                  style="
                    color: white !important;
                    padding: 8px 10px;
                    min-width: 52px;
                  ">
                  {{ j?.value }}
                </button>
              </div>
            </nz-form-item>
          </nz-row>
        </ng-container>
        <ng-container *ngSwitchCase="'textbox'">
          <nz-row [nzGutter]="16">
            <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
              <nz-form-item>
                <nz-form-label [nzXs]="{ span: 24 }" [nzLabelAlign]="'left'" class="dgrey-text titletext"
                  [nzNoColon]="true">Data Length</nz-form-label>
                <nz-form-control [nzXs]="{ span: 24 }">
                  <input class="f-12" #defaultFieldValue nz-input type="text"
                  (input)="numberInputValue(formData?.fieldForm?.dataLength.value)"
                    [(ngModel)]="formData?.fieldForm?.dataLength.value"
                    oninput="this.value = this.value.replace(/[^0-9]/g, '');"
                    [placeholder]="formData?.fieldForm?.dataLength?.placeholder" />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12" class="disableSelect">
              <nz-form-item>
                <nz-form-label [nzXs]="{ span: 24 }" [nzLabelAlign]="'left'" class="dgrey-text titletext"
                  [nzNoColon]="true">Data Length Check</nz-form-label>
  
                <nz-form-control [nzXs]="{ span: 24 }">
                  <nz-select (ngModelChange)="textboxValidation(formData?.fieldForm?.dataLength.value, formData.default_value,$event)" nzPlaceHolder="Select " [disabled]="!(formData?.fieldForm?.dataLength?.value?.length>0)" [(ngModel)]="formData?.fieldForm?.dataLengthCheck.value"
                    [nzDropdownClassName]="'dropDownContainer'" nzAllowClear>
                    <nz-option class="f-12" *ngFor="
                        let item of formData?.fieldForm?.dataLengthCheckOptions
                      " [nzValue]="item.value" [nzLabel]="item.value"></nz-option>
                  </nz-select>
                  <div class="errorText" *ngIf="(formData?.fieldForm?.dataLength?.value?.toString()?.length > 0) && (formData?.fieldForm?.dataLengthCheck.value?.length === 0)">required Data Length Check </div>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12" *ngIf="!formData.is_unique">
              <nz-form-item>
                <nz-form-label [nzXs]="{ span: 24 }" [nzLabelAlign]="'left'" class="dgrey-text titletext"
                  [nzNoColon]="true">Default Value</nz-form-label>
                <nz-form-control [nzXs]="{ span: 24 }">
                  <input (input)=" textboxValidation(formData?.fieldForm?.dataLength.value, formData.default_value,formData?.fieldForm?.dataLengthCheck.value)" #defaultFieldValue nz-input [(ngModel)]="formData.default_value" [placeholder]="'Enter'"
                    class="f-12" />
                    <div class="errorText" *ngIf="!(number_default_error ==='')">{{number_default_error }}</div>
                </nz-form-control>
              </nz-form-item>
            </div>
          </nz-row>
        </ng-container>
        <ng-container *ngSwitchCase="'priority'">
          <nz-row [nzGutter]="16">
            <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
              <nz-form-label [nzXs]="{ span: 24 }" [nzLabelAlign]="'left'" class="dgrey-text titletext"
                [nzNoColon]="true">Priority Options &nbsp;
                <img src="../../../assets/images/icons/blueInfoIcon.svg" alt="" /></nz-form-label>
              <div class="statusesContainer" style="padding: 10px 14px">
                <div *ngFor="
                    let item of formData.fieldForm.options;
                    let i = index
                  " style="
                    height: 30px;
                    border-radius: 100px;
                    width: 100%;
                    padding: 6px 8px;
                    margin-top: 5px;
                  ">
                  <div style="display: flex; justify-content: space-between">
                    <div>
                      <img src="../../../assets/images/icons/greenbullet.svg" alt="" />
                      {{ item.name }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
          </nz-row>
          <nz-row [nzGutter]="16">
            <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12" class="mt-20">
              <nz-form-label [nzXs]="{ span: 24 }" [nzLabelAlign]="'left'" class="dgrey-text titletext"
              [nzNoColon]="true">Default Value</nz-form-label>
              <input nz-dropdown nzTrigger="click" readonly [nzDropdownMenu]="menu" nz-input
                [(ngModel)]="formData.default_value" [placeholder]="'Select'" class="f-12" (click)="priorityDefaultOption = formData.fieldForm.options;saearhx2.value = ''" />
              <nz-dropdown-menu #menu="nzDropdownMenu" nzPlacement="bottomRight">
                <input #saearhx2 nz-input placeholder="Search..."  (input)="filterpriorityDefault(saearhx2.value)"/>
                <ul nz-menu (click)="$event.preventDefault();$event.stopPropagation()" nzSelectable class="option-list">
                  <ng-container *ngFor="let item of priorityDefaultOption">
                    <li #menuitem nz-menu-item (click)="formData.default_value= item.name">
                      <span style="padding: 2px;border-radius: 50%;pointer-events: auto;">
                        <div class="avtarcheck">
                          <span nz-icon nzType="flag" nzTheme="fill" [style.color]="getColor(item.name)"></span>
                        </div>
                      </span>
                      <span style="margin-left: 5px;pointer-events: auto;">{{item.name}}</span>
                    </li>
                  </ng-container>
                </ul>
              </nz-dropdown-menu>
            </div>
          </nz-row>
        </ng-container>
        <ng-container *ngSwitchCase="'textarea'">
          <nz-row [nzGutter]="16">
            <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
              <nz-form-item>
                <nz-form-label [nzXs]="{ span: 24 }" [nzLabelAlign]="'left'" class="dgrey-text titletext"
                  [nzNoColon]="true">Data Length</nz-form-label>
                <nz-form-control [nzXs]="{ span: 24 }">
                  <input (input)="numberInputValue(formData?.fieldForm?.dataLength.value)" #defaultFieldValue nz-input type="number" [(ngModel)]="formData?.fieldForm?.dataLength.value"
                    [placeholder]="formData?.fieldForm?.dataLength?.placeholder"
                    oninput="this.value = this.value.replace(/[^0-9]/g, '');" class="f-12" />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12" class="disableSelect">
              <nz-form-item>
                <nz-form-label [nzXs]="{ span: 24 }" [nzLabelAlign]="'left'" class="dgrey-text titletext"
                  [nzNoColon]="true">Data Length Check</nz-form-label>
  
                <nz-form-control [nzXs]="{ span: 24 }">
                  <nz-select (ngModelChange)="textboxValidation(formData?.fieldForm?.dataLength.value, formData.default_value,$event)" nzPlaceHolder="Select " [disabled]="!(formData?.fieldForm?.dataLength?.value?.toString()?.length>0)" [(ngModel)]="formData?.fieldForm?.dataLengthCheck.value"
                    [nzDropdownClassName]="'dropDownContainer'" nzAllowClear>
                    <nz-option class="f-12" *ngFor="
                        let item of formData?.fieldForm?.dataLengthCheckOptions
                      " [nzValue]="item.value" [nzLabel]="item.value"></nz-option>
                  </nz-select>
                  <div class="errorText" *ngIf="(formData?.fieldForm?.dataLength?.value?.toString()?.length > 0) && (formData?.fieldForm?.dataLengthCheck.value?.length === 0)">required Data Length Check </div>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
              <nz-form-item>
                <nz-form-label [nzXs]="{ span: 24 }" [nzLabelAlign]="'left'" class="dgrey-text titletext"
                  [nzNoColon]="true">Default Value</nz-form-label>
                <nz-form-control [nzXs]="{ span: 24 }">
                  <input (input)=" textboxValidation(formData?.fieldForm?.dataLength.value, formData.default_value,formData?.fieldForm?.dataLengthCheck.value)" #defaultFieldValue nz-input [(ngModel)]="formData.default_value" [placeholder]="'Enter'"
                    class="f-12" />
                    <div class="errorText" *ngIf="!(number_default_error ==='')">{{number_default_error }}</div>
                </nz-form-control>
              </nz-form-item>
            </div>
          </nz-row>
        </ng-container>
        <ng-container *ngSwitchCase="'location'">
          <nz-row [nzGutter]="16">
            <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
              <nz-form-item>
                <nz-form-label [nzXs]="{ span: 24 }" [nzLabelAlign]="'left'" class="dgrey-text titletext"
                  [nzNoColon]="true">Default Value</nz-form-label>
                <nz-form-control [nzXs]="{ span: 24 }">
                  <input #defaultFieldValue nz-input [(ngModel)]="formData.default_value" [placeholder]="'ENTER'"
                    class="f-12" />
                </nz-form-control>
              </nz-form-item>
            </div>
          </nz-row>
        </ng-container>
        <ng-container *ngSwitchCase="'checkbox'">
          <nz-row [nzGutter]="16">
            <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
              <nz-form-item>
                <nz-form-label [nzXs]="{ span: 24 }" [nzLabelAlign]="'left'" class="dgrey-text titletext"
                  [nzNoColon]="true">Default Value</nz-form-label>
                <nz-form-control [nzXs]="{ span: 24 }">
                  <label nz-checkbox [(ngModel)]="formData.default_value"></label>
                </nz-form-control>
              </nz-form-item>
            </div>
          </nz-row>
        </ng-container>
        <ng-container *ngSwitchCase="'currency'">
          <nz-row [nzGutter]="16">
            <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
              <nz-form-item>
                <nz-form-label [nzXs]="{ span: 24 }" [nzLabelAlign]="'left'" class="dgrey-text titletext"
                  [nzNoColon]="true">Decimal</nz-form-label>
                <nz-form-control [nzXs]="{ span: 24 }">
                  <input nz-input [(ngModel)]="formData.fieldForm.Decimal.value"
                    [placeholder]="formData?.fieldForm?.Decimal?.placeholder"
                    oninput="this.value = this.value.replace(/(?!^-)[^0-9.]+/g, '').replace(/(\..*)\./g, '$1').replace(/(?!^)-/g, '');" class="f-12" />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
              <nz-form-item>
                <nz-form-label [nzXs]="{ span: 24 }" [nzLabelAlign]="'left'" class="dgrey-text titletext"
                  [nzNoColon]="true">Separator</nz-form-label>
                <nz-form-control [nzXs]="{ span: 24 }">
                  <nz-select [(ngModel)]="formData.fieldForm.Seperator.value" [nzDropdownClassName]="'dropDownContainer'"
                    nzAllowClear>
                    <nz-option class="f-12" *ngFor="let item of formData?.fieldForm?.SeparatorOptions"
                      [nzValue]="item.value" [nzLabel]="item.label | titlecase"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
              <nz-form-item>
                <nz-form-label
                  [nzXs]="{ span: 24 }"
                  [nzLabelAlign]="'left'"
                  class="dgrey-text titletext"
                  [nzNoColon]="true"
                  >Number Value </nz-form-label
                >
                <nz-form-control [nzXs]="{ span: 24 }">
                  <input
                    class="f-12"
                    #defaultFieldValue
                    nz-input
                    type="text"
                    (input)="numberInputValue(formData?.fieldForm?.dataLength.value)"
                    [(ngModel)]="formData?.fieldForm?.dataLength.value"
                    oninput="this.value = this.value.replace(/[^0-9]/g, '');"
                    [placeholder]="formData?.fieldForm?.dataLength?.placeholder"
                  />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div
              nz-col
              [nzXs]="{ span: 12 }"
              [nzLg]="{ span: 12 }"
              [nzXXl]="12"
              class="disableSelect"
            >
              <nz-form-item>
                <nz-form-label
                  [nzXs]="{ span: 24 }"
                  [nzLabelAlign]="'left'"
                  class="dgrey-text titletext"
                  [nzNoColon]="true"
                  >Number Value Check</nz-form-label
                >
  
                <nz-form-control [nzXs]="{ span: 24 }">
                  <nz-select
                    nzPlaceHolder="Select"
                    [disabled]="
                      (formData?.fieldForm?.dataLength?.value?.length === 0)
                    "
                    (ngModelChange)="numberFieldValidation(formData?.fieldForm?.dataLength.value, formData.default_value,$event)"
                    [(ngModel)]="formData?.fieldForm?.dataLengthCheck.value"
                    [nzDropdownClassName]="'dropDownContainer'"
                    
                  >
                    <nz-option
                      class="f-12"
                      *ngFor="
                        let item of formData?.fieldForm?.dataLengthCheckOptions
                      "
                      [nzValue]="item.value"
                      [nzLabel]="item.value"
                    ></nz-option>
                  </nz-select>
                  <div class="errorText" *ngIf="!(formData?.fieldForm?.dataLength?.value?.length === 0) && (formData?.fieldForm?.dataLengthCheck.value?.length === 0)">required Number Value Check </div>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
              <nz-form-item>
                <nz-form-label [nzXs]="{ span: 24 }" [nzLabelAlign]="'left'" class="dgrey-text titletext"
                  [nzNoColon]="true">Symbol</nz-form-label>
                <nz-form-control [nzXs]="{ span: 24 }">
                  <nz-select (click)="saveCurrencyLists=CurrencyLists" [(ngModel)]="formData.fieldForm.Symbol.value"
                    [nzDropdownClassName]="'reverse_ant-select-dropdown'"
                    [nzDropdownRender]="selectSearchTemplateCurrency" nzAllowClear>
                    <ng-template #selectSearchTemplateCurrency>
                      <input #saearhx2 nz-input placeholder="Search..." (input)="filterCurrency(saearhx2.value)
                    " />
                    </ng-template>
                    <nz-option class="f-12" *ngFor="let item of CurrencyLists" [nzValue]="item.CurrencySymbol" [nzLabel]="item.CurrencySymbol
                      "></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
              <nz-form-item>
                <nz-form-label [nzXs]="{ span: 24 }" class="dgrey-text titletext" [nzLabelAlign]="'left'"
                  [nzNoColon]="true">Default Value</nz-form-label>
                <nz-form-control [nzXs]="{ span: 24 }">
                  <input #defaultFieldValue nz-input [(ngModel)]="formData.default_value"
                  (input)=" numberFieldValidation(formData?.fieldForm?.dataLength.value, formData.default_value,formData?.fieldForm?.dataLengthCheck.value)"               
                  oninput="this.value = this.value.replace(/(?!^-)[^0-9.]+/g, '').replace(/(\..*)\./g, '$1').replace(/(?!^)-/g, '');" class="f-12" [placeholder]="'Enter'"/>
                </nz-form-control>
              </nz-form-item>
              <div class="errorText" *ngIf="!(number_default_error ==='')">{{number_default_error }}</div>

            </div>
          </nz-row>
        </ng-container>
        <ng-container *ngSwitchCase="'status'">
          <nz-row [nzGutter]="16">
            <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
              <nz-form-label [nzXs]="{ span: 24 }" [nzLabelAlign]="'left'" class="dgrey-text titletext"
                [nzNoColon]="true">Open Statuses &nbsp;
                <img src="../../../assets/images/icons/blueInfoIcon.svg" alt="" /></nz-form-label>
              <div class="statusesContainer" [ngClass]="
                  getDropdown(formData.fieldForm.open_status).length === 0
                    ? 'd-flex'
                    : ''
                " style="padding: 10px 14px">
                <div class="cPointer" *ngIf="getDropdown(formData.fieldForm.open_status).length === 0"
                  style="color: #1d66ff; margin: auto; text-align: center" (click)="openStatuses(formData)">
                  Add Open Statuses
                </div>
                <div *ngFor="
                    let item of getDropdown(formData.fieldForm.open_status);
                    let i = index
                  " [style.background]="hexToRgba(item.color,0.2)"
                            [style.color]="item.color"
                  
                  style="
                    height: 30px;
                    border-radius: 100px;
                    width: 100%;
                    padding: 6px 8px;
                    margin-top: 5px;
                    
                  ">
                  <div style="display: flex; justify-content: space-between">
                    <div>
                      <img src="../../../assets/images/icons/greenbullet.svg" alt="" />
                      {{ item.name }}
                    </div>
                    <div>
                      <!-- <img class="ml-14 cPointer" src="../../../assets/images/icons/editIcon.svg" /> -->
                      <img *ngIf="!formData.dfc" class="ml-14 cPointer" (click)="deleteStatus(i)"
                        src="../../../assets/images/icons/deleteIcon.svg" />
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="getDropdown(formData.fieldForm.open_status).length > 0" class="cPointer" style="color: #1d66ff"
                (click)="openStatuses(formData)">
                Add Open Statuses <span *ngIf="formData.dfc && false" style="font-size: 14px!important;">Color</span>
              </div>
            </div>
            <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
              <nz-form-label [nzXs]="{ span: 24 }" [nzLabelAlign]="'left'" class="dgrey-text titletext"
                [nzNoColon]="true">Close Statuses &nbsp;
                <img src="../../../assets/images/icons/blueInfoIcon.svg" alt="" /></nz-form-label>
              <div class="statusesContainer" [ngClass]="
                  getDropdown(formData.fieldForm.close_status).length === 0
                    ? 'd-flex'
                    : ''
                " style="padding: 10px 14px">
                <div class="cPointer" *ngIf="
                    getDropdown(formData.fieldForm.close_status).length === 0
                  " style="color: #1d66ff; margin: auto; text-align: center" (click)="closeStatuses()">
                  Add Close Statuses
                </div>
                <div *ngFor="
                    let item of getDropdown(formData.fieldForm.close_status);
                    let i = index
                  " [style.background]="hexToRgba(item.color,0.2)"
                  [style.color]="item.color"
                  style="
                    height: 30px;
                    border-radius: 100px;
                    width: 100%;
                    padding: 6px 8px;
                    margin-top: 5px;
                  ">
                  <div style="display: flex; justify-content: space-between">
                    <div>
                      <img src="../../../assets/images/icons/greenbullet.svg" alt="" />
                      {{ item.name }}
                    </div>
                    <div>
                      <!-- <img class="ml-14 cPointer" src="../../../assets/images/icons/editIcon.svg" /> -->
                      <img *ngIf="!formData.dfc" class="ml-14 cPointer" (click)="delteCloseStatus(i)"
                        src="../../../assets/images/icons/deleteIcon.svg" />
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="getDropdown(formData.fieldForm.close_status).length > 0" class="cPointer" style="color: #1d66ff"
                (click)="closeStatuses()">
                Add Close Statuses <span *ngIf="formData.dfc && false">Color</span>
              </div>
            </div>
            <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12" *ngIf="!formData.dfc">
              <nz-form-item>
                <nz-form-label [nzXs]="{ span: 24 }" [nzLabelAlign]="'left'" class="dgrey-text titletext"
                  [nzNoColon]="true">Default Status
                </nz-form-label>
                <nz-form-control [nzXs]="{ span: 24 }">
                  <nz-select (click)="filterStatusDefault('')" [(ngModel)]="formData.default_value"
                    [nzDropdownClassName]="'reverse_ant-select-dropdown'"
                    [nzDropdownRender]="selectSearchTemplateStatusDefault" nzAllowClear nzPlaceHolder="Select">
                    <ng-template #selectSearchTemplateStatusDefault>
                      <input #saearhx2 nz-input placeholder="Search..." (input)="filterStatusDefault(saearhx2.value)
                      " />
                    </ng-template>
                    <nz-option class="f-12" *ngFor="let item of filteredStatusOptions" [nzValue]="item.name"
                      [nzLabel]="item.name"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
          </nz-row>
        </ng-container>
        <ng-container *ngSwitchCase="'tag'">
          <nz-row [nzGutter]="16">
            <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
              <nz-form-label [nzXs]="{ span: 24 }" [nzLabelAlign]="'left'" class="dgrey-text titletext"
                [nzNoColon]="true">Tag Options &nbsp;
                <img src="../../../assets/images/icons/blueInfoIcon.svg" alt="" /></nz-form-label>
              <div class="statusesContainer" [ngClass]="
                  getDropdown(formData.fieldForm.options).length === 0
                    ? 'd-flex'
                    : ''
                " style="padding: 10px 14px">
                <div class="cPointer" *ngIf="getDropdown(formData.fieldForm.options).length === 0"
                  style="color: #1d66ff; margin: auto; text-align: center" (click)="addtag('addNew')">
                  Tag Options
                </div>
                <div *ngFor="
                    let item of getDropdown(formData.fieldForm.options);
                    let i = index
                  " [style.background]="item.color" style="
                    height: 30px;
                    border-radius: 100px;
                    width: 100%;
                    padding: 6px 8px;
                    margin-top: 5px;
                  ">
                  <div style="display: flex; justify-content: space-between">
                    <div>
                      <img src="../../../assets/images/icons/greenbullet.svg" alt="" />
                      {{ item.name }}
                    </div>
                    <div>
                      <img class="ml-14 cPointer" (click)="deletetag(i)"
                        src="../../../assets/images/icons/deleteIcon.svg" />
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="getDropdown(formData.fieldForm.options).length > 0" class="cPointer" style="color: #1d66ff"
                (click)="addtag('addNew')">
                Add Tag
              </div>
            </div>
            <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12"></div>
            <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
              <nz-form-item>
                <nz-form-label [nzXs]="{ span: 24 }" [nzLabelAlign]="'left'" class="dgrey-text titletext"
                  [nzNoColon]="true">Default Value</nz-form-label>
                <nz-form-control>
                  <nz-select (click)="saveTagDefault = formData.fieldForm.options" [nzRemoveIcon]="null"
                    class="dropdownui" [(ngModel)]="formData.default_value" [nzMaxTagCount]="2" nzMode="multiple"
                    [nzMaxTagPlaceholder]="tagPlaceHolder" nzPlaceHolder="-"
                    [nzDropdownClassName]="'reverse_ant-select-dropdown'"
                    [nzDropdownRender]="selectSearchTemplateTagDefault" class="cPointer"
                    style="cursor: pointer !important" nzPlaceHolder="Select">
                    <ng-template #selectSearchTemplateTagDefault>
                      <input #saearhx2 nz-input placeholder="Search..." (input)="filterTagDefault(saearhx2.value)
                        " />
                    </ng-template>
                    <nz-layout *ngFor="
                        let option of formData.fieldForm.options
                      ">
                      <nz-option class="f-12" nzCustomContent [nzValue]="option?.name" [nzLabel]="option.name">
                        <ng-container>
  
                          <div class="f-12">{{ option.name }}</div>
                        </ng-container>
                      </nz-option>
                    </nz-layout>
                  </nz-select>
  
                  <ng-template #tagPlaceHolder let-selectedList style="background-color: none !important">
                    +{{ selectedList.length }} more</ng-template>
                </nz-form-control>
              </nz-form-item>
            </div>
          </nz-row>
        </ng-container>
        <ng-container *ngSwitchCase="'rating'">
          <nz-row [nzGutter]="16">
            <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
              <nz-form-item>
                <nz-form-label [nzXs]="{ span: 24 }" [nzLabelAlign]="'left'" class="dgrey-text titletext"
                  [nzNoColon]="true">Emoji Type</nz-form-label>
                <nz-form-control [nzXs]="{ span: 24 }">
                  <nz-select [(ngModel)]="formData.fieldForm.emojiValue" [nzDropdownClassName]="'dropDownContainer'"
                    [nzCustomTemplate]="defaultTemplate" nzAllowClear>
                    <nz-option class="f-12" nzCustomContent *ngFor="let item of formData?.fieldForm?.emojiType"
                      [nzValue]="item.value" [nzLabel]="item.value">
                      <img [src]="item.img1" alt="" /> &nbsp;
                      <img [src]="item.img2" alt="" />
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
              <ng-template #defaultTemplate let-selected>
                <div>
                  <img [src]="getEmojiicon(selected.nzLabel, 'fill')" alt="" />
                  &nbsp;
                  <img [src]="getEmojiicon(selected.nzLabel, 'outlined')" alt="" />
                </div>
              </ng-template>
            </div>
            <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
              <nz-form-item>
                <nz-form-label [nzXs]="{ span: 24 }" [nzLabelAlign]="'left'" class="dgrey-text titletext"
                  [nzNoColon]="true">Number</nz-form-label>
                <nz-slider [nzMax]="5" [nzMin]="1" class="sliderContainer" [nzTipFormatter]="null" [nzStep]="1"
                  [nzMarks]="marks" [(ngModel)]="formData.fieldForm.number.value" style="width: 100%"></nz-slider>
              </nz-form-item>
            </div>
          </nz-row>
        </ng-container>
        <ng-container *ngSwitchCase="'number'">
          <nz-row [nzGutter]="16">
            <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
              <nz-form-item>
                <nz-form-label [nzXs]="{ span: 24 }" [nzLabelAlign]="'left'" class="dgrey-text titletext"
                  [nzNoColon]="true">Decimal</nz-form-label>
                <nz-form-control [nzXs]="{ span: 24 }">
                  <input nz-input [(ngModel)]="formData.fieldForm.Decimal.value"
                    [placeholder]="formData?.fieldForm?.Decimal?.placeholder"
                    oninput="this.value = this.value.replace(/(?!^-)[^0-9.]+/g, '').replace(/(\..*)\./g, '$1').replace(/(?!^)-/g, '');" class="f-12" />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
              <nz-form-item>
                <nz-form-label [nzXs]="{ span: 24 }" [nzLabelAlign]="'left'" class="dgrey-text titletext"
                  [nzNoColon]="true">Separator</nz-form-label>
                <nz-form-control [nzXs]="{ span: 24 }">
                  <nz-select [(ngModel)]="formData.fieldForm.Seperator.value" [nzDropdownClassName]="'dropDownContainer'"
                    nzAllowClear>
                    <nz-option class="f-12" *ngFor="let item of formData?.fieldForm?.SeparatorOptions"
                      [nzValue]="item.value" [nzLabel]="item.value"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
  
            <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
              <nz-form-item>
                <nz-form-label
                  [nzXs]="{ span: 24 }"
                  [nzLabelAlign]="'left'"
                  class="dgrey-text titletext"
                  [nzNoColon]="true"
                  >Number Value </nz-form-label
                >
                <nz-form-control [nzXs]="{ span: 24 }">
                  <input
                    class="f-12"
                    #defaultFieldValue
                    nz-input
                    type="text"
                    (input)="numberInputValue(formData?.fieldForm?.dataLength.value)"
                    [(ngModel)]="formData?.fieldForm?.dataLength.value"
                    oninput="this.value = this.value.replace(/[^0-9]/g, '');"
                    [placeholder]="formData?.fieldForm?.dataLength?.placeholder"
                  />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div
              nz-col
              [nzXs]="{ span: 12 }"
              [nzLg]="{ span: 12 }"
              [nzXXl]="12"
              class="disableSelect"
            >
              <nz-form-item>
                <nz-form-label
                  [nzXs]="{ span: 24 }"
                  [nzLabelAlign]="'left'"
                  class="dgrey-text titletext"
                  [nzNoColon]="true"
                  >Number Value Check</nz-form-label
                >
  
                <nz-form-control [nzXs]="{ span: 24 }">
                  <nz-select
                    nzPlaceHolder="Select"
                    [disabled]="
                      (formData?.fieldForm?.dataLength?.value?.length === 0)
                    "
                    (ngModelChange)="numberFieldValidation(formData?.fieldForm?.dataLength.value, formData.default_value,$event)"
                    [(ngModel)]="formData?.fieldForm?.dataLengthCheck.value"
                    [nzDropdownClassName]="'dropDownContainer'"
                    
                  >
                    <nz-option
                      class="f-12"
                      *ngFor="
                        let item of formData?.fieldForm?.dataLengthCheckOptions
                      "
                      [nzValue]="item.value"
                      [nzLabel]="item.value"
                    ></nz-option>
                  </nz-select>
                  <div class="errorText" *ngIf="!(formData?.fieldForm?.dataLength?.value?.length === 0) && (formData?.fieldForm?.dataLengthCheck.value?.length === 0)">required Number Value Check </div>
  
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12" *ngIf="!formData.dfc">
              <nz-form-item>
                <nz-form-label [nzXs]="{ span: 24 }" class="dgrey-text titletext" [nzLabelAlign]="'left'"
                  [nzNoColon]="true">Default Value</nz-form-label>
                <nz-form-control [nzXs]="{ span: 24 }">
                  <input #defaultFieldValue nz-input [(ngModel)]="formData.default_value"
                  oninput="this.value = this.value.replace(/(?!^-)[^0-9.]+/g, '').replace(/(\..*)\./g, '$1').replace(/(?!^)-/g, '');"
                  (input)=" numberFieldValidation(formData?.fieldForm?.dataLength.value, formData.default_value,formData?.fieldForm?.dataLengthCheck.value)" class="f-12" [placeholder]="'Enter'"/>
                </nz-form-control>
              </nz-form-item>
              <div class="errorText" *ngIf="!(number_default_error ==='')">{{number_default_error }}</div>
            </div>
          </nz-row>
        </ng-container>
        <ng-container *ngSwitchCase="'website'">
          <nz-row [nzGutter]="16">
            <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12" *ngIf="!formData.is_unique">
              <nz-form-item>
                <nz-form-label [nzXs]="{ span: 24 }" [nzLabelAlign]="'left'" class="dgrey-text titletext"
                  [nzNoColon]="true">Default Value</nz-form-label>
                <nz-form-control [nzXs]="{ span: 24 }">
                  <input #defaultFieldValue nz-input [(ngModel)]="formData.default_value" [placeholder]="'Enter'"
                    class="f-12" />
                </nz-form-control>
              </nz-form-item>
            </div>
          </nz-row>
        </ng-container>
        <ng-container *ngSwitchCase="'phone'">
          <nz-row [nzGutter]="16">
            <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
            </div>
          </nz-row>
        </ng-container>
        <ng-container *ngSwitchCase="'progress'">
          <nz-row [nzGutter]="16">
            <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
              <nz-form-item>
                <nz-form-label [nzXs]="{ span: 24 }" [nzLabelAlign]="'left'" class="dgrey-text titletext"
                  [nzNoColon]="true">Start Value <span class="redColor">*</span></nz-form-label>
                <nz-form-control [nzXs]="{ span: 24 }">
                  <input #defaultFieldValue nz-input [(ngModel)]="formData?.fieldForm?.startValue.value"
                    oninput="this.value = this.value.replace(/[^0-9]/g, '');"
                    [placeholder]="formData?.fieldForm?.startValue?.placeholder" class="f-12" />
                </nz-form-control>
              </nz-form-item>
            <div *ngIf="!(formData?.fieldForm?.startValue.value?.toString().length > 0)" style="color: red; font-size: smaller">start value required</div>

            </div>
            <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
              <nz-form-item>
                <nz-form-label [nzXs]="{ span: 24 }" [nzLabelAlign]="'left'" class="dgrey-text titletext"
                  [nzNoColon]="true">End Value <span class="redColor">*</span></nz-form-label>
                <nz-form-control [nzXs]="{ span: 24 }">
                  <input #defaultFieldValue nz-input [(ngModel)]="formData?.fieldForm?.endValue.value"
                    [placeholder]="formData?.fieldForm?.endValue?.placeholder"
                    oninput="this.value = this.value.replace(/[^0-9]/g, '');" class="f-12" />
                </nz-form-control>
              </nz-form-item>
            <div *ngIf="!(formData?.fieldForm?.endValue.value?.toString().length > 0)" style="color: red; font-size: smaller">end value required</div>

            </div>
            <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
              <nz-form-item>
                <nz-form-label [nzXs]="{ span: 24 }" [nzLabelAlign]="'left'" class="dgrey-text titletext"
                  [nzNoColon]="true">Default Value</nz-form-label>
                <nz-form-control [nzXs]="{ span: 24 }">
                  <input #defaultFieldValue nz-input [(ngModel)]="formData.default_value" [placeholder]="'Enter'"
                    oninput="this.value = this.value.replace(/[^0-9]/g, '');" class="f-12" />
                </nz-form-control>
              </nz-form-item>
            </div>
          </nz-row>
        </ng-container>
  
        <ng-container *ngSwitchCase="'attachment'">
          <nz-row [nzGutter]="16"> </nz-row>
        </ng-container>
        <ng-container *ngSwitchCase="'dropdown_single_select'">
          <nz-row [nzGutter]="16">
            <div nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 24 }" [nzXXl]="24">
              <nz-form-item>
                <nz-form-label [nzXs]="{ span: 24 }" [nzLabelAlign]="'left'" class="dgrey-text titletext"
                  [nzNoColon]="true">Option Type</nz-form-label>
                <nz-radio-group [(ngModel)]="formData.fieldForm.optionType" (ngModelChange)="optionTypeChange($event)"
                  style="
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                  ">
                  <label nz-radio nzValue="STATIC" class="f-12">Static</label>
                  <label nz-radio nzValue="API" class="f-12">API</label>
                  <label nz-radio nzValue="ENTITY_DATA" style="opacity: 0;" class="f-12">Entity</label>

                </nz-radio-group>
              </nz-form-item>
            </div>
            <nz-row [nzGutter]="16" *ngIf="formData.fieldForm.optionType === 'STATIC'" style="width: 100%">
              <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
                <nz-form-label [nzXs]="{ span: 24 }" [nzLabelAlign]="'left'" class="dgrey-text titletext"
                  [nzNoColon]="true">Drop Down Options</nz-form-label>
                <div class="statusesContainer" [ngClass]="
                    getDropdown(formData?.fieldForm?.options)?.length === 0
                      ? 'd-flex'
                      : ''
                  " style="padding: 10px 14px">
                  <div class="cPointer" *ngIf="getDropdown(formData?.fieldForm?.options)?.length === 0"
                    style="color: #1d66ff; margin: auto; text-align: center" (click)="addDropDownoption('addNew')">
                    Add Drop Down
                  </div>
                  <div *ngFor="
                      let item of getDropdown(formData?.fieldForm?.options);
                      let i = index
                    " style="
                      height: 30px;
                      border-radius: 100px;
                      width: 100%;
                      padding: 6px 8px;
                      margin-top: 5px;
                    ">
                    <div style="display: flex; justify-content: space-between">
                      <div>
                        <img src="../../../assets/images/icons/greenbullet.svg" alt="" />
                        {{ item.name }}
                      </div>
                      <div>
                        <img class="ml-14 cPointer" (click)="delteDropDown(i)"
                          src="../../../assets/images/icons/deleteIcon.svg" />
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="getDropdown(formData.fieldForm.options)?.length > 0" class="cPointer" style="color: #1d66ff"
                  (click)="addDropDownoption('addNew')">
                  Add Drop Down
                </div>
              </div>
              <nz-col [nzSpan]="24">
                <nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
                  <nz-form-item>
                    <nz-form-label [nzXs]="{ span: 24 }" [nzLabelAlign]="'left'" class="dgrey-text titletext"
                      [nzNoColon]="true">Default Value</nz-form-label>
                    <nz-form-control [nzXs]="{ span: 24 }">
                      <nz-select (click)="saveDefaultValue = formData.fieldForm.options"
                        [(ngModel)]="formData.default_value" [nzDropdownClassName]="'reverse_ant-select-dropdown'"
                        [nzDropdownRender]="selectSearchdefault" nzAllowClear nzPlaceHolder="Select">
                        <ng-template #selectSearchdefault>
                          <input #saearhx2 nz-input placeholder="Search..." (input)="filterdefault(saearhx2.value)
                        " />
                        </ng-template>
                        <nz-option class="f-12" *ngFor="let item of formData.fieldForm.options" [nzValue]="item.name"
                          [nzLabel]="item.name"></nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </nz-col>
              </nz-col>
            </nz-row>
  
            <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12"
              *ngIf="formData.fieldForm.optionType === 'API'">
              <nz-form-item>
                <nz-form-label [nzXs]="{ span: 24 }" class="dgrey-text titletext" [nzLabelAlign]="'left'"
                  [nzNoColon]="true">Select API</nz-form-label>
                <nz-form-control [nzXs]="{ span: 24 }">
                  <nz-select (click)="save_api_options = select_api_options" [(ngModel)]="formData.fieldForm.api_name"
                    (ngModelChange)="selectApiChange($event)" [nzDropdownClassName]="'reverse_ant-select-dropdown'"
                    [nzDropdownRender]="selectSearchTemplateapi" nzAllowClear>
                    <!-- <nz-option></nz-option> -->
                    <ng-template #selectSearchTemplateapi>
                      <input #saearhx2 nz-input placeholder="Search..." (input)="filterapi(saearhx2.value)
                    " />
                    </ng-template>
                    <nz-option class="f-12" *ngFor="let item of select_api_options" [nzValue]="item.api_name"
                      [nzLabel]="item.api_name"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12"
              *ngIf="formData.fieldForm.optionType === 'API'">
              <nz-form-item>
                <nz-form-label [nzXs]="{ span: 24 }" class="dgrey-text titletext" [nzLabelAlign]="'left'"
                  [nzNoColon]="true">API Field</nz-form-label>
                <nz-form-control [nzXs]="{ span: 24 }">
                  <nz-select (click)="Save_api_field_options = api_field_options"
                    [(ngModel)]="formData.fieldForm.api_field" [nzDropdownClassName]="'reverse_ant-select-dropdown'"
                    [nzDropdownRender]="selectSearchTemplateFieldOption" nzAllowClear>
                    <ng-template #selectSearchTemplateFieldOption>
                      <input #saearhx2 nz-input placeholder="Search..." (input)="filterFieldOption(saearhx2.value)
                    " />
                    </ng-template>
                    <nz-option class="f-12" *ngFor="let item of api_field_options" [nzValue]="item"
                      [nzLabel]="item"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <ng-container *ngIf="formData.fieldForm.optionType === 'API'">
              <ng-container>
                <nz-col [nzSpan]="24" style="margin-bottom: 5px;">
                  <nz-switch id="switch0" [(ngModel)]="showDependentField"></nz-switch>
                  <label for="switch0" class="f-12">&nbsp;&nbsp;Dependent Field</label>
                </nz-col>
              </ng-container>
  
              <nz-col [nzSpan]="12" *ngIf="showDependentField">
                <nz-form-item>
                  <nz-form-label [nzXs]="{ span: 24 }" class="dgrey-text titletext" [nzLabelAlign]="'left'"
                    [nzNoColon]="true">Dependent Field</nz-form-label>
                  <nz-form-control [nzXs]="{ span: 24 }">
                    <nz-select (click)="getSelectedTabField()" [nzOptionOverflowSize]="5"
                      [(ngModel)]="formData.fieldForm.def" nzAllowClear
                      [nzDropdownClassName]="'reverse_ant-select-dropdown'"
                      [nzDropdownRender]="selectSearchTemplateFieldData" (ngModelChange)="Fieldentity($event)">
  
                      <ng-template #selectSearchTemplateFieldData>
                        <input #saearhFieldData nz-input placeholder="Search..."
                          (input)="filterFieldData(saearhFieldData.value)" /></ng-template>
  
                      <ng-container *ngFor="let item of fieldData">
                        <ng-container *ngIf="formData.fieldForm.field_name !== item.name">
                          <nz-option class="f-12" [nzValue]="item.name" [nzLabel]="item.label">
                          </nz-option>
                        </ng-container>
                      </ng-container>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </nz-col>
            </ng-container>
          </nz-row>
        </ng-container>
        <ng-container *ngSwitchCase="'dropdown_multi_select'">
          <nz-row [nzGutter]="16">
            <div nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 24 }" [nzXXl]="24">
              <nz-form-item>
                <nz-form-label [nzXs]="{ span: 24 }" [nzLabelAlign]="'left'" class="dgrey-text titletext"
                  [nzNoColon]="true">Option Type</nz-form-label>
                <nz-radio-group [(ngModel)]="formData.fieldForm.optionType" style="
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                  " (ngModelChange)="optionTypeChange($event)">
                  <label nz-radio nzValue="STATIC" class="f-12">Static</label>
                  <label nz-radio nzValue="API" class="f-12">API</label>
                  <label nz-radio nzValue="ENTITY_DATA" style="opacity: 0;" class="f-12">Entity</label>

                </nz-radio-group>
              </nz-form-item>
            </div>
            <nz-row [nzGutter]="16" *ngIf="formData.fieldForm.optionType === 'STATIC'" style="width: 100%">
              <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
                <nz-form-label [nzXs]="{ span: 24 }" [nzLabelAlign]="'left'" class="dgrey-text titletext"
                  [nzNoColon]="true">Drop Down Options</nz-form-label>
                <div class="statusesContainer" [ngClass]="
                    getDropdown(formData.fieldForm.options)?.length === 0
                      ? 'd-flex'
                      : ''
                  " style="padding: 10px 14px">
                  <div class="cPointer" *ngIf="getDropdown(formData.fieldForm.options)?.length === 0"
                    style="color: #1d66ff; margin: auto; text-align: center" (click)="addDropDownoption('addNew')">
                    Add Drop Down
                  </div>
                  <div *ngFor="
                      let item of getDropdown(formData.fieldForm.options);
                      let i = index
                    " style="
                      height: 30px;
                      border-radius: 100px;
                      width: 100%;
                      padding: 6px 8px;
                      margin-top: 5px;
                    ">
                    <div style="display: flex; justify-content: space-between">
                      <div>
                        <img src="../../../assets/images/icons/greenbullet.svg" alt="" />
                        {{ item.name }}
                      </div>
                      <div>
                        <img class="ml-14 cPointer" (click)="delteDropDown(i)"
                          src="../../../assets/images/icons/deleteIcon.svg" />
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="getDropdown(formData.fieldForm.options)?.length > 0" class="cPointer" style="color: #1d66ff"
                  (click)="addDropDownoption('addNew')">
                  Add Drop Down
                </div>
              </div>
            </nz-row>
  
            <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12"
              *ngIf="formData.fieldForm.optionType === 'API'">
              <nz-form-item>
                <nz-form-label [nzXs]="{ span: 24 }" class="dgrey-text titletext" [nzLabelAlign]="'left'"
                  [nzNoColon]="true">Select API</nz-form-label>
                <nz-form-control [nzXs]="{ span: 24 }">
                  <nz-select (click)="save_api_options = select_api_options" [(ngModel)]="formData.fieldForm.api_name"
                    (click)="selectApiChange($event)" (ngModelChange)="selectApiChange($event)"
                    [nzDropdownClassName]="'reverse_ant-select-dropdown'" [nzDropdownRender]="selectSearchTemplateapi"
                    nzAllowClear>
                    <ng-template #selectSearchTemplateapi>
                      <input #saearhx2 nz-input placeholder="Search..." (input)="filterapi(saearhx2.value)
                    " />
                    </ng-template>
                    <nz-option class="f-12" *ngFor="let item of select_api_options" [nzValue]="item.api_name"
                      [nzLabel]="item.api_name"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12"
              *ngIf="formData.fieldForm.optionType === 'API'">
              <nz-form-item>
                <nz-form-label [nzXs]="{ span: 24 }" class="dgrey-text titletext" [nzLabelAlign]="'left'"
                  [nzNoColon]="true">API Field</nz-form-label>
                <nz-form-control [nzXs]="{ span: 24 }">
                  <nz-select (click)="Save_api_field_options = api_field_options"
                    [(ngModel)]="formData.fieldForm.api_field" [nzDropdownClassName]="'reverse_ant-select-dropdown'"
                    [nzDropdownRender]="selectSearchTemplateFieldOption" nzAllowClear>
                    <ng-template #selectSearchTemplateFieldOption>
                      <input #saearhx2 nz-input placeholder="Search..." (input)="filterFieldOption(saearhx2.value)
                    " />
                    </ng-template>
                    <nz-option class="f-12" *ngFor="let item of api_field_options" [nzValue]="item"
                      [nzLabel]="item"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <ng-container *ngIf="formData.fieldForm.optionType === 'API'">
              <ng-container>
                <nz-col [nzSpan]="24" style="margin-bottom: 5px;">
                  <nz-switch id="switch0" [(ngModel)]="showDependentField"></nz-switch>
                  <label for="switch0" class="f-12">&nbsp;&nbsp;Dependent Field {{formData.fieldForm.def}}</label>
                </nz-col>
              </ng-container>
  
              <nz-col [nzSpan]="12" *ngIf="showDependentField">
                <nz-form-item>
                  <nz-form-label [nzXs]="{ span: 24 }" class="dgrey-text titletext" [nzLabelAlign]="'left'"
                    [nzNoColon]="true">Dependent Field </nz-form-label>
                  <nz-form-control [nzXs]="{ span: 24 }">
                    <nz-select (click)="getSelectedTabField()" [nzOptionOverflowSize]="5"
                      [(ngModel)]="formData.fieldForm.def" nzAllowClear
                      [nzDropdownClassName]="'reverse_ant-select-dropdown'"
                      [nzDropdownRender]="selectSearchTemplateFieldData" (ngModelChange)="Fieldentity($event)">
  
                      <ng-template #selectSearchTemplateFieldData>
                        <input #saearhFieldData nz-input placeholder="Search..."
                          (input)="filterFieldData(saearhFieldData.value)" /></ng-template>
  
                      <ng-container *ngFor="let item of fieldData">
                        <ng-container *ngIf="formData.fieldForm.field_name !== item.name">
                          <nz-option class="f-12" [nzValue]="item.name" [nzLabel]="item.label">
                          </nz-option>
                        </ng-container>
                      </ng-container>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </nz-col>
            </ng-container>
            <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12" *ngIf="
                formData.fieldForm.optionType !== 'ENTITY_DATA' &&
                formData.fieldForm.optionType !== 'API'
              ">
              <nz-form-item>
                <nz-form-label [nzXs]="{ span: 24 }" class="dgrey-text titletext" [nzLabelAlign]="'left'"
                  [nzNoColon]="true">Default Option</nz-form-label>
                <nz-form-control [nzXs]="{ span: 24 }">
                  <nz-select (click)="saveoptions = formData.fieldForm.options" [(ngModel)]="formData.default_value"
                    [nzDropdownClassName]="'reverse_ant-select-dropdown'"
                    [nzDropdownRender]="selectSearchTemplateMultiDefault" nzAllowClear nzPlaceHolder="Select">
                    <ng-template #selectSearchTemplateMultiDefault>
                      <input #saearhx2 nz-input placeholder="Search..." (input)="filterMultiDefault(saearhx2.value)
                    " />
                    </ng-template>
                    <nz-option class="f-12" [nzValue]="'blank'" [nzLabel]="''"></nz-option>
                    <nz-option class="f-12" [nzValue]="formData.default_value" [nzLabel]="formData.default_value"
                      nzHide></nz-option>
                    <nz-option class="f-12" *ngFor="let item of formData.fieldForm.options" [nzValue]="item.name"
                      [nzLabel]="item.name"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
          </nz-row>
        </ng-container>
        <ng-container *ngSwitchCase="'rm'">
          <nz-row [nzGutter]="16">
            <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
              <nz-form-item>
                <nz-form-label [nzXs]="{ span: 24 }" [nzLabelAlign]="'left'" class="dgrey-text titletext"
                  [nzNoColon]="true">Default Value</nz-form-label>
                <nz-form-control [nzXs]="{ span: 24 }">
                  <input #defaultFieldValue nz-input [(ngModel)]="formData.default_value" [placeholder]="'Enter'"
                    class="f-12" />
                </nz-form-control>
              </nz-form-item>
            </div>
          </nz-row>
        </ng-container>
        <ng-container *ngSwitchCase="'people'">
          <nz-row [nzGutter]="16">
            <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
              <nz-form-item>
                <nz-form-label [nzXs]="{ span: 24 }" [nzLabelAlign]="'left'" class="dgrey-text titletext"
                  [nzNoColon]="true">Field Name <span class="redColor">*</span></nz-form-label>
                <nz-select style="width: 100%" [(ngModel)]="formData.fieldForm.api_field"
                  [nzDropdownClassName]="'dropDownContainer'" (nzOpenChange)="onPeopleOpenChange($event);formData.fieldForm.api_field?formData.fieldForm.api_field_validators.error='':''"
                  (ngModelChange)="selectPeopleChange($event)" nzAllowClear>
                  <nz-option class="f-12" *ngIf="formData.fieldForm.api_field as p" [nzValue]="p" [nzLabel]="p"
                    nzHide=""></nz-option>
                  <nz-option *ngFor="let p of people$()|async" [nzValue]="p" [nzLabel]="p"></nz-option>
                </nz-select>
              </nz-form-item>
            <div *ngIf="formData.fieldForm.api_field_validators && formData.fieldForm.api_field_validators.error" style="color: red;text-align: start;font-size: 11px;">{{formData.fieldForm.api_field_validators.error | titlecase}}</div>

            </div>
            <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
              <nz-form-item>
                <nz-form-label [nzXs]="{ span: 24 }" [nzLabelAlign]="'left'" class="dgrey-text titletext"
                  [nzNoColon]="true">Default Value</nz-form-label>
                <nz-select [nzRemoveIcon]="null" class="dropdownui dropDownContainer" nzAllowClear
                  [nzDropdownClassName]="'reverse_ant-select-dropdown'" [nzDropdownRender]="selectSearchTemplatePeople"
                  [(ngModel)]="formData.default_value" [nzMaxTagCount]="2" nzMode="multiple"
                  [nzMaxTagPlaceholder]="selectPeople" class="cPointer"
                  style="cursor: pointer !important;width: 100%"
                  (click)=" this.selectAPI.emit({ action: true, people: 'people' });" nzPlaceHolder="Select">
                  <ng-template #selectSearchTemplatePeople>
                    <input #saearhx2 nz-input placeholder="Search..." [(ngModel)]="peopleDefaultInput" />
                  </ng-template>
                  <ng-container *ngIf="formData.default_value">
  
                    <nz-option class="f-12" *ngFor="let item of formData.default_value" [nzLabel]="item" [nzValue]="item"
                      nzHide></nz-option>
                  </ng-container>
                  <nz-option class="f-12" *ngFor="let option of getPeopleOption()|async" [nzValue]="option"
                    nzLabel="{{ option}}"></nz-option>
                </nz-select>
  
                <ng-template #selectPeople let-selectedList style="background-color: none !important">
                  +{{ selectedList?.length }} more</ng-template>
              </nz-form-item>
            </div>
          </nz-row>
        </ng-container>
      </ng-container>
    </ng-container>
    <nz-divider style="margin: 5px 0 !important"></nz-divider>
    <nz-row [nzGutter]="16" class="switchStyle">
      <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12" >
        <nz-form-item>
          <nz-form-control>
            <div class="" style="display: flex; align-items: center">
              <nz-switch [(ngModel)]="formData.isMandatory" id="switch1"></nz-switch>
              <label for="switch1" class="f-12">&nbsp;Is Mandatory?</label>
            </div>
          </nz-form-control>
        </nz-form-item>
      </div>
      <ng-container
        *ngIf="formData.data_type.value ==='textbox'|| formData.data_type.value ==='phone'|| formData.data_type.value ==='website'|| formData.data_type.value ==='email'">
        <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12">
          <nz-form-item>
            <nz-form-control>
              <div class="" style="display: flex; align-items: center">
                <nz-switch id="switchUnique" [(ngModel)]="formData.is_unique"></nz-switch>
                <label for="switchUnique" class="f-12">&nbsp;Is Unique?</label>
              </div>
            </nz-form-control>
          </nz-form-item>
        </div>
      </ng-container>
      <div nz-col [nzXs]="{ span: 12 }" [nzLg]="{ span: 12 }" [nzXXl]="12" *ngIf="formData.can_group">
        <nz-form-item>
          <nz-form-control style="position: relative">
            <div class="" style="display: flex; align-items: center">
              <nz-switch id="switch2" [(ngModel)]="formData.default_group"
                (ngModelChange)="onDefaultGropChange($event)"></nz-switch>
              <label for="switch2"> &nbsp;Default Group?</label>
            </div>
            <div style="position: absolute; color: red; font-size: 8px" class="fw-400" *ngIf="entityField?.length > 0">
              Default grouping has been set on -{{ entityField[0].label }}. This
              will override the default grouping setting
            </div>
          </nz-form-control>
        </nz-form-item>
      </div>
    </nz-row>
    <div nz-col [nzXs]="{ span: 24 }">
      <nz-form-label class="dgrey-text titletext" [nzLabelAlign]="'left'" [nzNoColon]="true">Tool Tip</nz-form-label>
      <nz-form-item>
        <nz-form-control>
          <input nz-input placeholder="Enter" type="text" [(ngModel)]="formData.tooltip.value" class="f-12" />
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzXs]="{ span: 24 }">
      <nz-form-label class="dgrey-text titletext" [nzLabelAlign]="'left'" [nzNoColon]="true">Date Privacy &
        Security</nz-form-label>
      <div nz-col [nzXs]="{ span: 24 }" style="display: flex">
        <div nz-col nzSpan="8">
          <label nz-checkbox [(ngModel)]="formData.is_pii" class="f-12">PII</label>
        </div>
        <div nz-col nzSpan="8">
          <label nz-checkbox [(ngModel)]="formData.to_encrypt" class="f-12">Encrypt</label>
        </div>
        <div nz-col nzSpan="8">
          <label nz-checkbox [(ngModel)]="formData.is_ephi" class="f-12">ePHI</label>
        </div>
      </div>
    </div>
  </div>
  <div class="form-footer">
    <nz-row [nzJustify]="'end'">
      <div class="text-center f-10 fw-700 lh-18 blueColor cPointer">
        <button nz-button nzType="primary" class="buttonDefault save f-12" style="height: 35px; width: 90px"
          (click)="onSave()">
          SAVE
        </button>
      </div>
      <div class="text-center f-10 fw-700 lh-18 cPointer" style="margin-left: 10px; height: 35px; width: 90px">
        <button nz-button nzType="default" style="height: 34px" class="cancel f-12" (click)="closeDrawer()">
          CANCEL
        </button>
      </div>
    </nz-row>
  </div>
