<div class="px-10 textStyle progress" fluidRowWidth [type]="header.name">
  <nz-row [nzAlign]="'middle'" class="min-height-37">
    <nz-col [nzSpan]="18" class="slidersContainer">
      <nz-slider
        (nzOnAfterChange)="changeProgress($event)"
        [(ngModel)]="data[header.name]"
        [disabled]="!this.header.can_edit || data?.is_locked || data?.lock_fields?.includes(this.header?.name)"
      ></nz-slider>
    </nz-col>
    <nz-col [nzSpan]="6" style="font-size: 9px">
      {{ data[header.name]>100?'100':  data[header.name] || "0" }} %
    </nz-col>
  </nz-row>
</div>
