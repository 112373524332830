<!-- [style.pointerEvents]="header.dfc ? 'none':'auto'" -->
<div class="px-10 textStyle"  fluidRowWidth [type]="header.name" [style.pointerEvents]="(header.dfc  && !header.editable) || !header.can_edit || data?.is_locked || data?.lock_fields?.includes(this.header?.name)? 'none':'auto'" >
  <div style="width: fit-content;text-align: center;">
    <div nz-dropdown (nzVisibleChange)="event($event)" [nzDropdownMenu]="menu" nzTrigger="click" >
      <div [style.border]="'1px solid ' + ((d$(data[header.name] ||header.default)|async)?.color || 'black')" style="border-radius:5px; position: relative;">
        <div style="display: flex; align-items: center; justify-content: center; ">
          <!-- <div [style.background]="(d$(data[header.name] ||header.default)|async)?.color || 'black'" style="height: 5px; width:5px; border-radius: 50px;"></div> -->
          <div style="padding: 3px 10px;" [style.color]="(d$(data[header.name] ||header.default)|async)?.color || 'black'">{{(d$(data[header.name])|async)?.name||header.default || '-'}}</div>
        </div>
        <div [style.background]="(d$(data[header.name] || header.default)|async)?.color || 'white'"

          style="position:absolute;opacity: 0.2; top: 0;left:0;right:0;bottom:0 ; border-radius:5px;">
          &nbsp;
        </div>
      </div>
    </div>
  </div>

<nz-dropdown-menu #menu="nzDropdownMenu" nzPlacement="bottomRight">
  <ng-container *ngIf="close">
  <nz-input-group [nzPrefix]="prefixTemplateUser" class="searchInputContainer">
    <input [nzBorderless]="false" #search autofocus nz-input placeholder="Search" type="text"  [(ngModel)]="searchs" />
    <ng-template #prefixTemplateUser><img src="../../assets/images/icons/searchIons.svg" /></ng-template>
  </nz-input-group>
  
    <ul nz-menu (click)="$event.preventDefault();$event.stopPropagation()" nzSelectable class="option-list">
      <li #menuitem nz-menu-item *ngFor="let status of  o$(search.value)|async" (click)="update(status)">
        <span style="padding: 2px;border-radius: 50%;pointer-events: auto;">
          <div class="avtarcheck">
            <div style="height:20px;width:20px;border-radius: 50px;opacity: 0.2" [style.background]="status.color">
            </div>
          </div>
        </span>
        <span style="margin-left: 5px;pointer-events: auto;">{{status.name}}</span>
      </li>
    </ul>
  </ng-container>

</nz-dropdown-menu>

</div>
