<div class="px-10 textStyle" fluidRowWidth [type]="header.name">
    <div style="width: 100%;height:100%;" class="field_hover">
        <div class="editable-cell p-0  word-break-all align-center min-height-37 d-flex"  *ngIf="!(editval$|async)" (click)="editing()">
            {{
            data[header.name]
            ? data[header.name]
            : header.default
            ? header.default
            : "&nbsp;&nbsp;&nbsp;"
            }}
        </div>
        <nz-form-control  *ngIf="(editval$|async)" >
            <nz-input-group>
                <input auto-focus-input style="padding: 2px;" 
                [nzStatus]="header.error?'error':''"
                (focusout)="checkValidation($event)" type="email" nz-input [(ngModel)]="data[header.name]"
                   />
    
            </nz-input-group>
            <div style="color: red ;font-size: smaller;text-align: left;">
                {{header.error}}
                </div>
        </nz-form-control>
    </div>
</div>