
<div class="header-wrapper" [ngClass]="{'hide_tab_select':hide_selected_tab}">
  <div nz-row [nzGutter]="16" style="display: flex !important; margin-bottom: 6px;padding-left: 20px!important;" class="headerbar">
    <div class="custom-header" nz-col [nzMd]="16"  style="height: 55px;">
      <!-- <nz-layout class="layout"> -->
        <!-- <ul nz-menu nzMode="horizontal">
          <li *ngIf="this.stateService.appState().metaData.dashboard" nz-menu-item (click)="navigateDashboard()"
            [nzSelected]="getTabName('overview')">
            {{ 'Dashboard'}}
          </li>
          <ng-container *ngFor="let tab of getTabes()">
            <li [hidden]="tab?.hide_entity === 'true' "  nz-menu-item (click)="navigate(tab.name);confirmSwitchOnEdit()" [nzSelected]="getTabName(tab.name)">
              {{ tab.display_name }}
            </li>
          </ng-container>
          <li  [hidden]="!selectedTab()" nz-menu-item (click)="navigateConversations()"
          >
          {{ 'Conversations'}}
        </li>

          <li nz-menu-item (click)="openAddmore()" [nzDisabled]="true" class="cPointer" *ngIf="isAdmin() ">
            <div style="color: #1D66FF;" class="f-12 fw-400">
              <span style="border: 1px dashed #A1A8B5;padding: 7px 21px 6px 15px;">+ {{(getEntity(stateService.appState().metaData?.entities))?'Manage':'Manage'}}  Entities</span>
            </div>
          </li>
          <li nz-menu-item style="opacity: 0; visibility: hidden; pointer-events: none">
            blank
          </li>

        </ul> -->

      <!-- </nz-layout> -->
      <div style="display: flex;">
        <nz-tabset
        [nzTabPosition]="'top'"
        style="height:64px;"
        [(nzSelectedIndex)]="selectedTabIndex"
        (nzSelectChange)="setIndex()"
      >

      <ng-container *ngIf="getTabes()">
        <!-- <nz-tab *ngIf="this.stateService.m s().metaData.dashboard" (nzClick)="navigateDashboard()"  [nzTitle]="'Dashboard'"></nz-tab> -->
      </ng-container>
      <ng-container *ngFor="let tab of getTabes()">
        <ng-container *ngIf="!(tab?.hide_entity === 'true')">
          <nz-tab
            [nzTitle]="tab.display_name"
            (nzClick)="navigate(tab.name);confirmSwitchOnEdit()"
          >
          </nz-tab>
        </ng-container>
      </ng-container>

      </nz-tabset>
      <ng-container *ngIf="getTabes() && showDashboarHeader">
        <!-- <div (click)="navigateConversations()" *ngIf="selectedTab()" class="f-12 fw-400 my-auto CPointer" style="margin-left: 16px;">Conversations</div> -->
        <div  *ngIf="isAdmin()" class="f-12 fw-400 my-auto " style="min-width: 137px;"><span class="customTabStyle CPointer" (click)="openAddmore()">Manage Entities'</span></div>
        <div [ngClass]="hide_selected_tab?'active':'in_active'" class="f-12 fw-400 my-auto " style="min-width: 80px;" (click)="open_my_approval()"><span class="CPointer">My Approval</span></div>
      </ng-container>

      </div>
    </div>
    <!-- <ng-container *ngIf="getTabes()"> -->
      <!-- <div nz-col [nzXXl]="2" (click)="navigateConversations()" class="f-12 fw-400 my-auto">Conversations</div>
      <div nz-col [nzXXl]="3"  (click)="openAddmore()"   class="f-12 fw-400 my-auto "><span class="customTabStyle">+ Manage Entities'</span></div> -->
      <!-- <div nz-col [nzSpan]="2" *ngIf="selectedTab()" (nzClick)="navigateConversations()" >Conversations</div>
      <div nz-col [nzSpan]="2" *ngIf="isAdmin()" (nzClick)="openAddmore()"   class="customTabStyle">+ Manage Entities'</div> -->
      <!-- </ng-container> -->
    <div nz-col [nzMd]="8"
      class="d-flex showHeaderMenu justifyRight">
      <div class="d-flex" nz-col [nzXs]="{ span: 12, offset: 0 }" [nzLg]="{ span: 12, offset: 0 }" style="height: 32px">
        <a nz-dropdown nzTrigger="click" (click)="getorglist()" [nzDisabled]="stateService.orgList()?.length == 0" [nzDropdownMenu]="menu"
          class="textcolor f-12 fw-400 text-center w100" style="
            background-color: #f2f2f2;
            display: flex;
            justify-content: space-between;
            padding: 0 6px 0 20px;
          " [nzPlacement]="'bottomCenter'">
          {{
          stateService.appState().selectedOrganization?.org_name ||
          "Select Organization Name"
          }}
          <span nz-icon><img src="../../../../../assets/images/arrow-up.png" /></span>
        </a>

        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu>
            <li style="padding-right: 0!important;padding-left: 0!important;padding-top: 0!important;">
              <input #saearhx2 nz-input placeholder="Search..." (input)="filterorg(saearhx2.value) "/>
            </li>
            <div style="height: 150px;overflow-y: auto;margin-top: 5px;">
              <li nz-menu-item *ngFor="let org of orglist" (click)="menuTextChange(org)"
              style="font-size: 12px;">
              {{ org.org_name }}
            </li>
            </div>
          </ul>
        </nz-dropdown-menu>
      </div>
      <div class="d-flex notificationContainer">
        <div (click)="setting()" nz-col [nzXs]="{ span: 6, offset: 0 }" [nzLg]="{ span: 6, offset: 0 }"
          class="imagePosition cPointer settings">
          <img src="../../../../../assets/images/settings.svg" />
        </div>
        <div nz-col [nzXs]="{ span: 6, offset: 0 }" [nzLg]="{ span: 6, offset: 0 }"
          class="imagePosition cPointer mr-40">
          <img src="../../../../../assets/images/solar.svg" />
        </div>
        <!-- <div
          nz-col
          [nzXs]="{ span: 8, offset: 0 }"
          [nzLg]="{ span: 8, offset: 0 }"
          class="imagePosition cPointer"
        >
          <img src="../../../../../assets/images/search.svg" />
        </div> -->
      </div>
    </div>
  </div>
</div>
<nz-content> </nz-content>
<ng-container *ngIf="showNewEntityDrawer">
  <nz-drawer
  [nzClosable]="false"
  [nzVisible]="showNewEntityDrawer"
  nzPlacement="right"
  [nzTitle]="(getEntity(stateService.appState().metaData?.entities))?'MANAGE ':'MANAGE ' + 'ENTITIES'"
  (nzOnClose)="newEntityclose()"
  [nzWrapClassName]="'manageEntityDrawers'"
>
<ng-container *nzDrawerContent>
<app-create-new-entity [headerText]="(getEntity(stateService.appState().metaData?.entities))?'Manage':'MANAGE'" [stateService]="stateService" [data]="showNewEntityDrawerDatas" (newEntityOutput)="newEntityOutput($event)"></app-create-new-entity>
</ng-container>
</nz-drawer>
</ng-container>
