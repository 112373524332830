<div class="px-10 textStyle" fluidRowWidth [type]="header.name" [style.pointerEvents]="!header.can_edit || data?.is_locked || data?.lock_fields?.includes(this.header?.name)?'none':'auto'">


<div  class="editable-cell w-100 min-height-37 align-center d-flex " [style.pointerEvents]="!header.can_edit?'none':'auto'"
  style="text-align: center;cursor: pointer;min-width: inherit;justify-content: center;" nz-dropdown
  [nzDropdownMenu]="menu" nzTrigger="click"  (click)="openDropDownAPIChange(header, searchInput, true)" (nzVisibleChange)="event($event)">
  <nz-avatar-group style="pointer-events: none;">
    <ng-container *ngIf="selectedPeople?.length==0"> <img src="../../assets/images/icons/tabelPeople.svg"
        style="width: 16px;" alt="People Icon"></ng-container>
    <ng-container *ngIf="selectedPeople?.length<=3">
      <ng-container *ngFor="let people of selectedPeople">
        <ng-container *ngIf="people !== ''">
                  <!-- <span class="avatar-container"> -->
        <div class="avtarImage">
          <nz-avatar [style.pointerEvents]="!header.can_edit?'none':'auto'" [nzText]="shortenName(people)" nz-tooltip [nzTooltipTitle]="people"  nzTooltipPlacement="top" [nzTooltipOverlayClassName]="'toolTipContainerss'"
            [style.backgroundColor]="'#1d66ff'"></nz-avatar>
          <div [style.pointerEvents]="!header.can_edit?'none':'auto'" class="closeImg" (click)="deleteUser(people)">
            <span nz-icon nzType="close" nzTheme="outline"></span>
          </div>
        </div>
        <!-- </span> -->
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="selectedPeople?.length>3">
      <!-- <span class="avatar-container"> -->
      <nz-avatar [style.pointerEvents]="!header.can_edit?'none':'auto'" [nzText]="shortenName(selectedPeople[0])" [style.backgroundColor]="'#1d66ff'" nz-tooltip [nzTooltipTitle]="selectedPeople[0]"  nzTooltipPlacement="top" [nzTooltipOverlayClassName]="'toolTipContainerss'"></nz-avatar>
      <!-- </span> -->
      <!-- <span class="avatar-container"> -->
      <nz-avatar [style.pointerEvents]="!header.can_edit?'none':'auto'" [nzText]="shortenName(selectedPeople[1])" [style.backgroundColor]="'#1d66ff'" nz-tooltip [nzTooltipTitle]="selectedPeople[1]"  nzTooltipPlacement="top" [nzTooltipOverlayClassName]="'toolTipContainerss'"></nz-avatar>
      <!-- </span> -->
      <!-- <span class="avatar-container"> -->
      <nz-avatar nzText="+{{selectedPeople.length-2}}" [style.backgroundColor]="'#1d66ff'"></nz-avatar>
      <!-- </span> -->
    </ng-container>
  </nz-avatar-group>
</div>


  <nz-dropdown-menu #menu="nzDropdownMenu" nzPlacement="bottomRight" >
    <ng-container *ngIf="close">
    <nz-input-group [nzPrefix]="prefixTemplateUser" class="searchInputContainer" *ngIf="showDropdown">
      <input [nzBorderless]="false" #search autofocus nz-input placeholder="Search" type="text" [(ngModel)]="searchInput"
      (input)="openDropDownAPIChange(header, searchInput, true)"/>
      <ng-template #prefixTemplateUser><img src="../../assets/images/icons/searchIons.svg" /></ng-template>
    </nz-input-group>
    <ul nz-menu (click)="$event.preventDefault();$event.stopPropagation()" nzSelectable class="option-list" *ngIf="showDropdown">
      <li style="pointer-events: none;" #menuitem nz-menu-item *ngFor="let people of fields">
        <span (click)="selectOption(people,menuitem); $event.stopPropagation()"
          style="padding: 2px;border-radius: 50%;pointer-events: auto;"
          [ngStyle]="{'border':selectedPeople.includes(people)?'1px solid green':'none'}">
          <div class="avtarcheck">
            <nz-avatar [nzText]="shortenName(people)" [style.backgroundColor]="'#1d66ff'"></nz-avatar>
            <div class="closeImg1">
              <span nz-icon nzType="close" nzTheme="outline"></span>
            </div>
          </div>
        </span>
        <span (click)="selectOption(people,menuitem); $event.stopPropagation()"
          style="margin-left: 5px;pointer-events: auto;">{{people}}</span>
      </li>
    </ul>
  </ng-container>
  </nz-dropdown-menu>
</div>
