import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  effect,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { cloneDeep } from 'lodash';
import { NzTableComponent } from 'ng-zorro-antd/table';
import {
  BehaviorSubject,
  debounceTime,
  filter,
  from,
  map,
  mergeMap,
  Observable,
  of,
  Subject,
  Subscription,
  takeUntil,
  tap,
  toArray,
} from 'rxjs';

import { ProcessoCustomTableService } from './processo-custom-table.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NzResizeEvent } from 'ng-zorro-antd/resizable';

@Component({
  selector: 'processo-custom-table',
  templateUrl: './processo-custom-table.component.html',
  styleUrls: ['./processo-custom-table.component.scss'],
})
export class ProcessoCustomTableComponent implements OnChanges, OnInit {
  @Input() state: any;
  @Input() pagination: any = {
    currentRecords: 0,
    totalRecords: 0,
  };
  @Input() erulistData: any = {};
  @Input() tabName: any;
  @Input() select_api_options: any = [];
  @Input() api_fields: any = [];
  filters: any = [{ key1: '', key2: '', key3: '', key4: true }];
  @Input() selectedSortLabel: any = [];
  @Input() pservice: any;
  @Output() tabelEventEmiter: EventEmitter<any> = new EventEmitter();
  @Output() dataUpdate: EventEmitter<any> = new EventEmitter();
  @Output() innerdataUpdate: EventEmitter<any> = new EventEmitter();
  @Output() UpdateTabs: EventEmitter<any> = new EventEmitter();
  @Output() deleterow: EventEmitter<any> = new EventEmitter();
  @Output() editrow: EventEmitter<any> = new EventEmitter();
  @Output() scrollEvents: EventEmitter<any> = new EventEmitter();
  @Output() showVisibility: EventEmitter<any> = new EventEmitter();
  @Output() selectIDs: EventEmitter<any> = new EventEmitter();
  @Output() sortGroups: EventEmitter<any> = new EventEmitter();
  @Output() filterGroups: EventEmitter<any> = new EventEmitter();
  @Output() delTab: EventEmitter<any> = new EventEmitter();
  @Output() emitSelectorAPI: EventEmitter<any> = new EventEmitter();
  @Output() singleSelectEntity: EventEmitter<any> = new EventEmitter();
  @Output() addNewChildTableColumn: EventEmitter<any> = new EventEmitter();
  @Input() getEntityptionList$!: Observable<any>;
  isGroupBy: boolean = false;
  @Input() environmentType: any;
  @Input() layoutService: any;
  Array = Array;
  dataStream$: Subject<any> = new Subject<any>();
  moreOptions: any = [
    {
      lable: 'Approved Records',
      value: 'approved_records',
    },
    {
      lable: 'For My Approval',
      value: 'for_my_approval',
    },
    {
      lable: 'My Drafts',
      value: 'my_drafts',
    },
    // {
    //   lable: 'Error Records',
    //   value: 'error_records',
    // },
  ];
  isSelectedMore: any = 'approved_records';
  @Input() setStatusFilterData: any;
  initial_load: boolean = true;
  fetchData() {
    // Placeholder for actual data fetch logic
    return [];
  }
  router = inject(Router);
  constructor(private cdRef: ChangeDetectorRef) {
    this.dataStream$.pipe(debounceTime(500)).subscribe((r: any) => {
      this.groupedTableData();
      this.getMetaData();
    });
    // this.router.events.subscribe((res:any)=>{
    //   const navigation = this.router.getCurrentNavigation();
    //   const state = navigation?.extras.state as {
    //     tabName: string;
    //     filter: string;
    //     filterValue: string;
    //   };
    //   if(state){
    //     this.setStatusFilterData = state
    //   }
    // })
  }

  isStatusData: any = [];
  showClosedStatus: boolean = false;
  initialLoad: boolean = true;
  @Input() preSelect!: any;
  @Input() gridlayout!: any;
  @Input() app_state!: any;
  @Output() tableTabChange = new EventEmitter<any>();
  updateDefaultValue() {
    let sort_data = this.pservice?.appState()?.layoutMetadata?.sortData?.sort;
    let sort_array = sort_data
      ?.map((_e: any) => {
        if (
          _e !== this.pservice?.appState()?.layoutMetadata?.sortData?.group &&
          _e
        ) {
          let data: any = {};
          if (_e.includes('-')) {
            let dec = _e.split('-');
            data[dec[1]] = 'desc';
          } else {
            data[_e] = 'asc';
          }
          return data;
        }
      })
      ?.filter(Boolean);
    let sortingOrder = sort_array?.reduce((acc: any, item: any) => {
      if (item) {
        return { ...acc, ...item };
      }
      return acc;
    }, {});
    this.sortingOrder = sortingOrder || {};

    let groubByValue =
      this.pservice?.appState()?.layoutMetadata?.sortData?.group;
    if (groubByValue) {
      let group_data = [];
      if (groubByValue.includes('-')) {
        let value = groubByValue.split('-');
        group_data.push(value?.[1]);
        group_data.push('desc');
      } else {
        group_data.push(groubByValue);
        group_data.push('asc');
      }
      this.selectedSortLabel = group_data;
      let item: any = {
        name: this.selectedSortLabel[0],
      };
      if (item) {
        this.selectedSortRow(item, '');
      }
    }
  }
  status_Value: any = null;
  status_Filter: any = null;
  is_filter_: boolean = false;
  initial_load_() {
    let findsortFieldbyTab = this.pservice
      ?.appState()
      ?.metaData?.entities?.find((e: any) => e.name === this.tabName)?.fields;
    let selected_entity = this.pservice
      ?.appState()
      ?.layoutMetadata?.entities?.find(
        (_entity: any) =>
          _entity?.name === this.pservice?.appState().selectedEntity
      );
    let sort_value = this.layoutService.convertLayoutSort(
      selected_entity?.sort
    );

    // table groupby
    if (sort_value?.group) {
      let group_data = [];
      if (sort_value?.group.includes('-')) {
        let value = sort_value?.group.split('-');

        group_data.push(value?.[1]);
        group_data.push('desc');
      } else {
        group_data.push(sort_value?.group);
        group_data.push('asc');
      }
      this.selectedSortLabel = group_data;
    } else {
      const filteredData = findsortFieldbyTab?.filter(
        (obj: any) => obj?.can_group === true
      );
      this.selectedSortLabel = [
        filteredData?.find((e: any) => e.default_group === true)?.name,
        'asc',
      ];
    }
    //table sort
    if (sort_value?.sort) {
      let sort_array = sort_value?.sort
        ?.map((_e: any) => {
          if (this.selectedSortLabel[0] === _e) {
            return;
          }
          if (_e) {
            let data: any = {};
            if (_e.includes('-')) {
              let dec = _e.split('-');
              data[this.get_label_by_name(findsortFieldbyTab, dec[1])] = 'desc';
            } else {
              data[this.get_label_by_name(findsortFieldbyTab, _e)] = 'asc';
            }
            return data;
          }
        })
        ?.filter(Boolean);
      let sortingOrder = sort_array?.reduce((acc: any, item: any) => {
        if (item) {
          return { ...acc, ...item };
        }
        return acc;
      }, {});
      this.sortingOrder = sortingOrder || {};
    }
    //table filter
    if (selected_entity?.approve_status) {
      let status_value = this.Object.keys(selected_entity?.approve_status)[0];

      this.status_Value = status_value === '$eq' ? '' : status_value;
      this.status_Filter = selected_entity?.approve_status[status_value];
    }
    if (selected_entity?.filter?.length > 0) {
      this.filters = selected_entity?.filter;
    } else {
      this.filters = [
        { key1: '', key2: '', key3: '', key4: true, entity_name: this.tabName },
      ];
    }
    //table show closed
    if (selected_entity?.show_closed) {
      this.show_closed_data = selected_entity?.show_closed;
    }
    this.getEntityList();
    //grid layout
    if (selected_entity?.grid_layout) {
      this.gridlayout = selected_entity?.grid_layout;
    }
    // check filter on
    let filter_new_data = this.filters.filter((item: any) => {
      return Object.entries(item).every(
        ([key, value]) => key === 'key2' || (value !== null && value !== '')
      );
    });
    if (filter_new_data?.length > 0 || this.status_Filter) {
      this.is_filter_ = true;
    } else {
      this.is_filter_ = false;
    }
    // else if(!this.is_log_filter){
    //   this.is_filter_ = false;
    // }
    if (
      this.setStatusFilterData &&
      this.pservice?.appState()?.tableDiable === 'ERRORS'
    ) {
      this.is_filter_ = true;
    }
  }

  get_label_by_name(field: any, name: any) {
    let selectedField = field?.find((_e: any) => _e.name === name)?.label;
    return selectedField;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['app_state']?.currentValue?.layoutMetadata?.sortData?.sort
        ?.length > 0 &&
      this.initial_load
    ) {
      setTimeout(() => {
        // this.updateDefaultValue();
        this.initial_load = false;
      });
    }
    if (Object.keys(changes).includes('tabName')) {
      if (
        changes['tabName']?.currentValue != changes['tabName']?.previousValue
      ) {
        // this.pservice.appState().layoutMetadata = [];
        // this.sortingOrder = {};

        // this.filters = this.pservice?.appState()?.layoutMetadata?.filters;

        this.tableMetaData = null;
        this.isSelectedMore = this.preSelect;
        this.tableInputSearch = '';
        this.pservice.tabelSearch = '';
        this.isStatusData = [];

        this.isStatusClose = false;
        this.onfirstClick = true;
        // setTimeout(() => {
        //   this.updateDefaultValue();
        // }, 700);
        this.tableTabChange.emit({ tabChange: true });
        this.selectedChekBox = []

        let entitiesData = [];

        let defaultEntities = this.pservice
          .appState()
          ?.metaData?.entities?.find((_e: any) => _e.name === this.tabName);
        if (defaultEntities) {
          let data = {
            label: defaultEntities?.display_name,
            entity_name: defaultEntities?.name,
            field_name: defaultEntities?.name,
            default: true,
          };
          entitiesData.push(data);
        }

        defaultEntities?.fields?.map((_field: any) => {
          if (
            (_field.datatype === 'dropdown_single_select' &&
              _field.option_type === 'ENTITY_DATA') ||
            (_field.datatype === 'dropdown_multi_select' &&
              _field.option_type === 'ENTITY_DATA')
          ) {
            let data = {
              label: _field?.label,
              field_name: _field.name,
              entity_name: _field?.entity_name,
              default: false,
            };
            entitiesData.push(data);
          }
        });
        this.entitiesData = entitiesData;
        this.initial_load = true;
      }
    }

    if (this.Object.keys(changes).includes('select_api_options')) {
      this.select_api_options = changes['select_api_options'].currentValue;
    }
    if (
      changes['erulistData'] &&
      (JSON.stringify(changes['erulistData'].currentValue?.data) !==
        JSON.stringify(changes['erulistData'].previousValue?.data) ||
        JSON.stringify(changes['erulistData'].currentValue?.entities) !==
          JSON.stringify(changes['erulistData'].previousValue?.entities))
    ) {
      // Perform the necessary actions when erulistData.data or erulistData.entities changes
      // this.groupedTableData()

      this.dataStream$.next(null);
    }
    this.checkUserAcessType();
    setTimeout(() => {
      const fields = this.getFields();
      const isStatus = fields?.find((e: any) => e?.datatype === 'status');
      if (isStatus) {
        this.showClosedStatus = true;
      } else {
        this.showClosedStatus = false;
      }
    }, 200);

    this.cdRef?.detectChanges();
  }
  defaultStatus: boolean = false;
  onfirstClick: boolean = true;
  showClosed() {
    if (this.onfirstClick) {
      // this.getEntityList();
      setTimeout(() => {
        this.show_closed_data = [];
        let show_closed = `0___${this.noOfStatusFields[0]?.statusFieldName}`;
        this.show_closed_data.push(show_closed);
        this.pservice.updateStoreLayoutMetadata(
          this.show_closed_data,
          'show_closed'
        );
        this.noOfStatusFields[0].showStatue = true;
        this.convertToJson('top');
      }, 100);
    }
  }
  service = inject(ProcessoCustomTableService);
  selectedMenu = null;
  isVisited = true;
  newFileTypeData: any = this.service.erulist;
  checked = false;
  passcurrency: string = '';
  newColumnFields: any;
  tableheaders = [];
  value1 = 30;
  value2 = [10, 20, 30, 40, 50];
  dateFormat = 'yyyy/MM/dd';
  showCalendar: boolean = false;
  isShowError: boolean = false;
  selectedLabel = null;
  selectedValue!: string;
  mymodel: any;
  today = new Date();
  draggedColumnIndex: any = [];
  isChecked: boolean = false;
  isChecked1: boolean = false;
  isGrid: boolean = false;
  myPhone: any;
  decimalPoint: any;
  mandatory: boolean = false;
  isShowEdit: boolean = false;
  addNewColumn: boolean = false;
  passGridIndex: any;
  dateFormatOptions: any = ['dd/MM/yyyy', 'yyyy/MM/dd'];
  currencyOptions: any = ['₹', '$'];
  seperatorOptions: any = ['null', 'million', 'thousand'];
  selectedCurrency: any = '';
  selectedSeperator: any = 'null';
  selectedCountryCode: any;
  selectedTabName!: string;
  selectedIds: any = [];
  hoverIndex: any = null;
  sortingOrder: any = {};
  sortList: any = {};
  hideSelectOption: boolean = true;
  @ViewChild('dropdownSelect') dropdownSelect: any;
  @HostListener('document:click', ['$event'])
  onClick(event: Event): void {
    const clickedElement = event.target as HTMLElement;

    let tableRows = Array.from(document.querySelectorAll('.tableRow'));
    if (!tableRows.some((row) => row.contains(clickedElement))) {
      if (!this.error) {
        if (!this.isPeopleDropDownOpen) {
          this.editId = null;
          this.dropdownSelect?.disableSelect();
        }
      }
    }
  }
  maskStyle = {
    opacity: 0,
  };
  listOfData: any = [];
  statuesColor: any = [
    {
      color: '#1DF1FF',
    },
    {
      color: '#56C0C6',
    },
    {
      color: '#FF1DE8',
    },
    {
      color: '#F70295',
    },
    {
      color: '#FFC01D',
    },
    {
      color: '#CF6BFF',
    },
    {
      color: '#E9FD00',
    },
    {
      color: '#FF8058',
    },
  ];
  entitiesData: any = [];
  ngOnInit() {
    this.selectedIds = [];
    this.getTableHeaders();

    this.newColumnFields = this.newFileTypeData.new_fields;
    this.getSortFields('');
    this.findDefaultSortRow();
    setTimeout(() => {
      // this.getEntityList();
      let entitiesData = [];
      let defaultEntities = this.pservice
        .appState()
        ?.metaData?.entities?.find((_e: any) => _e.name === this.tabName);
      if (defaultEntities) {
        let data = {
          label: defaultEntities?.display_name,
          entity_name: defaultEntities?.name,
          field_name: defaultEntities?.name,
          default: true,
        };
        entitiesData.push(data);
      }

      defaultEntities?.fields?.map((_field: any) => {
        if (
          (_field.datatype === 'dropdown_single_select' &&
            _field.option_type === 'ENTITY_DATA') ||
          (_field.datatype === 'dropdown_multi_select' &&
            _field.option_type === 'ENTITY_DATA')
        ) {
          let data = {
            label: _field?.label,
            field_name: _field.name,
            entity_name: _field?.entity_name,
            default: false,
          };
          entitiesData.push(data);
        }
      });
      this.entitiesData = entitiesData;
      this.filters = [
        { key1: '', key2: '', key3: '', key4: true, entity_name: this.tabName },
      ];
    }, 300);
    setTimeout(() => {
      // this.updateDefaultValue();
    }, 700);
  }
  tableMetaData: any;
  getMetaData() {
    let metadata = this.erulistData?.entities?.find(
      (e: any) => e?.display_name.toLowerCase() === this.tabName
    );
    this.tableMetaData = metadata;
  }
  // applyStatusFilter(state: any) {
  //   if(state){
  //     this.isSelectedMore = 'my_drafts'
  //     let filter = {
  //       status: {
  //         $like: 'ERRORS',
  //       },
  //     };
  //     setTimeout(()=>{
  //       this.morefilterOptions.emit({ filter, selectedoption: 'my_drafts' });
  //     },5000)
  //   }
  // }
  @Output() getCurrencyList = new EventEmitter<any>();
  @Input() CurrencyLists: any;
  getCurrencyLists(event: any) {
    if (event) {
      this.getCurrencyList.emit(true);
    }
  }
  sortSearchText: any;
  inputValue(event: any) {
    this.sortSearchText = event?.target?.value;
  }
  getSortFields(key: any) {
    let findsortFieldbyTab = this.erulistData?.entities?.find(
      (e: any) => e.name === this.tabName
    )?.fields;
    const filteredData = findsortFieldbyTab?.filter(
      (obj: any) =>
        ((obj?.can_group === true && obj?.show_grid === 'yes') ||
          obj?.show_grid === 'always') &&
        obj.datatype !== 'textarea'
    );
    let filterSortData =
      this.sortSearchText?.length > 0
        ? filteredData?.filter((e: any) =>
            e.label.toLowerCase().includes(this.sortSearchText?.toLowerCase())
          )
        : filteredData;
    // if(this.isSelectedMore === 'my_drafts' || this.isSelectedMore === 'for_my_approval'){
    //   filterSortData.push({
    //     name:'approval_status',
    //     label:'Approval status'
    //   })
    // }
    return (
      filterSortData?.sort((a: any, b: any) =>
        a?.label?.toLocaleString()?.localeCompare(b?.label?.toLocaleString())
      ) || []
    );
  }

  findDefaultSortRow() {
    let findsortFieldbyTab = this.erulistData?.entities?.find(
      (e: any) => e.name === this.tabName
    )?.fields;
    const filteredData = findsortFieldbyTab?.filter(
      (obj: any) => obj?.can_group === true
    );
    this.selectedSortLabel = filteredData?.find(
      (e: any) => e?.default_group === true
    )?.label;
  }
  selectedSortRow(item: any, order?: any) {
    if (item?.name === 'approval_status') {
      return;
    }
    if (this.selectedSortLabel?.[0] === item.name) {
      if (this.selectedSortLabel[1] == 'asc') {
        this.selectedSortLabel[1] = 'desc';
      } else {
        this.selectedSortLabel[1] = 'asc';
      }
    } else {
      this.selectedSortLabel = [item.name, 'asc'];
    }
    // this.pservice.groupbySortField = item.name;

    this.newSortJson();
  }
  updateFields(e: any) {
    this.erulistData.entities[
      this.erulistData.entities?.findIndex((e: any) => e.name === this.tabName)
    ]['fields'] = e;
  }

  listOption: any = [
    {
      name: 'John Brown',
    },
    {
      name: 'Jim Green',
    },
    {
      name: 'Joe Black',
    },
  ];

  showOptions(event: any) {
    this.isChecked = !this.isChecked;
  }
  showGrid(event: any) {
    this.isChecked1 = !this.isChecked1;
  }

  valuechange(newValue: any) {
    this.mymodel = newValue;
  }
  valuePhone(newValue: any) {
    this.myPhone = newValue;
  }
  value(newValue: any) {
    this.decimalPoint = newValue;
  }

  onSelectedValueChange() {
    if (this.selectedValue === '1') {
      this.passcurrency = this.mymodel.toFixed(1);
    } else if (this.selectedValue === '2') {
      this.passcurrency = this.mymodel.toFixed(2);
    } else if (this.selectedValue === '3') {
      this.passcurrency = this.mymodel.toFixed(3);
    }
  }

  openCalendar() {
    this.showCalendar = true;
  }
  // checking:any;
  getTableHeaders() {
    let fields =
      this.erulistData.entities?.[
        this.erulistData.entities?.findIndex(
          (e: any) => e.name === this.tabName
        )
      ]?.fields;
    fields?.map((_field: any) => {
      let layoutField = this.gridlayout?.find(
        (_e: any) => _e?.name === _field?.name
      );
      if (_field.name === 'error' && _field.tab_name === 'XX') {
        _field.grid_index = 999996;
      } else {
        _field.grid_index = layoutField?.grid_index
          ? layoutField?.grid_index
          : _field.grid_index;
      }

      return _field;
    });

    this.selectedTabName =
      this.erulistData.entities?.[
        this.erulistData.entities?.findIndex(
          (e: any) => e.name === this.tabName
        )
      ]?.display_name;
    let filteredData = fields
      ?.filter((e: any) => {
        return (
          (e?.show_grid == 'always' || e?.show_grid == 'yes') &&
          (this.selectedSortLabel?.[0]
            ? e?.name !== this.selectedSortLabel?.[0]
            : true)
        );
      })
      .sort((a: any, b: any) => a.grid_index - b.grid_index);
    return this.getShowFields().length > 0 ? filteredData || [] : [];
  }
  getTableData() {
    let fields = this.erulistData?.data;

    // Remove duplicates based on entity_id
    fields = fields?.filter(
      (value: any, index: any, self: any) =>
        index === self.findIndex((t: any) => t.entity_id === value.entity_id)
    );

    return this.getShowFields().length > 0 ? fields || [] : [];
  }
  shortenName(name: string) {
    const words = name?.trim()?.split(' ');

    if (words.length === 1) {
      return words[0].charAt(0);
    } else if (words.length >= 2) {
      return words[0].charAt(0) + words[words.length - 1].charAt(0);
    } else {
      return '';
    }
  }

  editId: any = null;
  error = false;
  startEdit(name: any, index: any, data: any) {
    if (this.previousComp) {
      this.previousComp.editClicked = false;
    }
    this.editId = `${name},${index},${data.entity_id}`;
  }
  peopleData(v: any, data: any): void {
    this.stopEdit(false, 'dropdown', data.entity_id);
  }
  stopEdit(v?: any, type?: any, entityId?: any) {
    if (!v) {
      if (['dropdown', 'status', 'date'].includes(type)) {
        this.dataUpdate.emit(entityId);
      } else {
        let entity_id = this.editId?.split(',')[2];
        this.dataUpdate.emit(entity_id);
        this.editId = null;
        this.error = false;
      }
      this.previousComp = null;
    } else {
      this.error = true;
    }
  }
  deleteRow(e: any, tableSelected?: any) {
    if (tableSelected === 'innerView') {
      let data = {
        selectedTable: tableSelected,
        e: e,
      };
      this.deleterow.emit(data);
    } else {
      this.deleterow.emit(e);
    }
  }
  editRow(e: any, selectedOption?: any) {
    if (selectedOption === 'innerView') {
      this.editrow.emit({ e, selectedOption, innerTable: true });
    } else {
      this.editrow.emit({ e, selectedOption });
    }
  }
  checkboxesState: boolean[] = [];
  selectedCheckBox: boolean = true;
  addIds(e: any, index?: any) {
    this.selectIDs.emit({ entity_id: e, select_all: true });
  }
  visible: boolean = false;
  openAction(): void {
    this.visible = true;
    this.isVisited = false;
  }
  close(): void {
    this.visible = false;
  }
  getShowFields(searchValue: string = '') {
    let fields = this.erulistData.entities?.[
      this.erulistData.entities?.findIndex((e: any) => e.name === this.tabName)
    ]?.fields?.filter((e: any) => e.datatype !== 'entity');

    let filteredData = fields
      ?.filter((e: any) => {
        const lowerCaseLabel = e?.label?.toLowerCase();
        const lowerCaseSearchValue = searchValue?.toLowerCase();
        return (
          (e?.show_grid == 'always' || e?.show_grid == 'yes') &&
          (lowerCaseSearchValue === '' ||
            lowerCaseLabel.includes(lowerCaseSearchValue))
        );
      })
      .sort((a: any, b: any) => a.grid_index - b.grid_index);

    return filteredData || [];
  }
  disableStatus(dataType: any) {
    if (dataType === 'status') {
      let fields = this.erulistData.entities?.[
        this.erulistData.entities?.findIndex(
          (e: any) => e.name === this.tabName
        )
      ]?.fields?.filter((e: any) => e.datatype !== 'entity');
      let showStatus = fields?.some((item: any) => item?.datatype === 'status');
      if (showStatus) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  getFieldsInspace(searchValue: string = '') {
    let fields =
      this.erulistData.entities?.[
        this.erulistData.entities?.findIndex(
          (e: any) => e.name === this.tabName
        )
      ]?.fields;

    let filteredData = fields
      ?.filter((e: any) => {
        const lowerCaseLabel = e?.label?.toLowerCase();
        const lowerCaseSearchValue = searchValue?.toLowerCase();
        return (
          e?.show_grid == 'no' &&
          (lowerCaseSearchValue === '' ||
            lowerCaseLabel.includes(lowerCaseSearchValue))
        );
      })
      .sort((a: any, b: any) => a.grid_index - b.grid_index);
    return filteredData || [];
  }
  newColumnValue: any;
  getNewFields(searchValue: string = '') {
    let filteredData = this.newColumnFields?.filter((e: any) => {
      const lowerCaseLabel = e.label.toLowerCase();
      const lowerCaseSearchValue = searchValue.toLowerCase();
      return (
        lowerCaseSearchValue === '' ||
        lowerCaseLabel.includes(lowerCaseSearchValue)
      );
    });
    this.newColumnValue = this.newColumnFields;
    return filteredData || [];
  }

  addFieldShow(item: any) {
    // item.mandatory = true
    if (item.datatype === 'textarea') {
      return;
    }
    let data = {
      method: 'showColumn',
      data: {
        entity_name: this.tabName,
        field_name: item.name,
        show_grid: 'yes',
      },
    };
    this.tabelEventEmiter.emit(data);
    item.show_grid = 'yes';
  }
  removeFieldShow(item: any) {
    if (item.show_grid === 'always') {
      return;
    }
    let data = {
      method: 'hideColumn',
      data: {
        entity_name: this.tabName,
        field_name: item.name,
        show_grid: 'no',
      },
    };
    item.show_grid = 'no';
    this.tabelEventEmiter.emit(data);
  }
  selectedMenuData: any;
  openNewfield(item: any) {
    this.isVisited = true;
    this.visible = false;
    this.addNewColumn = true;
    this.isEdit = false;
    this.selectedMenu = item.datatype;
    this.selectedMenuData = item;
  }
  addToColumn(type: any, value: any) {
    let fields =
      this.erulistData.entities[
        this.erulistData.entities?.findIndex(
          (e: any) => e.name === this.tabName
        )
      ]?.fields;
    this.isShowError = false;
    if (type === 'dropdown') {
      let data = {
        name: value.fieldName,
        label: value.fieldName,
        datatype: type,
        tab_name: this.tabName,
        default: value.inputValue,
        mandatory: value.checkboxMandatory,
        options: value.options.map((e: any) => ({
          name: e,
          value: e,
          isLast: false,
        })),
        show_grid: value.showGrid ? 'yes' : 'no',
        grid_index: value.showGrid ? fields.length + 1 : '-1',
        can_group: true,
        default_group: true,
      };
      // if (value.checkboxMandatory === true && value.options.length==0){
      fields.push(data);
      (this.selectedMenu = null), (this.isVisited = false);
      (this.visible = false), (this.Options = ['']);
      // }
      // else{
      //   this.isShowError = true;
      // }
    } else if (type === 'dropdown_multi_select') {
      let data = {
        name: value.fieldName,
        label: value.fieldName,
        datatype: type,
        tab_name: this.tabName,
        default: value.inputValue,
        mandatory: value.checkboxMandatory,
        options: value.options.map((e: any) => ({
          name: e,
          value: e,
          isLast: false,
        })),
        show_grid: value.showGrid ? 'yes' : 'no',
        grid_index: value.showGrid ? fields.length + 1 : '-1',
        can_group: true,
        default_group: true,
      };
      fields.push(data);
      (this.selectedMenu = null), (this.isVisited = false);
      (this.visible = false), (this.Options = ['']);
    } else if (type === 'text_area_unwrapped') {
      let data: any = {
        name: value.fieldName,
        label: value.fieldName,
        datatype: type,
        tab_name: this.tabName,
        default: value.inputValue,
        mandatory: this.isChecked,
        show_grid: value.showGrid ? 'yes' : 'no',
        grid_index: value.showGrid ? fields.length + 1 : '-1',
        can_group: false,
        default_group: false,
      };
      fields.push(data);
      (this.selectedMenu = null), (this.isVisited = false);
      this.visible = false;
    } else if (type === 'checkbox') {
      let data = {
        name: value.fieldName,
        label: value.fieldName,
        datatype: type,
        tab_name: this.tabName,
        default: this.selectedValue === 'true' ? true : false,
        mandatory: value.checkboxMandatory,
        show_grid: value.showGrid ? 'yes' : 'no',
        grid_index: value.showGrid ? fields.length + 1 : '-1',
        can_group: true,
        default_group: true,
      };
      fields.push(data);
      (this.selectedMenu = null), (this.isVisited = false);
    } else if (type === 'labels') {
      let data = {
        name: value.fieldName,
        label: value.fieldName,
        datatype: type,
        tab_name: this.tabName,
        default: value.inputValue,
        mandatory: value.checkboxMandatory,
        show_grid: value.showGrid ? 'yes' : 'no',
        grid_index: value.showGrid ? fields.length + 1 : '-1',
        can_group: true,
        default_group: true,
      };
      fields.push(data);
      (this.selectedMenu = null), (this.isVisited = false);
      (this.visible = false), (this.Options = ['']);
    } else if (type === 'location') {
      let data = {
        name: value.fieldName,
        label: value.fieldName,
        datatype: type,
        tab_name: this.tabName,
        default: '"Location',
        mandatory: value.checkboxMandatory,
        show_grid: value.showGrid ? 'yes' : 'no',
        grid_index: value.showGrid ? fields.length + 1 : '-1',
        can_group: true,
        default_group: true,
      };
      fields.push(data);
      (this.selectedMenu = null), (this.isVisited = false);
      (this.visible = false), (this.Options = ['']);
    } else if (type === 'website') {
      let data = {
        name: value.fieldName,
        label: value.fieldName,
        datatype: type,
        tab_name: this.tabName,
        default: value.inputValue,
        mandatory: value.checkboxMandatory,
        show_grid: value.showGrid ? 'yes' : 'no',
        grid_index: value.showGrid ? fields.length + 1 : '-1',
        can_group: true,
        default_group: true,
      };
      fields.push(data);
      (this.selectedMenu = null), (this.isVisited = false);
      (this.visible = false), (this.Options = ['']);
    } else if (type === 'progress') {
      let data = {
        name: value.fieldName,
        label: value.fieldName,
        datatype: type,
        tab_name: this.tabName,
        default: 'NEW',
        mandatory: value.checkboxMandatory,
        show_grid: value.showGrid ? 'yes' : 'no',
        grid_index: value.showGrid ? fields.length + 1 : '-1',
        can_group: true,
        default_group: true,
      };
      fields.push(data);
      (this.selectedMenu = null), (this.isVisited = false);
      (this.visible = false), (this.Options = ['']);
    } else if (type === 'phone') {
      let data = {
        name: value.fieldName,
        country: value.country,
        countryCode: this.selectedCountryCode,
        label: value.fieldName,
        datatype: type,
        tab_name: this.tabName,
        default: value.inputValue,
        mandatory: value.checkboxMandatory,
        show_grid: value.showGrid ? 'yes' : 'no',
        grid_index: value.showGrid ? fields.length + 1 : '-1',
        can_group: true,
        default_group: true,
      };
      fields.push(data);
      (this.selectedMenu = null), (this.isVisited = false);
      (this.visible = false), (this.Options = ['']);
    } else if (type === 'tag') {
      let data = {
        name: value.fieldName,
        label: value.fieldName,
        datatype: type,
        tab_name: this.tabName,
        default: 'NEW',
        mandatory: value.checkboxMandatory,
        show_grid: value.showGrid ? 'yes' : 'no',
        grid_index: value.showGrid ? fields.length + 1 : '-1',
        can_group: true,
        default_group: true,
      };
      fields.push(data);
      (this.selectedMenu = null), (this.isVisited = false);
      (this.visible = false), (this.Options = ['']);
    } else if (type === 'date') {
      let data = {
        name: value.fieldName,
        label: value.fieldName,
        datatype: type,
        tab_name: this.tabName,
        dateFormat: this.dateFormat,
        default: '10/11/2023',
        mandatory: value.checkboxMandatory,
        show_grid: value.showGrid ? 'yes' : 'no',
        grid_index: value.showGrid ? fields.length + 1 : '-1',
        can_group: true,
        default_group: true,
      };
      fields.push(data);
      (this.selectedMenu = null), (this.isVisited = false);
      (this.visible = false), (this.Options = ['']);
    } else if (type === 'rating') {
      let data = {
        name: value.fieldName,
        label: value.fieldName,
        datatype: type,
        tab_name: this.tabName,
        default: '23',

        mandatory: value.checkboxMandatory,
        show_grid: value.showGrid ? 'yes' : 'no',
        grid_index: value.showGrid ? fields.length + 1 : '-1',
        can_group: true,
        default_group: true,
      };
      fields.push(data);
      (this.selectedMenu = null), (this.isVisited = false);
      (this.visible = false), (this.Options = ['']);
    } else if (type === 'currency') {
      let data = {
        name: value.fieldName,
        label: value.fieldName,
        datatype: type,
        tab_name: this.tabName,
        decimal: Number(this.selectedValue),
        default: value.inputValue,
        mandatory: value.checkboxMandatory,
        show_grid: value.showGrid ? 'yes' : 'no',
        grid_index: value.showGrid ? fields.length + 1 : '-1',
        can_group: true,
        default_group: true,
        symbol: this.selectedCurrency,
        seperator: this.selectedSeperator,
      };
      fields.push(data);
      (this.selectedMenu = null), (this.isVisited = false);
      (this.visible = false), (this.Options = ['']);
    } else if (type === 'image') {
      let data = {
        name: value.fieldName,
        label: value.fieldName,
        datatype: type,
        tab_name: this.tabName,
        default: 'NEW',
        mandatory: value.checkboxMandatory,
        show_grid: value.showGrid ? 'yes' : 'no',
        grid_index: value.showGrid ? fields.length + 1 : '-1',
        can_group: true,
        default_group: true,
      };
      fields.push(data);
      (this.selectedMenu = null), (this.isVisited = false);
      (this.visible = false), (this.Options = ['']);
    } else if (type === 'number') {
      let data = {
        name: value.fieldName,
        label: value.fieldName,
        datatype: type,
        tab_name: this.tabName,
        decimal: this.decimalPoint,
        default: value.inputValue,
        mandatory: value.checkboxMandatory,
        show_grid: value.showGrid ? 'yes' : 'no',
        grid_index: value.showGrid ? fields.length + 1 : '-1',
        can_group: true,
        seperator: this.selectedSeperator,
        default_group: true,
      };
      fields.push(data);
      (this.selectedMenu = null), (this.isVisited = false);
      (this.visible = false), (this.Options = ['']);
    } else {
      let data: any = {
        name: value.fieldName,
        label: value.fieldName,
        datatype: type,
        tab_name: this.tabName,
        default: '',
        mandatory: false,
        show_grid: value.showGrid ? 'yes' : 'no',
        grid_index: value.showGrid ? fields.length + 1 : '-1',
        can_group: false,
        default_group: false,
      };
      fields.push(data);
      (this.selectedMenu = null), (this.isVisited = false);
      this.visible = false;
    }
  }
  Options = [''];

  ratingChange(e: any, v: any, n: any) {
    v[n] = e;
    this.dataUpdate.emit(v.entity_id);
    this.editId = null;
  }
  checkChange(e: any, v: any, n: any) {
    v[n] = e;
    this.dataUpdate.emit(v.entity_id);
    this.editId = null;
  }

  check(i: any, value: any) {
    if (isNaN(Number(value))) {
      return Number(i.default).toFixed(i.decimal);
    } else {
      return Number(value).toFixed(i.decimal);
    }
  }
  checkDefault(i: any, value: any) {
    if (i.mandatory) {
      return i.default;
    } else {
      return value;
    }
  }

  // checkWebsite(i: any, value: any) {
  //   if (i.mandatory) {
  //     return i.default;
  //   }
  //   else {
  //     return value;
  //   }
  // }

  // checkRating(i: any, value: any) {
  //   if (i.mandatory) {
  //     return i.default;
  //   }
  //   else {
  //     return value;
  //   }
  // }
  // checkLocation(i: any, value: any) {
  //   if(i.mandatory)  {
  //     return i.default;
  //   }
  //   else {
  //     return value;
  //   }
  // }
  openOption(i: any) {
    this.isShowEdit = !this.isShowEdit;
  }

  public async arrayMove(arr: any[], from: any, to: any) {
    if (from < 0 || from >= arr.length || to < 0 || to >= arr.length) {
      return arr;
    }

    const elementsWithGridIndexMinusOne = arr?.filter(
      (item) => item.grid_index === -1
    );

    arr = arr?.filter((item) => item.grid_index !== -1);

    const cutOut = arr.splice(from, 1)[0];
    arr.splice(to, 0, cutOut);

    arr = arr.map((item, index) => ({ ...item, grid_index: index }));

    arr = arr.concat(elementsWithGridIndexMinusOne);
    let noGridFIelds = cloneDeep(
      this.erulistData.entities[
        this.erulistData.entities?.findIndex(
          (e: any) => e.name === this.tabName
        )
      ]['fields']
    ).filter((e: any) => e.show_grid == 'no');
    arr = [...arr, ...noGridFIelds];
    this.erulistData.entities[
      this.erulistData.entities?.findIndex((e: any) => e.name === this.tabName)
    ]['fields'] = arr;
    return arr;
  }
  drag_initial_column: any;
  drag_drop_column: any;
  public dragStartColumn(index: any, header: any) {
    if (header.name === 'error') {
      return;
    }
    this.drag_initial_column = header;
    this.draggedColumnIndex = index;
  }

  public allowDrop(event: any) {
    event.preventDefault();
  }

  public dropColumn(index: any, header: any) {
    this.drag_drop_column = header;
    let col1Index = this.gridlayout?.findIndex(
      (e: any) => e?.name === this.drag_initial_column?.name
    );
    let col2Index = this.gridlayout?.findIndex(
      (e: any) => e?.name === this.drag_drop_column?.name
    );

    if (
      col1Index !== undefined &&
      col1Index !== -1 &&
      col2Index !== undefined &&
      col2Index !== -1
    ) {
      let temp = this.gridlayout[col1Index].grid_index;
      this.gridlayout[col1Index].grid_index =
        this.gridlayout[col2Index].grid_index;
      this.gridlayout[col2Index].grid_index = temp;
      this.pservice.updateStoreLayoutMetadata(this.gridlayout, 'grid_layout');
      // this.pservice.updateStoreMetadata(this.gridlayout, 'gridWidth');
    }

    // this.gridlayout?.map((_e:any)=>{
    //   if(_e.name === this.drag_initial_column.name){
    //     _e.grid_index = this.drag_drop_column?.grid_index
    //   }else if(_e.name === this.drag_drop_column.name){
    //     _e.grid_index = this.drag_initial_column?.grid_index
    //   }
    //   return _e
    // });
    //
    this.arrayMove(this.getTableHeaders(), this.draggedColumnIndex, index).then(
      () => {
        this.save_layout_meta_data();
      }
    );
  }
  // dynamicWidth(datatype: any){
  // }
  dynamicWidth: any = {
    people: '100px',
    status: '150px',
    tag: '100px',
    date: '150px',
    attachment: '100px',
    website: '150px',
    email: '100px',
    phone: '125px',
    dropdown: '150px',
    textbox: '150px',
    rating: '150px',
    location: '150px',
    currency: '100px',
    number: '150px',
    labels: '150px',
    textarea: '150px',
    progress: '150px',
    text_area_unwrapped: '150px',
    checkbox: '150px',
    dropdown_multi_select: '150px',
    dropdown_single_select: '150px',
    image: '150px',
    time: '150px',
    priority: '150px',
  };

  dynamic(e: any) {}

  toggleVisibility() {}
  addSaveColumns(event: any) {
    let data = {
      method: 'addColumn',
      data: event.data,
      form: event.form,
    };
    this.tabelEventEmiter.emit(data);
    this.isVisited = false;
    this.visible = false;
    this.addNewColumn = false;
    this.selectedMenu = null;
    this.selectedMenuData = null;
  }
  editSaveColumns(event: any) {
    let data = {
      method: 'editColumn',
      data: event,
    };
    this.tabelEventEmiter.emit(data);
    this.isVisited = false;
    this.visible = false;
    this.addNewColumn = false;
    this.selectedMenu = null;
    this.selectedMenuData = null;
  }
  removeTabelHeader(item: any, index: any) {
    let selectedColumn = item?.label;
    // let fields =
    //   this.erulistData.entities[
    //     this.erulistData.entities?.findIndex(
    //       (e: any) => e.name === this.tabName
    //     )
    //   ].fields;
    let entities = this.erulistData?.entities;
    let tabNameIndex = entities?.findIndex(
      (e: any) => e?.name === this.tabName
    );
    if (tabNameIndex !== -1) {
      let fields = entities[tabNameIndex]?.fields;
      let columnIndex = fields?.findIndex(
        (field: any) => field.label === selectedColumn
      );
      if (columnIndex !== -1) {
        let data = {
          method: 'deleteColumn',
          headerName: item.name,
          selectedTabName: this.tabName,
          field: fields,
          index: columnIndex,
          selectedField: item.label,
          hide_child_record: true,
        };
        this.tabelEventEmiter.emit(data);
      }
    }
  }
  private destroy$ = new Subject<boolean>();

  groupby() {
    this.isGroupBy = !this.isGroupBy;
  }
  isFilter: boolean = false;
  filter() {
    this.getFields();

    let entitiesData = [];
    let defaultEntities = this.pservice
      .appState()
      ?.metaData?.entities?.find((_e: any) => _e.name === this.tabName);
    if (defaultEntities) {
      let data = {
        label: defaultEntities?.display_name,
        entity_name: defaultEntities?.name,
        field_name: defaultEntities?.name,
        default: true,
      };
      entitiesData.push(data);
    }

    defaultEntities?.fields?.map((_field: any) => {
      if (
        (_field.datatype === 'dropdown_single_select' &&
          _field.option_type === 'ENTITY_DATA') ||
        (_field.datatype === 'dropdown_multi_select' &&
          _field.option_type === 'ENTITY_DATA')
      ) {
        let data = {
          label: _field?.label,
          field_name: _field.name,
          entity_name: _field?.entity_name,
          default: false,
        };
        entitiesData.push(data);
      }
    });
    this.entitiesData = entitiesData;
    // this.filters = this.pservice?.appState()?.layoutMetadata?.filters;
    this.isFilter = !this.isFilter;
  }

  getFields(): any {
    const fields =
      this.erulistData?.entities?.[
        this.erulistData.entities?.findIndex(
          (e: any) => e?.name === this.tabName
        )
      ]?.fields;
    return fields;
  }
  getField(entityName: string): any {
    let entity_Name = this.entitiesData?.find(
      (_e: any) => _e?.field_name === entityName
    )?.entity_name;
    let fields = this.pservice
      .appState()
      ?.metaData?.entities?.find((_e: any) => _e.name === entity_Name)?.fields;
    return fields;
  }
  saveFilter(e: any) {
    if (e) {
      this.isFilter = false;
      this.filterList = {};
      this.convertToJson('top');
    }
  }
  @Output() logFilterDatas = new EventEmitter<any>();
  is_log_filter: boolean = false;
  logFilterData(event: any) {
    this.is_log_filter = true;
    this.logFilterDatas.emit(event);
    this.is_filter_ = true;
  }
  isDefault(fieldName: any) {
    let entity_Name = this.entitiesData?.find(
      (_e: any) => _e?.field_name === fieldName
    );
    if (entity_Name?.entity_name === this.tabName && entity_Name?.default) {
      return false;
    } else {
      return true;
    }
  }
  key3Values(field: any, data: any) {
    if (
      ['currency', 'progress', 'number', 'rating', 'date', 'time'].includes(
        field.datatype
      )
    ) {
      return null;
    } else if (
      ['email', 'location', 'phone', 'textarea', 'textbox', 'website'].includes(
        field.datatype
      )
    ) {
      return '';
    } else if (
      [
        'dropdown_single_select',
        'dropdown_multi_select',
        'people',
        'priority',
        'tag',
        'status',
      ].includes(field.datatype)
    ) {
      return [''];
    }

    return data;
  }
  filterList: any = {};
  show_closed_data: any = [];
  convertToJson(scroll: string) {
    if (this.filters === undefined) {
      this.filters = [
        { key1: '', key2: '', key3: '', key4: true, entity_name: this.tabName },
      ];
    }
    if (this.filters?.length > 0 && this.filters[0]?.key1 == '') {
      this.filterList = {};
    } else {
      // this.pservice.updateStoreMetadata(this.filters, 'filter');
      for (let r of this.filters) {
        const fields = this.getField(r.entity_name);
        let findFieldData = fields?.find((_e: any) => _e.name === r.key1);
        if (
          findFieldData?.datatype === 'currency' ||
          findFieldData?.datatype === 'progress' ||
          findFieldData?.datatype === 'number' ||
          findFieldData?.datatype === 'rating'
        ) {
          r.key3 = r.key3 ? Number(r.key3) : r.key3;
        }
        // For first level group
        if (this.Array.isArray(r)) {
          if (r.length > 1) {
            // For group or
            if (this.filters?.[0].key4 === true) {
              for (let a of r) {
                // For group item
                if (!this.Array.isArray(a)) {
                  if (Object.keys(this.filterList).includes('$or')) {
                    const fields = this.getField(a.entity_name);
                    const selectedField = fields.find(
                      (item: any) => item.name === a.key1
                    );
                    if (
                      [
                        'currency',
                        'progress',
                        'number',
                        'rating',
                        'phone',
                      ].includes(selectedField.datatype)
                    ) {
                      a.key3 = a.key3 ? Number(a.key3) : a.key3;
                    }
                    if (selectedField.datatype === 'checkbox') {
                      this.filterList['$or'] = [
                        ...this.filterList['$or'],
                        {
                          [this.isDefault(a.entity_name)
                            ? `${a.entity_name}~${a.key1}`
                            : a.key1]: a.key2,
                        },
                      ];
                    } else {
                      this.filterList['$or'] = [
                        ...this.filterList['$or'],
                        {
                          [this.isDefault(a.entity_name)
                            ? `${a.entity_name}~${a.key1}`
                            : a.key1]:
                            a.key2 === ''
                              ? a?.key3?.length === 0
                                ? this.key3Values(selectedField, a.key3)
                                : a.key3
                              : {
                                  [a.key2]:
                                    a?.key3?.length === 0
                                      ? this.key3Values(selectedField, a.key3)
                                      : a.key3,
                                },
                        },
                      ];
                    }
                  } else {
                    const fields = this.getField(a.entity_name);
                    const selectedField = fields.find(
                      (item: any) => item.name === a.key1
                    );
                    if (
                      [
                        'currency',
                        'progress',
                        'number',
                        'rating',
                        'phone',
                      ].includes(selectedField.datatype)
                    ) {
                      a.key3 = a.key3 ? Number(a.key3) : a.key3;
                    }
                    if (selectedField.datatype === 'checkbox') {
                      this.filterList['$or'] = [
                        {
                          [this.isDefault(a.entity_name)
                            ? `${a.entity_name}~${a.key1}`
                            : a.key1]: a.key2,
                        },
                      ];
                    } else {
                      this.filterList['$or'] = [
                        {
                          [this.isDefault(a.entity_name)
                            ? `${a.entity_name}~${a.key1}`
                            : a.key1]:
                            a.key2 === ''
                              ? a?.key3?.length === 0
                                ? this.key3Values(selectedField, a.key3)
                                : a.key3
                              : {
                                  [a.key2]:
                                    a?.key3?.length === 0
                                      ? this.key3Values(selectedField, a.key3)
                                      : a.key3,
                                },
                        },
                      ];
                    }
                  }
                }
                // For group -> child group
                else {
                  if (a.length > 1) {
                    // For and
                    if (this.filters?.[0].key4 === true) {
                      this.filterList['$or'].push({});
                      const m = this.filterList['$or'].length - 1;
                      for (let b of a) {
                        const fields = this.getField(b.entity_name);
                        const selectedField = fields.find(
                          (item: any) => item.name === b.key1
                        );
                        if (
                          [
                            'currency',
                            'progress',
                            'number',
                            'rating',
                            'phone',
                          ].includes(selectedField.datatype)
                        ) {
                          b.key3 = b.key3 ? Number(b.key3) : b.key3;
                        }
                        if (selectedField.datatype === 'checkbox') {
                          this.filterList['$or'][m][
                            this.isDefault(b.entity_name)
                              ? `${b.entity_name}~${b.key1}`
                              : b.key1
                          ] = b.key2;
                        } else {
                          this.filterList['$or'][m][
                            this.isDefault(b.entity_name)
                              ? `${b.entity_name}~${b.key1}`
                              : b.key1
                          ] =
                            b.key2 === ''
                              ? b?.key3?.length === 0
                                ? this.key3Values(selectedField, b.key3)
                                : b.key3
                              : {
                                  [b.key2]:
                                    b?.key3?.length === 0
                                      ? this.key3Values(selectedField, b.key3)
                                      : b.key3,
                                };
                        }
                      }
                    }
                  } else {
                    const fields = this.getField(a[0].entity_name);
                    const selectedField = fields.find(
                      (item: any) => item.name === a[0].key1
                    );
                    if (
                      [
                        'currency',
                        'progress',
                        'number',
                        'rating',
                        'phone',
                      ].includes(selectedField.datatype)
                    ) {
                      a[0].key3 = a[0].key3 ? Number(a[0].key3) : a[0].key3;
                    }
                    if (selectedField.datatype === 'checkbox') {
                      this.filterList['$or'] = [
                        ...this.filterList['$or'],
                        {
                          [this.isDefault(a[0].entity_name)
                            ? `${a[0].entity_name}~${a[0].key1}`
                            : a[0].key1]: a[0].key2,
                        },
                      ];
                    } else {
                      this.filterList['$or'] = [
                        ...this.filterList['$or'],
                        {
                          [this.isDefault(a[0].entity_name)
                            ? `${a[0].entity_name}~${a[0].key1}`
                            : a[0].key1]:
                            a[0].key2 === ''
                              ? a[0]?.key3?.length === 0
                                ? this.key3Values(selectedField, a[0].key3)
                                : a[0].key3
                              : {
                                  [a[0].key2]:
                                    a[0]?.key3?.length === 0
                                      ? this.key3Values(
                                          selectedField,
                                          a[0].key3
                                        )
                                      : a[0].key3,
                                },
                        },
                      ];
                    }
                  }
                }
              }
            }
            // For group and
            else {
              this.filterList?.['$or'].push({});
              const n = this.filterList?.['$or'].length - 1;
              for (let a of r) {
                if (!this.Array.isArray(a)) {
                  const fields = this.getField(a.entity_name);
                  const selectedField = fields.find(
                    (item: any) => item.name === a.key1
                  );
                  if (
                    [
                      'currency',
                      'progress',
                      'number',
                      'rating',
                      'phone',
                    ].includes(selectedField.datatype)
                  ) {
                    a.key3 = a.key3 ? Number(a.key3) : a.key3;
                  }
                  if (selectedField.datatype === 'checkbox') {
                    this.filterList['$or'][n][
                      this.isDefault(a.entity_name)
                        ? `${a.entity_name}~${a.key1}`
                        : a.key1
                    ] = a.key2;
                  } else {
                    this.filterList['$or'][n][
                      this.isDefault(a.entity_name)
                        ? `${a.entity_name}~${a.key1}`
                        : a.key1
                    ] =
                      a.key2 === ''
                        ? a?.key3?.length === 0
                          ? this.key3Values(selectedField, a.key3)
                          : a.key3
                        : {
                            [a.key2]:
                              a?.key3?.length === 0
                                ? this.key3Values(selectedField, a.key3)
                                : a.key3,
                          };
                  }
                } else {
                  if (a.length > 1) {
                    const m = this.filterList['$or'].length - 1;
                    for (let b of a) {
                      if (
                        Object.keys(this.filterList['$or'][m]).includes('$or')
                      ) {
                        const fields = this.getField(b.entity_name);
                        const selectedField = fields.find(
                          (item: any) => item.name === b.key1
                        );
                        if (
                          [
                            'currency',
                            'progress',
                            'number',
                            'rating',
                            'phone',
                          ].includes(selectedField.datatype)
                        ) {
                          b.key3 = b.key3 ? Number(b.key3) : b.key3;
                        }
                        if (selectedField.datatype === 'checkbox') {
                          this.filterList['$or'][m]['$or'] = [
                            ...this.filterList['$or'][m]['$or'],
                            {
                              [this.isDefault(b.entity_name)
                                ? `${b.entity_name}~${b.key1}`
                                : b.key1]: b.key2,
                            },
                          ];
                        } else {
                          this.filterList['$or'][m]['$or'] = [
                            ...this.filterList['$or'][m]['$or'],
                            {
                              [this.isDefault(b.entity_name)
                                ? `${b.entity_name}~${b.key1}`
                                : b.key1]:
                                b.key2 === ''
                                  ? b?.key3?.length === 0
                                    ? this.key3Values(selectedField, b.key3)
                                    : b.key3
                                  : {
                                      [b.key2]:
                                        b?.key3?.length === 0
                                          ? this.key3Values(
                                              selectedField,
                                              b.key3
                                            )
                                          : b.key3,
                                    },
                            },
                          ];
                        }
                      } else {
                        const fields = this.getField(b.entity_name);
                        const selectedField = fields.find(
                          (item: any) => item.name === b.key1
                        );
                        if (
                          [
                            'currency',
                            'progress',
                            'number',
                            'rating',
                            'phone',
                          ].includes(selectedField.datatype)
                        ) {
                          b.key3 = b.key3 ? Number(b.key3) : b.key3;
                        }
                        if (selectedField.datatype === 'checkbox') {
                          this.filterList['$or'][m]['$or'] = [
                            {
                              [this.isDefault(b.entity_name)
                                ? `${b.entity_name}~${b.key1}`
                                : b.key1]: b.key2,
                            },
                          ];
                        } else {
                          this.filterList['$or'][m]['$or'] = [
                            {
                              [this.isDefault(b.entity_name)
                                ? `${b.entity_name}~${b.key1}`
                                : b.key1]:
                                b.key2 === ''
                                  ? b?.key3?.length === 0
                                    ? this.key3Values(selectedField, b.key3)
                                    : b.key3
                                  : {
                                      [b.key2]:
                                        b?.key3?.length === 0
                                          ? this.key3Values(
                                              selectedField,
                                              b.key3
                                            )
                                          : b.key3,
                                    },
                            },
                          ];
                        }
                      }
                    }
                  } else {
                    const m = this.filterList['$or'].length - 1;
                    let sample: any = {};
                    const fields = this.getField(a[0].entity_name);
                    const selectedField = fields.find(
                      (item: any) => item.name === a[0].key1
                    );
                    if (
                      [
                        'currency',
                        'progress',
                        'number',
                        'rating',
                        'phone',
                      ].includes(selectedField.datatype)
                    ) {
                      a[0].key3 = a[0].key3 ? Number(a[0].key3) : a[0].key3;
                    }
                    if (selectedField.datatype === 'checkbox') {
                      sample[
                        this.isDefault(a[0].entity_name)
                          ? `${a[0].entity_name}~${a[0].key1}`
                          : a[0].key1
                      ] = a[0].key2;
                    } else {
                      sample[
                        this.isDefault(a[0].entity_name)
                          ? `${a[0].entity_name}~${a[0].key1}`
                          : a[0].key1
                      ] =
                        a[0].key2 === ''
                          ? a[0]?.key3?.length === 0
                            ? this.key3Values(selectedField, a[0].key3)
                            : a[0].key3
                          : {
                              [a[0].key2]:
                                a[0]?.key3?.length === 0
                                  ? this.key3Values(selectedField, a[0].key3)
                                  : a[0].key3,
                            };
                    }

                    this.filterList['$or'][m] = {
                      ...this.filterList['$or'][m],
                      ...sample,
                    };
                  }
                }
              }
            }
          }
          // If group has only one element
          else {
            if (this.filters?.[0].key4 === true) {
              const fields = this.getField(r[0].entity_name);
              const selectedField = fields.find(
                (item: any) => item.name === r[0].key1
              );
              if (
                ['currency', 'progress', 'number', 'rating', 'phone'].includes(
                  selectedField.datatype
                )
              ) {
                r[0].key3 = r[0].key3 ? Number(r[0].key3) : r[0].key3;
              }
              if (selectedField.datatype === 'checkbox') {
                this.filterList[
                  this.isDefault(r[0].entity_name)
                    ? `${r[0].entity_name}~${r[0].key1}`
                    : r[0].key1
                ] = r[0].key2;
              } else {
                this.filterList[
                  this.isDefault(r[0].entity_name)
                    ? `${r[0].entity_name}~${r[0].key1}`
                    : r[0].key1
                ] =
                  r[0].key2 === ''
                    ? r[0]?.key3?.length === 0
                      ? this.key3Values(selectedField, r[0].key3)
                      : r[0].key3
                    : {
                        [r[0].key2]:
                          r[0]?.key3?.length === 0
                            ? this.key3Values(selectedField, r[0].key3)
                            : r[0].key3,
                      };
              }
            } else {
              const fields = this.getField(r[0].entity_name);
              const selectedField = fields.find(
                (item: any) => item.name === r[0].key1
              );
              if (
                ['currency', 'progress', 'number', 'rating', 'phone'].includes(
                  selectedField.datatype
                )
              ) {
                r[0].key3 = r[0].key3 ? Number(r[0].key3) : r[0].key3;
              }
              if (selectedField.datatype === 'checkbox') {
                this.filterList['$or'] = [
                  ...this.filterList['$or'],
                  {
                    [this.isDefault(r[0].entity_name)
                      ? `${r[0].entity_name}~${r[0].key1}`
                      : r[0].key1]: r[0].key2,
                  },
                ];
              } else {
                this.filterList['$or'] = [
                  ...this.filterList['$or'],
                  {
                    [this.isDefault(r[0].entity_name)
                      ? `${r[0].entity_name}~${r[0].key1}`
                      : r[0].key1]:
                      r[0].key2 === ''
                        ? r[0]?.key3?.length === 0
                          ? this.key3Values(selectedField, r[0].key3)
                          : r[0].key3
                        : {
                            [r[0].key2]:
                              r[0]?.key3?.length === 0
                                ? this.key3Values(selectedField, r[0].key3)
                                : r[0].key3,
                          },
                  },
                ];
              }
            }
          }
        }
        // For first level item
        else {
          // For and
          if (this.filters?.[0].key4 == true) {
            const fields = this.getField(r.entity_name);
            const selectedField = fields.find(
              (item: any) => item.name === r.key1
            );
            if (
              ['currency', 'progress', 'number', 'rating', 'phone'].includes(
                selectedField.datatype
              )
            ) {
              r.key3 = r.key3 ? Number(r.key3) : r.key3;
            }
            if (selectedField.datatype === 'checkbox') {
              this.filterList[
                this.isDefault(r.entity_name)
                  ? `${r.entity_name}~${r.key1}`
                  : r.key1
              ] = r.key2;
            } else {
              this.filterList[
                this.isDefault(r.entity_name)
                  ? `${r.entity_name}~${r.key1}`
                  : r.key1
              ] =
                r.key2 === ''
                  ? r?.key3?.length === 0
                    ? this.key3Values(selectedField, r.key3)
                    : r.key3
                  : {
                      [r.key2]:
                        r?.key3?.length === 0
                          ? this.key3Values(selectedField, r.key3)
                          : r.key3,
                    };
            }
          }
          // For or
          else {
            if (Object.keys(this.filterList).includes('$or')) {
              const fields = this.getField(r.entity_name);
              const selectedField = fields.find(
                (item: any) => item.name === r.key1
              );
              if (
                ['currency', 'progress', 'number', 'rating', 'phone'].includes(
                  selectedField.datatype
                )
              ) {
                r.key3 = r.key3 ? Number(r.key3) : r.key3;
              }
              if (selectedField.datatype === 'checkbox') {
                this.filterList['$or'] = [
                  ...this.filterList['$or'],
                  {
                    [this.isDefault(r.entity_name)
                      ? `${r.entity_name}~${r.key1}`
                      : r.key1]: r.key2,
                  },
                ];
              } else {
                this.filterList['$or'] = [
                  ...this.filterList['$or'],
                  {
                    [this.isDefault(r.entity_name)
                      ? `${r.entity_name}~${r.key1}`
                      : r.key1]:
                      r.key2 === ''
                        ? r?.key3?.length === 0
                          ? this.key3Values(selectedField, r.key3)
                          : r.key3
                        : {
                            [r.key2]:
                              r?.key3?.length === 0
                                ? this.key3Values(selectedField, r.key3)
                                : r.key3,
                          },
                  },
                ];
              }
            } else {
              const fields = this.getField(r.entity_name);
              const selectedField = fields.find(
                (item: any) => item.name === r.key1
              );
              if (
                ['currency', 'progress', 'number', 'rating', 'phone'].includes(
                  selectedField.datatype
                )
              ) {
                r.key3 = r.key3 ? Number(r.key3) : r.key3;
              }
              if (selectedField.datatype === 'checkbox') {
                this.filterList['$or'] = [
                  {
                    [this.isDefault(r.entity_name)
                      ? `${r.entity_name}~${r.key1}`
                      : r.key1]: r.key2,
                  },
                ];
              } else {
                this.filterList['$or'] = [
                  {
                    [this.isDefault(r.entity_name)
                      ? `${r.entity_name}~${r.key1}`
                      : r.key1]:
                      r.key2 === ''
                        ? r?.key3?.length === 0
                          ? this.key3Values(selectedField, r.key3)
                          : r.key3
                        : {
                            [r.key2]:
                              r?.key3?.length === 0
                                ? this.key3Values(selectedField, r.key3)
                                : r.key3,
                          },
                  },
                ];
              }
            }
          }
        }
      }
    }
    let entity = this.noOfStatusFields?.find(
      (currentEntity: any) =>
        currentEntity?.entityName === this.tabName &&
        currentEntity.child === false
    );
    this.isStatusClose = this.noOfStatusFields?.some(
      (e: any) => e?.showStatue === true
    );
    let filterStatus: any = {};
    if (entity?.statusFieldName && !entity?.showStatue) {
      filterStatus[`_${entity?.statusFieldName}`] = {
        $ne: !entity?.showStatue ? 'close' : undefined,
      };
    }

    this.filterList = { ...this.filterList, ...filterStatus };
    this.onfirstClick = false;
    if (
      this.isSelectedMore === 'my_drafts' ||
      this.isSelectedMore === 'for_my_approval' ||
      this.isSelectedMore === 'error_records'
    ) {
      this.statusFilters(this.statusData || null, scroll);
    } else {
      // this.filterGroups.emit(this.filterList);
      this.statusFilters(this.statusData || null, scroll);
    }
  }

  ascendingSortByOrder(name: any) {}

  descendingSortByOrder(name: any) {}

  addToSortingOrder(name: any) {
    if (Object.keys(this.sortingOrder).length == 0) {
      this.sortingOrder[name] = 'asc';
    } else if (Object.keys(this.sortingOrder).length == 1) {
      if (Object.keys(this.sortingOrder).includes(name)) {
        if (this.sortingOrder[name] == 'asc') {
          this.sortingOrder[name] = 'desc';
        } else {
          delete this.sortingOrder[name];
        }
      } else {
        this.sortingOrder[name] = 'asc';
      }
    } else {
      if (Object.keys(this.sortingOrder).includes(name)) {
        if (this.sortingOrder[name] === 'asc') {
          this.sortingOrder[name] = 'desc';
        } else {
          this.sortingOrder[name] = 'asc';
        }
      } else {
        this.sortingOrder[name] = 'asc';
      }
    }

    this.newSortJson();
  }

  removeFromSort(name: any) {
    delete this.sortingOrder[name];
    this.newSortJson();
  }
  clearSort(): void {
    this.selectedSortLabel = [];
    this.newSortJson();
  }

  getSortOrder(name: any) {
    if (Object?.keys(this.sortingOrder).includes(name)) {
      return true;
    } else {
      return false;
    }
  }

  getSortingOrderlength() {
    return Object.keys(this.sortingOrder).length;
  }

  getSortingOrderNumber(name: any) {
    const number = Object.keys(this.sortingOrder)?.findIndex(
      (item: any) => item === name
    );
    return Object.keys(this.sortingOrder).length > 1 ? number + 1 : '';
  }

  getSortingOrderArray() {
    return Object.keys(this.sortingOrder);
  }

  // Changing sort attributes to json
  newSortJson() {
    this.sortList = { entity_data: [] };
    if (this.selectedSortLabel.length > 0) {
      this.sortList['entity_data'].push(
        this.selectedSortLabel[1] == 'desc'
          ? `-${this.selectedSortLabel[0]}`
          : this.selectedSortLabel[0]
      );
    }
    let headers = this.getTableHeaders();
    for (let item of Object.keys(this.sortingOrder)) {
      let name = headers?.find((j: any) => j.label === item)?.name;
      this.sortList['entity_data'].push(
        this.sortingOrder[item] == 'asc' ? name : `-${name}`
      );
    }

    let sortData = {
      group: this.selectedSortLabel?.[0],
      sort: this.sortList?.entity_data?.filter((e: any) => e !== undefined),
    };
    this.groupedTableData();
    this.pservice.updateStoreLayoutMetadata(sortData, 'sort');
    this.sortGroups.emit({ ['sort']: this.sortList });
  }

  deleteItemFromTable(item: any) {
    const headers = this.getTableHeaders();
    const index = headers?.findIndex((i: any) => i.name === item.name);
    this.removeTabelHeader(item, index);
  }

  editField(item: any) {
    this.isVisited = true;
    this.visible = false;
    this.addNewColumn = true;
    this.selectedMenu = item.datatype;
    this.selectedMenuData = item;
    this.isEdit = true;
  }

  isEdit = false;
  getLabelNameForSort(name: any) {
    let findsortFieldbyTab = this.erulistData?.entities?.find(
      (e: any) => e.name === this.tabName
    )?.fields;
    const filteredData = findsortFieldbyTab?.filter(
      (obj: any) =>
        (obj?.can_group === true && obj?.show_grid === 'yes') ||
        obj?.show_grid === 'always'
    );
    if (name?.length > 0) {
      let find = filteredData?.find((e: any) => e.name == name[0]);
      return find?.label || 'NA';
    }
    return 'NA';
  }

  closeAddColumn() {
    this.addNewColumn = false;
    this.isEdit = false;
    let data = {
      method: 'cancelChanges',
      data: {},
    };
    // this.tabelEventEmiter.emit(data);
  }

  openVisibilityOptions(data: any) {
    this.showVisibility.emit([data, 'entity']);
  }

  showVisibilityFromField(item: any) {
    this.visible = false;
    this.showVisibility.emit([item, 'field']);
  }

  //! Refactored

  getIconForGroupBy(name: string) {
    return this.newColumnFields?.find((_: any) => _.datatype == name)?.image;
  }
  scrollEvent(_e: any) {}
  trackByIndex(_: number, data: any): number {
    return data.index;
  }
  @ViewChild('virtualTable', { static: false })
  nzTableComponent?: NzTableComponent<any>;

  ngAfterViewInit() {
    // this.cdRef.detectChanges();
    this.nzTableComponent?.cdkVirtualScrollViewport?.scrolledIndexChange
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {});
  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  textboxValidation(field: any, event: any) {
    let inputText = event;
    let length = Number(field?.data_length);
    if (event?.length > 0) {
      if (field?.data_length_check === 'MAX') {
        if (inputText?.length > length) {
          return `Maximum value is ${field?.data_length}`;
        } else {
          return '';
        }
      } else if (field?.data_length_check === 'MIN') {
        if (inputText?.length < length) {
          return `Minimum value is ${field?.data_length}`;
        } else {
          return '';
        }
      } else if (field?.data_length_check === 'EXACT') {
        if (inputText?.length !== length) {
          return `Exact value is ${field?.data_length}`;
        } else {
          return '';
        }
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  formatNumber(v: any, field: any) {
    const inputValue = v ? v.toString() : '';
    try {
      if (field.seperator && inputValue) {
        // const numericValue = Number(inputValue);
        const numericValue = Number(inputValue?.replace(/,/g, ''));
        if (numericValue) {
          let separator = '';
          if (field.seperator.toLowerCase() === 'millions') {
            separator = 'en-US';
          } else if (field.seperator.toLowerCase() === 'thousands') {
            separator = 'en-IN';
          } else {
            separator = '';
          }
          const formattedValue = this.formatNumberWithSeparator(
            numericValue,
            separator,
            field.decimal
          );
          return formattedValue;
        }
      }
      return inputValue;
    } catch (e) {
      return '';
    }
  }
  formatNumberWithSeparator(
    value: number,
    format: string,
    decimal: number
  ): string {
    if (format) {
      const formattedValue = value.toLocaleString(format, {
        maximumFractionDigits: decimal,
        minimumFractionDigits: decimal,
      });
      return formattedValue;
    } else {
      return value.toFixed(decimal || 0);
    }
  }
  getOptionStatus(f: any) {
    let arr: any = [];
    if (f.open_status?.length > 0) {
      arr = [...arr, ...f.open_status];
    }
    if (f.close_status?.length > 0) {
      arr = [...arr, ...f.close_status];
    }
    return arr;
  }
  UpdateTabsEvnt(e: any) {
    this.UpdateTabs.emit(e);
  }
  getStatusColor(h: any, v: any, d: any) {
    let arr: any = [];
    if (h.close_status) {
      arr = [...arr, ...h.close_status];
    }
    if (h.open_status) {
      arr = [...arr, ...h.open_status];
    }
    let color = arr.find((e: any) => e.name == v)?.color || '';
    return color;
  }
  showEmailError(email: string): boolean {
    const emailPattern =
      /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailPattern.test(email);
  }

  deleteTab(e: any) {
    this.delTab.emit(e);
  }
  computed = false;
  groupedTableData() {
    let getField = this.getFields();
    let priorityField = getField?.find(
      (_priorityField: any) => _priorityField?.datatype === 'priority'
    );
    let statusField = getField?.find(
      (_priorityField: any) => _priorityField?.datatype === 'status'
    );
    let numberField = getField?.find(
      (_priorityField: any) => _priorityField?.datatype === 'number'
    );
    let currencyField = getField?.find(
      (_priorityField: any) => _priorityField?.datatype === 'currency'
    );
    let progressField = getField?.find(
      (_priorityField: any) => _priorityField?.datatype === 'progress'
    );
    this.computed = false;
    let arr = this.getTableData() || [];
    let saveValue = this.getTableData() || [];
    let filterEntitty = saveValue
      .map((fieldValue: any) => {
        if (priorityField) {
          if (
            !(
              fieldValue[`${priorityField?.name}`] === 'Urgent' ||
              fieldValue[`${priorityField?.name}`] === 'High' ||
              fieldValue[`${priorityField?.name}`] === 'Normal' ||
              fieldValue[`${priorityField?.name}`] === 'Low' ||
              fieldValue[`${priorityField?.name}`] === ''
            )
          ) {
            fieldValue[`${priorityField?.name}`] = '';
          }
        }
        if (numberField) {
          if (!(typeof fieldValue[`${numberField?.name}`] === 'number')) {
            fieldValue[`${numberField?.name}`] = null;
          }
        }
        if (currencyField) {
          if (!(typeof fieldValue[`${currencyField?.name}`] === 'number')) {
            fieldValue[`${currencyField?.name}`] = null;
          }
        }
        if (progressField) {
          if (
            !(
              typeof fieldValue[`${progressField?.name}`] === 'number' &&
              fieldValue[`${progressField?.name}`] >= 0
            )
          ) {
            fieldValue[`${progressField?.name}`] = null;
          }
        }
        return fieldValue;
      })
      .filter(Boolean);
    let groupedData: any = {};
    if (this.selectedSortLabel && this.selectedSortLabel.length > 0) {
      filterEntitty.forEach((item: any) => {
        if (!groupedData[item[this.selectedSortLabel[0]]]) {
          groupedData[item[this.selectedSortLabel[0]]] = [];
        }
        groupedData[item[this.selectedSortLabel[0]]].push({
          ...item,
          expand: false,
        });
      });
    } else {
      groupedData['undefined'] = [];
      filterEntitty.forEach((item: any) => {
        groupedData['undefined'].push({ ...item, expand: false });
      });
    }    
    this.groupData$ = groupedData;

    this.computed = true;
  }

  groupData$: any = {};

  Object = Object;

  selectAPIEmitter(e: any) {
    if (e.action === true) {
      this.emitSelectorAPI.emit(e);
    }
  }
  scrollTimeout: any = null;
  lastScrollTop: number = 0;
  onScroll(event: any) {
    if (this.pagination.totalRecords >= this.pagination.currentRecords) {
      const scrollPosition = event.target.scrollTop;
      const maxScroll = event.target.scrollHeight - event.target.clientHeight;
      const scrollDirection =
        this.lastScrollTop < scrollPosition ? 'down' : 'up';
      this.lastScrollTop = scrollPosition;
      if (scrollPosition / maxScroll >= 0.95 && scrollDirection === 'down') {
        if (!this.scrollTimeout) {
          this.scrollTimeout = setTimeout(() => {
            this.convertToJson('bottom');
            // this.scrollEvents.emit('bottom');
            this.scrollTimeout = null;
          }, 300);
        }
      }
    } else {
    }
  }
  selectedColapsePannel(selected: any) {
    if (selected) {
      if (this.pagination.totalRecords >= this.pagination.currentRecords) {
        this.convertToJson('bottom');
      }
    }
  }
  entityselectFields(e: any) {
    this.singleSelectEntity.emit({ e });
  }
  isPeopleDropDownOpen = false;
  peopleSelect(comp: any) {
    // comp.host.nativeElement.click();
    this.selectAPIEmitter({ action: true, people: 'people' });

    // this.isPeopleDropDownOpen=true;
  }
  selectedPeople() {
    this.selectAPIEmitter({ action: true, people: 'people' });
  }
  peopleDropdownEventEmitter(e: any, data: any) {
    if (!e) {
      this.isPeopleDropDownOpen = false;
      this.stopEdit(false, 'dropdown', data.entity_id);
    }
  }
  peopleDropdownEventEmitters(event: any) {
    this.peopleDropdownEventEmitter(event.event, event.data);
  }
  getPeopleOption(header: any) {
    let getUserData = header.api_field;
    if (this.api_fields && header.api_field) {
      let optionValues = this.api_fields.map((e: any) => e[getUserData]);
      return optionValues;
    } else {
      return [];
    }
  }
  selectedPeoples(event: any) {
    this.selectedPeople();
  }
  resetFilters() {
    this.filters = [
      { key1: '', key2: '', key3: '', key4: true, entity_name: this.tabName },
    ];
    this.statusData = undefined;
    this.is_log_filter = false;
    // this.pservice.updateStoreMetadata(this.filters, 'filter');
  }
  previousComp: any = null;
  peopleComp: any;
  startDropDownEditEmitter(comp: any) {
    if (this.previousComp) {
      this.previousComp.editClicked = false;
    }
    this.previousComp = comp;
  }
  peopleDatas(event: any) {
    this.peopleData(event.header, event.data);
  }
  @Output() getMobileCodes = new EventEmitter<any>();
  getMobileCode(event: any) {
    if (event) {
      this.getMobileCodes.emit(event);
    }
  }
  expandInnerChild(expandref: any) {}
  isEntityDataType$ = new BehaviorSubject<any>([]);
  isEntityDataTypeColumns$ = new BehaviorSubject<any>([]);
  isEntityDataTypeRow$ = new BehaviorSubject<any>([]);
  isEntityDataTypeRowCopy$ = new BehaviorSubject<any>([]);

  isEntityDataType(d: any) {
    this.isEntityDataType$.next([]);
    return of(this.getFieldsInspace('')).pipe(
      mergeMap((fields: any) => from(fields)),
      filter((field: any) => field.datatype === 'entity'),
      map((field: any, index: number) => ({ ...field, expand: index === 0 })),
      toArray(),
      tap((res) => {
        res.forEach((_e: any) => {
          let entity = this.erulistData?.entities?.find(
            (e: any) => e?.name === _e?.name
          );
          _e.index = entity?.index;
        });
        res.sort((a: any, b: any) => a?.index?.localeCompare(b?.index));
        res.forEach((_data: any, index: number) => {
          if (index === 0) {
            _data.expand = true;
          } else {
            _data.expand = false;
          }
        });
        this.isEntityDataType$.next(res);
        this.getInnerTableColumn(res[0], d.entity_id);
      })
    );
  }
  childTabDetails: any;
  inner_table_details: any;
  expand(i: any, d: any, arr: any) {
    arr.forEach((e: any) => (e.expand = false));
    i.expand = !i.expand;
    this.inner_table_details = i;
    this.getInnerTableColumn(i, d.entity_id);
  }
  getDisplayName(name: string) {
    let entityDisplayName =
      this.erulistData?.entities?.find((e: any) => e?.name === name)
        ?.display_name || '';
    return entityDisplayName;
  }
  existingInnerChild: any = null;
  dataInnerExpand(data: any) {
    if (!this.existingInnerChild) {
      data['expand'] = true;
      this.existingInnerChild = data;
    } else if (this.existingInnerChild === data) {
      data['expand'] = !data['expand'];
    } else {
      this.existingInnerChild['expand'] = false;
      data['expand'] = true;
      this.existingInnerChild = data;
    }

    this.isEntityDataType(data).subscribe();
  }
  has_am = false;
  innerTabledata: any;
  innerTableeid: any;
  child_entity_name!: string;
  selected_nic = '';
  child_nic:any=''
  getInnerTableColumn(i: any, eid: any) {
    let get_layout_metadata = this.pservice.appState().layoutMetadata.entities;
    let selected_layout = get_layout_metadata.find(
      (_e: any) => _e.name === i?.name
    );
    this.innerTabledata = i;
    this.innerTableeid = eid;
    this.childTabDetails = i;

    this.isEntityDataTypeColumns$.next([]);
    this.has_am = false;
    let findsortFieldbyTab = this.erulistData?.entities?.find(
      (e: any) => e?.name === i?.name
    )?.fields;
    this.has_am = this.erulistData?.entities?.find(
      (e: any) => e?.name === i?.name
    )?.has_am;

    findsortFieldbyTab.forEach((_field: any) => {
      let new_index = selected_layout?.grid_layout?.find(
        (_e: any) => _e?.name === _field?.name
      )?.grid_index;
      _field.layout_index = new_index;
    });
    findsortFieldbyTab.sort(
      (a: any, b: any) => a.layout_index - b.layout_index
    );

    this.isEntityDataTypeColumns$.next(
      findsortFieldbyTab?.filter(
        (e: any) => e.show_grid == 'yes' || e.show_grid == 'always'
      )
    );
    let body: any = {
      org_id: this.pservice?.appState()?.selectedOrganization?.org_id,
      process_id: this.pservice?.appState()?.selectedProcess?.process_id,
      entity_name: i.name,
      process_name: this.addUnderscoresToSpaces(
        this.pservice?.appState()?.selectedProcess.process_name
      ),
      limit: 10,
      skip: 0,
      sort: [],
    };
    let selected_entity_value = this.pservice
      ?.appState()
      ?.metaData.entities?.find((_en: any) => _en.name === i.name);
    if (selected_entity_value) {
      Object.keys(selected_entity_value).forEach((key) => {
        if (
          key.startsWith('p') &&
          (key.endsWith('_ef') || key.endsWith('_pen'))
        ) {
          body[key] = selected_entity_value[key];
        }
      });
    }
    let filterStatus: any = {};
    let entity = this.noOfStatusFields?.find(
      (currentEntity: any) =>
        currentEntity?.entityName === i.name && currentEntity.child === true
    );
    this.isStatusClose = this.noOfStatusFields?.some(
      (e: any) => e?.showStatue === true
    );
    if (entity?.statusFieldName) {
      filterStatus[`_${entity?.statusFieldName}`] = !entity?.showStatue
        ? 'open'
        : undefined;
    }
    this.selected_nic = i.nic;
    let filterData = { [`_${i.nic}`]: eid };
    this.child_nic = filterData;    
    filterData = { ...filterData, ...filterStatus };
    body['filter'] = filterData;
    this.child_entity_name = body?.entity_name;
    this.pservice
      .fetchEntityInnerRowData(body, this.isSelectedMore)
      .subscribe((res: any) => {
        let response =
          res[0]?.entity_data?.map((e: any) => ({
            ...e.entity_data,
            entity_id: e.entity_id,
            entity_name: i.name,
            a_status: e.a_status,
            org_id: body.org_id,
            process_id: body.process_id,
            process_name: body.process_name,
            total_records: res[1]?.entity_count?.[0]?.entity_cnt || 0,
          })) || [];
        this.isEntityDataTypeRow$.next([]);
        this.isEntityDataTypeRowCopy$.next([]);
        this.isEntityDataTypeRowCopy$.next(cloneDeep(response));
        this.isEntityDataTypeRow$.next(response);
        this.total_child_record = response;
      });
  }
  total_child_record: any;
  addUnderscoresToSpaces(input: string): string {
    return input?.replace(/ /g, '_');
  }

  // updateDataOnStopEdit(data:any){
  //   this.dataUpdate.emit(data.entity_id);
  // }
  loadingState$ = new BehaviorSubject<boolean>(false);
  updateDataOnStopEdit(
    value: any,
    header_name: any,
    data: any,
    erulistData: any,
    entity?: any,
    p_created_by: any = []
  ) {
    let editedData = erulistData.data.find((item: any) => {
      return item.entity_id == data.entity_id;
    });
    if (this.selected_entity_created_by_id[`${data.entity_id}`] === undefined) {
      this.loadingState$.next(false);
      this.update_p_created_by(data, erulistData);
    }
    if (value?.target?.datatype === 'time') {
      editedData[`${header_name}`] = value.target.value?.[`${header_name}`]
        ? Number(value.target.value?.[`${header_name}`] || null)
        : null;

      editedData[`${header_name}_st`] = value.target.value?.[
        `${header_name}_st`
      ]
        ? Number(value.target.value?.[`${header_name}_st`] || null)
        : null;

      editedData[`${header_name}_et`] = value.target.value?.[
        `${header_name}_et`
      ]
        ? Number(value.target.value?.[`${header_name}_et`] || null)
        : null;
    } else if (value?.dataType === 'status') {
      editedData[header_name] = value.value;
      editedData[`_${header_name}`] = value.selectedOption;
    } else {
      editedData[header_name] = value.target.value;
    }
    if (entity) {
      editedData = { ...editedData, ...entity };
    }
    this.loadingState$.subscribe((isLoading) => {
      if (isLoading) {
        let default_data =
          this.selected_entity_created_by_id[`${data?.entity_id}`];
        let unique_arrayp_created_by: any = [];
        if (default_data?.length > 0) {
          let combinedArray = Object.values(default_data)?.flat();
          unique_arrayp_created_by = [...new Set(combinedArray)];
        }
        this.dataUpdate.emit({
          id: editedData.entity_id,
          updatedData: editedData,
          p_created_by: unique_arrayp_created_by,
        });
        this.customAction(data);
      }
    });
  }
  update_p_created_by(selected_row_data: any, erulistData: any) {
    let fields = erulistData.entities.find(
      (_entities: any) =>
        _entities.name === this.pservice?.appState()?.selectedEntity
    ).fields;
    const promises = Object.keys(selected_row_data).map((_field_name: any) => {
      return new Promise<void>((resolve) => {
        let is_drop_down_entity_field = fields?.find(
          (_field: any) =>
            _field.name === _field_name &&
            (_field.datatype == 'dropdown_single_select' ||
              _field.datatype == 'dropdown_multi_select') &&
            _field.option_type === 'ENTITY_DATA'
        );

        if (is_drop_down_entity_field) {
          let f = is_drop_down_entity_field;
          let allfield = fields;
          let value = selected_row_data[_field_name];
          let entity_id = selected_row_data['entity_id'];
          let body: any = {
            org_id: this.pservice.appState().selectedOrganization.org_id,
            process_id: this.pservice.appState().selectedProcess.process_id,
            entity_name: f.entity_name,
            process_name: this.pservice
              .appState()
              .selectedProcess.process_name?.replace(/\s/g, '_'),
            field_name: f.field_name,
            limit: 50,
            skip: 0,
            field_str: '',
          };

          if (f.def) {
            let filterValue = allfield.find(
              (e: any) => e.name === f.def
            ).default;
            let val: any = [];
            if (Array.isArray(filterValue)) {
              val = [...filterValue];
            } else {
              val = [filterValue || ''];
            }

            if (
              allfield.find((e: any) => e.name === f.def)?.datatype ==
              'dropdown_single_select'
            ) {
              body['filter'] = {
                [f.dpef]: val[0],
              };
            } else {
              body['filter'] = {
                [`$in`]: val,
              };
            }
          }
          this.pservice.getentityfielddata(body).subscribe((res: any) => {
            let entity_data = res?.[0]?.entity_data;
            let created_by_id: any = [];
            let final_data: any = {};
            if (f.datatype === 'dropdown_single_select' && entity_data) {
              let p_created_by = entity_data.find(
                (_data: any) => _data?.entity_data === value
              )?.p_created_by;
              if (p_created_by) {
                p_created_by?.forEach((_e: any) => {
                  if (_e) {
                    created_by_id.push(_e);
                  }
                });
              }
            } else if (f.datatype === 'dropdown_multi_select' && entity_data) {
              value.forEach((_value_name: any) => {
                let p_created_by = entity_data.find(
                  (_data: any) => _data?.entity_data === _value_name
                )?.p_created_by;
                if (p_created_by) {
                  p_created_by?.forEach((_e: any) => {
                    if (_e) {
                      created_by_id.push(_e);
                    }
                  });
                }
              });
            }
            if (this.selected_entity_created_by_id[entity_id] === undefined) {
              this.selected_entity_created_by_id[entity_id] = [];
            }
            this.selected_entity_created_by_id[entity_id][`${f.name}`] =
              created_by_id;
            resolve();
          });
        } else {
          resolve();
        }
      });
    });
    Promise.all(promises).then(() => {
      this.loadingState$.next(true);
    });
  }

  selected_entity_created_by_id: any = [];
  openDropDownEntityChange(f: any, allfield: any, value: any, entity_id: any) {
    let body: any = {
      org_id: this.pservice.appState().selectedOrganization.org_id,
      process_id: this.pservice.appState().selectedProcess.process_id,
      entity_name: f.entity_name,
      process_name: this.pservice
        .appState()
        .selectedProcess.process_name?.replace(/\s/g, '_'),
      field_name: f.field_name,
      limit: 50,
      skip: 0,
      field_str: '',
    };

    if (f.def) {
      let filterValue = allfield.find((e: any) => e.name === f.def).default;
      let val: any = [];
      if (Array.isArray(filterValue)) {
        val = [...filterValue];
      } else {
        val = [filterValue || ''];
      }

      if (
        allfield.find((e: any) => e.name === f.def)?.datatype ==
        'dropdown_single_select'
      ) {
        body['filter'] = {
          [f.dpef]: val[0],
        };
      } else {
        body['filter'] = {
          [`$in`]: val,
        };
      }
    }
    this.pservice.getentityfielddata(body).subscribe((res: any) => {
      let entity_data = res?.[0]?.entity_data;
      let created_by_id: any = [];
      let final_data: any = {};
      if (f.datatype === 'dropdown_single_select' && entity_data) {
        let p_created_by = entity_data.find(
          (_data: any) => _data?.entity_data === value
        )?.p_created_by;
        if (p_created_by) {
          p_created_by?.forEach((_e: any) => {
            if (_e) {
              created_by_id.push(_e);
            }
          });
        }
      } else if (f.datatype === 'dropdown_multi_select' && entity_data) {
        value.forEach((_value_name: any) => {
          let p_created_by = entity_data.find(
            (_data: any) => _data?.entity_data === _value_name
          )?.p_created_by;
          if (p_created_by) {
            p_created_by?.forEach((_e: any) => {
              if (_e) {
                created_by_id.push(_e);
              }
            });
          }
        });
      }
      // final_data[`${f.name}`] = created_by_id;
      if (this.selected_entity_created_by_id[entity_id] === undefined) {
        this.selected_entity_created_by_id[entity_id] = [];
      }
      this.selected_entity_created_by_id[entity_id][`${f.name}`] =
        created_by_id;
    });
  }
  updateTimeOnEdit(newValue: any, data: any, erulistData: any) {
    let editedData = erulistData.data.find((item: any) => {
      return item.entity_id == data.entity_id;
    });
  }
  updateDataOnInnerStopEdit(
    value: any,
    header_name: any,
    data: any,
    entity?: any,
    p_created_by: any = []
  ) {
    let editedData = this.isEntityDataTypeRow$.getValue().find((item: any) => {
      return item.entity_id === data.entity_id;
    });
    if (value?.dataType === 'status') {
      editedData[header_name] = value.value;
      editedData[`_${header_name}`] = value.selectedOption;
    } else {
      editedData[header_name] = value.target.value;
    }

    if (entity) {
      editedData = { ...editedData, ...entity };
    }
    let body: any = {
      id: editedData.entity_id,
      fields: this.isEntityDataTypeColumns$.getValue(),
      updatedData: editedData,
      data: this.isEntityDataTypeRowCopy$.getValue(),
      has_am: this.has_am,
      p_created_by: p_created_by,
    };
    this.innerdataUpdate.emit(body);
    this.customAction(data);
  }

  @Output() morefilterOptions = new EventEmitter<any>();
  moreOption(item: any) {
    this.isSelectedMore = item;
    // this.pservice.updateStoreMetadata(this.isSelectedMore, 'selectedTab');
    let body: any;
    if (this.statusData?.a_status) {
      this.statusData.a_status = null;
    }

    // if (this.isSelectedMore === 'my_drafts') {
    //   this.morefilterOptions.emit({ body, selectedoption: 'my_drafts' });
    // } else if (this.isSelectedMore === 'for_my_approval') {
    //   this.morefilterOptions.emit({ body, selectedoption: 'for_my_approval' });
    // } else if (this.isSelectedMore === 'approved_records') {
    //   this.morefilterOptions.emit({ body, selectedoption: 'approved_records' });
    // } else if (this.isSelectedMore === 'error_records') {
    //   this.morefilterOptions.emit({ body, selectedoption: 'error_records' });
    // }
    if (!this.noOfStatusFields) {
      // this.getEntityList();
    }
    setTimeout(() => {
      this.convertToJson('top');
    }, 200);
  }

  statusData: any;
  flattenArray(arr: any[]): any[] {
    let result: any[] = [];
    arr.forEach((element) => {
      if (Array.isArray(element)) {
        result = result.concat(this.flattenArray(element));
      } else {
        result.push(element);
      }
    });
    return result;
  }

  getPfilterdata(data: any) {
    let FilterData: any = [];
    data.forEach((_element: any) => {
      if (_element.key3 === '' || _element.entity_name === '') {
        return;
      }
      let field = this.entitiesData?.find(
        (_name: any) => _name.field_name === _element?.entity_name
      );
      if (!field?.default) {
        let data = {
          pen: `${this.pservice?.appState()?.selectedProcess?.process_name}_${
            field?.entity_name
          }`.replace(/\s/g, '_'),
          ef: `_${_element.entity_name}`,
        };
        FilterData.push(data);
      }
    });

    return FilterData;
  }
  statusFilters(event: any, scroll: string) {
    let filterData = this.flattenArray(this.filters);
    let pFilter = this.getPfilterdata(filterData);
    this.statusData = event;
    if (
      this.setStatusFilterData &&
      this.pservice?.appState()?.tableDiable === 'ERRORS' &&
      scroll === 'bottom'
    ) {
      let a_status = {
        a_status: this.setStatusFilterData.filter,
      };
      this.statusData = a_status;
      let bu_file_id_xx = this.setStatusFilterData.file_id;
      this.filterList = { ...this.filterList, bu_file_id_xx };
    }
    let filter: any = {
      filters: this.filterList,
      pfilter: pFilter ? pFilter : [],
    };
    if (!this.statusData?.a_status?.null) {
      filter['status'] = this.statusData?.a_status;
    }

    if (this.isSelectedMore === 'for_my_approval') {
      this.morefilterOptions.emit({
        filter,
        selectedoption: 'for_my_approval',
        scroll: scroll,
      });
    } else if (this.isSelectedMore === 'my_drafts') {
      this.morefilterOptions.emit({
        filter,
        selectedoption: 'my_drafts',
        scroll: scroll,
      });
    } else if (this.isSelectedMore === 'error_records') {
      this.morefilterOptions.emit({
        filter,
        selectedoption: 'error_records',
        scroll: scroll,
      });
    } else if (this.isSelectedMore === 'approved_records') {
      this.morefilterOptions.emit({
        filter,
        selectedoption: 'approved_records',
        scroll: scroll,
      });
    }
  }
  statusFilter(event: any) {
    if (event) {
      this.statusData = event;
      this.saveFilter(true);
    }
  }
  @Input() nt: any;
  sendForApproval(from: any, data: any, args: any) {
    let body = {};
    if (from == 'outer') {
      body = {
        org_id: this.pservice?.appState()?.selectedOrganization?.org_id,
        process_id: data.process_id,
        entity_name: this.tabName,
        process_name: this.addUnderscoresToSpaces(data.process_name),
        entity_id: data.entity_id,
      };
    }
    if (from == 'inner') {
      body = {
        org_id: this.pservice?.appState()?.selectedOrganization?.org_id,
        process_id: this.pservice?.appState()?.selectedProcess?.process_id,
        entity_name: data.entity_name,
        process_name: this.addUnderscoresToSpaces(
          this.pservice?.appState()?.selectedProcess.process_name
        ),
        entity_id: data.entity_id,
      };
    }
    this.pservice.sendForApproval(body).subscribe(
      (res: any) => {
        if (res) {
          this.morefilterOptions.emit({ body, selectedoption: 'my_drafts' });
          this.nt.show('Success', 200, `Approval send successfully`);
        } else {
          // this.nt.show('Error', '', `Something went wrong`);
        }
      },
      (e: any) => {
        // this.nt.show('Error', '', `Something went wrong`);
      }
    );
  }
  selectedMenuOption(from: any, data: any, args: any, menuName: string) {
    let body = {};
    if (from == 'outer') {
      body = {
        org_id: this.pservice?.appState()?.selectedOrganization?.org_id,
        process_id: data.process_id,
        entity_name: this.tabName,
        process_name: this.addUnderscoresToSpaces(data.process_name),
        entity_id: data.entity_id,
      };
    }
    if (from == 'inner') {
      body = {
        org_id: this.pservice?.appState()?.selectedOrganization?.org_id,
        process_id: this.pservice?.appState()?.selectedProcess?.process_id,
        entity_name: data.entity_name,
        process_name: this.addUnderscoresToSpaces(
          this.pservice?.appState()?.selectedProcess.process_name
        ),
        entity_id: data.entity_id,
      };
    }
    let URl: any;
    if (menuName === 'approve') {
      URl = '/processo/func/approve_data';
    } else if (menuName === 'reject') {
      URl = '/processo/func/reject_data';
    }
    this.pservice.actionData(body, URl).subscribe(
      (res: any) => {
        if (res) {
          this.morefilterOptions.emit({
            body,
            selectedoption: 'for_my_approval',
          });
          this.nt.show('Success', 200, `Approval send successfully`);
        } else {
          // this.nt.show('Error', '', `Something went wrong`);
        }
      },
      (e: any) => {
        // this.nt.show('Error', '', `Something went wrong`);
      }
    );
  }

  isInnerChild() {
    let fields = this.getFieldsInspace('');

    return fields.some((e: any) => e.datatype == 'entity');
  }

  viewRow(e: any, selectedOption?: any) {
    let body = {};
    if (selectedOption == 'outerView') {
      body = {
        org_id: this.pservice?.appState()?.selectedOrganization?.org_id,
        process_id: e.process_id,
        entity_name: this.tabName,
        process_name: this.addUnderscoresToSpaces(e.process_name),
        entity_id: e.entity_id,
      };
    }
    if (selectedOption == 'innerView') {
      body = {
        org_id: this.pservice?.appState()?.selectedOrganization?.org_id,
        process_id: this.pservice?.appState()?.selectedProcess?.process_id,
        entity_name: e.entity_name,
        process_name: this.addUnderscoresToSpaces(
          this.pservice?.appState()?.selectedProcess.process_name
        ),
        entity_id: e.entity_id,
      };
    }
    let data = {
      selectedOption: selectedOption,
      body: body,
    };
    this.editrow.emit({ e, data });
  }

  isAdmin() {
    if (this.state?.tableDiable === 'ERRORS') {
      return false;
    }
    let UserIds =
      this.pservice?.appState()?.selectedProcess?.public___vw_org_process_users;
    let loggedInUser = this.pservice.isUserLoggedIn().userDetail.id;
    let user = UserIds?.find((u: any) => u.user_id == loggedInUser);
    if (user?.user_access == 'ADMIN') {
      return true;
    } else {
      return false;
    }
  }

  viewMore(e: any, selectedOption?: any) {
    this.editrow.emit({
      e,
      selectedOption,
      viewMore: true,
      nic: this.selected_nic,
    });
  }
  isViewOnlyAccess = true;
  checkUserAcessType() {
    let loggedUser = this.pservice?.isUserLoggedIn()?.userDetail?.id;
    let accessType = this.pservice
      ?.appState()
      ?.selectedProcess?.public___vw_org_process_users?.find(
        (e: any) => e.user_id == loggedUser
      );
    this.isViewOnlyAccess = accessType?.user_access == 'VIEW';
  }

  addMoreChildTable(d: any) {    
    this.addNewChildTableColumn.emit({
      childData: this.childTabDetails,
      parentData: d,
      child_nic:this.child_nic,
    });
  }
  @Output() openPeopleAssignes = new EventEmitter<any>();
  openPeopleAssignee(item: any) {
    this.visible = false;
    let data = {
      value: item,
      type: 'open',
    };
    this.openPeopleAssignes.emit(data);
  }
  saveEntityDropdownData(field: any, table: any, datas: any) {
    let EntityDropdownData: any = [];
    let fields = this.getFields()?.map((e: any) => {
      if (e.option_type === 'ENTITY_DATA') {
        let data: any = {};
        data[`${e?.name}`] = datas[`${e?.name}`];
        data[`_${e?.name}`] = datas[`_${e?.name}`];
        let index = EntityDropdownData?.findIndex((e: any) =>
          e?.hasOwnProperty(e?.name)
        );
        if (index !== -1) {
          EntityDropdownData?.splice(index, 1);
        }
        EntityDropdownData?.push(data);
      }
      if (e.datatype === 'people') {
        let existing = e?.auto_assignee?.some(
          (e: any) => e?.efn === field?.name
        );
        if (!existing && field?.option_type !== 'ENTITY_DATA') {
          return;
        }
        if (e?.auto_assignee?.length > 0) {
          e?.auto_assignee?.map((e: any) => {
            if (e?.efn) {
              let data: any = {};
              data[`${e?.efn}`] = datas[`${e?.efn}`];
              let index = EntityDropdownData?.findIndex((e: any) =>
                e.hasOwnProperty(`${e?.efn}`)
              );
              if (index !== -1) {
                EntityDropdownData?.splice(index, 1);
              }
              EntityDropdownData?.push(data);
            }
          });
          setTimeout(() => {
            let combinedObject: any = {};
            EntityDropdownData?.forEach((obj: any) => {
              Object?.keys(obj)?.forEach((key) => {
                if (obj[key] !== undefined && obj[key] !== '') {
                  combinedObject[key] = obj[key];
                }
              });
            });
            let payload: any = {
              org_id: this.pservice?.appState()?.selectedOrganization?.org_id,
              process_id:
                this.pservice?.appState()?.selectedProcess?.process_id,
              process_name: this.pservice
                ?.appState()
                ?.selectedProcess?.process_name?.replace(/\s/g, '_'),
              entity_name:
                table === 'innerTable'
                  ? datas.entity_name
                  : this.pservice?.appState()?.selectedEntity,
            };
            payload['entity_data'] = combinedObject;
            e['entity_id'] = datas.entity_id;
            this.pservice
              .fetchAutoAssigneeUsers(payload)
              .subscribe((res: any) => {
                if (res) {
                  let data = res?.Results;
                  let defaultValueOption = data
                    ?.map((option: any) => option[e?.api_field])
                    ?.filter(Boolean);

                  if (defaultValueOption?.length > 0) {
                    const missingValues = defaultValueOption.filter(
                      (option: any) => !e.default.includes(option)
                    );
                    let newPeopleOption = [];
                    newPeopleOption.push(...missingValues);
                    this.updateDataOnStopEdit(
                      { target: { value: newPeopleOption } },
                      e.name,
                      e,
                      this.erulistData
                    );
                    this.pservice?.getMetaData();
                  }
                }
              });
          }, 100);
        }
      }
    });
  }
  @Output() tableSearchText = new EventEmitter<any>();
  resetTable: boolean = false;
  tableInputSearch: any;
  tabelSearch(text: string) {
    if (text.length >= 3) {
      this.pservice.tabelSearch = this.tableInputSearch;
      this.tableSearchText.emit(text);
      this.resetTable = true;
    } else if (this.resetTable) {
      this.pservice.tabelSearch = '';
      this.tableSearchText.emit(text);
      this.resetTable = false;
    }
  }
  isStatusClose: boolean = false;
  statusDefaultClosed(event: any, index: number, value: any) {
    this.show_closed_data = [];
    this.noOfStatusFields[index].showStatue = event;
    let count = 1;
    this.noOfStatusFields?.forEach((_status: any) => {
      if (!_status?.child && _status.showStatue) {
        let show_closed = `0___${_status?.statusFieldName}`;
        this.show_closed_data.push(show_closed);
      } else if (_status?.child && _status.showStatue) {
        let show_closed = `${count++}___${_status?.statusFieldName}`;
        this.show_closed_data.push(show_closed);
      }
    });
    this.pservice.updateStoreLayoutMetadata(
      this.show_closed_data,
      'show_closed'
    );
    if (!value.child) {
      this.convertToJson('top');
    } else if (this.innerTabledata && this.innerTableeid && value.child) {
      this.getInnerTableColumn(this.innerTabledata, this.innerTableeid);
    }
    this.isStatusClose = this.noOfStatusFields?.some(
      (e: any) => e?.showStatue === true
    );
  }

  noOfStatusFields: any = [];
  getEntityList() {
    const fields = this.getFields();
    let findEntity = fields
      ?.map((field: any, index: number) => {
        if (field?.datatype === 'entity') {
          let getEntity = this.erulistData?.entities
            ?.find((entity: any) => entity?.name === field?.name)
            ?.fields.find(
              (entityField: any) => entityField?.datatype === 'status'
            );
          if (getEntity) {
            return {
              label: field.label,
              name: field.name,
              showStatue: false,
              index: index,
              statusFieldName: getEntity.name,
              entityName: field.name,
              child: true,
            };
          } else {
            return;
          }
        } else {
          return;
        }
      })
      .filter(Boolean);
    let currentEntityStatus = this.erulistData?.entities
      ?.find((entity: any) => entity?.name === this.tabName)
      ?.fields?.find((entityField: any) => entityField?.datatype === 'status');

    if (currentEntityStatus) {
      let currentEntity = this.erulistData?.entities?.find(
        (entity: any) => entity?.name === this.tabName
      );
      findEntity.push({
        label: currentEntity?.display_name,
        name: currentEntity?.name,
        showStatue: false,
        index: -1,
        statusFieldName: currentEntityStatus.name,
        entityName: this.tabName,
        child: false,
      });
    }

    this.noOfStatusFields = findEntity?.sort(
      (a: any, b: any) => a?.index - b?.index
    );
    this.show_closed_data?.forEach((_e: any) => {
      if (_e) {
        let field_name = _e?.split('___');
        if (field_name[0] === '0') {
          let new_data = this.noOfStatusFields?.find(
            (_statusFields: any) =>
              !_statusFields?.child &&
              _statusFields?.statusFieldName === field_name[1]
          );
          if (new_data) {
            new_data.showStatue = true;
          }
        } else if (field_name[0] !== '0') {
          let new_data = this.noOfStatusFields?.find(
            (_statusFields: any) =>
              _statusFields?.child &&
              _statusFields?.statusFieldName === field_name[1]
          );
          if (new_data) {
            new_data.showStatue = true;
          }
        }
      }
    });
    this.isStatusClose = this.noOfStatusFields?.some(
      (e: any) => e?.showStatue === true
    );
    if (this.isStatusClose) {
      this.onfirstClick = false;
    }
  }

  customAction(data: any) {
    const entity_data =
      this.erulistData?.entities?.[
        this.erulistData.entities?.findIndex(
          (e: any) => e?.name === this.tabName
        )
      ];
    let body: any = {
      org_id: this.pservice?.appState()?.selectedOrganization?.org_id,
      process_id: this.pservice?.appState()?.selectedProcess?.process_id,
    };
    body['entity_data'] = entity_data?.fields?.reduce(
      (acc: any, field: any) => {
        acc[field.name] = data[field.name];
        // if(data[`_${field.name}`]){
        // acc[`_${field.name}`] = data[`_${field.name}`];
        // }
        return acc;
      },
      {}
    );
    if (entity_data?.custom_action?.length > 0) {
      entity_data.custom_action.map((_e: any) => {
        if (_e) {
          body['api_name'] = _e.apiname;
          // this.pservice.getApiFields(body).subscribe();
        }
      });
    }
  }
  getError(error: any) {
    return error;
  }
  filterCustomAction(tabName: string) {
    let data = this.pservice
      ?.appState()
      ?.metaData?.entities?.find(
        (_e: any) => _e?.name === tabName
      )?.custom_action;
    return data || [];
  }
  selectedCustomAction(
    selectedRowData: any,
    apiData: any,
    tab_name: string,
    data_from: string
  ) {
    // let tableHeader =this.getTableHeaders()

    // let entityData = tableHeader?.reduce((acc: any, _e: any) => {
    //   if(_e.datatype === 'time'){
    //     acc[_e?.name] = selectedRowData[_e?.name];
    //     acc[`${_e?.name}_st`] = selectedRowData[`${_e?.name}_st`];
    //     acc[`${_e?.name}_et`] = selectedRowData[`${_e?.name}_et`];
    //     return acc;
    //   }else{
    //     acc[_e?.name] = selectedRowData[_e?.name];
    //     return acc;
    //   }

    // }, {});

    let entityid = selectedRowData?.entity_id;
    let entityData: any;
    if (data_from === 'parrent') {
      entityData = this.pservice
        ?.appState()
        ?.metaData.data?.find((_e: any) => _e?.entity_id === entityid);
    } else if (data_from === 'child') {
      entityData = this.total_child_record?.find(
        (_e: any) => _e?.entity_id === entityid
      );
    }
    if (selectedRowData && apiData) {
      let body: any = {
        org_id: selectedRowData?.org_id,
        process_id: selectedRowData?.process_id,
        entity_name: tab_name,
        process_name: selectedRowData?.process_name?.replace(/\s/g, '_'),
        api_name: apiData.apiname,
        entity_id: [selectedRowData?.entity_id],
        entity_data: entityData,
        filter: apiData.filter,
        pfilter: apiData.pfilter,
        ca_filter: apiData?.ca_filter
        ,
      };
      //
      if (body?.pfilter) {
        body?.pfilter?.forEach((_e: any, index: number) => {
          body[`p${index + 1}_pen`] = _e?.pen;
          body[`p${index + 1}_ef`] = _e?.ef;
        });
      }
      let apiFields = this.pservice
        ?.appState()
        ?.apiData?.find(
          (_e: any) => _e?.api_name === apiData?.apiname
        )?.api_fields_metadata;

      if (apiFields?.fields?.length === 0) {
        body['params'] = {};
        this.pservice.executeApi(body).subscribe(() => {
          this.convertToJson('top');
          this.pservice.showNotificationSuccess(
            `Custom action trigger sucessfully.`
          );
        });
      } else {
        this.tableCustomAction.emit({ api: apiFields?.fields, body: body });
      }
    }
  }
  @Output() tableCustomAction = new EventEmitter<any>();
  @Input() selectedChekBox: any = [];
  getChecked(i: any) {
    return i;
  }
  groupSelect(data: any, defaultSelect: boolean) {
    let entityid = data?.map((_e: any) => {
      if (_e.entity_id) {
        this.selectIDs.emit({
          entity_id: _e?.entity_id,
          select_all: defaultSelect,
        });
      }
    });
  }
  getgroupChecked(selectedEntityId: any, data: any) {
    let entityid = data?.map((_e: any) => _e.entity_id);
    return entityid.every((id: any) => selectedEntityId.includes(id));
  }
  getDynamicWidth(name: string) {
    let gridlayout = this.gridlayout;
    let size = gridlayout?.find((_e: any) => _e?.name === name);
    return size ? `${size?.field_size}px` : '150px';
  }
  onResize({ width }: NzResizeEvent, col: string): void {
    if (width && width <= 150) {
      return;
    }
    this.gridlayout = this.gridlayout?.map((e: any) =>
      e?.name === col ? { ...e, field_size: `${width}` } : e
    );
    this.pservice.updateStoreLayoutMetadata(this.gridlayout, 'grid_layout');
    // this.pservice.updateStoreMetadata(this.gridlayout, 'gridWidth');
    // this.save_layout_meta_data();
  }
  save_layout_meta_data() {
    this.pservice.save_layout_meta_data(this.isSelectedMore);
  }
  // is_filter_on(){
  // let filter_new_data = this.filters.filter((item: any) => {
  //   return Object.entries(item).every(
  //     ([key, value]) => key === 'key2' || (value !== null && value !== '')
  //   );
  // });

  // if(filter_new_data?.length>0){
  //   return true
  // }
  //   return false
  // }

  get_user_access() {
    let UserIds =
      this.pservice?.appState()?.selectedProcess?.public___vw_org_process_users;
    let loggedInUser = this.pservice?.isUserLoggedIn()?.userDetail?.id;
    let user = UserIds?.find((u: any) => u?.user_id == loggedInUser);
    return user?.user_access;
  }
  show_error_field(header_data: any) {    
    if ((this.setStatusFilterData?.filter !== 'ERRORS' && header_data.name === 'error')) {
      return false;
    }
    return true;
  }
}
