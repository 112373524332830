<div class="contacts">
  <div
    nz-col
    [nzXs]="{ span: 24, offset: 0 }"
    [nzLg]="{ span: 10, offset: 0 }"
    class="m-auto"
  >
    <nz-row
      class="f-14 fw-400 lh-24 justifyCenter dgrey-text"
      style="padding-top: 20px"
      >You haven't added any Organization yet.</nz-row
    >
    <nz-row class="justifyCenter mt-30">
      <img src="../../../../../assets/images/contact.svg" />
    </nz-row>
    <nz-row class="justifyCenter mt-30">
      <div class="m-auto text-center f-10 fw-700 lh-18 cPointer">
        <button nz-button nzType="primary" (click)="addOrganization()">
          Add Organization
        </button>
      </div>
    </nz-row>
  </div>
</div>
