import { ChangeDetectorRef, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { ViewFileComponent } from '@processo/modules/organization/field-list/popup/view-file/view-file.component';
import { NotificationService } from '@processo/services/notification.service';
import { UserService } from '@processo/services/user.service';
import { StateService } from '@processo/shared/state/state.service';
import { cloneDeep } from 'lodash';
import * as moment from 'moment';
import { NZ_MODAL_DATA, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { Observable, map, shareReplay, tap } from 'rxjs';

@Component({
  selector: 'app-show-field-popup',
  templateUrl: './show-field-popup.component.html',
  styleUrls: ['./show-field-popup.component.scss']
})
export class ShowFieldPopupComponent {
  fields: any
  stateService = inject(StateService);
  cdr = inject(ChangeDetectorRef);
  getApiptionList$: any = new Observable();
  userService = inject(UserService);
  nzModalService = inject(NzModalService);
  isUniqueValue: boolean = true;
  disableFields: boolean = false;
  Number = Number;
  entityDataVals = [];
  getEntityptionList$: any = new Observable();
  apiChange: boolean = false;

  private _nt = inject(NotificationService);
  apiSearch: string = '';
  allowedDaysInPicker = [];
  today = new Date();
  moment: any = moment;
  addTagOption: any;
  disableTagOptionButton: boolean = false;
  Object: any;
  statuesColor: any = [
    {
      color: '#1DF1FF',
    },
    {
      color: '#56C0C6',
    },
    {
      color: '#FF1DE8',
    },
    {
      color: '#F70295',
    },
    {
      color: '#FFC01D',
    },
    {
      color: '#CF6BFF',
    },
    {
      color: '#E9FD00',
    },
    {
      color: '#FF8058',
    },
  ];
  priority: any = [
    {
      name: 'Urgent',
      color: 'rgb(227, 131, 136)'
    },
    {
      name: 'High',
      color: 'rgb(251, 203, 92)'
    },
    {
      name: 'Normal',
      color: 'rgb(135, 157, 255)'
    },
    {
      name: 'Low',
      color: 'rgb(101, 111, 125)'
    },
  ]
  mobileCountryCode: any;
  storemobileCountryCode: any;
  selectedCountryCode: string[] = [];
  editId: any = null;
  timerRange1: any;
  timerRange2: any;
  manualTime: any;
  selectedRTimeTabIndex: any = 0;
  defaultOpenValue = new Date(0, 0, 0, 0, 0, 0);
  filteredValues: any = [];
  dropdown_data: any = ['Shah Ruk', 'Hrithik Roshan', 'Salman Khan'];
  formInputValue:any;
  allowEdit:any;
  selectedProcessId:any;
  api_fields:any
  @Input() showCustomFieldData:any
  @Input() selectedCustomFrom:any
  ngOnInit(): void {
    this.formInputValue = this.showCustomFieldData?.formInputValue
    this.allowEdit = this.showCustomFieldData?.allowEdit
    this.selectedProcessId = this.showCustomFieldData.selectedProcessId
    this.api_fields = this.showCustomFieldData?.api_fields
    this.filteredValues = [...this.dropdown_data];
    this.fields =this.showCustomFieldData?.formInputValue;
    
    
  }
  checkIsUnique(field: any) {
    if (field.is_unique) {
      let body: any = {
        org_id: this.selectedProcessId.org_id,
        process_id: this.selectedProcessId.process_id,
        filter: {
          [field.name]: field.default,
        },
      };
      this.stateService.fetchUserDataUnique(body).subscribe((res: any) => {
        if (res.exists) {
          this.isUniqueValue = false;
          field.error = `${field.name} value already exists`;
          this.cdr.detectChanges();
        } else {
          this.isUniqueValue = true;
          field.error = '';
          this.cdr.detectChanges();
        }
      })
    }
  }
  textboxValidation(field: any, event: any) {
    let inputText = event;
    let length = Number(field?.data_length);
    if (event?.length > 0) {
      if (field?.data_length_check === 'MAX') {
        if (inputText?.length > length) {
          field.error = `Maximum value is ${field?.data_length}`;
        } else {
          field.error = '';
        }
      } else if (field?.data_length_check === 'MIN') {
        if (inputText?.length < length) {
          field.error = `Minimum value is ${field?.data_length}`;
        } else {
          field.error = '';
        }
      } else if (field?.data_length_check === 'EXACT') {
        if (inputText?.length !== length) {
          field.error = `Exact value is ${field?.data_length}`;
        } else {
          field.error = '';
        }
      } else {
        field.error = '';
      }
    } else {
      field.error = '';
    }
  }
  emailValidation(field: any, event: any) {
    let inputText = event;
    const emailPattern = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/g;
    if (!emailPattern.test(inputText)) {
      field.error = 'Invalid email format';
    } else {
      field.error = '';
    }
  }
  openDropDownEntityChange(f: any, search = '', event?: any) {
    if (event) {
      let body: any = {
        org_id: this.stateService.appState().selectedOrganization.org_id,
        process_id: this.stateService.appState().selectedProcess.process_id,
        entity_name: f.entity_name,
        process_name: this.stateService
          .appState()
          .selectedProcess.process_name?.replace(/\s/g, '_'),
        field_name: f.field_name,
        limit: 50,
        skip: 0,
        field_str: search,
      };

      if (f.def) {
        let filterValue = this.fields.find(
          (e: any) => e.name === f.def
        ).default;
        let val: any = [];
        if (Array.isArray(filterValue)) {
          val = [...filterValue];
        } else {
          val = [filterValue || ''];
        }

        if (
          this.fields.find((e: any) => e.name === f.def)?.datatype ==
          'dropdown_single_select'
        ) {
          body['filter'] = {
            [f.dpef]: val[0],
          };
        } else {
          body['filter'] = {
            [`$in`]: val,
          };
        }
      }
      this.getEntityptionList$ = this.userService.getentityfielddata(body).pipe(
        shareReplay(),
        map((r: any) => r[0].entity_data),
        tap((res: any) => {
          this.entityDataVals = res;
        })
      );
    }
  }
  openDropDownApiChange(f: any, search = '', event?: any) {
    if (event) {
      let body: any = {
        org_id: this.stateService.appState().selectedOrganization?.org_id,
        entity_id:this.selectedCustomFrom === 'table'?this.showCustomFieldData?.selectedProcessId?.entity_id:this.showCustomFieldData?.selectedProcessId?.entity_id
      };

      body['api_name'] = f.api_name;
      body['process_id'] = this.stateService.appState().selectedProcess.process_id;
      if (f.def) {
        let filterValue = this.fields?.find(
          (e: any) => e.name === f.def
        ).default;

        let val: any = [];
        if (Array.isArray(filterValue)) {
          val = [...filterValue];
        } else {
          val = [filterValue || ''];
        }

        body['filter'] = {
          [`$in`]: val,
        };
      }
      this.getApiptionList$ = this.userService.getApiFields(body).pipe(
        shareReplay(),
        map((res: any) => res?.org?.map((e: any) => e[f.api_field]))
      );
    }
  }

  openDropDownApiChanges(f: any, search = '', event?: any) {
    if (event) {
      let body: any = {
        org_id: this.stateService.appState().selectedOrganization?.org_id,
        entity_id:this.selectedCustomFrom === 'table'?this.showCustomFieldData?.selectedProcessId?.entity_id:this.showCustomFieldData?.selectedProcessId?.entity_id
      };

      body['api_name'] = f.api_name;
      body['process_id'] = this.stateService.appState().selectedProcess.process_id;
      if (search.length >= 3) {
        this.getApiptionList$ = this.userService.getApiFields(body).pipe(
          shareReplay(),
          map((res: any) => {
            let options = res?.org.map((e: any) => e[f.api_field]);
            if (search.trim() !== '') {
              this.apiChange = true;
              options = options.filter((option: string) =>
                option.toLowerCase().includes(search.toLowerCase())
              );
            }
            return options;
          })
        );
      } else if (search.length === 2 && this.apiChange) {
        this.getApiptionList$ = this.userService.getApiFields(body).pipe(
          shareReplay(),
          map((res: any) => {
            this.apiChange = false;
            let options = res?.org.map((e: any) => e[f.api_field]);
            return options;
          })
        );
      }
    }
  }
  updateFieldEntity(e: any, f: any, index1: any) {
    let id: any = this.entityDataVals.find(
      (entity: any) => entity.entity_data == e
    );
    let bod = {
      [`_${f.name}`]: id.entity_id,
    };
    let index = this.formInputValue.findIndex((E: any) => E.name == f.name);

    setTimeout(() => {
      this.formInputValue[index] = { ...this.formInputValue[index], ...bod };
      this.fields[index] = { ...this.fields[index], ...bod };
    }, 100);
  }
  emitSelectValues(name: any, value: any) {
    let body = {
      fieldName: name,
      value: value.toLocaleString(),
    };
    // this.saveInputField.emit(body);
  }
  getApiOption(value: any) {
    if (this.apiSearch.length > 0) {
      let result = value.filter((e: any) => {
        return e
          .toString()
          .toLowerCase()
          .includes(this.apiSearch.toLowerCase());
      });
      return result;
    } else {
      return value;
    }
  }
  websiteValidation(field: any, event: any) {
    let inputText = event;
    const websitePattern =
      /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/g;
    if (!websitePattern.test(inputText)) {
      field.error = 'Invalid website format';
    } else {
      field.error = '';
    }
  }
  updateInputValues(name: any, value: any, field: any) {
    let numericValue: any;
    if (field.datatype === 'number' || field.datatype === 'currency') {
      numericValue = Number(value.replace(/,/g, '').split('.')[0]);
    } else {
      numericValue = value;
    }

    field.default = numericValue.toLocaleString();
    this.formatNumber(field);
  }
  formatNumber(field: any) {
    const inputValue = field.default;
    if (field.seperator && inputValue) {
      // const numericValue = Number(inputValue);
      const numericValue = Number(inputValue?.replace(/,/g, ''));
      if (numericValue) {
        let separator = '';
        if (field.seperator.toLowerCase() === 'millions') {
          separator = 'en-US';
        } else if (field.seperator.toLowerCase() === 'thousands') {
          separator = 'en-IN';
        } else {
          separator = '';
        }
        const formattedValue = this.formatNumberWithSeparator(
          numericValue,
          separator,
          field.decimal
        );
        field.default = formattedValue;
      }
    }
  }
  formatNumberWithSeparator(
    value: number,
    format: string,
    decimal: number
  ): string {
    if (format) {
      const formattedValue = value.toLocaleString(format, {
        maximumFractionDigits: decimal,
        minimumFractionDigits: decimal,
      });
      return formattedValue;
    } else {
      return value.toFixed(decimal || 0);
    }
  }
  numberValidation(field: any, val: any) {
    let result = field.mandatory && !val ? true : false;
    if (result) {
      field.error = field.label + ' is required';
    } else {
      this.numberFieldValidation(field, field.default)
    }
  }
  numberFieldValidation(field: any, event: any) {
    let inputText = event?.replace(/[-.]/g, '');
    let length = Number(field?.num_val);
    if (event?.length > 0) {
      if (field?.num_val_check === 'MAX') {
        if (inputText?.length > length) {
          field.error = `Maximum value is ${field?.num_val}`;
        } else {
          field.error = '';
        }
      } else if (field?.num_val_check === 'MIN') {
        if (inputText?.length < length) {
          field.error = `Minimum value is ${field?.num_val}`;
        } else {
          field.error = '';
        }
      } else {
        field.error = '';
      }
    } else {
      field.error = '';
    }
  }
  getHoverValue(c: any, i: any) {
    if (i + 1 <= c.hoverValue) {
      return true;
    } else {
      return false;
    }
  }
  emojiType: any = [
    {
      value: '(smile)',
      checked: false,
      img1: '../../../../../assets/images/addNewFieldIcons/whiteSmiley.svg',
      img2: '../../../../../assets/images/addNewFieldIcons/blueSmiley.svg',
    },
    {
      value: '(tick)',
      checked: false,
      img1: '../../../../../assets/images/addNewFieldIcons/whiteTick.svg',
      img2: '../../../../../assets/images/addNewFieldIcons/blueTick.svg',
    },
    {
      value: '(like)',
      checked: false,
      img1: '../../../../../assets/images/addNewFieldIcons/whiteLike.svg',
      img2: '../../../../../assets/images/addNewFieldIcons/blueLike.svg',
    },
    {
      value: '(star)',
      checked: false,
      img1: '../../../../../assets/images/addNewFieldIcons/whitestar.svg',
      img2: '../../../../../assets/images/addNewFieldIcons/bluestar.svg',
    },
  ];
  getEmojiImage(value: any, data: any) {
    let result = this.emojiType.find((e: any) => e.value === value);
    if (result) {
      return data === 'blue' ? result.img1 : result.img2;
    } else {
      return data === 'blue' ? this.emojiType[0].img1 : this.emojiType[0].img2;
    }
  }
  datePickerOpen(e: any, allowdays: any) {
    if (e) {
      this.allowedDaysInPicker = allowdays;
    } else {
      this.allowedDaysInPicker = [];
    }
  }
  disabledDate = (current: Date): boolean => {
    let days = {
      Mon: '1',
      Tue: '2',
      Wed: '3',
      Thu: '4',
      Fri: '5',
      Sat: '6',
      Sun: '0',
    };
    let allowedDays = [];
    if (this.allowedDaysInPicker.length == 0) {
      return false;
    } else {
      for (let i of this.allowedDaysInPicker) {
        if (days[i]) {
          allowedDays.push(Number(days[i]));
        }
      }
      return !allowedDays.includes(current.getDay());
    }
  };
  dateUpdate(e: any, field: any) {
    field.default = moment(e).format('YYYY-MM-DD');
    field.error = '';
  }
  parentTabName:any
  saveTagOption(field: any) {
    if((this.addTagOption?.length > 0)){
      return
    }
    this.disableTagOptionButton = true;
    let addOption = {
      name: `${this.addTagOption}`,
      color: '',
    };
    let fields = field.options.push(addOption);
    let payload = {
      org_id: this.stateService.appState().selectedOrganization.org_id,
      process_id: this.stateService.appState().selectedProcess.process_id,
      entity_name: this.parentTabName,
      field: field,
      parent_entity: field.entity_name || '',
      child_field: field.name || '',
      option_type: field.option_type || '',
      parent_field: field.field_name || '',
      f_name: field.name,
    };
    if (this.addTagOption.length > 0) {
      this.addTagOption = '';
      this.stateService.saveAddColumn(payload).subscribe((res: any) => {
        this.addTagOption = '';
        this.disableTagOptionButton = false;
        let copy = cloneDeep(this.stateService.appState());
        let alterdata = copy.metaData.entities.find(
          (e: any) => e.name === payload.entity_name
        );
        let fields = alterdata.fields.find(
          (e: any) => e.name == payload.field.name
        );
        for (let i of this.Object.keys(payload.field)) {
          fields[i] = payload.field[i];
        }
        this.stateService.state.update((user) => ({
          ...user,
          appState: { ...copy },
        }));
      });
    }
  }
  getColor(value: any) {
    let color = this.priority.find((e: any) => e.name === value).color
    return color || ''
  }
  getOptionStatus(f: any) {
    let arr: any = [];
    if (f.open_status?.length > 0) {
      arr = [...arr, ...f.open_status];
    }
    if (f.close_status?.length > 0) {
      arr = [...arr, ...f.close_status];
    }
    return arr;
  }
  getStatusColor(value: any, field: any) {
    let result = field;
    let color = result.find((e: any) => {
      return e.name === value.nzValue;
    })?.color;
    return color || '';
  }
  getStatusColors(value: any, field: any) {
    let result = field;
    let color = result.find((e: any) => {
      return e.name === value;
    })?.color;
    return color || '';
  }
  downloadAttachment(file_name: any) {
    let payload = {
      file_name: file_name,
      org_process_id: this.stateService.appState().selectedProcess.org_process_id,
    };
    this.stateService.downloadFile(payload).subscribe((res: any) => {
      if (res) {
        let file = res.file;
        let type = res.file_type
        let fileName = file_name;
        let decodedString = window.atob(file);
        this.openFile(file, type, fileName);

      }
    });
  }
  openFile(file: any, type: any, fileName: any) {
    const data = {
      file: file,
      type: type,
      fileName: fileName,
    };
    if (!this.isSupportedFileType(type)) {
      this.downloadFile(data,fileName);
      return
    }
    let popup = this.nzModalService.create({
      nzWidth: '400px',
      nzTitle: '',
      nzContent: ViewFileComponent,
      nzMaskClosable: true,
      nzFooter: null,
      nzClosable: true,
      nzData: {
        data: data,
      },
      nzClassName: 'viewFile',
    });
  }
  supportedFileTypes: string[] = ['application/pdf', 'image/jpeg', 'image/png'];
  isSupportedFileType(fileType: string): boolean {
    return this.supportedFileTypes.includes(fileType);
  }
  async downloadFile(data:any,fileName:any): Promise<void> {
    await this.saveFile(data,fileName);
  }
  async saveFile(res: any, name: any): Promise<void> {
    let link = document.createElement('a');
    link.href = `data:${res.type};base64,${res.file}`;
    link.download = name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  getShortenName(field: any) {
    let newFilename = field?.split("_")?.pop();
    return newFilename
  }


  async handleChange(e: any, field: any) {
    setTimeout(async () => {
      const files = e.target.files;
      const filesArray = Array.from(files);

      const uploadPromises = filesArray.map((file: any) => {
        const reader = new FileReader();
        const fileName = file?.name?.replace(/_/g, '');

        return new Promise<void>((resolve, reject) => {
          reader.onload = () => {
            const base64String = reader.result as string;
            const removeBase64Text = base64String?.split(',')?.[1];
            this.uploadAttachment(fileName, removeBase64Text, field)
              .then(resolve)
              .catch(reject);
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      });

      try {
        await Promise.all(uploadPromises);
      } catch (error) {
        console.error('File upload failed', error);
      }
    }, 100);
  }
  uploadAttachment(name: any, file: any, field: any): Promise<void> {
    let body = {
      file_name: name,
      org_process_id: this.stateService.appState().selectedProcess.org_process_id,
      file: file,
    };

    return new Promise<void>((resolve, reject) => {
      this.stateService.uploadFile(body).subscribe((res: any) => {
        if (res) {
          if (field.default === undefined) {
            field.default = [];
          }
          field.default.push(res.file_name);
        this.cdr.detectChanges();
          resolve();
        } else {
          reject(new Error('Upload failed'));
        }
      }, reject);
    });
  }
  mobileCode() {
    let body = {
      org_id: this.stateService.appState().selectedOrganization?.org_id,
      api_name: 'Phone Code',
      process_id: this.stateService.appState().selectedProcess.process_id,
      entity_id:this.selectedCustomFrom === 'table'?this.showCustomFieldData?.selectedProcessId?.entity_id:this.showCustomFieldData?.selectedProcessId?.entity_id
    };
    if (!this.mobileCountryCode) {
      this.userService.getMobileCountryCode(body).subscribe((res: any) => {
        if (res) {
          this.mobileCountryCode = res?.org;
          this.mobileCountryCode.sort((a: any, b: any) => {
            const codeA = parseInt(a.PhoneCode.replace(/[^\d]/g, ''), 10);
            const codeB = parseInt(b.PhoneCode.replace(/[^\d]/g, ''), 10);
            return codeA - codeB;
          });
          this.storemobileCountryCode = this.mobileCountryCode;
        }
      });
    } else {
      this.mobileCountryCode = this.storemobileCountryCode;
    }
  }
  setMobileNumber(data: any, countryCode: any, input: any, i: any) {
    if(!countryCode){
      countryCode = '+91'
    }

    this.selectedCountryCode[i] = countryCode;
    // }

    // let combineNumber = `${countryCode} - ${input.value?.length>0 ?input.value?.length :data?.default.split('-')[1]?.trim()}`;

    // if(!this.defaultEdit){
    if (input.value.length > 0) {
      data.error = '';
    } else {
      data.error = 'phone is required';
    }
    let combineNumber = `${countryCode} - ${input.value}`;
    data.default = combineNumber;
    // }
  }
  getMobileNumber(field: any, i: any) {
    return field?.default?.split('-').map((e: any) => e?.trim() || '');
  }
  filtercountryCode(value: any) {
    this.mobileCountryCode = this.storemobileCountryCode.filter((e: any) =>
      e.CountryName.toLowerCase().includes(value.toLowerCase()) ||    e.PhoneCode.toLowerCase().includes(value.toLowerCase())
    );
  }
  getCountryCode(mobileCountryCode: any) {
    return mobileCountryCode;
  }
  checkPeopleVald(f: any) {
    if (f.default.length > 0) {
      f.error = '';
    }
  }
  stopEdit() {
    this.editId = null;
  }
  startEdit(field: any) {
    this.selectAPIEmitter({ action: true, people: 'people' });
    this.editId = `${field.api_field},${field.grid_index}`;
  }
  selectAPIEmitter(e: any) {
    if (e.action === true) {
      this.selectorEmitterAPI(e);
    }
  }
  selectorEmitterAPI(e: any) {
    let body: any = {
      org_id: this?.userService?.id,
      entity_id:this.selectedCustomFrom === 'table'?this.showCustomFieldData?.selectedProcessId?.entity_id:this.showCustomFieldData?.selectedProcessId?.entity_id
    };

    if (e.data) {
      body['api_name'] = e.data;
      body['process_id'] = this.selectedProcessId?.process_id;
      this.userService.getApiFields(body).subscribe({
        next: (res: any) => {
          this.api_fields = Object.keys([...res.org][0]).filter(
            (item: any) => item != 'rowIndex'
          );
        },
      });
    } else if (e.people === 'people') {
      body['api_name'] = 'People';
      body['process_id'] = this.selectedProcessId?.process_id;
      this.userService?.getApiFields(body).subscribe({
        next: (res: any) => {
          this.api_fields = res.org;
        },
      });
    } else {
      body['process_id'] = this.selectedProcessId?.process_id;
      this.userService.getApis(body).subscribe({
        next: (res: any) => {
          let apis = res.filter((item: any) =>
            Object.keys(item).includes('apis')
          )[0];

          // this.select_apis = apis.apis;
        },
      });
    }
  }
  getPeopleOption(data: any) {
    let getUserData = data.api_field;
    if (this.api_fields && data.api_field) {
      let optionValues = this.api_fields.map((e: any) => e[getUserData]);
      return optionValues.filter((_e:any)=>_e!=='');
    } else {
      return [];
    }
  }
  shortenName(name: string) {
    const words = name?.trim()?.split(' ');

    if (words?.length === 1) {
      return words[0]?.charAt(0);
    } else if (words?.length >= 2) {
      return words[0]?.charAt(0) + words[words?.length - 1]?.charAt(0);
    } else {
      return '';
    }
  }
  onOpenTime(d: any) {

    this.manualTime = (Number(d.default?.[`${d.name}`]) || 0).toFixed(0) || 0;
    this.timerRange1 = d.default?.[`${d.name}_st`] || null;
    this.timerRange2 = d.default?.[`${d.name}_et`] || null;
    if (this.manualTime && this.timerRange1 && this.timerRange2) {
      this.selectedRTimeTabIndex = 1
    } else if (this.manualTime && !this.timerRange1 && !this.timerRange2) {
      this.selectedRTimeTabIndex = 0
    } else {
      this.selectedRTimeTabIndex = 0
    }
  }
  getTimeValue(d: any) {

    let totalMinutes = Number(d.default[d.name]);
    let hours = Math.floor(totalMinutes / 60);
    let remainingMinutes = (totalMinutes - hours * 60) % 60;
    if (remainingMinutes > 60) {
      hours += Math.floor(remainingMinutes / 60);
      remainingMinutes %= 60;
    }

    return `${(hours || 0).toString().padStart(1, '0')} hr ${(remainingMinutes || 0)?.toFixed(0)} min`;
  }
  saveTime(field: any) {
    if (this.selectedRTimeTabIndex === 0) {
      this.timerRange1 = '';
      this.timerRange2 = '';
      field.default[field.name] = Number(this.manualTime);
      field.default[`${field?.name}_st`] = ''
      field.default[`${field?.name}_et`] = ''

    }
    if (this.selectedRTimeTabIndex === 1) {
      let startTime = moment.duration(this.timerRange1).asMinutes();
      let endTime = moment.duration(this.timerRange2).asMinutes();
      if (startTime > endTime) {
        this.manualTime = Math.abs((1440 - startTime) + endTime);
      } else {
        this.manualTime = Math.abs(endTime - startTime);
      }
      field.default[field.name] = this.manualTime;
      field.default[`${field?.name}_st`] = this.timerRange1 ? moment.duration(this.timerRange1).asMilliseconds() : ''
      field.default[`${field?.name}_et`] = this.timerRange2 ? moment.duration(this.timerRange2).asMilliseconds() : ''
    }
    let datas: any = {}
    datas[`${field?.name}`] = this.manualTime ? this.manualTime : '';
    datas[`${field?.name}_st`] = this.timerRange1 ? moment.duration(this.timerRange1).asMilliseconds() : ''
    datas[`${field?.name}_et`] = this.timerRange1 ? moment.duration(this.timerRange2).asMilliseconds() : ''
    if (
      field.default[`${field.name}_st`].length > 0 &&
      field.default[`${field.name}_et`]
    ) {
      field.error = '';
    }
    this.manualTime = null;
    this.timerRange1 = null
    this.timerRange2 = null
   if(field.default[field.name] !==0){
    field.error = '';
   }
  }

  hasError() {
    let hasError = false
    for (const field of [...this.formInputValue]) {
      if (field.datatype == 'date') {
        if (field.default == 'Current_Date') {
          field.default = new Date(this.today).toISOString().split('T')[0];
          field.error = '';
        }
        // else if(field.datatype == 'phone'){
        //   if (field.default) {
        //     field.default =
        //   }
        // }
        else {
          if (field.mandatory && !field.default) {
            field.error = field.label + ' is required';
            hasError = true;
          } else {
          }
        }
      } else if (field.datatype == 'checkbox') {
        if (field.default) {
          field.error = '';
        }
      } else if (field.datatype == 'progress') {
        if (field.default.length !== 0) {
          field.error = '';
        } else {
          if (field.mandatory) {
            field.error = field.label + ' is required';
            hasError = true;
          }
        }
      } else if (field.datatype == 'people') {
        if (field.default.length !== 0 && !(field?.default?.length === 1 && field?.default?.[0] ==='')) {
          field.error = '';
        } else {
          if (field.mandatory) {
            field.error = field.label + ' is required';
            hasError = true;
          }
        }
      } else if (field.datatype == 'rating') {
        if (field.default.length !== 0) {
          field.error = '';
        } else {
          if (field.mandatory) {
            field.error = field.label + ' is required';
            hasError = true;
          }
        }
      } else if (field.datatype === 'time') {
        if (field.mandatory) {
          if (
            field.default[field.name]?.length > 0 ||
            (field.default[`${field.name}_st`]?.length > 0 &&
              field.default[`${field.name}_et`] > 0) ||field?.default[field?.name]?.toString()?.length > 0
          ) {
            field.error = '';
          } else {
            field.error = field.label + ' is required';
            hasError = true;
          }
        }
      } else if (
        field.mandatory &&
        !field.default?.length &&
        field.datatype !== 'time'
      ) {
        field.error = field.label + ' is required';
        hasError = true;
      }else if (
        field.mandatory &&
        [
          'dropdown_single_select',
          'people',
          'dropdown_multi_select',
          'status',
          'tag',
          'priority',
        ].includes(field.datatype) &&
        field.default?.length === 1 &&
        field.default[0] === ''
      ) {
        field.error = field.label + ' is required';
        hasError = true;
      }

      // else if((field.datatype === 'textbox' || field.datatype === 'phone'|| field.datatype === 'website' || field.datatype === 'email') && field.default.length>0){
      //   this.checkIsUnique(field)
      // }
    }
    for (let i of this.formInputValue) {
      if (i.error) {
        hasError = true;
        break;
      }
    }
    return hasError
  }
  setDefaultValue(field: any) {
    if(field.datatype ==='checkbox'){
      return field.default
    }
    if (
      ['currency', 'progress', 'number', 'rating', 'date', 'time'].includes(
        field.datatype
      )
    ) {
      if(field.default){
        return field.default
      }
      return null;
    } else if (
      ['email', 'location', 'phone', 'textarea', 'textbox', 'website','dropdown_single_select','priority','status'].includes(
        field.datatype
      )
    ) {
      if(field.default){
        return field.default
      }
      return '';
    } else if (
      [
        'dropdown_multi_select',
        'people',
        'tag',
      ].includes(field.datatype)
    ) {
      if(field.default.length === 0){
        return [''];
      }else{
        return field.default
      }
      
    }

    return '';
  }
  saveField() {
    if(this.allowEdit){
      return
    }
    if (this.hasError()) { } else {
      let userValue: any = {}
      for (let i of this.formInputValue) {
        if(((i?.default === "") || (i?.default?.length === 0)) && (i?.datatype !== 'time') && (i?.datatype !== 'phone')){
          i.default= this.setDefaultValue(i)
        }
        if(i?.datatype ==='number' || i?.datatype ==='currency' || i?.datatype ==='progress' || i?.datatype ==='rating'     ){
          userValue[i.name] = i.default?Number(i.default):i.default
        }else{
          userValue[i.name] = i.default
        }

        if (i[`_${i.name}`]) {
          userValue[`_${i.name}`] = i[`_${i.name}`]
        }
      }

      const oldUserData: any = {};
      const newUserData: any = {};

      Object.keys(userValue).forEach(key => {
        if (this.userService?.processUserId?.data.hasOwnProperty(key)) {
          if (userValue[key] !== this.userService.processUserId.data[key]) {
            oldUserData[key] = this.userService.processUserId.data[key];
            newUserData[key] = userValue[key];
          }
        } else {
          if(userValue[key]){
            newUserData[key] = userValue[key];
          }

        }
      });
      this.closeOnAction({action:'close',data:newUserData})
      // if (Object.keys(payload.old_user_data).length == 0 && Object.keys(payload.user_data).length == 0) {
      //   this._nt.show('Success', '', 'Field List added sucessfully');
      //   this.closeOnAction('close')
      // } else {
      //   this.userService.saveUserData(payload).subscribe((res) => {
      //     this._nt.show('Success', '', 'Field List added sucessfully');

      //     this.closeOnAction('success')
      //   })
      // }

    }






  }
  // modalRef=inject(NzModalRef)
  cancel() {
    this.closeOnAction({action:'close'})
  }
  modalService= inject(NzModalService)

  hexToRgba(hex: string, alpha: number): string {
    hex = hex.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  convertToString(value:any){
    return value?.toString()
  }
  @Output()closeCustomApiParameter = new EventEmitter<any>()
  closeOnAction(data:any){
    this.closeCustomApiParameter.emit({data})
  }
}
