import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { generateUUID } from 'projects/processo-custom-table/src/usecase';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'lib-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationComponent {
  @Input() header: any;
  @Input() i: any;
  @Input() data: any;
  @Input() parent: any;
  ngOnInit(): void {}
  @Input() innerTable: any;
  @HostBinding('attr.unique-id')
  uniqueId = `${generateUUID()}`;
  editval$: any;
  editval = new BehaviorSubject<boolean>(false);
  constructor(private cdr: ChangeDetectorRef) {
    this.editval$ = this.editval;
  }
  error = '';
  editing() {
    if(!this.header.can_edit ||this.data?.is_locked || this.data?.lock_fields?.includes(this.header?.name)){
      return
    }
    if (this.editval.getValue()) {
      this.editval.next(false);
    } else {
      this.editval.next(true);
    }
    this.cdr.markForCheck();
  }
  checkValidation(e: any) {
    this.error = this.textboxValidation(this.header, e.target.value);
    if (!this.error) {
      let tableEdit: any;
      if (this.innerTable) {
        tableEdit = 'innerTable';

        this.parent.updateDataOnInnerStopEdit(e, this.header.name, this.data);
      } else {
        this.parent.updateDataOnStopEdit(
          e,
          this.header.name,
          this.data,
          this.parent.erulistData
        );
      }
      this.editval.next(false);
      this.parent.saveEntityDropdownData(this.header, tableEdit, this.data);
    }
  }
  textboxValidation(field: any, event: any) {
    let inputText = event;
    if (field.mandatory && !inputText) {
      return `${field?.label} is mandatory`;
    } else {
      return '';
    }
  }
}
