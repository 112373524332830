<div class="px-10 textStyle"  fluidRowWidth [type]="header.name" [style.pointerEvents]="(header.dfc && !header.editable) || parent.state?.tableDiable === 'ERRORS' ||data?.is_locked || data?.lock_fields?.includes(this.header?.name) ? 'none':'auto'">
  <div  style="width: 100%;height:100%;" class="field_hover">
    <div class="editable-cell p-0 word-break-all align-center min-height-37 " (click)="editing()"
      *ngIf="!(editval$|async)" style="display: flex;
  justify-content: end;">
    {{ header.symbol ? header.symbol + " " : "" }} &nbsp;  {{
      data[header.name]
      ? parent.formatNumber(data[header.name], header)
      :parent.formatNumber(header.default, header) 
      ? parent.formatNumber(header.default, header) 
      : "&nbsp;&nbsp;&nbsp;"
      }}
    </div>
    <nz-form-control *ngIf="(editval$|async)" style="display: flex;
  justify-content: end;" class="p-0">
      <nz-input-group>
        <input auto-focus-input class="text-end" style="padding: 2px;"[nzStatus]="error?'error':''"
        oninput="this.value = this.value.replace(/(?!^-)[^0-9.]+/g, '').replace(/(\..*)\./g, '$1').replace(/(?!^)-/g, '');" type="text" nz-input
          [(ngModel)]="data[header.name]"   (focusout)="checkValidation( $event)"/>
      </nz-input-group>
      <div style="color: red ;font-size: smaller;text-align: left;">
        {{error}}
        </div>
    </nz-form-control>
  </div>
</div>
