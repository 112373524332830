import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import * as moment from 'moment';
import { Observable, map, shareReplay } from 'rxjs';
import { ProcessoCustomTableService } from '../../processo-custom-table.service';

@Component({
  selector: 'lib-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterComponent implements OnInit, OnChanges {
  constructor(private tableService: ProcessoCustomTableService) {}

  moment = moment;
  @Input() state: any;
  @Input() filters: any = [{ key1: '', key2: '', key3: '', key4: true }];
  @Input() erulistData: any;
  @Input() tabName: any;
  @Input() parent: any;
  @Output() onSaveFilter: EventEmitter<any> = new EventEmitter();
  @Output() resetFilter: EventEmitter<any> = new EventEmitter();
  @Output() logFilterData = new EventEmitter<any>();
  @Input()entitiesData:any

  Array = Array;
  datatypesOperator: any = {
    dropdown_single_select: [
      { label: 'in', value: '$in' },
      { label: 'not in', value: '$nin' },
    ],
    dropdown_multi_select: [
      { label: 'in', value: '$jin' },
      { label: 'not in', value: '$jnin' },
    ],
    status: [
      { label: 'in', value: '$in' },
      { label: 'not in', value: '$nin' },
    ],
    location: [
      { label: 'is', value: '' },
      { label: 'is not', value: '$ne' },
    ],
    textarea: [
      { label: 'contains', value: '$like' },
      { label: 'does not contains', value: '$nlike' },
    ],
    checkbox: [
      { label: 'is selected', value: 'true' },
      { label: 'is not selected', value: 'false' },
    ],
    rating: [
      { label: '=', value: '' },
      { label: '<>', value: '$ne' },
      { label: '>=', value: '$gte' },
      { label: '<=', value: '$lte' },
      { label: '>', value: '$gt' },
      { label: '<', value: '$lt' },
    ],
    currency: [
      { label: '=', value: '' },
      { label: '<>', value: '$ne' },
      { label: '>=', value: '$gte' },
      { label: '<=', value: '$lte' },
      { label: '>', value: '$gt' },
      { label: '<', value: '$lt' },
    ],
    number: [
      { label: '=', value: '' },
      { label: '<>', value: '$ne' },
      { label: '>=', value: '$gte' },
      { label: '<=', value: '$lte' },
      { label: '>', value: '$gt' },
      { label: '<', value: '$lt' },
    ],
    labels: [
      { label: 'is', value: '$in' },
      { label: 'is not', value: '$nin' },
    ],
    website: [
      { label: 'is', value: '' },
      { label: 'is not', value: '$ne' },
      { label: 'contains', value: '$like' },
      { label: 'does not contains', value: '$nlike' },
    ],
    people: [
      { label: 'in', value: '$jin' },
      { label: 'not in', value: '$jnin' },
    ],
    phone: [
      { label: 'is', value: '' },
      { label: 'is not', value: '$ne' },
      { label: 'contains', value: '$like' },
      { label: 'does not contains', value: '$nlike' },
    ],
    progress: [
      { label: '=', value: '' },
      { label: '<>', value: '$ne' },
      { label: '>=', value: '$gte' },
      { label: '<=', value: '$lte' },
      { label: '>', value: '$gt' },
      { label: '<', value: '$lt' },
    ],
    date: [
      { label: 'is', value: '' },
      { label: 'is not', value: '$ne' },
      { label: 'is after or equal', value: '$gte' },
      { label: 'is before or equal', value: '$lte' },
      { label: 'is after', value: '$gt' },
      { label: 'is before', value: '$lt' },
    ],
    textbox: [
      { label: 'is', value: '' },
      { label: 'is not', value: '$ne' },
      { label: 'contains', value: '$like' },
      { label: 'does not contains', value: '$nlike' },
    ],
    email: [
      { label: 'is', value: '' },
      { label: 'is not', value: '$ne' },
      { label: 'contains', value: '$like' },
      { label: 'does not contains', value: '$nlike' },
    ],
    tag: [
      { label: 'in', value: '$jin' },
      { label: 'not in', value: '$jnin' },
    ],
    time: [
      { label: '=', value: '' },
      { label: '<>', value: '$ne' },
      { label: '>=', value: '$gte' },
      { label: '<=', value: '$lte' },
      { label: '>', value: '$gt' },
      { label: '<', value: '$lt' },
    ],
    priority: [
      { label: 'in', value: '$in' },
      { label: 'not in', value: '$nin' },
    ],
  };
  showFiltersLog!: boolean;
  @Output() statusFilters = new EventEmitter<any>();
  @Input() isSelectedMore!: any;
  @Input() tableMetaData!: any;
  @Input() environmentType!: any;
  statusFilterOption: any = [
    {
      label: 'PENDING APPROVAL',
      value: 'PENDING APPROVAL',
    },
    {
      label: 'PARTIALLY APPROVED',
      value: 'PARTIALLY APPROVED',
    },

    {
      label: 'REJECTED',
      value: 'REJECTED',
    },
    {
      label: 'DRAFT',
      value: 'DRAFT',
    },

  {
    label: 'APPROVED',
    value: 'APPROVED',
  },
  {
    label: 'ERRORS',
    value: 'ERRORS',
  },
  ];
  isSelectedMoreDefault: any;
  ngOnInit(): void {

    if (this.isSelectedMoreDefault !== this.isSelectedMore) {
      this.statusValue = '';
      this.approvalStatus = '';
      this.statusFilter = [];
    }
    this.isSelectedMoreDefault = this.isSelectedMore;

  }
  changeOperatorAdd(e: any, i: any) {
    this.filters.map((item: any, index: any) => {
      if (!this.Array.isArray(item) && index != 0) {
        item.key4 = e;
      } else {
        if (this.Array.isArray(item)) {
          for (let i of item) {
            if (!this.Array.isArray(i)) {
              i.key4 = !e;
            } else {
              if (this.Array.isArray(i)) {
                for (let j of i) {
                  j.key4 = e;
                }
              }
            }
          }
        }
      }
    });
  }

  filterTypeFieldChange(event: any, i: any,entityName:any) {
    const fields = this.getFields('',entityName);
    const selectedField = fields?.find((item: any) => item?.name === event);
    if (selectedField) {
      if (
        [
          'dropdown_single_select',
          'people',
          'dropdown_multi_select',
          'status',
          'tag','priority',
        ].includes(selectedField?.datatype)
      ) {
        this.filters[i].key3 = [];
      }
      if (
        [
          'currency',
          'progress',
          'number',
          'ratings',
          'phone'
        ].includes(selectedField?.datatype)
      ) {
        this.filters[i].key3 = null;
      }
      this.filters[i].key2 =
        this.getOperators(this.filters[i]?.key1,entityName)?.[0]?.value || '';
    }
  }
  totalFields: any;
  searchField: any = '';
  getFields(searchField: any,entityName:any): any {
    let entity_Name= this.entitiesData?.find((_e:any)=>_e?.field_name === entityName)?.entity_name;
    const fields =
      this.erulistData.entities?.[
        this.erulistData.entities?.findIndex(
          (e: any) => e.name === entity_Name
        )
      ]?.fields;
    this.totalFields = fields;
    let filterFields = fields
      ?.filter((e: any) => e?.datatype !== 'entity' && e?.datatype !== 'textarea' && e?.datatype !== 'attachment')
      ?.sort((a: any, b: any) => a.label?.localeCompare(b.label));
    if (filterFields && searchField !== '') {
      return filterFields?.filter((e: any) =>
        e.label?.toLowerCase()?.includes(searchField?.toLowerCase())
      );
    } else {
      return filterFields;
    }
  }

  getOperators(field: any,entityName:any): any {
    const fields = this.getFields('',entityName);
    const selectedtype = fields?.find(
      (item: any) => item?.name === field
    )?.datatype;
    const operators = this.datatypesOperator[selectedtype];
    return operators;
  }

  deleteItem(i: any) {
    this.filters.splice(i, 1);
  }

  changegroupOperatorAdd(e: any, i: any, j: any) {
    this.filters[i].map((item: any, index: any) => {
      if (!this.Array.isArray(item)) {
        item.key4 = e;
      }
    });
  }

  filterTypeFieldChangeGroups(event: any, i: any, j: any,entityName:any) {
    const fields = this.getFields('',entityName);
    const selectedField = fields?.find((item: any) => item?.name === event);
    if (selectedField) {
      if (
        [
          'dropdown_single_select',
          'people',
          'dropdown_multi_select',
          'status',
          'tag','priority',
        ].includes(selectedField?.datatype)
      ) {
        this.filters[i][j].key3 = [];
      }
      if (
        [
          'currency',
          'progress',
          'number',
          'ratings',
          'phone'
        ].includes(selectedField?.datatype)
      ) {
        this.filters[i][j].key3 = null;
      }
    }
    this.filters[i][j].key2 = '';
  }

  deleteGroupFilter(item: any, index: any) {
    if (this.filters[item].length > 1) {
      this.filters[item].splice(index, 1);
    } else {
      const index = this.filters.splice(item, 1);
    }
  }

  filterTypeFieldChangeChildGroups(event: any, i: any, j: any, k: any,entityName:any) {
    const fields = this.getFields('',entityName);
    const selectedField = fields?.find((item: any) => item?.name === event);
    if (selectedField) {
      if (
        [
          'dropdown_single_select',
          'people',
          'dropdown_multi_select',
          'status',
          'tag','priority',
        ].includes(selectedField?.datatype)
      ) {
        this.filters[i][j][k].key3 = [];
      }
      if (
        [
          'currency',
          'progress',
          'number',
          'ratings',
          'phone'
        ].includes(selectedField?.datatype)
      ) {
        this.filters[i][j][k].key3 = null;
      }
    }
    this.filters[i][j][k].key2 = '';
  }

  deleteChildGroup(i: any, j: any, k: any) {
    if (this.filters[i][j].length > 1) {
      this.filters[i][j].splice(k, 1);
    } else {
      this.filters[i].splice(j, 1);
    }
  }

  addNewChildFilter(i: any, j: any) {
    this.filters[i][j].push({ key1: '', key2: '', key3: '', key4: 'AND',entity_name: this.tabName });
  }

  clearChildGroup(i: any, j: any) {
    this.filters[i].splice(j, 1);
  }

  addNewGroupFilter(i: any) {
    this.filters[i].push({
      key1: '',
      key2: '',
      key3: '',
      key4: this.filters[i][0].key4,entity_name: this.tabName
    });
  }

  addChildGroup(i: any) {
    this.filters[i].push([
      {
        key1: '',
        key2: '',
        key3: '',
        key4: this.filters[i][0].key4,entity_name: this.tabName
      },
    ]);
  }

  clearGroup(index: any) {
    this.filters.splice(index, 1);
  }

  addNewFilter() {
    this.filters.push({
      key1: '',
      key2: '',
      key3: '',
      key4: this.filters[0].key4,entity_name: this.tabName
    });
  }

  addGroup() {
    this.filters.push([
      { key1: '', key2: '', key3: '', key4: !this.filters[0].key4,entity_name: this.tabName },
    ]);
  }

  saveFilter() {
    this.onSaveFilter.emit(true);
  }
  findItsDataTypeValue(field: string,entityName:any) {

    let entity_Name = this.entitiesData?.find((_e:any)=>_e?.field_name === entityName)?.entity_name;
    let headersField = this.getFields('',entityName)
    let fieldInfo = headersField?.find((e: any) => e?.name == field);
    return fieldInfo;
  }

  emojiType: any = [
    {
      value: '(smile)',
      checked: false,
      img1: '../../../../assets/images/addNewFieldIcons/whiteSmiley.svg',
      img2: '../../../../assets/images/addNewFieldIcons/blueSmiley.svg',
    },
    {
      value: '(tick)',
      checked: false,
      img1: '../../../../assets/images/addNewFieldIcons/whiteTick.svg',
      img2: '../../../../assets/images/addNewFieldIcons/blueTick.svg',
    },
    {
      value: '(like)',
      checked: false,
      img1: '../../../../assets/images/addNewFieldIcons/whiteLike.svg',
      img2: '../../../../assets/images/addNewFieldIcons/blueLike.svg',
    },
    {
      value: '(star)',
      checked: false,
      img1: '../../../../assets/images/addNewFieldIcons/whitestar.svg',
      img2: '../../../../assets/images/addNewFieldIcons/bluestar.svg',
    },
  ];
  getEmojiImage(value: any, data: any) {
    let result = this.emojiType.find((e: any) => e.value === value);
    if (result) {
      return data === 'blue' ? result.img1 : result.img2;
    } else {
      return data === 'blue' ? this.emojiType[0].img1 : this.emojiType[0].img2;
    }
  }
  getHoverValue(c: any, i: any) {
    if (i + 1 <= c.hoverValue) {
      return true;
    } else {
      return false;
    }
  }

  getEntityptionList$: any = new Observable();
  openDropDownEntityChange(f: any, search = '', event?: any) {
    if (event) {
      let body = {
        org_id: this.state?.selectedOrganization.org_id,
        process_id: this.state?.selectedProcess.process_id,
        entity_name: f.entity_name,
        process_name: this.state?.selectedProcess.process_name.replace(
          / /g,
          '_'
        ),
        field_name: f.field_name,
        limit: 50,
        skip: 0,
        field_str: search,
      };
      this.getEntityptionList$ = this.parent.pservice
        .getentityfielddata(body)
        .pipe(
          shareReplay(),
          map((r: any) => {
            return r?.[0]?.entity_data;
          })
        );
    }
  }

  getApiOptionList$: any = new Observable();
  getPeopleOptionList$: any = new Observable();
  saveApiOption: any;
  openDropDownAPIChange(f: any, search = '', event?: any) {
    if (event) {
      let body = {
        org_id: this.state?.selectedOrganization.org_id,
        api_name: f.api_name,
        process_id: this.state?.selectedProcess.process_id,
        entity_id:[]
      };
      this.getApiOptionList$ = this.parent.pservice.getApiFields(body).pipe(
        map((res: any) => {
          return res?.org
            .map((item: any) => item[f.api_field])
            .sort((a: any, b: any) =>
              a?.toLowerCase().localeCompare(b?.toLowerCase())
            );
        })
      );
      this.saveApiOption = this.getApiOptionList$;
    }
  }
  filterApiOptions(value: string) {
    this.getApiOptionList$ = this.saveApiOption.pipe(
      map((options: any) =>
        options.filter((option: any) =>
          option.toLowerCase().includes(value.toLowerCase())
        )
      )
    );
  }
  savePeopleList: any;
  openDropDownPeopleChange(f: any, search = '', event?: any) {
    if (event) {
      let body = {
        org_id: this.state?.selectedOrganization.org_id,
        api_name: 'People',
        process_id: this.state?.selectedProcess.process_id,
        entity_id:[]
      };
      this.getPeopleOptionList$ = this.parent.pservice.getApiFields(body).pipe(
        map((res: any) => {
          return res?.org.map((item: any) => item[f.api_field]);
        })
      );
      this.savePeopleList = this.getPeopleOptionList$;
    }
  }
  filterpeopleOptions(value: string) {
    this.getPeopleOptionList$ = this.savePeopleList.pipe(
      map((options: any) =>
        options.filter((option: any) =>
          option.toLowerCase().includes(value.toLowerCase())
        )
      )
    );
  }
  statusInput: any = '';
  getStatusOptions(o: any, c: any, search: any) {
    let combinedStatus = [...new Set([...o, ...c])];
    if (search !== '') {
      return combinedStatus?.filter((e: any) =>
        e.name?.toLowerCase()?.includes(search?.toLowerCase())
      );
    }
    return combinedStatus;
  }
  selectedIndex: any;
  clearFilters() {
    // if(this.selectedIndex === 2){
    //   this.clearStatusFilter()
    //   return
    // }
    let filter = this.filters
      .map((data: any) => {
        if (data.key1 !== '' && data.key3 !== '') {
          return {
            key1: data.key1,
            key2: data.key2,
            key3: data.key3,
            key4: data.key4,
          };
        } else {
          return null;
        }
      })
      .filter((data: any) => data !== null);
    if (this.statusFilter?.length !== 0) {
      this.resetFilters();
      return;
    }
    if (filter.length === 0) {
      return;
    } else {
      this.resetFilters();
    }
  }
  resetFilters() {
    let save_layout_filter ={
      a_status:{},
      filter:[],
      pFilter:[]
    }
    this.parent.pservice.updateStoreLayoutMetadata(save_layout_filter,'filter')
    this.statusValue = null;
    this.statusFilter = null;
    if(this.setStatusFilterData){
      this.setStatusFilterData.filter=null
      this.setStatusFilterData.filterValue=null
    }
    this.SelectedFieldLog = 'New Record';
    this.numberOfDays = '';
    this.filters = [{ key1: '', key2: '', key3: '', key4: true }];
    this.resetFilter.emit(true);
    this.saveFilter();

    this.approvalStatus = '';

  }
  numberOfDays: any;
  SelectedFieldLog: any = 'New Record';
  saveLogFilter() {
    this.tableService;
    let body = {
      org_id: this.state?.selectedOrganization.org_id,
      process_id: this.state?.selectedProcess.process_id,
      entity_name: this.tabName,
      process_name: this.state?.selectedProcess?.process_name?.replace(
        /\s/g,
        '_'
      ),
      limit: 50,
      skip: 0,
      sort: [],
      field_name: this.SelectedFieldLog,
      no_of_days: this.numberOfDays,
    };
    this.logFilterData.emit(body);
    this.SelectedFieldLog = 'New Record';
    this.numberOfDays = '';
  }
  saveTabeName: any;
  @Input()setStatusFilterData:any
  ngOnChanges(changes: SimpleChanges): void {

    if (this.tabName && changes) {
      this.SelectedFieldLog = 'New Record';
      this.numberOfDays = '';

    }
    if (this.isSelectedMoreDefault !== this.isSelectedMore) {
      this.statusValue = null;
      this.approvalStatus = '';
      this.statusFilter = [];
    }
    this.isSelectedMoreDefault = this.isSelectedMore;
    // if (this.tableMetaData?.has_am) {

    //     this.statusFilterOption = [
    //       {
    //         label: 'PENDING APPROVAL',
    //         value: 'PENDING APPROVAL',
    //       },
    //       {
    //         label: 'PARTIALLY APPROVED',
    //         value: 'PARTIALLY APPROVED',
    //       },

    //       {
    //         label: 'REJECTED',
    //         value: 'REJECTED',
    //       },
    //       {
    //         label: 'DRAFT',
    //         value: 'DRAFT',
    //       },

    //     {
    //       label: 'APPROVED',
    //       value: 'APPROVED',
    //     },
    //     {
    //       label: 'ERRORS',
    //       value: 'ERRORS',
    //     },
    //   ];
    // }
    // if(this.setStatusFilterData?.tabName !== this.tabName ){
    //         if(this.setStatusFilterData){
    //     this.setStatusFilterData.filter=null
    //     this.setStatusFilterData.filterValue=null
    //   }
    // }
    if(this.setStatusFilterData){
      this.statusValue = this.setStatusFilterData?.filterValue==='error'?'':this.setStatusFilterData?.filterValue
      this.statusFilter = this.setStatusFilterData?.filter
    }
  }
  clear_record_status(){
    this.statusValue = null
    this.statusFilter =null
  }
  tabChange(e: any) {
    this.selectedIndex = e?.index;
    if (e) {
      this.SelectedFieldLog = 'New Record';
      this.numberOfDays = '';
    }
  }
  @Input()statusValue: any=null
  @Input()statusFilter: any;
  approvalStatus: any;

  flattenArray(arr: any[]): any[] {
    let result: any[] = [];
    arr.forEach((element) => {
      if (Array.isArray(element)) {
        result = result.concat(this.flattenArray(element));
      } else {
        result.push(element);
      }
    });
    return result;
  }

  getPfilterdata(data: any) {
    let FilterData: any = [];
    data.forEach((_element: any) => {
      if (_element.key3 === '' || _element.entity_name === '') {
        return;
      }
      let field = this.entitiesData?.find(
        (_name: any) => _name.field_name === _element?.entity_name
      );
      if (!field?.default) {
        let data = {
          pen: `${this.parent.pservice?.appState()?.selectedProcess?.process_name}_${
            field?.entity_name
          }`.replace(/\s/g, '_'),
          ef: `_${_element.entity_name}`,
        };
        FilterData.push(data);
      }
    });

    return FilterData;
  }
  submitStatus() {
    let data: any = {};
    let filter:any
    if(this.statusValue ===""){
      filter = {
        a_status: this.statusFilter,
      };
    }else{
      data[this.statusValue] = this.statusFilter;
      filter = {
        a_status: data,
      };
    }
    let filterData = this.flattenArray(this.filters);
    let pFilter = this.getPfilterdata(filterData);
    let a_status_value:any = {}
    a_status_value[this.statusValue===''?'$eq':this.statusValue] = this.statusFilter;
    let save_layout_filter ={
      a_status:a_status_value,
      filter:this.filters,
      pFilter:pFilter
    }
    this.parent.pservice.updateStoreLayoutMetadata(save_layout_filter,'filter')
    if(this.isSelectedMore === 'for_my_approval'){
      let datas = {
        filterValue: this.statusValue,
        filter: this.statusFilter,
      };
      // this.parent.pservice.updateStoreMetadata(datas, 'ma_status');
    }
    if(this.isSelectedMore === 'my_drafts'){
      let datas = {
        filterValue: this.statusValue,
        filter: this.statusFilter,
      };
      // this.parent.pservice.updateStoreMetadata(datas, 'draft_status');
    }
    if(this.isSelectedMore === 'approved_records'){
      let datas = {
        filterValue: this.statusValue,
        filter: this.statusFilter,
      };
      // this.parent.pservice.updateStoreMetadata(datas, 'a_status');
    }
  if(filter){
    this.statusFilters.emit(filter);
    if(this.statusValue && this.statusFilter){
      if(!this.setStatusFilterData){
        this.setStatusFilterData = {
          filterValue:'',
          filter:''
        }
      }
      
      this.setStatusFilterData.filterValue = this.statusValue;
      this.setStatusFilterData.filter =  this.statusFilter
    }

  }else{
    this.onSaveFilter.emit(true);
    if(this.statusValue && this.statusFilter){
      this.setStatusFilterData.filterValue = this.statusValue;
      this.setStatusFilterData.filter =  this.statusFilter
    }
  }

  }
  clearStatusFilter() {
    this.statusValue = '';
    this.statusFilter = [];
    this.approvalStatus = '';
    this.statusFilters.emit({});
  }
  multiStaticInput: any = '';
  getMultiStaticOption(options: any, search: any) {
    if (search !== '') {
      return options?.filter((e: any) =>
        e?.name?.toLowerCase().includes(search)
      );
    }
    return options;
  }
  priority: any = [
    {
      name: 'Urgent',
      color: 'rgb(227, 131, 136)',
    },
    {
      name: 'High',
      color: 'rgb(251, 203, 92)',
    },
    {
      name: 'Normal',
      color: 'rgb(135, 157, 255)',
    },
    {
      name: 'Low',
      color: 'rgb(101, 111, 125)',
    },
  ];
  getColor(value: any) {
    let color = this.priority.find((e: any) => e.name === value).color;
    return color || '';
  }
  convertToString(value: any) {
    return value?.toString();
  }

  //time
  timerRange1: any;
  timerRange2: any;
  manualTime: any;
  selectedRTimeTabIndex: any = 0;
  defaultOpenValue = new Date(0, 0, 0, 0, 0, 0);
  getTimeValue(d: any) {
    let totalMinutes = Number(d.key3);
    let hours = Math.floor(totalMinutes / 60);
    let remainingMinutes = (totalMinutes - hours * 60) % 60;
    if (remainingMinutes > 60) {
      hours += Math.floor(remainingMinutes / 60);
      remainingMinutes %= 60;
    }

    return `${(hours || 0).toString().padStart(1, '0')} hr ${(
      remainingMinutes || 0
    )?.toFixed(0)} min`;
  }

  onOpenTime(d: any, f: any) {
    this.manualTime = (Number(f.key3) || 0).toFixed(0) || 0;
  }
  saveTime(filter: any) {
    filter.key3 = this.manualTime;
    this.manualTime = null;
  }
  selectedEntities($event: any,filter:any) {
      if($event){
        filter.key1=''
        filter.key2=''
        filter.key3=''
        filter.key4=true
      }
  }
}
