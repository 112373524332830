<ng-container>
<div [style.pointerEvents]="!header.can_edit || data?.is_locked || data?.lock_fields?.includes(this.header?.name)?'none':'auto'">
  <div fluidRowWidth nz-dropdown nzTrigger="click"  (nzVisibleChange)="event($event)" (click)="saearhx2.value = '';filterpriorityDefault('')"
  [nzPlacement]="'bottomCenter'" [nzDropdownMenu]="menu" style="font-size: 12px;">
    <span  nz-icon nzType="flag" [nzTheme]="data[header.name]?'fill' :header.default===''?'outline':'fill' "
    [style.color]="getColor((data[header.name]?data[header.name] :header.default))"></span> {{(data[header.name]?data[header.name] :header.default) || ''}}
  </div>
  <nz-dropdown-menu #menu="nzDropdownMenu">
   
    <div [hidden]="!close">
      <input #saearhx2 nz-input placeholder="Search..." (input)="filterpriorityDefault(saearhx2.value)" />
      <ul nz-menu>
        <ng-container *ngFor="let item of header?.options">
          <li nz-menu-item (click)="selectOption(item.name);data[header.name] =item.name ">
            <div>
              <span nz-icon nzType="flag" nzTheme="fill" [style.color]="getColor(item.name)"></span>
              <span style="margin-left: 5px;pointer-events: auto;">{{item.name}}</span>
            </div>
          </li>
        </ng-container>
      </ul>
    </div>

    <!-- <div class="clearInput">
      <nz-input-group [nzPrefix]="prefixTemplateUser" (click)="removePriority();data[header.name] =''" >
        <input type="text" nz-input [(ngModel)]="clearInput" readonly/>
      </nz-input-group>
    </div> -->
    <ng-template #prefixTemplateUser><span nz-icon nzType="close-circle" nzTheme="outline"></span></ng-template>

  </nz-dropdown-menu>
</div>
</ng-container>