import { Component, HostBinding, Input } from '@angular/core';
import { generateUUID } from 'projects/processo-custom-table/src/usecase';

@Component({
  selector: 'lib-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss'],
})
export class ProgressComponent {
  @HostBinding('attr.unique-id')
  uniqueId = `${generateUUID()}`;
  @Input() innerTable: any;
  @Input() header: any;
  @Input() i: any;
  @Input() data: any;
  @Input() parent: any;
  changeProgress(e: any) {
    let tableEdit: any;
    if (this.innerTable) {
      tableEdit = 'innerTable';

      this.parent.updateDataOnInnerStopEdit(
        { target: { value: e } },
        this.header.name,
        this.data
      );
    } else {
      this.parent.updateDataOnStopEdit(
        { target: { value: e } },
        this.header.name,
        this.data,
        this.parent.erulistData
      );
    }
    this.parent.saveEntityDropdownData(this.header, tableEdit, this.data);
  }
}
