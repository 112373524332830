<ng-container *ngFor="let item of filterby; let i = index; let last = last">
  <ng-container *ngIf="!Array.isArray(item)">
    <nz-row [nzGutter]="16">
      <nz-col [nzSpan]="12" class="mt-10">
        <nz-select
          class="w-100"
          nzPlaceHolder="Select field"
          [nzDropdownClassName]="'reverse_ant-select-dropdown'"
          [nzDropdownRender]="selectSearchTemplateField"
          [(ngModel)]="item.entity_name"
          (ngModelChange)="
            selectedEntities($event, item);
            call_save($event, item, item.entity_name)
          "
        >
          <ng-template #selectSearchTemplateField> </ng-template>
          <ng-container *ngFor="let option of entitiesData">
            <ng-container *ngIf="option.label">
              <nz-option
                nzValue="{{ option?.field_name }}"
                nzLabel="{{ option?.label }}"
              ></nz-option>
            </ng-container>
          </ng-container>
        </nz-select>
      </nz-col>
      <nz-col [nzSpan]="12" class="mt-10">
        <nz-select
          class="w-100"
          [(ngModel)]="item.key1"
          (ngModelChange)="
            filterTypeFieldChange($event, i, item.entity_name);
            call_save($event, item, item.key1)
          "
          nzPlaceHolder="Select field"
          [nzDropdownClassName]="'reverse_ant-select-dropdown'"
          [nzDropdownRender]="selectSearchTemplateField"
        >
          <ng-template #selectSearchTemplateField> </ng-template>
          <ng-container *ngFor="let option of getFields('', item.entity_name)">
            <ng-container *ngIf="option.label">
              <nz-option
                nzValue="{{ option?.name }}"
                nzLabel="{{ option?.label }}"
              ></nz-option>
            </ng-container>
          </ng-container>
        </nz-select>
      </nz-col>
      <nz-col [nzSpan]="12" class="mt-10">
        <nz-select
          [(ngModel)]="item.key2"
          (ngModelChange)="call_save($event, item, item.key2)"
          class="w-100"
          nzPlaceHolder="Select field"
          [nzDropdownClassName]="'reverse_ant-select-dropdown'"
          [nzDropdownRender]="selectSearchTemplateField"
        >
          <ng-template #selectSearchTemplateField> </ng-template>
          <ng-container
            *ngFor="let item of getOperators(item?.key1, item.entity_name)"
          >
            <ng-container *ngIf="item.label">
              <nz-option
                nzValue="{{ item?.value }}"
                nzLabel="{{ item?.label }}"
              ></nz-option>
            </ng-container>
          </ng-container>
        </nz-select>
      </nz-col>
      <nz-col [nzSpan]="12" class="mt-10">
        <!-- <ng-template
          class="w-100"
          [ngTemplateOutlet]="valueOutlet"
          [ngTemplateOutletContext]="{ filterby: item }"
        ></ng-template> -->
        <!-- <input [(ngModel)]="item.key3" nz-input nzPlaceHolder="in" class="w-100" /> -->
        <nz-select
          [(ngModel)]="item.key3"
          [disabled]="!(userFields.length > 0)"
          (ngModelChange)="call_save($event, item, item.key3)"
          class="w-100"
          [nzMode]="option_multi?'multiple':'default'"
          nzPlaceHolder="Select field"
          [nzDropdownClassName]="'reverse_ant-select-dropdown'"
          [nzDropdownRender]="selectSearchTemplateField"
        >
          <ng-template #selectSearchTemplateField> </ng-template>
          <ng-container *ngFor="let item of userFields">
            <ng-container *ngIf="item.label">
              <nz-option
                nzValue="{{ item?.name }}"
                nzLabel="{{ item?.label }}"
              ></nz-option>
            </ng-container>
          </ng-container>
        </nz-select>
      </nz-col>
      <ng-container *ngIf="filterby?.length > 1">
        <nz-col [nzSpan]="24" class="mt-10 text-end color_blue f-12 fw-400">
          <span class="cPointer" (click)="filterby.splice(i, 1); submit()"
            >Delete</span
          >
        </nz-col>
      </ng-container>
      <ng-container *ngIf="!last">
        <nz-col [nzSpan]="24" class="mt-10 text-center">
          <nz-switch
            (ngModelChange)="changeOperatorAdd($event, i)"
            [(ngModel)]="filterby[0].key4"
            [disabled]="i != 0"
            nzCheckedChildren="AND"
            nzUnCheckedChildren="OR"
          ></nz-switch>
        </nz-col>
      </ng-container>
    </nz-row>
  </ng-container>
  <ng-container *ngIf="Array.isArray(item)">
    <div
      style="
        width: 100%;
        background-color: #f2f2f2;
        padding: 10px;
        margin-top: 10px;
        border-radius: 8px;
      "
    >
      <ng-container
        *ngFor="let childFilter of item; let j = index; let final = last"
      >
        <nz-row [nzGutter]="16">
          <nz-col [nzSpan]="12" class="mt-10">
            <nz-select
              class="w-100"
              nzPlaceHolder="Select field"
              [nzDropdownClassName]="'reverse_ant-select-dropdown'"
              [nzDropdownRender]="selectSearchTemplateField"
              [(ngModel)]="childFilter.entity_name"
              (ngModelChange)="
                selectedEntities($event, item);
                call_save($event, childFilter, childFilter.entity_name)
              "
            >
              <ng-template #selectSearchTemplateField> </ng-template>
              <ng-container *ngFor="let option of entitiesData">
                <ng-container *ngIf="option.label">
                  <nz-option
                    nzValue="{{ option?.field_name }}"
                    nzLabel="{{ option?.label }}"
                  ></nz-option>
                </ng-container>
              </ng-container>
            </nz-select>
          </nz-col>
          <nz-col [nzSpan]="12" class="mt-10">
            <nz-select
              class="w-100"
              [(ngModel)]="childFilter.key1"
              (ngModelChange)="
                filterTypeFieldChangeGroups(
                  $event,
                  i,
                  j,
                  childFilter.entity_name
                );
                call_save($event, childFilter, childFilter.key1)
              "
              nzPlaceHolder="Select field"
              [nzDropdownClassName]="'reverse_ant-select-dropdown'"
              [nzDropdownRender]="selectSearchTemplateField"
            >
              <ng-template #selectSearchTemplateField> </ng-template>
              <ng-container
                *ngFor="let option of getFields('', childFilter.entity_name)"
              >
                <ng-container *ngIf="option.label">
                  <nz-option
                    nzValue="{{ option?.name }}"
                    nzLabel="{{ option?.label }}"
                  ></nz-option>
                </ng-container>
              </ng-container>
            </nz-select>
          </nz-col>
          <nz-col [nzSpan]="12" class="mt-10">
            <nz-select
              [(ngModel)]="childFilter.key2"
              (ngModelChange)="call_save($event, childFilter, childFilter.key2)"
              class="w-100"
              nzPlaceHolder="Select field"
              [nzDropdownClassName]="'reverse_ant-select-dropdown'"
              [nzDropdownRender]="selectSearchTemplateField"
            >
              <ng-template #selectSearchTemplateField> </ng-template>
              <ng-container
                *ngFor="
                  let item of getOperators(
                    childFilter?.key1,
                    childFilter.entity_name
                  )
                "
              >
                <ng-container *ngIf="item.label">
                  <nz-option
                    nzValue="{{ item?.value }}"
                    nzLabel="{{ item?.label }}"
                  ></nz-option>
                </ng-container>
              </ng-container>
            </nz-select>
          </nz-col>
          <nz-col [nzSpan]="12" class="mt-10">
            <!-- <ng-template
              class="w-100"
              [ngTemplateOutlet]="valueOutlet"
              [ngTemplateOutletContext]="{ filterby: item }"
            ></ng-template> -->
            <!-- <input [(ngModel)]="item.key3" nz-input nzPlaceHolder="in" class="w-100" /> -->
            <nz-select
              [(ngModel)]="childFilter.key3"
              (ngModelChange)="call_save($event, childFilter, childFilter.key3)"
              class="w-100"
              [disabled]="!(userFields.length > 0)"
              nzPlaceHolder="Select field"
              [nzDropdownClassName]="'reverse_ant-select-dropdown'"
              [nzDropdownRender]="selectSearchTemplateField"
              [nzMode]="option_multi?'multiple':'default'"
            >
              <ng-template #selectSearchTemplateField> </ng-template>
              <ng-container *ngFor="let item of userFields">
                <ng-container *ngIf="item.label">
                  <nz-option
                    nzValue="{{ item?.name }}"
                    nzLabel="{{ item?.label }}"
                  ></nz-option>
                </ng-container>
              </ng-container>
            </nz-select>
          </nz-col>
          <ng-container>
            <nz-col [nzSpan]="24" class="mt-10 text-end color_blue f-12 fw-400">
              <span class="cPointer" (click)="deleteGroupFilter(i, j)"
                >Delete</span
              >
            </nz-col>
          </ng-container>
          <ng-container *ngIf="!final">
            <nz-col [nzSpan]="24" class="mt-10 text-center">
              <nz-switch
                [(ngModel)]="item[0].key4"
                [disabled]="i != 0"
                nzCheckedChildren="AND"
                nzUnCheckedChildren="OR"
              ></nz-switch>
            </nz-col>
          </ng-container>
        </nz-row>
      </ng-container>
      <ng-container>
        <div
          class="f-12 fw-400 mt-20 color_blue"
          style="column-gap: 15px; display: flex"
        >
          <span class="cPointer" (click)="addNewGroupFilter(i)">++Filter</span>
        </div>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
<ng-container>
  <div
    class="f-12 fw-400 mt-20 color_blue"
    style="column-gap: 15px; display: flex"
  >
    <span class="cPointer" (click)="addFilterOne()">++Filter</span>
    <span class="cPointer" (click)="addGroupOne()">++Group</span>
  </div>
</ng-container>
