import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from './core/authentication/auth.guard';
import { ViewNewOrganizationComponent } from './modules/view-new-organization/view-new-organization.component';
import { LoadingComponent } from './modules/registeration/components/loading/loading.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'loading',
    component: LoadingComponent,
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./modules/registeration/registeration.module').then(
        (m) => m.RegisterationModule,
      ),
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./modules/forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordModule,
      ),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./modules/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'onboarding',
    canActivate: [authGuard],
    loadChildren: () =>
      import('./modules/onboarding/onboarding.module').then(
        (m) => m.OnboardingModule,
      ),
  },
  {
    path: 'dashboard',
    canActivate: [authGuard],
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule,
      ),
  },

  {
    path: 'profile',
    loadChildren: () =>
      import('./modules/profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'organization',
    loadChildren: () =>
      import('./modules/organization/organization.module').then(
        (m) => m.OrganizationModule,
      ),
  },

  {
    path: 'view-organization',
    loadChildren: () =>
      import(
        './modules/view-new-organization/view-new-organization.module'
      ).then((m) => m.ViewNewOrganizationModule),
  },
  {
    path: '**',
    redirectTo: '/login',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
