import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { generateUUID } from 'projects/processo-custom-table/src/usecase';

@Component({
  selector: 'lib-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent implements OnInit {
  @Input() header: any;
  @Input() i: any;
  @Input() data: any;
  @Input() parent: any;
  @Input() innerTable: any;
  ngOnInit(): void {
    this.data[this.header.name] =  this.data[this.header.name] === true?true:false
    if (this.header.mandatory == true) {        
      this.data[this.header.name] = this.header?.default === true?true:false;
    }
  }
  @HostBinding('attr.unique-id')
  uniqueId = `${generateUUID()}`;

  getchec(e: any) {
    let tableEdit: any;
    if (this.innerTable) {
      tableEdit = 'innerTable';
      this.parent.updateDataOnInnerStopEdit(
        { target: { value: `${e}` } },
        this.header.name,
        this.data
      );
    } else {
      this.parent.updateDataOnStopEdit(
        { target: { value: `${e}` } },
        this.header.name,
        this.data,
        this.parent.erulistData
      );
    }
    this.parent.saveEntityDropdownData(this.header, tableEdit, this.data);
  }
}
