<div class="sidebar showSideBar">
  <div class="lb-bgcolor w80 text-center sidebar-wrapper" style="height: 100%">
    <div class="processo" (click)="home()">
      <img
        class="cPointer"
        class="side_logo" [src]="environment.is_artfine?'../../../../assets/images/logo/ArtfineBilzSideLogo.svg':'../../../../../assets/images/processo-icon.svg'"
      />
    </div>
    <ng-container *ngIf="checkRoutes()">
      <div class="mt-20" style="overflow-y: auto; max-height: 70dvh;z-index: 10001;
    position: relative;">
        <ng-container *ngFor="let item of getProcess() || []">
          <ng-container *ngIf="item.process_name">
          <div
          class="processContainers"
          
          (click)="selectedProcess(item)"
          [ngClass]="currentProcessSelected(item) ? 'bgColorBlue' : ''"
          nz-tooltip
          [nzTooltipOverlayClassName]="'toolTipContainer'"
          [nzTooltipTitle]="item.d_process_name"
          nzTooltipPlacement="right"
        >
          <img src="../../../../assets/images/logo/processImg.svg" *ngIf="!item?.icon"/>
          <img [src]="'data:image/svg+xml;base64,' + item.icon" *ngIf="item?.icon" style="width: 48px;height: 48px;"/>
        </div>
      </ng-container>
        </ng-container>

      </div>
    </ng-container>
    <div
      style="
        position: absolute;
        bottom: 5%;
        width: 100%;
        z-index: 10001;
      "
    >
      <div
        class="signout cPointer"
        nz-tooltip
        [nzTooltipOverlayClassName]="'signOutToolTipContainers'"
        [nzTooltipTitle]="'Sign Out'"
        nzTooltipPlacement="right"
      >
        <img
          src="../../../../assets/images/icons/power.png"
          (click)="signOut()"
        />
      </div>
      <div class="login">
        <a nz-dropdown [nzDropdownMenu]="menu" nzTrigger="click">
          <div class="profileLogoContainer fw-400 f-12">
            {{ firstName }}{{ lastName }}
          </div>
        </a>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu class="sideBarContainer">
            <li
              style="padding: 10px 12px !important"
              nz-menu-item
              (click)="gotoOrganization()"
            >
              Organizations
            </li>
            <li
              style="padding: 10px 12px !important"
              nz-menu-item
              (click)="gotoProfileSettings()"
            >
              Profile Settings
            </li>
            <nz-divider style="margin: 0 !important; background: #00000030">
            </nz-divider>
            <li
              class="fw-600"
              style="cursor: default; padding: 10px 12px !important"
            >
              {{ fullName }}
            </li>
          </ul>
        </nz-dropdown-menu>
      </div>
    </div>
  </div>
</div>
