import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgZorroAntdModule } from './shared/ng-zorro-antd.module';
import { NgOtpInputModule } from 'ng-otp-input';
import { AuthInterceptor } from './core/authentication/auth.interceptor';
import { LoadingSpinnerComponent } from './shared/components/loading-spinner/loading-spinner.component';
import { OrganisationComponent } from './modules/onboarding/organisation/organisation.component';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { ProfileComponent } from './modules/profile/profile.component';
import { SharedModule } from './shared/shared.module';
import { ProcessoCustomTableModule } from 'projects/processo-custom-table/src/public-api';
import { appInitializer } from './core/helpers/app.initializer';
import { TokenService } from './services/token.service';
import { AuthService } from './core/authentication/auth.service';
import { ViewNewOrganizationComponent } from './modules/view-new-organization/view-new-organization.component';
import { provideUserIdleConfig, UserIdleModule } from 'angular-user-idle';
import { environment } from '../environments/environment';

registerLocaleData(en);

@NgModule({
  declarations: [AppComponent, ViewNewOrganizationComponent],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [TokenService, AuthService],
    },
    provideUserIdleConfig({
      idle: environment.idleTime,
      timeout: environment.timeout,
      ping: environment.ping,
    })
    
  ],
  bootstrap: [AppComponent],
  imports: [
    LoadingSpinnerComponent,
    BrowserModule,
    ProcessoCustomTableModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgZorroAntdModule,
    NgOtpInputModule,
    SharedModule,
    // UserIdleModule.forRoot({
      // idle: environment.idleTime,
      // timeout: environment.timeout,
      // ping: 200,
    // }),
  ],
})
export class AppModule {}
