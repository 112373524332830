import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { generateUUID } from 'projects/processo-custom-table/src/usecase';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'lib-input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputFieldComponent implements OnInit, OnChanges, DoCheck {
  @Input() innerTable: any;
  @Input() header: any;
  @Input() data: any;
  @Input() parent: any;
  ngOnInit(): void {
    // this.header.error=''
  }
  @HostBinding('attr.unique-id')
  uniqueId = `${generateUUID()}`;
  editval$: any;
  editval = new BehaviorSubject<boolean>(false);
  constructor(private cdr: ChangeDetectorRef) {
    this.editval$ = this.editval;
  }
  ngDoCheck(): void {
    if (this.header?.callUniqueApi && this.editval.getValue()) {
      this.checkValidation({ target: { value: this.data[this.header.name] } });
    }
    this.cdr.markForCheck();
  }
  ngOnChanges(changes: SimpleChanges): void {}
  // error = '';
  editing() {
    if (!this.header.can_edit || this.data?.is_locked || this.data?.lock_fields?.includes(this.header?.name)) {
      return;
    }
    if (this.editval.getValue()) {
      this.editval.next(false);
    } else {
      this.editval.next(true);
    }
    this.cdr.markForCheck();
  }
  checkValidation(e: any) {
    this.header.error = this.textboxValidation(this.header, e.target.value);
    if (this.header.datatype === 'textbox' && this.header.is_unique) {
      this.header.callUniqueApi = false;
      let filterData: any = {};
      let body: any = {
        org_id: this.parent.pservice.appState().selectedOrganization.org_id,
        process_id: this.parent.pservice.appState().selectedProcess.process_id,
        process_name: this.parent.pservice
          .appState()
          .selectedProcess.process_name?.replace(/\s/g, '_'),
        entity_name: this.parent.pservice.appState()?.selectedEntity,
        // filter: {
        //   [this.header.name]: this.data[this.header.name],
        // },
        entity_id: this.data?.entity_id,
      };
      filterData[this.header.name] = this.data[this.header.name];
      let data = this.header?.unique_fn?.map((fieldName: any) => {
        if (fieldName) {
          filterData[fieldName] = this.data[fieldName];
        }
      });
      body['filter'] = filterData;
      this.parent.pservice.fetchEntityDataUnique(body).subscribe((res: any) => {
        if (res.exists) {
          this.header.error = `${this.header.label} value already exists`;
          this.cdr.detectChanges();
          return;
        } else {
          let field = this.parent.getTableHeaders();
          let data = this.header?.unique_fn?.map((fieldName: any) => {
            if (fieldName) {
              field.map((_e: any) => {
                if (_e?.name === fieldName) {
                  _e.callUniqueApi = true;
                  _e.error = '';
                }
              });
            }
          });

          this.header.error = '';
          this.saveData(e);
          this.cdr.detectChanges();
        }
      });
    } else {
      this.saveData(e);
    }
  }
  saveData(e: any) {
    if (!this.header.error) {
      let tableEdit: any;
      if (this.innerTable) {
        tableEdit = 'innerTable';

        this.parent.updateDataOnInnerStopEdit(e, this.header.name, this.data);
        this.editval.next(false);
      } else {
        this.parent.updateDataOnStopEdit(
          e,
          this.header.name,
          this.data,
          this.parent.erulistData
        );
        this.editval.next(false);
      }
      this.parent.saveEntityDropdownData(this.header, tableEdit, this.data);
    }
  }
  textboxValidation(field: any, event: any) {
    let inputText = event;
    if (field.mandatory && !inputText) {
      return `${field?.label} is mandatory`;
    }
    let length = Number(field?.data_length);
    if (event?.length > 0) {
      if (field?.data_length_check === 'MAX') {
        if (inputText?.length > length) {
          return `Maximum value is ${field?.data_length}`;
        } else {
          return '';
        }
      } else if (field?.data_length_check === 'MIN') {
        if (inputText?.length < length) {
          return `Minimum value is ${field?.data_length}`;
        } else {
          return '';
        }
      } else if (field?.data_length_check === 'EXACT') {
        if (inputText?.length !== length) {
          return `Exact value is ${field?.data_length}`;
        } else {
          return '';
        }
      } else {
        return '';
      }
    } else {
      return '';
    }
  }
}
