import { TokenService } from '@processo/services/token.service';
import { AuthService } from '../authentication/auth.service';

export function appInitializer(
  tokenService: TokenService,
  authService: AuthService,
) {
  const refresh_token: any = localStorage.getItem('refresh_token');

  return () =>
    new Promise((resolve: any) => {
      if (
        refresh_token &&
        refresh_token != '' &&
        refresh_token != 'undefined'
      ) {
        authService.updateToken().subscribe(()=>{
          resolve(true);
        },()=>{
          resolve('foo');
        });

      } else {
        setTimeout(() => {
          resolve('foo');
        }, 300);
      }
    });
}
