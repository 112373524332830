<div class="px-10 textStyle" fluidRowWidth [type]="header.name">
  <div class="flex-column d-flex min-height-37 align-center">
    <nz-rate
      [nzCharacter]="characterIcon"
      #rate
      [ngModel]="data[header.name]"
      (ngModelChange)="ratingChange($event, data, header.name)"
      [nzCount]="header.end_value"
      [disabled]="!header.can_edit || data?.is_locked || data?.lock_fields?.includes(this.header?.name)"
    >
      <ng-template #characterIcon let-index>
        <img
        style="width: 15px;"
          *ngIf="!getHoverValue(rate, index)"
          [src]="getEmojiImage(header.emoji_value, 'blue')"
        />
        <img
        style="width: 15px"
          *ngIf="getHoverValue(rate, index)"
          [src]="getEmojiImage(header.emoji_value, 'white')"
        />
      </ng-template>
  
    </nz-rate>
  
  
  </div>
</div>

